import React, { useEffect } from "react";
import { useField } from "formik";
import { PartsStore, Query } from "../../generated/nest-graphql";
import { defaultTo, map, prop, descend, contains, sortWith, ascend, concat, isNil, filter } from "ramda";
import { useQuery } from "@apollo/client";
import { GET_PARTS_STORES } from "../../graphql/queries/getPartsStores";
import { flow } from "fp-ts/lib/function";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";
import { GET_TECHNICIANS_HOME_PARTS_STORES } from "../../graphql/queries/getTechniciansHomePartsStores";
import { Checkbox, CircularProgress, FormControlLabel, Typography } from "@material-ui/core";

type PartsStoreOption = {
  label: string;
  value: PartsStore;
};

type PartsStoreOptionType = {
  label: string;
  value: PartsStore;
};

export type PartsStoreFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  homeStore: PartsStore;
  market?: string;
  postOnChange: (homePartsStore: PartsStore) => any;
  latitude?: number;
  longitude?: number;
};

export const JobPartsStoreSelectField = ({
  name,
  label,
  homeStore,
  required = false,
  postOnChange,
  market,
  latitude,
  longitude,
}: PartsStoreFieldProps) => {
  const [field, meta, helpers] = useField(name);

  const [showOnlyForMarket, setShowOnlyForMarket] = React.useState(market ? true : false);
  const [showOnlyHomeStores, setShowOnlyHomeStores] = React.useState(false);
  const currentGeocode = latitude && longitude ? { lat: latitude, longitude: longitude } : null;

  const { loading: loadingPartsStores, data: partsStoresData } = useQuery<Query, {}>(GET_PARTS_STORES, {
    variables: {
      filter: {
        deactivated: { $ne: true },
      },
    },
  });

  const {
    loading: loadingTechsPartsStores,
    data: techsPartsStoresData,
    refetch: refetchTechsPartsStores,
  } = useQuery<Query, {}>(GET_TECHNICIANS_HOME_PARTS_STORES, {
    skip: !showOnlyHomeStores,
    variables: {
      filter: {
        deactivated: { $ne: true },
      },
      referencePoint: currentGeocode
        ? {
            lat: currentGeocode.lat,
            lng: currentGeocode.longitude,
          }
        : undefined,
    },
  });

  useEffect(() => {
    if (showOnlyHomeStores) {
      refetchTechsPartsStores();
    }
  }, [showOnlyHomeStores, latitude, longitude]);

  const homePartsStoreId = prop("id", homeStore);
  const partsStoresToOption = (partsStore: PartsStore) => {
    const { name, id } = partsStore;
    const distanceLabel = (distanceInMeters: number | null) => {
      const distanceInKm = isNil(distanceInMeters) ? null : distanceInMeters / 1000;
      const distanceInMiles = isNil(distanceInKm) ? null : distanceInKm * 0.621371;
      if (isNil(distanceInMiles)) return "";
      if (distanceInMiles > 1000) return " - 1000+ miles";
      if (distanceInMiles < 0.3) return ` - less than 0.3 miles`;
      if (distanceInMiles < 1) return ` - ${distanceInMiles.toFixed(2)} miles`;
      return ` - ${distanceInMiles.toFixed(1)} miles`;
    };
    if (id === homePartsStoreId) {
      return {
        label: `Home Store: ${name}${distanceLabel(partsStore?.distanceFromReference)}`,
        value: partsStore,
      };
    }

    return {
      label: `${name}${distanceLabel(partsStore?.distanceFromReference)}`,
      value: partsStore,
    };
  };
  const filterByMarket = (partsStore: PartsStore) => {
    if (partsStore.id === homePartsStoreId) return true;
    return !market || !showOnlyForMarket || (partsStore.market && partsStore.market.name === market);
  };
  const partsStoresOptions: any = flow(
    filter(filterByMarket),
    map(partsStoresToOption),
    concat([{ value: null, label: "" }]),
    sortWith([
      descend(flow(prop("label"), contains("Home Store"))),
      ...(showOnlyHomeStores && currentGeocode ? [] : [ascend(prop("label") as any)]),
    ])
  )(
    showOnlyHomeStores
      ? techsPartsStoresData?.getTechniciansHomePartsStores || []
      : partsStoresData?.getPartsStores || []
  );

  const filterOptions = createFilterOptions<PartsStoreOptionType>({
    matchFrom: "any",
    stringify: (option) => {
      return option.label;
    },
  });
  const onChange = (_: object, value: any) => {
    helpers.setValue(prop("value", value));
    postOnChange(prop("value", value));
  };

  const shouldRenderLoading = loadingPartsStores || loadingTechsPartsStores || partsStoresOptions.length === 0;
  const renderLoading = () => {
    return (
      <div className="flex flex-row items-center pt-4 pb-2">
        <CircularProgress size={22} className="mr-4" />
        <p>Loading parts stores...</p>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-2 mb-4">
      {shouldRenderLoading ? (
        renderLoading()
      ) : (
        <Autocomplete<PartsStoreOption>
          getOptionSelected={(option, value) => option.label === value.label}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            return defaultTo("", option.label);
          }}
          value={field.value ? partsStoresToOption(field.value) : { value: null, label: "" }}
          options={partsStoresOptions}
          onChange={onChange}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              error={!!meta.error}
              {...params}
              label={label}
              required={required}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
              }}
              helperText={<>{meta.error && <ErrorTextField displayOnSpan={true} fieldName={field.name} />}</>}
            />
          )}
        />
      )}
      <div className="ml-4">
        {market ? (
          <FormControlLabel
            control={
              <Checkbox
                checked={showOnlyForMarket}
                onChange={(_, checked) => {
                  setShowOnlyForMarket(checked);
                }}
              />
            }
            label={<Typography variant="body2">Show {`${market}`} only</Typography>}
          />
        ) : null}
        <FormControlLabel
          control={
            <Checkbox
              checked={showOnlyHomeStores}
              onChange={(_, checked) => {
                setShowOnlyHomeStores(checked);
              }}
            />
          }
          label={<Typography variant="body2">Show Technicians Home Stores by distance</Typography>}
        />
      </div>
    </div>
  );
};
