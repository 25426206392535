import { Job } from "../../generated/nest-graphql";
import { GOOD_CONDITION_OPTION } from "./constants";

export type ColorGradeOption = "Red" | "Yellow" | "Green";

export const ColorGradeMap =( colorGrade?: ColorGradeOption[] | null) => {
  if (colorGrade.includes("Red")) return "Serivice Recommended immediately"
  if (colorGrade.includes("Yellow")) return "Service Soon"
  if (colorGrade.includes("Green")) return "All Systems Go"
  return null;
}

// Shared
export const getConditionColorGrade = (condition: string[]): ColorGradeOption | null => {
  if (!Array.isArray(condition)) return null;
  if (condition.length === 0) return null;
  if (condition.includes(GOOD_CONDITION_OPTION)) return "Green";
  return "Red";
};

// Fluid
export const getFluidPpmColorGrade = (ppm: string | number): ColorGradeOption | null => {
  if (!ppm) return null;
  const ppmNumber = Number(ppm);
  if (ppmNumber < 30) {
    return "Green";
  } else if (ppmNumber < 200) {
    return "Yellow";
  } else if (ppmNumber >= 200) {
    return "Red";
  }
  return null;
};
export const getFluidLevelColorGrade = (level: string): ColorGradeOption | null => {
  if (level === "Full") return "Green";
  if (level === "Not full") return "Red";
  return null;
};

// Pads
export const getPadsThicknessColorGrade = (thickness: string | number): ColorGradeOption | null => {
  if (!thickness) return null;
  const thicknessNumber = Number(thickness);
  if (Number.isNaN(thicknessNumber)) return null;
  if (thicknessNumber <= 3) {
    return "Red";
  } else if (thicknessNumber <= 6) {
    return "Yellow";
  } else if (thicknessNumber > 6) {
    return "Green";
  }
  return null;
};

// Rotors
export const getRotorsThicknessColorGrade = (
  thickness: string | number | null ,
  discardThickness: string | null = null
): ColorGradeOption | null => {
  if (!thickness) return null;
  const thicknessNumber = Number(thickness);
  if (Number.isNaN(thicknessNumber)) return null;

  if (!discardThickness) return null;
  const discardThicknessNumber = Number(discardThickness);
  if (Number.isNaN(discardThicknessNumber)) return null;

  if (thicknessNumber <= discardThicknessNumber) {
    return "Red";
  } else {
    return "Green";
  }
};

interface GetStatusProps  {
  thickness?: string | number,
  condition?: string[],
  colorGrade?: ColorGradeOption,
  job: Job,
  typeName: string,
}

export const getStatus = ({thickness, condition, colorGrade, job, typeName}: GetStatusProps) => {
  let thicknessColorGrade, conditionColorGrade
  if(thickness) {
    if(typeName === "CheckListV2BrakeFluidInfo") {
      thicknessColorGrade = isNaN(Number(thickness)) ? getFluidLevelColorGrade(thickness as string) : getFluidPpmColorGrade(thickness)
    }
    if(typeName === "CheckListV2RotorsInfo") {
      thicknessColorGrade = getRotorsThicknessColorGrade(thickness, job?.vehicleInfo?.frontRotorsDiscardThicknessInches?.toString())
    }
    if(["CheckListV2PadsInfo", "BrakePadsListItem"].includes(typeName)) {
      thicknessColorGrade = getPadsThicknessColorGrade(thickness)
    }
  }
  if(condition) conditionColorGrade = getConditionColorGrade(condition)

  return ColorGradeMap([thicknessColorGrade, conditionColorGrade, colorGrade])
}
