import gql from "graphql-tag";
import { partsStoreDetailsFields } from "../queries/getPartsStore";

export const GET_TECHNICIANS_HOME_PARTS_STORES = gql`
  query getTechniciansHomePartsStores($filter: JSON, $referencePoint: GeocodeInput) {
    getTechniciansHomePartsStores(filter: $filter, referencePoint: $referencePoint) {
      ${partsStoreDetailsFields}
      distanceFromReference
    }
  }
`;
