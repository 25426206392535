import gql from "graphql-tag";

export const GET_MARKET_FOR_ZIPCODE = gql`
  query getMarketForZipcode($zipcode: String!) {
    getMarketForZipcode(zipcode: $zipcode) {
      id
      name
      timeZone
      activeServiceArea
      activeServiceAreaGeoJson
      marketArea
      marketAreaGeoJson
      marketStatus
    }
  }
`;
