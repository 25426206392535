import React from "react";
import { default as TextField } from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { SubmitButton } from "../Buttons/SubmitButton";
import { CancelButton } from "../Buttons/CancelButton";
import { Controller, useWatch } from "react-hook-form";
import { DateTime } from "luxon";
import { DateTimeInputRHF } from "../FormFields/DateTimeInputRHF";
import { TechnicianSelectRHF } from "../FormFields/TechnicianSelectRHF";
import { propOr, isNil, defaultTo, any, reject, propEq, pathOr, gt, __ } from "ramda";
import { flow, pipe } from "fp-ts/lib/function";
import Typography from "@material-ui/core/Typography";
import { formatDateTime, isValidDate } from "../../lib/functions";
import { AutoCompleteSelectFieldRHF, OptionType } from "../FormFields/AutoCompleteSelectFieldRHF";
import { RESCHEDULE_REASONS } from "../../lib/constants";
import { useQuery } from "@apollo/client";
import { GET_APPOINTMENTS_CONFLICTS } from "../../graphql/queries/getAppointmentConflicts";
import { Query, QueryGetAppointmentsArgs } from "../../generated/nest-graphql";
import { errorLabelStyles } from "../../material-ui-styles";
import { AppointmentFormValues } from "./AppointmentForm";
import { CheckBoxFieldRHF } from "../FormFields/CheckBoxField";

export const BookingDetailsForm: React.FC<{
  appointmentId?: string;
  scheduledDate: Date | undefined;
  jobId?: any;
  control: any;
  isSubmitting: boolean;
  isValid: boolean;
  dirtyFields: any;
  setValue;
  isFixed: boolean;
  onSubmit: any;
  onManuallyChangeValue?: () => void;
  isUsingSuggestion: boolean;
  onCancel: any;
  resetSnapshot: any;
  market: string;
  laborTimes: number[];
  timeZone: string;
  showIsFixed?: boolean
}> = ({
  isFixed,
  appointmentId,
  scheduledDate,
  jobId,
  onSubmit,
  isUsingSuggestion,
  onManuallyChangeValue,
  control,
  setValue,
  resetSnapshot,
  isSubmitting,
  isValid,
  dirtyFields,
  onCancel,
  market,
  laborTimes,
  timeZone,
  showIsFixed
}) => {
  const [watchedStartDate, watchedLaborTime, watchedTechnician] = useWatch({
    control,
    name: ["startDate", "laborTime", "technician", "isFixed"],
  });

  const endDate = any(isNil, [watchedStartDate, watchedLaborTime])
    ? null
    : DateTime.fromJSDate(watchedStartDate).plus({ minutes: watchedLaborTime }).toJSDate();

  const getTimeDiff = () =>
    pipe(
      DateTime.fromJSDate(watchedStartDate).diff(DateTime.fromJSDate(new Date(scheduledDate ?? null)), ["minutes"])
        .minutes,
      Math.abs,
      gt(__, 15)
    );

  const rescheduleOnChange = (newValue: OptionType) => {
    if (typeof newValue === "string") {
      return newValue;
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      return newValue.inputValue;
    } else {
      return propOr(null, "value", newValue);
    }
  };

  const onManualBookingChange = () => {
    if (Object.keys(dirtyFields).some((k) => ["technician", "startDate"].includes(k))) {
      resetSnapshot();
    }
  };

  const technician = defaultTo(null, watchedTechnician);
  const filter = isNil(technician)
    ? {}
    : {
        technician: technician.id,
        "jobCopy.status": { $not: { $regex: "^Withdra" } },
      };

  const data = useQuery<Query, QueryGetAppointmentsArgs>(GET_APPOINTMENTS_CONFLICTS, {
    variables: {
      startRange: isValidDate(watchedStartDate)
        ? DateTime.fromJSDate(watchedStartDate).plus({ minutes: 1 }).toJSDate()
        : null,
      endRange: isValidDate(endDate) ? DateTime.fromJSDate(endDate).minus({ minutes: 1 }).toJSDate() : null,
      filter,
    },
    skip:
      any(isNil, [watchedStartDate, watchedLaborTime, watchedTechnician]) ||
      !isValidDate(watchedStartDate) ||
      !isValidDate(endDate),
    fetchPolicy: "network-only",
  });

  const conflictAppointments = isNil(appointmentId)
    ? pathOr([], ["data", "getAppointmentConflicts"], data)
    : flow(pathOr([], ["data", "getAppointmentConflicts"]), reject(propEq("id", appointmentId)))(data);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault?.();
        event.stopPropagation?.();
        return onSubmit(event);
      }}
    >
      <div className="border-2 p-4">
        <div className="mb-4 text-lg flex flex-row justify-between">
          <div>Booking Details</div>
          {showIsFixed && <Controller
            control={control}
            name={"isFixed"}
            render={({ field }) => (
              <CheckBoxFieldRHF name={field.name} label={"Is Fixed"} onChange={field.onChange} value={field.value} />
            )}
          />}
        </div>
        <div className="text-right mb-2">{`Time Zone: ${timeZone ?? "-"}`}</div>
        <div className="grid grid-rows-2 gap-4">
          <div className="grid col-span-3 grid-cols-3 gap-4">
            <Controller
              control={control}
              name="startDate"
              render={({ field: { value, onChange }, fieldState }) => (
                <DateTimeInputRHF
                  label="Date"
                  required
                  error={fieldState.error}
                  onChange={(val: DateTime) => {
                    if (onManuallyChangeValue) onManuallyChangeValue();
                    return onChange(val?.toJSDate() ?? val);
                  }}
                  onBlur={onManualBookingChange}
                  value={value}
                />
              )}
            />
            <Controller
              control={control}
              name="laborTime"
              render={({ field: { onChange, ...field } }) => (
                <TextField
                  select
                  label="Job Duration"
                  id="laborTime"
                  required
                  variant="outlined"
                  onChange={(event) => {
                    if (onManuallyChangeValue) onManuallyChangeValue();
                    return onChange(event);
                  }}
                  {...field}
                >
                  {laborTimes.map((duration, idx) => (
                    <MenuItem key={duration} value={duration}>
                      {duration}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              control={control}
              name="technician"
              render={({ field: { value, onChange }, fieldState }) => {
                return (
                  <TechnicianSelectRHF
                    value={value}
                    shouldHideName={isUsingSuggestion}
                    error={fieldState.error}
                    label={"Technician"}
                    required={true}
                    market={market}
                    onChange={(_, selectedOption) => {
                      if (onManuallyChangeValue) onManuallyChangeValue();
                      return onChange(selectedOption?.value);
                    }}
                    onBlur={onManualBookingChange}
                  />
                );
              }}
            />
          </div>
          {!!jobId && scheduledDate && watchedStartDate && getTimeDiff() && (
            <div className="grid col-span-3 gap-4">
              <Controller
                control={control}
                name="rescheduleReason"
                render={({ field: { value, onChange }, fieldState }) => (
                  <div className="col-span-3">
                    <AutoCompleteSelectFieldRHF
                      value={value}
                      options={RESCHEDULE_REASONS}
                      label={"Reschedule Reason"}
                      required
                      onChange={(_, val) => onChange(rescheduleOnChange(val))}
                      error={fieldState.error}
                    />
                  </div>
                )}
              />
            </div>
          )}
          {conflictAppointments.length > 0 && (
            <div className="grid col-span-3">
              <AppointmentConflictErrorText conflicts={conflictAppointments} />
            </div>
          )}
          <div className="grid grid-rows-1 grid-cols-3 col-span-3 gap-4">
            <div className="justify-items-start">
              <div className="justify-self-stretch flex flex-col">
                <CancelButton type={"button"} onClick={onCancel}>
                  Cancel
                </CancelButton>
              </div>
            </div>
            <div className="justify-items-start col-start-3">
              <div className="justify-self-stretch flex flex-col">
                <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
                  Save
                </SubmitButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

const AppointmentConflictErrorText: React.FC<{
  conflicts: AppointmentFormValues[];
}> = ({ conflicts }) => {
  const classes = errorLabelStyles();

  return (
    <>
      <Typography className={classes.root} variant="caption">
        This appointment conflicts with technician's current appointments:
      </Typography>
      <ul>
        <div className="pl-4">
          {conflicts.map((val: AppointmentFormValues, i) => (
            <li key={i}>
              <Typography className={classes.root} variant="caption">
                {formatDateTime(val.startDate)} - {formatDateTime(val.endDate)}
              </Typography>
            </li>
          ))}
        </div>
      </ul>
    </>
  );
};
