import React from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetContactArgs, QueryGetEstimatesPaginatedArgs } from "../../generated/nest-graphql";
import { GET_CONTACT_DETAILS } from "../../graphql/queries/getContactDetails";
import { EstimateColumns, estimatesToEstimateColumns } from "../Estimates/EstimatesTable";
import { mergeDeepRight, path } from "ramda";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { GET_ESTIMATES_PAGINATED } from "../../graphql/queries/getEstimatesPaginated";
import { ROW_LIMIT } from "../../lib/constants";

export const ContactsEstimates: React.FC<{ contactId: string }> = ({ contactId }) => {
  const { data, fetchMore } = useQuery<Query, QueryGetEstimatesPaginatedArgs>(GET_ESTIMATES_PAGINATED, {
    variables: {
      paginatedQueryInput: {
        skip: 0,
        limit: ROW_LIMIT,
        filter: {
          contact: contactId,
        },
      },
    },
  });
  const contactResult = useQuery<Query, QueryGetContactArgs>(GET_CONTACT_DETAILS, {
    variables: {
      id: contactId,
    },
  });
  if (!data || !path(["data", "getContact"], contactResult)) return null;
  const loadMore = async () => {
    await fetchMore({
      variables: {
        paginatedQueryInput: {
          filter: {
            contact: contactId,
          },
          limit: ROW_LIMIT,
          skip: data.getEstimatesPaginated.pageInfo.offset + ROW_LIMIT,
        },
      },
      updateQuery: (prev: Query, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          getEstimatesPaginated: {
            edges: [...prev.getEstimatesPaginated.edges, ...fetchMoreResult.getEstimatesPaginated.edges],
            pageInfo: mergeDeepRight(
              prev.getEstimatesPaginated.pageInfo,
              fetchMoreResult.getEstimatesPaginated.pageInfo
            ),
            __typename: prev.getEstimatesPaginated.__typename,
          },
        };
      },
    });
  };

  return (
    <div className={"px-4"}>
      <SelectablePageableEntityTable
        title={"Estimates"}
        queryResult={data}
        columns={EstimateColumns}
        spec={estimatesToEstimateColumns}
        queryKey={"getEstimatesPaginated"}
        loadMore={loadMore}
        numRecords={data.getEstimatesPaginated.edges.length}
        limit={ROW_LIMIT}
      />
    </div>
  );
};
