import { applySpec, path, prop, propOr } from "ramda";
import { PartsStoreInput } from "../../generated/nest-graphql";
import { PartsStoreFormValues } from "../Forms/PartsStoreForm";

export const partsStoreSpec = (val: PartsStoreFormValues) => {
  return applySpec<PartsStoreInput>({
    storeNumber: prop("storeNumber"),
    name: prop("name"),
    address: prop("address"),
    market: path(["market", "value", "id"]),
    vendor: prop("vendor"),
    city: prop("city"),
    state: prop("state"),
    zipcode: prop("zipcode"),
    deactivated: propOr(false, "deactivated"),
  })(val);
};
