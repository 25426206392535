import { PartsStore, Query, QueryGetPartsStoresArgs } from "../generated/nest-graphql";
import { useQuery } from "@apollo/client";
import { defaultTo } from "ramda";
import { GET_PARTS_STORES } from "../graphql/queries/getPartsStores";

interface UsePartsStoresOptions {
  showInactive?: boolean;
}

export const usePartsStores = (options?: UsePartsStoresOptions): PartsStore[] => {
  const { data } = useQuery<Query, QueryGetPartsStoresArgs>(GET_PARTS_STORES, {
    variables: {
      filter: options?.showInactive ? {} : { deactivated: { $ne: true } },
    },
  });
  return defaultTo([])(data?.getPartsStores);
};
