import { CreateInquiryRequestInput } from "../../generated/nest-graphql";

export const inquiryRequestSpec = (formData): CreateInquiryRequestInput => {
  return {
    address: formData.address,
    context: "Ops Admin",
    email: formData.email,
    extraInfo: formData.extraInfo,
    fleet: {
      name: formData.fleetName,
    },
    frontPadLife: formData.frontPadLife,
    rearPadLife: formData.rearPadLife,
    fullName: formData.firstName + " " + formData.lastName,
    hearAboutUs: formData.hearAboutUs,
    licensePlate: formData.licensePlate,
    licensePlateState: formData.licensePlateState,
    make: formData.make,
    model: formData.model,
    year: formData.year,
    odometer: formData.odometer,
    frontRotorsDiscardThicknessInches: formData.frontRotorsDiscardThicknessInches,
    rearRotorsDiscardThicknessInches: formData.rearRotorsDiscardThicknessInches,
    phoneNumber: formData.phoneNumber,
    source: formData.source,
    symptoms: formData.symptoms,
    vehicleSymptoms: formData.vehicleSymptoms,
    vehicleId: formData.vehicleId,
    vin: formData.vin,
    zip: formData.zip,
    customerExpectation: formData.customerExpectation,
    additionalNotes: formData.additionalNotes,
    frontBrakeSymptoms: formData.frontBrakeSymptoms,
    rearBrakeSymptoms: formData.rearBrakeSymptoms,
    outreachMedium: formData.outreachMedium,
    market: formData.market,
    timeZone: formData.timeZone,
  };
};
