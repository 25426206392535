import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { detailsViewTitleStyles } from "../../material-ui-styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

export const DetailViewContainer: React.FC<{
  title: string;
  defaultExpanded?: boolean;
  data?: string;
  className?: string;
}> = ({ children, title, defaultExpanded = true, data, className }) => {
  const classes = detailsViewTitleStyles();
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <Box border={1} borderRadius={4} marginTop={2} className={className}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className={classes.root}
        borderBottom={1}
        paddingX={2}
        paddingY={1}
        onClick={() => setExpanded(!expanded)}
      >
        <div>{title}</div>
        <div>{data ?? ""}</div>
        <IconButton>{expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
      </Box>
      {expanded &&
        <Box paddingX={2} paddingY={2}>
          {children}
        </Box>
      }
    </Box>
  );
};
