import React from "react";
import { useField } from "formik";
import { Query, ViocStore } from "../../generated/nest-graphql";
import { defaultTo, map, prop, sortWith, ascend, concat } from "ramda";
import { useQuery } from "@apollo/client";
import { flow } from "fp-ts/lib/function";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";
import { CircularProgress } from "@material-ui/core";
import { GET_ALL_VIOC_STORES } from "../../graphql/queries/getAllViocStores";

type ViocStoreOption = {
  label: string;
  value: ViocStore;
};

type ViocStoreOptionType = {
  label: string;
  value: ViocStore;
};

export type PartsStoreFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  postOnChange?: (newValue: ViocStore) => any;
};

export const JobViocStoreSelectField = ({ name, label, required = false, postOnChange }: PartsStoreFieldProps) => {
  const [field, meta, helpers] = useField(name);

  const { loading, data } = useQuery<Query, {}>(GET_ALL_VIOC_STORES, {
    variables: {
      filter: {
        isActive: true,
      },
    },
  });

  const viocStoresToOption = (viocStore: ViocStore) => {
    const { address, city } = viocStore;

    return {
      label: `${address}, ${city}`,
      value: viocStore,
    };
  };

  const viocStoresOptions: any = flow(
    map(viocStoresToOption),
    concat([{ value: null, label: "" }]),
    sortWith([ascend(prop("label") as any)])
  )(data?.getAllViocStores || []);

  const filterOptions = createFilterOptions<ViocStoreOptionType>({
    matchFrom: "any",
    stringify: (option) => {
      return option.label;
    },
  });
  const onChange = (_: object, value: any) => {
    helpers.setValue(prop("value", value));
    if (postOnChange) postOnChange(prop("value", value));
  };

  const shouldRenderLoading = loading || !data?.getAllViocStores;
  const renderLoading = () => {
    return (
      <div className="flex flex-row items-center pt-4 pb-2">
        <CircularProgress size={22} className="mr-4" />
        <p>Loading vioc stores...</p>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-2 mb-4">
      {shouldRenderLoading ? (
        renderLoading()
      ) : (
        <Autocomplete<ViocStoreOption>
          getOptionSelected={(option, value) => option.label === value.label}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            return defaultTo("", option.label);
          }}
          value={field.value ? viocStoresToOption(field.value) : { value: null, label: "" }}
          options={viocStoresOptions}
          onChange={onChange}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              error={!!meta.error}
              {...params}
              label={label}
              required={required}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
              }}
              helperText={<>{meta.error && <ErrorTextField displayOnSpan={true} fieldName={field.name} />}</>}
            />
          )}
        />
      )}
    </div>
  );
};
