function linearRegression(dates: Date[], values: number[]) {
  // Convert dates to timestamps for numerical operations
  const timestamps = dates.map(date => new Date(date).getTime());

  const n = values.length;
  const sumX = timestamps.reduce((acc, curr) => acc + curr, 0);
  const sumY = values.reduce((acc, curr) => acc + curr, 0);
  const sumXY = timestamps.reduce((acc, curr, i) => acc + curr * values[i], 0);
  const sumXX = timestamps.reduce((acc, curr) => acc + curr * curr, 0);

  const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const intercept = (sumY - slope * sumX) / n;

  return { slope, intercept };
}

export function predictFromDate(dates: Date[], values: number[], targetDate: Date) {
  const { slope, intercept } = linearRegression(dates, values);
  const targetTimestamp = new Date(targetDate).getTime();
  const prediction = slope * targetTimestamp + intercept;
  return prediction;
}

export function predictFromValue(dates: Date[], values: number[], targetValue: number) {
  const { slope, intercept } = linearRegression(dates, values);
  const targetTimestamp = (targetValue - intercept) / slope;
  const predictedDate = new Date(targetTimestamp);
  return predictedDate;
}


export function estimateAverageMilesPerYear(dates: Date[], values: number[]) {
  const { slope } = linearRegression(dates, values);

  // Convert slope from miles per millisecond to miles per year
  const millisecondsPerYear = 365.25 * 24 * 60 * 60 * 1000; // Considering leap years
  const milesPerYear = slope * millisecondsPerYear;

  return milesPerYear;
}