import React from "react";
import { ContactDetailsForm } from "../../components/Forms/ContactDetailsForm";
import { Container } from "../../components/Container";
import { cleanObject } from "../../lib/functions";
import { keys } from "ramda";
import { useMutation } from "@apollo/client";
import {
  CreateInquiryRequestInput,
  Mutation,
  MutationOpsAdminCreateInquiryRequestArgs,
  MutationUpsertVehicleArgs,
} from "../../generated/nest-graphql";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { OPS_ADMIN_CREATE_INQUIRY_REQUEST } from "../../graphql/mutations/opsAdminCreateInquiryRequest";
import { flow } from "fp-ts/lib/function";
import { inquiryRequestSpec } from "../../components/InquiryRequest/spec";
import { UPSERT_VEHICLE } from "../../graphql/mutations/upsertVehicle";

const AddContactPage: React.FC = () => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [upsertVehicle] = useMutation<Mutation, MutationUpsertVehicleArgs>(UPSERT_VEHICLE);
  const [opsAdminCreateInquiryRequest] = useMutation<Mutation, MutationOpsAdminCreateInquiryRequestArgs>(
    OPS_ADMIN_CREATE_INQUIRY_REQUEST
  );

  const opsAdminCreateInquiryRequestOnSubmit = async (values) => {
    const updates = flow(inquiryRequestSpec, cleanObject)(values) as CreateInquiryRequestInput;
    if (keys(updates).length) {
        const { data } = await opsAdminCreateInquiryRequest({
          variables: {
            createInquiryRequestInput: { ...updates },
          },
        });
        const id = data.opsAdminCreateInquiryRequest?.serviceRequest?.id;
        const contactId = data.opsAdminCreateInquiryRequest.contact?.id
        if(values.vehicle && contactId) {
          await upsertVehicle({
            variables: {
              vehicleInputData: {
                ...values.vehicle,
                contactId,
              },
            }
          })
        }
        showSuccess({ message: "Successfully Added Contact" });
        await history.push(`/jobs/${id}`);
    }
  };

  return (
    <div>
      <Container>
        <div className="mt-16" />
        <div>Add Contacts Page</div>
        <ContactDetailsForm
          initialValues={{
            zip: "",
            phoneNumber: "",
            market: "",
            make: "",
            vehicleId: undefined,
            outreachMedium: "Inbound Call",
            hearAboutUs: "",
            lastName: "",
            firstName: "",
            email: "",
            address: "",
            extraInfo: "",
            model: "",
            source: "",
            symptoms: "",
            year: "",
            additionalNotes: "",
            customerExpectation: "",
            timeZone: "",
            fleetName: "",
            isFleet: false,
            taxExemptEin: "",
            isTaxExempt: false,
            frontBrakeSymptoms: [],
            rearBrakeSymptoms: [],
            vehicleSymptoms: [],
            requestedServices: [],
          }}
          onSubmit={opsAdminCreateInquiryRequestOnSubmit}
        />
      </Container>
    </div>
  );
};

export default AddContactPage;
