import React, { useRef, useState } from "react";
import { Form, Formik } from "formik";
import currency from "currency.js";
import * as Yup from "yup";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { SelectField } from "../FormFields/SelectField";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { DateInput } from "../FormFields/DateInput";
import { Button } from "../Buttons/Button";
import { ItemsTable, ItemsTableType } from "../Items/ItemsTable";
import { TextField } from "../FormFields/TextField";
import Modal from "react-modal";
import { ItemForm, ItemFormValues } from "./ItemForm";
import { anyPass, append, equals, isEmpty, isNil, keys, omit, path, pipe, prop, remove } from "ramda";
import { ContactsSelect } from "../FormFields/ContactsSelect";
import {
  Contact,
  EjiDiscount,
  EjiPriceInfo,
  EjiService,
  PossibleEjiService,
  Query,
  Event,
  PromoCode,
} from "../../generated/nest-graphql";
import { handleNoDecimal } from "../../lib/functions";
import { VehicleInfoFormSection, VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import { NuModal } from "../NuModal";
import { SaveCancelRow } from "../SaveCancelRow";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import { useToggle } from "../../hooks/useToggle";
import { ProductOptionType } from "../FormFields/ProductSelectTypeHead";
import { GET_PRODUCTS } from "../../graphql/queries/getProducts";
import { useQuery } from "@apollo/client";
import { Space } from "../Space";
import { ItemsReceiptSection } from "../Items/ItemsReceiptSection";
import { usePartsStores } from "../../hooks/usePartsStores";
import ServicesSection from "../Services/ServicesSection";
import { servicesSchema } from "../../yupSchemas";
import { SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { servicesToPossibleEjiServiceSpec } from "../specs/servicesSpec";
import { GET_PART_CATALOGUE } from "../../graphql/queries/getPartCatalogue";
import { partSpec } from "../specs/partSpec";
import { VehicleSymptomFormValues } from "./ContactDetailsForm";

export type EstimateDetailsFormValues = VehicleInfoValues & {
  status?: string;
  items?: ItemsTableType[];
  taxable?: boolean;
  contact?: Contact;
  market?: string;
  serviceLocation?: string;
  issuedDate?: Date;
  estimateNotes?: string;
  serviceLocationNotes?: string;
  locationType?: string;
  privateNotes?: string;
  partsLeadTimeInDays?: number;
  job?: string;
  serviceCatalogueUsed?: string;
  services?: PossibleEjiService[] | EjiService[];
  discounts?: EjiDiscount[];
  promoCodes?: PromoCode[];
  priceInfo?: EjiPriceInfo;
  serviceGenerationEvents?: Event[];
  callForPartsTicketNumber?: string;
  partsNotes?: string;
  vehicleSymptoms?: VehicleSymptomFormValues[];
  requestedServices?: string[];
};

const EstimateDetailsFormValidationSchema = Yup.object().shape({
  status: Yup.string().required("required"),
  market: Yup.string().required("required"),
  estimateNotes: Yup.string().required("required"),
  services: servicesSchema,
  vin: Yup.string().length(17, "VIN must be 17 digits").nullable(),
});

export const EstimateDetailsForm: React.FC<{
  initialValues: EstimateDetailsFormValues;
  onSubmit: (values: any, helpers: any) => void;
  estimateId?: string;
  estimateNumber?: string;
  submitRowNotFixed?: boolean;
  onCancel?: () => void;
}> = ({ initialValues, onSubmit, estimateId, estimateNumber, submitRowNotFixed = false, onCancel }) => {
  Modal.setAppElement("#root");
  const [itemModalIsOpen, , toggleItemModal] = useToggle();
  const [errorModalIsOpen, , toggleErrorModal] = useToggle();
  const [errorMessage, setErrorMessage] = useState("");
  const currentRef = useRef();
  const { width, left } = useContainerDimensions(currentRef);
  const { data, loading } = useQuery<Query>(GET_PRODUCTS);
  const { data: partsData, loading: partsLoading } = useQuery<Query>(GET_PART_CATALOGUE);
  const partsStoresData = usePartsStores();
  const wrappedOnSubmit = async (values: EstimateDetailsFormValues, formikHelpers) => {
    // Custom validation needs to be done here (instead of using Formik and Yup)
    // since there is no nice way to communicate these error conditions to the
    // user prior to clicking the save button.
    const valuesWithPossibleEJIs = { ...values, services: servicesToPossibleEjiServiceSpec(values.services) };
    const isServiceCatalogueUsed = valuesWithPossibleEJIs.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES;
    const isNoServiceIncludedInEstimate =
      valuesWithPossibleEJIs.services.filter((service) => service.inEstimate).length === 0;
    const isCustomerPriceZero = currency(valuesWithPossibleEJIs.priceInfo?.amountDue ?? "0.00").intValue === 0;
    if (isServiceCatalogueUsed && isNoServiceIncludedInEstimate) {
      setErrorMessage("At least one service must be included in the estimate to generate a quote.");
      toggleErrorModal();
      return;
    }
    if (isServiceCatalogueUsed && isCustomerPriceZero) {
      setErrorMessage(
        "The combined customer price of all included services must be greater than $0.00 to generate a quote."
      );
      toggleErrorModal();
      return;
    }
    await onSubmit(values, formikHelpers);
  };
  return (
    <div ref={currentRef}>
      <Formik<EstimateDetailsFormValues>
        onSubmit={wrappedOnSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={EstimateDetailsFormValidationSchema}
        validateOnMount
      >
        {({ isSubmitting, isValid, setValues, resetForm, values, setFieldValue, errors }) => {
          const onDeleteItem = (idx) => () => {
            const result = remove(idx, 1, values.items);
            setFieldValue("items", result);
          };
          const postSelectProduct = (prefix: string) => (value: ProductOptionType) => {
            const selectedValue = prop("value", value);
            const valueKeys = pipe(omit(["id"]), keys)(selectedValue);
            valueKeys.forEach((key) => {
              const val = selectedValue[key];
              const keyWithPrefix = `${prefix}.${key}`;
              setFieldValue(keyWithPrefix, val ? val : "");
            });
          };
          const market = prop("market", values);
          const partsStores = anyPass([isNil, isEmpty])(values.market)
            ? partsStoresData
            : partsStoresData.filter((x) => equals(path(["market", "name"], x), values.market));
          const parts = partsLoading ? [] : partsData.getPartCatalogue?.map(partSpec);
          return (
            <div>
              <Form>
                {estimateNumber && <div className={"mb-4"}>Estimate Number: {estimateNumber}</div>}
                <div className={"grid grid-cols-2 gap-4 mb-4"}>
                  <SelectField name={"status"} options={["Draft", "Sent"]} label={"Status"} />
                  <DateInput name={"issuedDate"} label={"Issued Date"} />
                </div>
                <DetailViewContainer title={"Contact info"}>
                  <div className={"grid grid-cols-3 gap-4"}>
                    <div className="grid col-span-1 gap-4">
                      <ContactsSelect name={"contact"} label={"Contact"} />
                      <MarketFieldSelect name={"market"} required={true} />
                    </div>
                    <div className="grid col-span-2 gap-4">
                      <LocationSearchInput name={"serviceLocation"} label={"Service Location"} />
                      <TextField
                        name={"serviceLocationNotes"}
                        label={"Service Location Notes"}
                        multiline={true}
                        rows={4}
                      />
                    </div>
                  </div>
                </DetailViewContainer>
                <VehicleInfoFormSection />
                <DetailViewContainer title={"Estimate Notes"}>
                  <div className={"grid grid-cols-2 gap-4"}>
                    <div className={"grid col-span-1 gap-4"}>
                      <TextField
                        name={"estimateNotes"}
                        label={"Estimate Notes"}
                        multiline={true}
                        rows={4}
                        required={true}
                      />
                      <TextField
                        name={"privateNotes"}
                        label={"Private Notes"}
                        multiline={true}
                        rows={4}
                        helperText="Internal notes used by ops and technicians."
                      />
                    </div>
                    <div className={"grid col-span-1 gap-2"}>
                      <TextField name={"partsNotes"} label={"Parts Notes"} multiline={true} rows={4} />
                      <SelectField
                        name={"partsLeadTimeInDays"}
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                        label={"Parts Lead Time in Days"}
                        noneValue={0}
                      />
                      <TextField name={"callForPartsTicketNumber"} label={"Call For Parts Ticket #"} />
                    </div>
                  </div>
                </DetailViewContainer>
                {values?.serviceCatalogueUsed === "Services" ? (
                  <DetailViewContainer title={"Services"}>
                    {!market || market === "ZipUnknown" || market === "" ? (
                      "Please select a market first."
                    ) : (
                      <ServicesSection
                        taxable={values.taxable}
                        partsStores={partsStores}
                        values={values}
                        setValues={setValues}
                        ejiType={"ESTIMATE"}
                        parts={parts}
                      />
                    )}
                  </DetailViewContainer>
                ) : (
                  <>
                    <DetailViewContainer title={"Items"}>
                      <div className="flex flex-row justify-start mb-4">
                        <div>
                          <Button onClick={toggleItemModal} type={"button"}>
                            + Add Item
                          </Button>
                        </div>
                      </div>
                      {!loading && data.getProducts && (
                        <ItemsTable
                          items={values.items}
                          onDeleteItem={onDeleteItem}
                          postItemSelect={postSelectProduct}
                          products={data.getProducts}
                          partsStores={partsStores}
                        />
                      )}{" "}
                    </DetailViewContainer>
                    <ItemsReceiptSection
                      items={prop("items", values) as ItemFormValues[]}
                      market={market}
                      taxable={values.taxable}
                    />
                  </>
                )}
                <Space height={30} />
                <SaveCancelRow
                  width={width}
                  offsetLeft={left}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  onCancel={() => {
                    resetForm(initialValues);
                    onCancel && onCancel();
                  }}
                  notFixed={submitRowNotFixed}
                />
                <NuModal isOpen={itemModalIsOpen} title="Add Item">
                  {!loading && data.getProducts && (
                    <ItemForm
                      products={data.getProducts}
                      onSubmit={async (itemFormValues: ItemFormValues) => {
                        const oldItems: ItemsTableType[] = values.items;
                        const { product, name, description, partNumber, partsCost, laborCost, amount } = itemFormValues;
                        const cleanedItemFormValues: ItemsTableType = {
                          amount: handleNoDecimal(amount),
                          laborCost: handleNoDecimal(laborCost),
                          partsCost: handleNoDecimal(partsCost),
                          partNumber,
                          description,
                          isInEstimate: true,
                          name,
                          product,
                        };
                        const result = append(cleanedItemFormValues, oldItems);
                        setFieldValue("items", result, false);
                        toggleItemModal();
                      }}
                      onCancel={toggleItemModal}
                    />
                  )}
                </NuModal>
                <NuModal isOpen={errorModalIsOpen} title="No Services Included in Estimate">
                  <div>
                    <p>{errorMessage}</p>
                    <Button className="mt-4 float-right" type={"button"} onClick={toggleErrorModal}>
                      OK
                    </Button>
                  </div>
                </NuModal>
              </Form>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};
