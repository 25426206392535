import { __, includes, isEmpty, isNil, filter, pipe, prop, where, either } from "ramda";
import PlaceType from "./PlaceType.interface";

export const loadGoogleMapsScript = (id: string) => {
  if (document.getElementById(id)) {
    return;
  }

  const mapsApiKey = "AIzaSyDRZkNoSOvupa2xr4DAGxDexWGEq06Z2tc";
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = `https://maps.googleapis.com/maps/api/js?key=${mapsApiKey}&libraries=places`;
  document.querySelector("head").appendChild(script);
};

export const setPostalCode = (placeId: string, onCompleteGetPostalCode: (postalCode: string) => void) => {
  const geocoder = { current: null };

  if (!geocoder.current && (window as any).google) {
    geocoder.current = new (window as any).google.maps.Geocoder();
  }

  if (!geocoder.current || isEmpty(placeId) || isNil(placeId)) {
    return;
  }

  geocoder.current.geocode({ placeId }, (results: PlaceType[], status: string) => {
    if (status !== "OK") {
      return;
    }
    const postalCodeComponent = pipe(
      prop("address_components"),
      //@ts-ignore
      filter(where({ types: includes("postal_code") }), __)
      //@ts-ignore
    )(results[0]);

    // Location might be too broad to have a unique postal code
    const postalCode = isEmpty(postalCodeComponent) ? "" : prop("long_name", postalCodeComponent[0]);

    onCompleteGetPostalCode(postalCode);
  });
};

const getPlaceGeocodeCache: { [key: string]: { lat: number; lng: number } } = {};

export const getPlaceGeocode = async ({
  placeId,
  address,
}: {
  placeId?: string;
  address?: string;
}): Promise<{
  lat: number;
  lng: number;
} | null> => {
  return new Promise((resolve) => {
    const cacheKey = placeId || address;

    if (getPlaceGeocodeCache[cacheKey]) {
      return resolve(getPlaceGeocodeCache[cacheKey]);
    }

    const geocoder = { current: null };

    if (!geocoder.current && (window as any).google) {
      geocoder.current = new (window as any).google.maps.Geocoder();
    }

    const hasNoInput = either(isEmpty, isNil)(placeId) && either(isEmpty, isNil)(address);

    if (!geocoder.current || hasNoInput) {
      return resolve(null);
    }

    const input = placeId ? { placeId } : { address };

    geocoder.current.geocode(input, (results: PlaceType[], status: string) => {
      if (status !== "OK") {
        return resolve(null);
      }

      const geometryLocation = results?.[0]?.geometry?.location;

      if (!geometryLocation?.lat || !geometryLocation?.lng) {
        return resolve(null);
      }

      const coordinates = {
        lat: geometryLocation.lat(),
        lng: geometryLocation.lng(),
      };

      getPlaceGeocodeCache[cacheKey] = coordinates;

      return resolve(coordinates);
    });
  });
};
