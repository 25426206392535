import gql from "graphql-tag";

//removed scores, and technicianExistingAppointments

export const appointmentSuggestionFields = `
    appointmentSnapshotId
    availabilitySnapshotId
    score
    technician {
      id
      firstName
      lastName
      email
      role {
        name
      }
      homePartsStore {
        id
        vendor
        name
      }
      market
      isActive
      phoneNumber
      timeZone
      schedulingDays {
        dayOfWeek
        startTime
        endTime
      }
      serviceAreaAnchorAddress
      homeAddress
      onWayMessage
      numSlots
    }
    timeWindow {
        startTimeWindow
    }
    subject
    startDate
    endDate
    market {
        name
        timeZone
    }
    timeCategory
    serviceLocation
    arrivalServiceLocation
    arrivalAddressType
    departureServiceLocation
    departureAddressType
    driveTimes {
      arrival {
        durationWithTrafficInSeconds
        readableDurationWithTraffic
      }
      departure {
        durationWithTrafficInSeconds
        readableDurationWithTraffic
      }
    }
  `;
export const GET_APPOINTMENT_SUGGESTIONS = gql`
  query getAppointmentSuggestions($getAppointmentSuggestionsInput: GetAppointmentSuggestionsInput!) {
    getAppointmentSuggestions(
            getAppointmentSuggestionsInput: $getAppointmentSuggestionsInput
    ) {
      ${appointmentSuggestionFields}
    }
  }
`;
