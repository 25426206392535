import gql from "graphql-tag";

export const marketFields = `
      id
      name
      timeZone
      phoneNumber
      email
      address
      businessID
      activeServiceArea
      activeServiceAreaGeoJson
      marketArea
      marketAreaGeoJson
      marketStatus
      salesTaxType
      partsTaxRate
      laborTaxRate
`;

export const GET_MARKET = gql`
  query getMarket($id: ID!) {
    getMarket(id: $id) {
      ${marketFields}
    }
  }
`;
