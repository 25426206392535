import gql from "graphql-tag";

export const SEND_ESTIMATE_FOR_JOB = gql`
  mutation sendEstimateForJob($sendEstimateForJobInput: SendEstimateForJobInput!) {
    sendEstimateForJob(sendEstimateForJobInput: $sendEstimateForJobInput) {
      id
      estimateSentAt
    }
  }
`;
