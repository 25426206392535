import gql from "graphql-tag";

export const GET_TECHNICIANS = gql`
  query GetTechnicians($filter: JSON) {
    getTechnicians(filter: $filter) {
      id
      auth0Id
      email
      role {
        id
        name
        payMultiplier
      }
      firstName
      lastName
      market
      isActive
      is1099
      phoneNumber
      timeZone
      homePartsStore {
        id
        vendor
        name
      }
      homeAddress
      schedulingDays {
        dayOfWeek
        startTime
        endTime
      }
      onWayMessage
    }
  }
`;
