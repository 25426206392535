import { useMutation, useQuery } from "@apollo/client";
import { cond, keys, omit, path, pathOr, pipe, prop, T } from "ramda";
import React from "react";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { Mutation, MutationUpdateEstimateArgs, Query, QueryGetEstimateArgs } from "../../generated/nest-graphql";
import { UPDATE_ESTIMATE } from "../../graphql/mutations/updateEstimate";
import { GET_ESTIMATE } from "../../graphql/queries/getEstimate";
import { cleanObject, objectDiff } from "../../lib/functions";
import { ActivityFeedDrawer } from "../ActivityFeed/ActivityFeedDrawer";
import { EstimateDetailsForm, EstimateDetailsFormValues } from "../Forms/EstimateDetailsForm";
import { estimateDetailSpec, getSymptoms } from "../specs/estimateDetailSpec";
import { DEFAULT_SERVICE_CATALOGUE_USED, SERVICE_CATALOGUE_USED_SERVICES } from "../../lib/constants";
import { servicesToPossibleEjiServiceSpec } from "../specs/servicesSpec";
import { vehicleSymptomToVehicleSymptomFormValue } from "../Contacts/contactDetailsFormHelpers";
import { exists } from "../../commonFunctions";

export const EstimateDetails: React.FC<{ estimateId: string }> = ({ estimateId }) => {
  const { data } = useQuery<Query, QueryGetEstimateArgs>(GET_ESTIMATE, {
    variables: {
      id: estimateId,
    },
  });
  const showSuccess = useShowSuccess();
  const [updateEstimate] = useMutation<Mutation, MutationUpdateEstimateArgs>(UPDATE_ESTIMATE);
  if (!data) return null;
  const estimateNumber = path<string>(["getEstimate", "estimateNumber"], data);
  const initialValues: EstimateDetailsFormValues = {
    year: pathOr("", ["vehicleInfo", "year"], data.getEstimate),
    symptoms: getSymptoms(data.getEstimate) ?? "",
    model: pathOr("", ["vehicleInfo", "model"], data.getEstimate),
    make: pathOr("", ["vehicleInfo", "make"], data.getEstimate),
    frontPadLife: pathOr("", ["vehicleInfo", "frontPadLife"], data.getEstimate),
    licensePlate: pathOr("", ["vehicleInfo", "licensePlate"], data.getEstimate),
    licensePlateState: pathOr("", ["vehicleInfo", "licensePlateState"], data.getEstimate),
    odometer: pathOr("", ["vehicleInfo", "odometer"], data.getEstimate),
    frontRotorsDiscardThicknessInches: pathOr(null, ["vehicleInfo", "frontRotorsDiscardThicknessInches"], data.getEstimate),
    rearRotorsDiscardThicknessInches: pathOr(null, ["vehicleInfo", "rearRotorsDiscardThicknessInches"], data.getEstimate),
    rearPadLife: pathOr("", ["vehicleInfo", "rearPadLife"], data.getEstimate),
    vin: pathOr("", ["vehicleInfo", "vin"], data.getEstimate),
    extraInfo: pathOr("", ["vehicleInfo", "extraInfo"], data.getEstimate),
    additionalNotes: pathOr("", ["vehicleInfo", "additionalNotes"], data.getEstimate),
    customerExpectation: pathOr("", ["vehicleInfo", "customerExpectation"], data.getEstimate),
    frontBrakeSymptoms: pathOr([], ["vehicleInfo", "frontBrakeSymptoms"], data.getEstimate),
    rearBrakeSymptoms: pathOr([], ["vehicleInfo", "rearBrakeSymptoms"], data.getEstimate),
    issuedDate: data.getEstimate.issuedDate,
    taxable: !exists(data?.getEstimate?.contact?.taxExemptEin) ?? data?.getEstimate?.taxable ?? true,
    serviceLocation: data.getEstimate.serviceLocation,
    estimateNotes: data.getEstimate.estimateNotes,
    privateNotes: data.getEstimate.privateNotes,
    market: data.getEstimate.market,
    serviceLocationNotes: data.getEstimate.serviceLocationNotes,
    contact: data.getEstimate.contact,
    items: data.getEstimate.items.map((item) => {
      return {
        name: prop("name", item),
        description: prop("description", item),
        amount: prop("amount", item),
        laborCost: prop("laborCost", item),
        partNumber: prop("partNumber", item),
        partsCost: prop("partsCost", item),
        vendorPartsCost: prop("vendorPartsCost", item),
        isInEstimate: prop("isInEstimate", item),
        product: prop("product", item),
        partsStore: prop("partsStore", item),
      };
    }),
    partsLeadTimeInDays: prop("partsLeadTimeInDays", data.getEstimate),
    status: prop("status", data.getEstimate),
    serviceCatalogueUsed: data.getEstimate?.serviceCatalogueUsed ?? DEFAULT_SERVICE_CATALOGUE_USED,
    services: cond([
      [
        (_val) =>
          data.getEstimate?.serviceCatalogueUsed === SERVICE_CATALOGUE_USED_SERVICES &&
          data.getEstimate?.services?.length > 0,
        () => data.getEstimate?.services,
      ],
      [T, () => []],
    ])(null),
    discounts: data?.getEstimate?.priceInfo?.discounts ?? [],
    promoCodes: data?.getEstimate?.priceInfo?.promoCodes ?? [],
    priceInfo: data?.getEstimate?.priceInfo,
    callForPartsTicketNumber: prop("callForPartsTicketNumber", data.getEstimate),
    partsNotes: prop("partsNotes", data.getEstimate),
    vehicleSymptoms: data?.getEstimate?.vehicleSymptoms?.map?.(vehicleSymptomToVehicleSymptomFormValue) ?? [],
    requestedServices: data?.getEstimate?.requestedServices ?? [],
  };
  const onSubmit = async (values: EstimateDetailsFormValues, formikHelkpers) => {
    const initialValuesWithPossibleEJIs = {
      ...initialValues,
      services: servicesToPossibleEjiServiceSpec(initialValues.services),
    };
    const valuesWithPossibleEJIs = { ...values, services: servicesToPossibleEjiServiceSpec(values.services) };
    const diff = objectDiff(valuesWithPossibleEJIs, initialValuesWithPossibleEJIs);
    // @ts-ignore
    const updates = pipe(estimateDetailSpec, omit(["pricingConfig"]), cleanObject)(diff);
    if (keys(updates).length) {
      await updateEstimate({
        variables: {
          id: estimateId,
          updateEstimateInput: updates,
        },
      });
      showSuccess({ message: "Successfully Updated Estimate" });
    }
  };
  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        <EstimateDetailsForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          estimateId={estimateId}
          estimateNumber={estimateNumber}
        />
      </div>
      <ActivityFeedDrawer activities={data.getEstimate.activityFeed} />
    </div>
  );
};
