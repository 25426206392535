import { Form, Formik, FormikProps } from "formik";
import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { CircularProgress, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { TextField } from "../FormFields/TextField";
import { SelectField } from "../FormFields/SelectField";
import { AutoCompleteSelectField } from "../FormFields/AutoCompleteSelectField";
import { getMakesByYear, getModelsByYearAndMake, getYears } from "../../lib/quoteFormUtil";
import { US_STATES } from "../../lib/constants";
import SearchIcon from "@material-ui/icons/Search";
import { useVinDecoder } from "../../hooks/useVinDecoder";
import { debounce } from "lodash";
import { DiscardThicknessField } from "../VehicleInfo/VehicleInfoFormSection";
import { NuModal } from "../NuModal";
import { Button } from "../Buttons/Button";
import { SaveDeleteCancelRow } from "../SaveDeleteCancelRow";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { CancelButton } from "../Buttons/CancelButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "../Alert";
import axios from "axios";
import { useMutation } from "@apollo/client";
import { Mutation, MutationGetVinFromLicensePlateArgs } from "../../generated/nest-graphql";
import { GET_VIN_FROM_LICENSE_PLATE } from "../../graphql/mutations/getVinFromLicensePlate";

export type VehicleDetailsFormValues = {
    id: string;
    contactId: string;
    make: string,
    model: string,
    subModel: string,
    year: string,
    vin: string,
    name: string,
    notes: string,
    licensePlate: string,
    licensePlateState: string,
    wheelTorque: string,
    frontRotorsDiscardThicknessInches: number,
    rearRotorsDiscardThicknessInches: number,
    odometer: string,
    inactive: boolean,
    hasDrumBrakes: boolean,
    parkingBrake: string,
    odometerHistory?: OdometerHistoryFormValues[],
}
export type OdometerHistoryFormValues = {
    include: boolean
    updatedAt: Date
    updatedBy: string
    mileage: string
}

const PARKING_BRAKE_OPTIONS = [
    {
        label: 'Manual',
        value: 'Manual'
    },
    {
        label: 'Electric',
        value: 'Electric'
    }
]

export const vehicleValidationSchema = Yup.object().shape({
    year: Yup.string().required("Year is necesary"),
    make: Yup.string().required("Make is necesary"),
    model: Yup.string().required("Model is necesary"),
    vin: Yup.string().length(17, "VIN must be 17 digits").nullable(),
});

export const VehicleDetailsForm: React.FC<{
    contactId?: string;
    vehicleId?: string;
    initialValues?: VehicleDetailsFormValues;
    withSubTitle?: boolean;
    onSubmit?: any;
    onCancel?: any;
    onSave?: any;
    onBack?: any;
}> = ({ initialValues, onSubmit, ...restProps }) => {
    return (
        <Formik<VehicleDetailsFormValues>
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={vehicleValidationSchema}
            enableReinitialize={true}
            validateOnMount>
            {(formController) => {

                return (
                    <VehicleForm initialValues={initialValues} onSubmit={onSubmit} formController={formController} {...restProps} />
                )
            }}
        </Formik>
    );
}

export const VehicleForm: React.FC<{
    initialValues?: VehicleDetailsFormValues
    formController: FormikProps<any>
    withSubTitle?: boolean,
    contactId?: string;
    vehicleId?: string;
    onSubmit?: any;
    onCancel?: any;
    onSave?: any;
    onBack?: any;
    parentName?: string;
}> = ({
    initialValues,
    formController,
    onSubmit,
    onCancel,
    onSave,
    onBack,
    withSubTitle = false,
    parentName,
}) => {
        const { setFieldValue, setValues, values, resetForm, isSubmitting, isValid, submitForm, dirty } = formController
        const currentRef = useRef();
        const { width, left } = useContainerDimensions(currentRef);
        const years = getYears();
        const [modelOptions, setModelOptions] = useState([""]);
        const [makeOptions, setMakeOptions] = useState([""]);
        const [getVinFromLicensePlate] = useMutation<Mutation, MutationGetVinFromLicensePlateArgs>(GET_VIN_FROM_LICENSE_PLATE);

        const getQuickVinData = async (licensePlate: string, stateCode: string) => {
            const { data } = await getVinFromLicensePlate({
                variables: {
                    licensePlate,
                    stateCode
                },
            });
            return data?.getVinFromLicensePlate;
        }

        const {
            vinInfo,
            loading: loadingVinInfo,
            errorMessage: errorMessageVin,
            setErrorMessage: setErrorVin,
            fetchVinInfo,
            setVinInfo,
        } = useVinDecoder();

        const onChangeVin = (vin: string) => {
            if (vin.length === 17) {
                fetchVinInfo({ vin });
                return;
            }
            setVinInfo(null);
            setErrorVin(null);
        };

        const [isVinWarningOpen, setIsVinWarningOpen] = useState(false);

        const onChangeVinDebounced = debounce(onChangeVin, 500);
        const onChangeLPDebounced = debounce(getQuickVinData, 500);

        const title = withSubTitle ? `Vehicle Info for ${initialValues.year} ${initialValues.make} ${initialValues.model} ${initialValues.subModel}` : "Vehicle Info";
        const parent = parentName ? `${parentName}.` : ''
        return <div ref={currentRef}>
            <Form>
                <DetailViewContainer title={title}>
                    {dirty &&
                        <Alert severity="warning" style={{ margin: "10px 0" }}>
                            {"Do you want to repalce this vehicle or add a new vehicle"}
                        </Alert>
                    }
                    <div className={"grid grid-cols-3 gap-4"}>
                        <div className="col-span-3">
                            <TextField name={`${parent}name`} label={"Vehicle Name"} />
                        </div>
                        <AutoCompleteSelectField
                            name={`${parent}year`}
                            label={"Year"}
                            options={years}
                            required={true}
                            postOnChange={(year) => {
                                setFieldValue("make", "");
                                setFieldValue("model", "");
                                if (year) {
                                    setMakeOptions(getMakesByYear(year));
                                }
                            }}
                        />
                        <AutoCompleteSelectField
                            name={`${parent}make`}
                            options={makeOptions}
                            label={"Make"}
                            required={true}
                            postOnChange={(make) => {
                                setFieldValue("model", "");
                                const year = parentName ? values[parentName].year : values.year
                                if (make && year) {
                                    setModelOptions(getModelsByYearAndMake(year, make));
                                }
                            }}
                        />
                        <AutoCompleteSelectField name={`${parent}model`} options={modelOptions} label={"Model"} required={true} />
                        <div className="col-span-3 grid grid-cols-3 gap-4">
                            <TextField name={`${parent}subModel`} label={"Sub Model"} />
                            <TextField
                                name={`${parent}vin`}
                                disabled={loadingVinInfo}
                                label={"VIN"}
                                onChangeText={onChangeVinDebounced}
                                inputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip title="Search for VIN">
                                                <IconButton
                                                    edge="end"
                                                    color="primary"
                                                    onClick={() => {
                                                        const vin = parentName ? values[parentName].vin : values.vin
                                                        fetchVinInfo({ vin });
                                                    }}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className="grid grid-cols-3 gap-4">
                                <div className="col-span-1">
                                    <TextField name={`${parent}licensePlate`} label={"License Plate"} />
                                </div>
                                <div className="col-span-1">
                                    <SelectField name={`${parent}licensePlateState`} options={US_STATES} label={"State"} />
                                </div>
                                <div className="col-span-1">
                                    <Button 
                                        className="w-full" 
                                        type='button' 
                                        disabled={!(values?.licensePlateState && values?.licensePlate)}
                                        onClick={async () => {
                                        const stateCode = US_STATES.find(s => s.value === values.licensePlateState).label;
                                        const vin = await getQuickVinData(values.licensePlate, stateCode);
                                        if(vin){
                                            setFieldValue(`${parent}vin`, vin);
                                            onChangeVinDebounced(vin);
                                            fetchVinInfo({ vin });
                                        }
                                    }}>
                                        Find VIN
                                    </Button>                       
                                 </div>
                            </div>
                            <div className="col-span-2 flex flex-row pb-6 items-center">
                                {loadingVinInfo && <CircularProgress size={20} />}
                                {errorMessageVin && !loadingVinInfo && <div className="text-red-500">{errorMessageVin}</div>}
                                {vinInfo && !loadingVinInfo && !errorMessageVin && (
                                    <Tooltip title="Vehicle data from NHTSA public VIN decoder">
                                        <div className="flex flex-row items-center">
                                            <strong>Year:&nbsp;</strong>
                                            {vinInfo.year}&nbsp;&nbsp;
                                            <strong>Make:&nbsp;</strong>
                                            {vinInfo.make}&nbsp;&nbsp;
                                            <strong>Model:&nbsp;</strong>
                                            {vinInfo.model}
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                        <div className="col-span-3 grid grid-cols-3 gap-3">
                            <div>
                                <TextField
                                    name={`${parent}wheelTorque`}
                                    label={"Wheel Torque"}
                                    type="number"
                                    inputProps={{
                                        endAdornment: <div>lbs-ft</div>,
                                    }}
                                />
                            </div>
                            <DiscardThicknessField
                                className="px-0"
                                name={`${parent}frontRotorsDiscardThicknessInches`}
                                label={"Front Rotors Discard Thickness"}
                            />
                            <DiscardThicknessField
                                className="px-0"
                                name={`${parent}rearRotorsDiscardThicknessInches`}
                                label={"Rear Rotors Discard Thickness"}
                            />
                            <div className="px-0">
                                <TextField name={`${parent}notes`} label={"Vehicle Info"} multiline={true} rows={5} />
                            </div>
                            <div className="px-0">
                                <TextField name={`${parent}odometer`} label={"Odometer"} />
                                <SelectField name={`${parent}parkingBrake`} options={PARKING_BRAKE_OPTIONS} label={"Parking Brake Type"} />
                            </div>
                            <div className="px-2">
                                <CheckBoxField name={`${parent}inactive`} label={"Inactive"} />
                                <CheckBoxField name={`${parent}hasDrumBrakes`} label={"has Drum Brakes"} />
                            </div>

                        </div>
                        <div className="col-span-3 grid grid-cols-3 gap-3">
                            {onBack && (
                                <div className="col-start-1 col-span-4 ...">
                                    <CancelButton onClick={async () => {
                                        if (parentName) setFieldValue(parentName, null)
                                        else resetForm({});
                                        await onBack()
                                    }} type={"reset"}>
                                        Cancel Add new vehicle
                                    </CancelButton>
                                </div>
                            )}
                            {onSave && (
                                <div className="col-start-10 col-span-4 ...">
                                    <Button type='submit' onClick={async () => {
                                        await onSave(values)
                                        if (onBack) {
                                            await onBack()
                                        }
                                    }} disabled={isSubmitting || !isValid || !dirty}>
                                        {isSubmitting ? <FontAwesomeIcon icon={faSpinner as any} spin={true} /> : "Save vehicle data"}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </DetailViewContainer>
                <NuModal isOpen={isVinWarningOpen} maxWidth="md" title="VIN does not match Vehicle">
                    <p>The VIN you entered does not seem to match the vehicle selected. Please verify the VIN and vehicle.</p>
                    <br />
                    <p>Vehicle:</p>
                    <p>
                        <strong>Year:&nbsp;</strong>
                        {values?.year}&nbsp;&nbsp;
                        <strong>Make:&nbsp;</strong>
                        {values?.make}&nbsp;&nbsp;
                        <strong>Model:&nbsp;</strong>
                        {values?.model}
                    </p>
                    <br />
                    <p>VIN corresponds to:</p>
                    <p>
                        <strong>Year:&nbsp;</strong>
                        {vinInfo?.year}&nbsp;&nbsp;
                        <strong>Make:&nbsp;</strong>
                        {vinInfo?.make}&nbsp;&nbsp;
                        <strong>Model:&nbsp;</strong>
                        {vinInfo?.model}
                    </p>
                    <div className="flex flex-row mt-8 justify-between">
                        <Button
                            onClick={() => {
                                setIsVinWarningOpen(false);
                            }}
                        >
                            OK
                        </Button>
                    </div>

                </NuModal>
                {onCancel && onSubmit && (
                    <SaveDeleteCancelRow
                        onDelete={undefined}
                        width={width}
                        offsetLeft={left}
                        isValid={isValid}
                        isSubmitting={isSubmitting}
                        onCancel={async () => {
                            resetForm({});
                            await onCancel()
                        }}
                    />
                )}
            </Form>
        </div>
    }