import React from "react";
import { VehicleDetailsForm, VehicleDetailsFormValues } from "../Forms/VehicleDetailsForm";
import { useHistory } from "react-router-dom";
import { useShowSuccess, useShowError } from "../../redux/slices/snackbar";
import { useMutation } from "@apollo/client";
import { Mutation, MutationUpsertVehicleArgs, VehicleInput } from "../../generated/nest-graphql";
import { UPSERT_VEHICLE } from "../../graphql/mutations/upsertVehicle";


export const AddVehicle: React.FC<{
    initialValues?: VehicleDetailsFormValues;
    contactId: string;
    onCancel?: () => Promise<void> | void;
    onBack?: () => Promise<void> | void;
    onSave?: (values: VehicleDetailsFormValues) => Promise<void> | void;
  }> = ({ initialValues, contactId, onCancel, onBack, onSave }) => {

    const values = initialValues? initialValues : {
        id: "",
        contactId: "",
        make: "",
        model: "",
        subModel: "",
        year: "",
        vin: "",
        name: "",
        notes: "",
        licensePlate: "",
        licensePlateState: "",
        wheelTorque: "",
        frontRotorsDiscardThicknessInches: 0,
        rearRotorsDiscardThicknessInches: 0,
        odometer: "",
        inactive: false,
        hasDrumBrakes: false,
        parkingBrake: ""
    };

    const history = useHistory();
    const showSuccess = useShowSuccess();
    const showError = useShowError();
    const [upsertVehicle] = useMutation<Mutation, MutationUpsertVehicleArgs>(UPSERT_VEHICLE);

    const onSubmit = async (values: VehicleDetailsFormValues, helpers) => {
        //const input = pipe(vehicleDetailsSpec, cleanObject)(values);
        const inputValues = {
            ...values,
            contactId,
            wheelTorque: String(values.wheelTorque),
          } as unknown as VehicleInput;      
        try{
            const {data, errors} = await upsertVehicle({
                variables:{
                    vehicleInputData: {
                        ...inputValues,
                        contactId,
                    }
                }
            });
            //const id = data.createInvoice.id;
            //await history.push(`/vehicles/${id}`);
            showSuccess({ message: "Vehicle Successfully Added" });
            await onCancel();
        }catch(e){
            console.log(e)
            showError({ message: "Error Saving Vehicle" });
        }
      };

     const vehicleDetailsFormProps = {
        ...(onBack? {onBack}: {onCancel}),
        ...(onSave? {onSave}: {onSubmit})
     }

    return(<VehicleDetailsForm 
        initialValues={values} 
        {...vehicleDetailsFormProps}/>)
  }