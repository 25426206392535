import React, { useState } from "react";
import { Button } from "../Buttons/Button";
import { SelectablePageableEntityTable } from "../TableViewsPages/SelectablePageableEntityTable";
import { ROW_LIMIT } from "../../lib/constants";
import { VehicleColumns, vehicleToVehicleColumns } from "../Vehicles/VehiclesTable";
import { AddVehicle } from "../Vehicles/AddVehicle";
import { useQuery } from "@apollo/client";
import { Query, QueryGetVehiclesArgs } from "../../generated/nest-graphql";
import { GET_VEHICLES } from "../../graphql/queries/getVehicles";
import { LinearProgress } from "@material-ui/core";

export const ContactsVehicles: React.FC<{ contactId: string }> = ({ contactId }) => {
    const [enableAddVehicle, setEnableAddVehicle] = useState(false);
    const { data, refetch, loading } = useQuery<Query, QueryGetVehiclesArgs>(GET_VEHICLES, {
        variables: {
            contactId
        },
    });

    //TODO: Return this shape from the backend
    const queryResult = {
        ...data,
        getVehicles: {
            edges: data?.getVehicles,
            pageInfo: {
                hasNextPage: false,
                numRecords: data?.getVehicles?.length || 0,
                offset: null
            }
        }
    } as unknown as Query;

    const onCancel = async ()=>{
        setEnableAddVehicle(false);
        await refetch();
    }

    return (
        <div className={"px-4"}>
            {enableAddVehicle ? (<>
                <AddVehicle contactId={contactId} onCancel={onCancel} />
            </>

            ) : (
                <>
                    <div className="flex flex-row justify-end">
                        <div className="my-4 mr-0">
                            <Button onClick={() => setEnableAddVehicle(true)}>+ Add Vehicle</Button>
                        </div>
                    </div>
                    {loading && <LinearProgress color="secondary" />}
                    {!loading && 
                    <SelectablePageableEntityTable
                        title={"Vehicles"}
                        queryResult={queryResult}
                        columns={VehicleColumns}
                        queryKey={"getVehicles"}
                        loadMore={refetch}
                        numRecords={data?.getVehicles?.length}
                        limit={ROW_LIMIT}
                        spec={vehicleToVehicleColumns}
                    />}

                </>
            )}
        </div>
    )
}