import * as React from "react";
import { GET_TECHNICIAN } from "../../graphql/queries/getTechnician";
import { Mutation, MutationUpdateTechnicianArgs, Query, QueryGetTechnicianArgs } from "../../generated/nest-graphql";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_TECHNICIAN } from "../../graphql/mutations/updateTechnician";
import { TechnicianDetailsForm, TechnicianDetailsFormValues } from "../Forms/TechnicianDetailsForm";
import { cleanObject, objectDiff } from "../../lib/functions";
import { isEmpty, keys } from "ramda";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { flow } from "fp-ts/lib/function";
import { technicianDetailsSpec } from "../spec/technicianDetailsSpec";
import { defaultTechnicianDaySchedule } from "../../data/defaultTechnicianDaySchedule";

export const TechnicianDetails: React.FC<{ technicianId: string }> = ({ technicianId }) => {
  const { data } = useQuery<Query, QueryGetTechnicianArgs>(GET_TECHNICIAN, {
    variables: {
      id: technicianId,
    },
  });
  const [updateTechnician] = useMutation<Mutation, MutationUpdateTechnicianArgs>(UPDATE_TECHNICIAN);
  const showSuccess = useShowSuccess();
  if (!data) return null;
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    isActive,
    isVirtual,
    isEllegibleToViocInspection,
    is1099,
    market,
    payMultiplier,
    role,
    timeZone,
    homePartsStore,
    schedulingDays,
    onWayMessage,
    serviceAreaAnchorAddress,
    homeAddress,
  } = data.getTechnician;

  const initialValues: TechnicianDetailsFormValues = {
    firstName,
    lastName,
    role,
    email,
    payMultiplier,
    phoneNumber,
    isActive,
    isVirtual,
    isEllegibleToViocInspection,
    is1099,
    market,
    timeZone,
    homePartsStore,
    onWayMessage,
    schedulingDays: isEmpty(schedulingDays) ? defaultTechnicianDaySchedule : schedulingDays,
    serviceAreaAnchorAddress,
    homeAddress,
  };

  const onSubmit = async (values: any) => {
    const diff: any = objectDiff(values, initialValues);
    const updates = flow(technicianDetailsSpec, cleanObject)(diff);
    if (keys(updates).length) {
      await updateTechnician({
        variables: {
          id: technicianId,
          updateTechnicianInput: updates,
        },
      });
      showSuccess({ message: "Successfully Updated Product" });
    }
  };
  return (
    <div className="flex flex-row">
      <div className={"flex-1 mr-6"}>
        <TechnicianDetailsForm onSubmit={onSubmit} initialValues={initialValues} />
      </div>
    </div>
  );
};
