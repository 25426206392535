import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Query, QueryGetTechniciansArgs, Technician } from "../../generated/nest-graphql";
import { defaultTo, equals, map, pipe, propOr } from "ramda";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { GET_TECHNICIANS } from "../../graphql/queries/getTechnicians";
import { FieldError } from "react-hook-form";
import { CircularProgress } from "@material-ui/core";

type TechnicianOptionType = {
  label: string;
  value: Technician;
};
export const TechnicianSelectRHF: React.FC<{
  value: any;
  market: string;
  label: string;
  shouldHideName?: boolean;
  required?: boolean;
  error: FieldError;
  onChange: any;
  onBlur: any;
}> = ({ value, required, label, market, error, onChange, onBlur, shouldHideName = false }) => {
  const marketFilter = useMemo(() => {
    return equals("", market) ? { $exists: true } : market;
  }, [market]);
  const { data, loading } = useQuery<Query, QueryGetTechniciansArgs>(GET_TECHNICIANS, {
    variables: {
      filter: {
        isActive: true,
        market: marketFilter,
      },
    },
  });
  const technicianToOptions = (technician: Technician): TechnicianOptionType => {
    const { firstName, lastName } = technician;
    return {
      label: `${firstName} ${lastName}`,
      value: technician,
    };
  };

  const handleNameVisibility = (option: TechnicianOptionType): TechnicianOptionType => {
    if (shouldHideName) {
      return {
        ...option,
        label: "Suggested Technician",
      };
    }
    return option;
  };

  const technicianOptions = pipe(propOr([], "getTechnicians"), map(technicianToOptions))(data);
  const filterOptions = createFilterOptions<TechnicianOptionType>({
    matchFrom: "start",
    stringify: (option) => {
      return option.label;
    },
  });

  if (!data || technicianOptions.length === 0) {
    if (loading) {
      return (
        <div className="flex items-center justify-center">
          <CircularProgress size={22} />
          <p className="ml-4">Loading technicians</p>
        </div>
      );
    }
    return null;
  }
  return (
    <Autocomplete<TechnicianOptionType>
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return defaultTo("", option.label);
      }}
      value={value ? handleNameVisibility(technicianToOptions(value)) : { value: null, label: "" }}
      options={technicianOptions}
      onChange={onChange}
      onBlur={onBlur}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          error={!!error}
          {...params}
          label={label}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
          helperText={error && <div>{error.message}</div>}
        />
      )}
    />
  );
};
