import React, { useEffect, useMemo, useRef, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useField } from "formik";
import { path, pathOr } from "ramda";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import PlaceType from "./location/PlaceType.interface";
import LocationFieldOption from "./location/LocationFieldOption";
import { loadGoogleMapsScript } from "./location/functions";
import { ErrorTextField } from "./ErrorTextField";

const autocompleteService = { current: null };

export const LocationSearchInput: React.FC<{
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  postOnChange?: (newValue: PlaceType | null) => void;
}> = ({ name, label, required, type = "text", postOnChange, ...props }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helper] = useField(name);
  const { value } = field;

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<PlaceType[]>([]);
  const loaded = useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadGoogleMapsScript("#google-maps");
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const handleChange = (_: object, newValue: PlaceType | null) => {
    setOptions(newValue ? [newValue, ...options] : options);
    helper.setValue(newValue ? newValue.description : newValue);

    postOnChange && postOnChange(newValue);
  };

  const renderOption = (option: any) => {
    const matches = pathOr([], ["structured_formatting", "main_text_matched_substrings"], option);

    const parts = parse(
      path<string>(["structured_formatting", "main_text"], option),
      matches.map((match: any) => [match.offset, match.offset + match.length])
    );
    return (
      <LocationFieldOption
        parts={parts}
        secondaryText={path<string>(["structured_formatting", "secondary_text"], option)}
      />
    );
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      value={value}
      includeInputInList
      filterSelectedOptions
      onChange={handleChange}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          error={!!meta.error}
          {...params}
          fullWidth
          variant="outlined"
          label={label}
          required={required}
          value={value ? value : ""}
          helperText={<>{meta.error && <ErrorTextField displayOnSpan={true} fieldName={field.name} />}</>}
          {...props}
        />
      )}
      renderOption={renderOption}
    />
  );
};
