import { TechnicianDetailsFormValues } from "../Forms/TechnicianDetailsForm";
import { applySpec, map, path, prop } from "ramda";
import { SchedulingDay, UpdateTechnicianInput } from "../../generated/nest-graphql";
import { flow } from "fp-ts/lib/function";
import { toNumber } from "../../lib/functions";
import { Optional } from "monocle-ts";
import * as Option from "fp-ts/Option";

export const technicianDetailsSpec = (values: TechnicianDetailsFormValues) => {
  const { role } = values;
  return applySpec<UpdateTechnicianInput>({
    payMultiplier: flow(prop("payMultiplier"), (val) => (val ? toNumber(val) : val)),
    isActive: prop("isActive"),
    isVirtual: prop("isVirtual"),
    isEllegibleToViocInspection: prop("isEllegibleToViocInspection"),
    is1099: prop("is1099"),
    email: prop("email"),
    firstName: prop("firstName"),
    role: path(["role", "id"]),
    roleCopy: (..._ignored) => ({
      _id: path(["role", "id"], values),
      ...role,
    }),
    lastName: prop("lastName"),
    market: prop("market"),
    phoneNumber: prop("phoneNumber"),
    password: prop("password"),
    confirmPassword: prop("confirmPassword"),
    timeZone: prop("timeZone"),
    homePartsStore: path(["homePartsStore", "id"]),
    schedulingDays: flow(
      Optional.fromNullableProp<TechnicianDetailsFormValues>()("schedulingDays").getOption,
      Option.map(
        map(
          applySpec<SchedulingDay>({
            dayOfWeek: prop("dayOfWeek"),
            startTime: prop("startTime"),
            endTime: prop("endTime"),
          })
        )
      ),
      Option.toNullable
    ),
    onWayMessage: prop("onWayMessage"),
    serviceAreaAnchorAddress: prop("serviceAreaAnchorAddress"),
    homeAddress: prop("homeAddress"),
  })(values);
};
