import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import {
  Mutation,
  MutationUpsertVehicleArgs,
  Query,
  QueryGetContactArgs,
  QueryGetJobsPaginatedV2Args,
  QueryGetJobsArgs,
  QueryGetVehicleArgs,
  VehicleType,
  Job,
} from "../../generated/nest-graphql";
import { GET_VEHICLE } from "../../graphql/queries/getVehicles";
import { LinearProgress } from "@material-ui/core";
import { VehicleDetailsForm, VehicleDetailsFormValues, VehicleForm, vehicleValidationSchema } from "../Forms/VehicleDetailsForm";
import { useHistory } from "react-router-dom";
import { UPSERT_VEHICLE } from "../../graphql/mutations/upsertVehicle";
import { useShowError, useShowSuccess } from "../../redux/slices/snackbar";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { Space } from "../Space";
import { SelectablePageableEntityTableRenderer } from "../TableViewsPages/SelectablePageableEntityTableRenderer";
import { GET_FIRST_NAME_LAST_NAME } from "../../graphql/queries/getFirstNameLastName";
import { path } from "ramda";
import { Alert, AlertTitle } from "@material-ui/lab";
import { formatDateTime } from "../../lib/functions";
import { GET_JOBS } from "../../graphql/queries/getJobs";
import { ROW_LIMIT } from "../../lib/constants";
import { Link } from "react-router-dom";
import { GET_JOBS_PAGINATED_V2 } from "../../graphql/queries/getJobsPaginatedV2";
import { getSnapshotData, StatusSnapshot } from "./StatusSnapshot";
import { estimateAverageMilesPerYear, predictFromDate, predictFromValue } from "./utils";
import { defaultMilesPerYear, padLifeMiles } from "./constants";
import { DateTime } from "luxon";
import { CellProps } from "react-table";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { Formik } from "formik";

export const convertVehicleTypeToFormValues = (vehicle: VehicleType): VehicleDetailsFormValues => {
  return {
    id: vehicle?.id,
    contactId: vehicle?.contactId,
    year: vehicle?.year,
    make: vehicle?.make,
    model: vehicle?.model,
    subModel: vehicle?.subModel,
    vin: vehicle?.vin,
    name: vehicle?.name,
    notes: vehicle?.notes,
    licensePlate: vehicle?.licensePlate,
    licensePlateState: vehicle?.licensePlateState,
    odometer: vehicle?.odometer,
    wheelTorque: vehicle?.vehicleTechnicalInfo.wheelTorque,
    frontRotorsDiscardThicknessInches: vehicle?.vehicleTechnicalInfo?.frontRotorsDiscardThicknessInches,
    rearRotorsDiscardThicknessInches: vehicle?.vehicleTechnicalInfo?.rearRotorsDiscardThicknessInches,
    hasDrumBrakes: vehicle?.vehicleTechnicalInfo?.hasDrumBrakes,
    parkingBrake: vehicle?.vehicleTechnicalInfo?.parkingBrake,
    inactive: vehicle?.inactive,
    odometerHistory: (vehicle?.odometerHistory || []).map(item => ({
      mileage: item.mileage,
      include: item.include,
      updatedAt: item.updatedAt,
      updatedBy: item.updatedBy
    }))
  };
};

interface EstimedData {
  dates: Date[];
  milages: number[];
  milesPerYear?: number;
  currentMileage?: number;
}

interface EstimedService {
  service?: string;
  milesUntilReapir?: number;
  repairDate?: string;
}

interface OdometerHistory {
  updatedAt: string;
  include: boolean;
  __typename?: "OdometerHistoryType";
  mileage?: string;
  updatedBy?: string;
}

interface ServiceHistory {
  service: string;
  jobId: string;
  jobNumber: string;
  date: string;
  jobType: string;
}

export const VehicleEditView: React.FC<{
  vehicleId: string;
}> = ({ vehicleId }) => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const showError = useShowError();
  const [odometerHistory, setOdometerHistory] = useState<OdometerHistory[]>([]);
  const [filteredOdometerHistory, setFilteredOdometerHistory] = useState<OdometerHistory[]>([])
  const [serviceHistory, setServiceHistory] = useState<ServiceHistory[]>([]);
  const [estimedData, setEstimedData] = useState<EstimedData | null | undefined>();
  const [estimedServices, setEstimedServices] = useState<EstimedService[]>([]);

  const [upsertVehicle] = useMutation<Mutation, MutationUpsertVehicleArgs>(UPSERT_VEHICLE);
  const { data, loading, error } = useQuery<Query, QueryGetVehicleArgs>(GET_VEHICLE, {
    variables: {
      id: vehicleId,
    },
  });
  const { data: lastJob, loading: lastJobLoading, error: lastJobError } = useQuery<Query, QueryGetJobsPaginatedV2Args>(GET_JOBS_PAGINATED_V2, {
    variables: {
      paginatedQueryInput: {
        filter: {
          "jobCopy.vehicleId": vehicleId,
          "jobCopy.status": "Closed",
        },
        skip: 0,
        limit: 1,
        sort: {
          "startDate": "desc",
        },
      },
    },
  });
  const initialValues: VehicleDetailsFormValues = loading
    ? undefined
    : convertVehicleTypeToFormValues(data?.getVehicle);
  const contactId = initialValues?.contactId || "";

  const job = lastJob?.getJobsPaginatedV2?.edges?.[0];
  const snapshotData = getSnapshotData(job);
  const nameQuery = useQuery<Query, QueryGetContactArgs>(GET_FIRST_NAME_LAST_NAME, {
    variables: {
      // @ts-ignore
      id: contactId,
    },
    skip: !contactId,
  });

  const {
    data: jobsData,
    loading: loadingJobsData,
    error: errorJobsData
  } = useQuery<Query, QueryGetJobsArgs>(GET_JOBS, {
    variables: {
      limit: ROW_LIMIT,
      filter: {
        contact: contactId,
        vehicleId: vehicleId,
      },
    },
  });

  useEffect(() => {
    const newServiceHistory = loadingJobsData
      ? []
      : jobsData?.getJobs?.edges
          .map((job) => {
            const invoiceServces = job.invoice?.services || [];
            const services = invoiceServces
              .map((service) => ({
                service: service?.name,
                jobId: job.id || "",
                jobNumber: job?.jobNumber || "",
                date: formatDateTime(job?.invoice?.issuedDate),
                jobType: job?.type,
              }));
            return services;
          })
          .flat();
    setServiceHistory(newServiceHistory);
  }, [jobsData?.getJobs?.edges, loadingJobsData]);
  useEffect(() => {
    const newOdometerHistory = loading
      ? []
      : data?.getVehicle?.odometerHistory
          .map((odometerValue) => ({
            ...odometerValue,
            mileage: parseInt(odometerValue.mileage).toString(),
            updatedAt: formatDateTime(odometerValue.updatedAt),
            include: odometerValue.include,
          }))
          .reverse();
    setOdometerHistory(newOdometerHistory);
  }, [data?.getVehicle?.odometerHistory, loading]);

  useEffect(() => {
    let newEstimedData: EstimedData;
    const newFilteredOdometerHistory = (odometerHistory || []).filter(item => item.include)
    if (newFilteredOdometerHistory?.length >= 2) {
      let dates: Date[] = [],
        milages: number[] = [];
        newFilteredOdometerHistory.forEach((item) => {
        dates.push(new Date(item.updatedAt));
        milages.push(Number(item.mileage));
      });

      newEstimedData = {
        dates,
        milages,
        milesPerYear: Math.round(estimateAverageMilesPerYear(dates, milages)),
        currentMileage: Math.round(predictFromDate(dates, milages, new Date())),
      };
    } else if (newFilteredOdometerHistory?.length === 1) {
      let { updatedAt, mileage } = newFilteredOdometerHistory[0];
      const date = new Date(updatedAt);
      const mileageFormated = Number(mileage);
      const milesPerMs = defaultMilesPerYear / (365.25 * 24 * 60 * 60 * 1000);
      const MsDiference = new Date().getTime() - date.getTime();
      newEstimedData = {
        dates: [date],
        milages: [mileageFormated],
        milesPerYear: Math.round(defaultMilesPerYear),
        currentMileage: Math.round(MsDiference * milesPerMs + mileageFormated),
      };
    }
    setFilteredOdometerHistory(newFilteredOdometerHistory)
    setEstimedData(newEstimedData);
  }, [odometerHistory]);

  const getMileageSinceJob = (job: Job, useDefault: boolean, milesPerMs: number) => {
    const now = new Date();
    const lastJobDate = new Date(job.invoice?.issuedDate || job.invoice?.updatedAt);

    if (useDefault) {
      return Math.round((now.getTime() - lastJobDate.getTime()) * milesPerMs);
    } else {
      const replacementMilages = Math.round(predictFromDate(estimedData.dates, estimedData.milages, lastJobDate));
      return estimedData.currentMileage - replacementMilages;
    }
  }

  const getRapirDate = (useDefault: boolean, milesPerMs: number, newEstimedService: EstimedService) => {
    const now = new Date();
    if (useDefault) {
      return newEstimedService.milesUntilReapir === 0
          ? now
          : new Date(now.setTime(now.getTime() + Math.round(newEstimedService.milesUntilReapir / milesPerMs)));
    }
    return predictFromValue(
      estimedData.dates,
      estimedData.milages,
      estimedData.currentMileage + newEstimedService.milesUntilReapir
    );
  }

  const parseServices = (type: "front" | "rear", job: Job) => {
    let newEstimedService: EstimedService = {
      service: `${type.charAt(0).toUpperCase() + type.slice(1)} Pad`,
    };
    const snItem = snapshotData.find(item => item.service.toLowerCase() === type + ' pads');
    let thicknes = Number(snItem?.thickness);
    if (job.invoice?.items.length) {
      const padsChanged = job.invoice.items.find((service) => service.name.toLowerCase().includes(`${type} pad`));
      thicknes = padsChanged ? 12 : thicknes;
    }

    if (isNaN(thicknes) || !thicknes) {
      return false;
    }
    const useDefault = filteredOdometerHistory?.length === 1;
    const milesPerMs = defaultMilesPerYear / (365.25 * 24 * 60 * 60 * 1000);
    const mileageSinceLastJob = getMileageSinceJob(job, useDefault, milesPerMs);
    const milesPerMM = padLifeMiles[type] / 12;
    // -2 because we need to change at most when they have 2 mm
    const restMiles = (milesPerMM * (thicknes - 2)) - mileageSinceLastJob;
    newEstimedService.milesUntilReapir = restMiles <= 0 ? 0 : Math.round(restMiles);
    const repairDate = getRapirDate(useDefault, milesPerMs, newEstimedService);
    newEstimedService.repairDate = DateTime.fromJSDate(repairDate).toLocaleString(DateTime.DATE_FULL);
    return newEstimedService;
  };

  useEffect(() => {
    let newEstimedServices = [];
    if (estimedData && job) {
      const estimatedFront = parseServices("front", job);
      if (estimatedFront) newEstimedServices.push(estimatedFront);
      const estimatedRear = parseServices("rear", job);
      if (estimatedRear) newEstimedServices.push(estimatedRear);
      setEstimedServices(newEstimedServices);
    }
  }, [estimedData, job]);

  const onSubmit = async (values: VehicleDetailsFormValues) => {
    try {
      await upsertVehicle({
        variables: {
          vehicleInputData: {
            ...values,
            wheelTorque: String(values.wheelTorque),
            contactId: values.contactId,
            id: vehicleId,
          },
        },
      });
      showSuccess({ message: "Vehicle Successfully Saved" });
    } catch (e) {
      console.log(e);
      showError({ message: "Error Saving Vehicle" });
    }
  };

  const onCancel = () => {
    history.push(`/contacts/${initialValues?.contactId}?tab=vehicles`);
  };


  const isLoading = loading || loadingJobsData || nameQuery?.loading || lastJobLoading;

  console.log("[isLoading]", isLoading);
  console.log("[vehicleId]", vehicleId);
  console.log("[contactId]", contactId);
  console.log("[Names]", nameQuery?.data, nameQuery?.error);
  console.log("[Vehicle]", data, error);
  console.log("[lastJob]", lastJob, lastJobError);
  console.log("[jobsData]", jobsData, errorJobsData);
  console.log("----------------------------");


  if(isLoading){
    return null;
  }

  return (
    <Formik<VehicleDetailsFormValues>
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={vehicleValidationSchema}
    enableReinitialize={true}
    validateOnMount>
    {(formController) => {

        return <div className="m-4">
        {!isLoading && !initialValues && (
          <div>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Vehicle not found with this ID <strong>{vehicleId}</strong>
            </Alert>
          </div>
        )}
        {isLoading && <LinearProgress color="secondary" />}
        {!isLoading && initialValues && (
          <div>
            <div className={"my-4 text-3xl px-4"}>
              {`${path(["data", "getContact", "firstName"])(nameQuery)} ${path(["data", "getContact", "lastName"])(
                nameQuery
              )}`}
            </div>
            <VehicleForm initialValues={initialValues} onCancel={onCancel} onSubmit={onSubmit} formController={formController}/>
            {job && (
              <div>
                <StatusSnapshot job={job} snapshotData={snapshotData} />
              </div>
            )}
            <div>
              {!isLoading && (
                <DetailViewContainer title={"Service History"} defaultExpanded={true}>
                  <SelectablePageableEntityTableRenderer
                    title={""}
                    currentPage={0}
                    columns={[
                      { Header: "Service", accessor: "service" },
                      {
                        Header: "Job number",
                        accessor: (values) => values,
                        Cell: (props) => {
                          const { jobId, jobNumber } = props.cell.value;
                          return (
                            <Link className={"text-primary"} to={`/jobs/${jobId}`}>
                              {jobNumber}
                            </Link>
                          );
                        },
                      },
                      { Header: "Date", accessor: "date" },
                      { Header: "Type", accessor: "jobType" },
                    ]}
                    numRecords={serviceHistory?.length || 0}
                    data={serviceHistory || []}
                    canNextPage={false}
                    nextPage={undefined}
                    canPrevPage={false}
                    prevPage={undefined}
                    hiddenSelection
                  />
                </DetailViewContainer>
              )}
            </div>
            {!isLoading && odometerHistory && (
              <div>
                <DetailViewContainer title={"Mileage History"} defaultExpanded={true}>
                  <SelectablePageableEntityTableRenderer
                    title={""}
                    currentPage={0}
                    columns={[
                      { Header: "Mileage", accessor: "mileage" },
                      { Header: "UpdatedAt", accessor: "updatedAt" },
                      { Header: "UpdatedBy", accessor: "updatedBy" },
                      {
                        Header: "Include",
                        accessor: "include",
                        Cell: ({row}: CellProps<any, boolean>) => <CheckBoxField name={`odometerHistory.${odometerHistory.length - row.index - 1}.include`}/>
                      },
                    ]}
                    numRecords={odometerHistory?.length || 0}
                    data={odometerHistory || []}
                    canNextPage={false}
                    nextPage={undefined}
                    canPrevPage={false}
                    prevPage={undefined}
                    hiddenSelection
                  />
                </DetailViewContainer>
              </div>
            )}
            <Space />
            {!isLoading && estimedData && (
              <div>
                <SelectablePageableEntityTableRenderer
                  title={""}
                  currentPage={0}
                  columns={[
                    { Header: "Estimated Miles driven per year", accessor: "milesPerYear" },
                    { Header: "Estimated current Mileage", accessor: "currentMileage" },
                  ]}
                  numRecords={1}
                  data={[estimedData]}
                  canNextPage={false}
                  nextPage={undefined}
                  canPrevPage={false}
                  prevPage={undefined}
                  hiddenSelection
                />
                <Space />
              </div>
            )}
            {!isLoading && !!estimedServices.length && (
              <div>
                <SelectablePageableEntityTableRenderer
                  title={""}
                  currentPage={0}
                  columns={[
                    { Header: "Service", accessor: "service" },
                    { Header: "Estimated Miles until Repair", accessor: "milesUntilReapir" },
                    { Header: "Estimated Repair Date", accessor: "repairDate" },
                  ]}
                  numRecords={estimedServices.length}
                  data={estimedServices}
                  canNextPage={false}
                  nextPage={undefined}
                  canPrevPage={false}
                  prevPage={undefined}
                  hiddenSelection
                />
            </div>
          )}
          <Space />
        </div>
      )}
    </div>
    }}
    </Formik>
  );
};
