import * as React from "react";
import { JobDetailsForm, JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { useMutation } from "@apollo/client";
import { Mutation, MutationCreateJobArgs, MutationUpdateContactArgs } from "../../generated/nest-graphql";
import { CREATE_JOB } from "../../graphql/mutations/createJob";
import { UPDATE_CONTACT } from "../../graphql/mutations/updateContact";
import { keys, path, pipe, prop } from "ramda";
import { jobDetailsSpec } from "../specs/jobDetailsSpec";
import { cleanObject, isPresent } from "../../lib/functions";
import { useHistory } from "react-router-dom";
import { useShowSuccess } from "../../redux/slices/snackbar";
import { DEFAULT_SERVICE_CATALOGUE_USED, INITIAL_JOB_STATUS } from "../../lib/constants";
import { exists } from "../../commonFunctions";

export const AddJob: React.FC<{ initialValues?: JobDetailsFormValues }> = ({ initialValues }) => {
  const history = useHistory();
  const showSuccess = useShowSuccess();
  const [createJob] = useMutation<Mutation, MutationCreateJobArgs>(CREATE_JOB);
  const [updateContact] = useMutation<Mutation, MutationUpdateContactArgs>(UPDATE_CONTACT);
  const onSubmit = async (values: JobDetailsFormValues, helpers) => {
    const input = pipe(jobDetailsSpec, cleanObject)(values);
    const newEmail = prop("email", values);
    const contactEmail = path(["contact", "email"], values);
    const contactUpdates = isPresent(newEmail) && newEmail !== contactEmail ? { email: newEmail } : {};

    if (keys(contactUpdates).length) {
      await updateContact({
        variables: {
          id: path(["contact", "id"], values),
          updateContactInput: { email: newEmail },
        },
      });
    }
    const result = await createJob({
      variables: {
        // @ts-ignore
        createJobInput: input,
      },
    });

    const id = result.data.createJob.id;
    showSuccess({ message: "Successfully Added Job" });
    await history.push(`/jobs/${id}`);
  };

  return (
    <JobDetailsForm
      appointment={null}
      showAddAppointment={true}
      // @ts-ignore
      initialValues={
        initialValues
          ? initialValues
          : {
              status: INITIAL_JOB_STATUS,
              jobName: "",
              type: "",
              contact: null,
              market: "",
              serviceLocation: "",
              appointmentId: null,
              items: [],
              taxable: !exists(initialValues?.contact?.taxExemptEin) ?? true,
              privateNotes: "",
              serviceLocationNotes: "",
              partsLocation: "",
              additionalNotes: "",
              customerExpectation: "",
              frontBrakeSymptoms: [],
              rearBrakeSymptoms: [],
              jobCausedWarrantyCheck: null,
              jobCausedDiagnostic: null,
              jobViocBrakeRepair: null,
              jobViocInspection: null,
              serviceCatalogueUsed: DEFAULT_SERVICE_CATALOGUE_USED,
              services: [],
              vehicleSymptoms: [],
              requestedServices: [],
            }
      }
      onSubmit={onSubmit}
    />
  );
};
