import { Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { TextField } from "../FormFields/TextField";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { SelectField } from "../FormFields/SelectField";
import { adminSources } from "../adminSources";
import { LocationSearchInput } from "../FormFields/LocationSearchInput";
import { PhoneNumberField } from "../FormFields/PhoneNumberField";
import * as Yup from "yup";
import { VehicleInfoValues } from "../VehicleInfo/VehicleInfoFormSection";
import { SaveCancelRow } from "../SaveCancelRow";
import { useContainerDimensions } from "../../hooks/useContainerDimensions";
import { getFieldValueFromMarkets, MarketFieldSelect } from "../FormFields/MarketFieldSelect";
import PlaceType from "../FormFields/location/PlaceType.interface";
import { setPostalCode } from "../FormFields/location/functions";
import TimeZoneSelect from "../FormFields/TimeZoneSelect";
import Link from "@material-ui/core/Link";
import { Space } from "../Space";
import { useMarkets } from "../../hooks/useMarkets";
import { isEmpty, prop } from "ramda";
import { CheckBoxField } from "../FormFields/CheckBoxField";
import { generateFakeEmail } from "../../commonFunctions";
import { useLazyQuery } from "@apollo/client";
import { GET_MARKET_FOR_ZIPCODE } from "../../graphql/queries/getMarketForZipcode";
import { Query, QueryGetMarketForZipcodeArgs } from "../../generated/nest-graphql";
import { useShowInfo, useShowWarning } from "../../redux/slices/snackbar";
import { Button, CircularProgress } from "@material-ui/core";
import { VehicleDetailsFormValues, VehicleForm, vehicleValidationSchema } from "./VehicleDetailsForm";

export const ContactDetailValidationSchema = (withVehicle: boolean) => Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().nullable(),
  email: Yup.string().email("Must be in email format").required("Required"),
  phoneNumber: Yup.string().required("Required"),
  market: Yup.string().required("Required"),
  hearAboutUs: Yup.string().nullable(),
  zip: Yup.string().nullable(),
  address: Yup.string().nullable(),
  source: Yup.string().nullable(),
  outreachMedium: Yup.string().nullable(),
  make: Yup.string().nullable(),
  model: Yup.string().nullable(),
  year: Yup.string().nullable(),
  symptoms: Yup.string().nullable(),
  extraInfo: Yup.string().nullable(),
  fleetName: Yup.string().when("isFleet", {
    is: (value) => value,
    then: Yup.string().required("Must be a valid Fleet Name"),
    otherwise: Yup.string().nullable(),
  }),
  taxExemptEin: Yup.string().when("isTaxExempt", {
    is: (value) => value,
    then: Yup.string().required("Must be a valid EIN"),
    otherwise: Yup.string().nullable(),
  }),
  isFleet: Yup.boolean().nullable(),
  isTaxExempt: Yup.boolean().nullable(),
  vin: Yup.string().length(17, "VIN must be 17 digits").nullable(),
  vehicle: withVehicle ? vehicleValidationSchema : null
});

export type VehicleSymptomFormValues = {
  axle?: string;
  symptom?: string;
};

export type ContactDetailsFormValues = VehicleInfoValues & {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  market: string;
  hearAboutUs: string;
  zip: string;
  address: string;
  vehicleId: string;
  source?: string;
  outreachMedium;
  timeZone: string;
  fleetName: string;
  isFleet: boolean;
  taxExemptEin: string;
  isTaxExempt: boolean;
  vehicleSymptoms?: VehicleSymptomFormValues[];
  requestedServices?: string[];
  vehicle?: VehicleDetailsFormValues;
};

export const ContactDetailsForm: React.FC<{
  initialValues: ContactDetailsFormValues;
  onSubmit: (values: any, helpers: any) => void;
  enableReinitialize?: boolean;
  noVehicleSection?: boolean;
}> = ({ initialValues, onSubmit, enableReinitialize, noVehicleSection }) => {
  const [enableAddVehicle, setEnableAddVehicle] = useState(false)
  const currentRef = useRef();
  const { width, left } = useContainerDimensions(currentRef);
  const markets = useMarkets();
  const showWarning = useShowWarning();
  const showInfo = useShowInfo();
  const [getMarketForZipcode, { loading: isLoadingMarket }] = useLazyQuery<Query, QueryGetMarketForZipcodeArgs>(
    GET_MARKET_FOR_ZIPCODE,
    {}
  );
  const onZipChange = async (
    zip: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (zip.length === 5) {
      const data = await getMarketForZipcode({ variables: { zipcode: zip } });
      const marketName = data?.data?.getMarketForZipcode?.name;
      if (marketName) {
        setFieldValue("market", marketName);
        setFieldValue("timeZone", prop(marketName as any, getFieldValueFromMarkets(markets, "timeZone", marketName)));
        showInfo({
          message: `Market for zipcode ${zip}: ${marketName}`,
          autoHideDuration: 4000,
        });
      } else {
        showWarning({
          message: `Zipcode ${zip} was not found in our service area`,
          autoHideDuration: 4000,
        });
        setFieldValue("market", "");
      }
    }
  };
  return (
    <Formik<ContactDetailsFormValues>
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={ContactDetailValidationSchema(enableAddVehicle)}
      enableReinitialize={enableReinitialize}
    >
      {(formController) => {
        const {
          isSubmitting,
          isValid,
          resetForm,
          setFieldValue,
          values: { email, isFleet, isTaxExempt, firstName, lastName, phoneNumber },
        } = formController
        return (
          <Form ref={currentRef}>
            <DetailViewContainer title={"General Information"}>
              <div className="grid grid-cols-2 gap-4">
                <TextField name={"firstName"} label={"First Name"} required={true} />
                <TextField name={"lastName"} label={"Last Name"} />
                <TextField
                  name={"email"}
                  label={"Email"}
                  type={"email"}
                  required={true}
                  helperText={
                    isEmpty(email) && (
                      <>
                        <Link
                          style={{ color: "#fff" }}
                          href="#"
                          onClick={() => setFieldValue("email", generateFakeEmail(phoneNumber), false)}
                        >
                          Couldn't Collect?
                        </Link>
                      </>
                    )
                  }
                />
                <PhoneNumberField name={"phoneNumber"} label={"Phone Number"} required={true} />
                <LocationSearchInput
                  name={"address"}
                  label={"Address"}
                  postOnChange={(value: PlaceType | null) => {
                    if (value === null) {
                      return;
                    }
                    const placeId = value.place_id;
                    setPostalCode(placeId, (postalCode: string) => {
                      setFieldValue("zip", postalCode);
                      onZipChange(postalCode, setFieldValue);
                    });
                  }}
                />
                <TextField
                  name={"zip"}
                  label={"Zip"}
                  onChangeText={(zip) => {
                    onZipChange(zip, setFieldValue);
                  }}
                />
                <SelectField name={"source"} options={adminSources} label={"Source"} />
                <SelectField
                  name={"outreachMedium"}
                  options={[
                    "Inbound Call",
                    "Outbound Call",
                    "Yelp",
                    "Facebook",
                    "Text",
                    "Chat Widget",
                    "Email",
                    "Other",
                  ]}
                  label={"Outreach Medium"}
                />
                <TextField name={"hearAboutUs"} label={"Hear About Us"} />
                <div className="flex flex-row">
                  <MarketFieldSelect
                    name={"market"}
                    required={true}
                    postOnChange={(value: any) => {
                      if (value === null) return;
                      setFieldValue("timeZone", prop(value, getFieldValueFromMarkets(markets, "timeZone", value)));
                    }}
                  />
                  {isLoadingMarket ? <CircularProgress className="ml-2 mt-4" size={24} /> : null}
                </div>
                <TimeZoneSelect name={"timeZone"} label={"Time Zone"} />
                <Space />
                <CheckBoxField name={"isFleet"} label={"Fleet Contact"} />
                {isFleet && (
                  <>
                    <Space />
                    <TextField name={"fleetName"} label={"Fleet Name"} />
                  </>
                )}
                <Space />
                <CheckBoxField name={"isTaxExempt"} label={"Tax Exempt"} />
                {isTaxExempt && (
                  <>
                    <Space />
                    <TextField name={"taxExemptEin"} label={"Employer Identification Number (EIN)"} />
                  </>
                )}
              </div>
            </DetailViewContainer>
            {!noVehicleSection && (<>
              {!enableAddVehicle && <div className="flex justify-end mt-2">
                <Button variant="contained" color="secondary" onClick={() => setEnableAddVehicle(true)}>+ Add Vehicle</Button>
              </div>}
              {enableAddVehicle && <VehicleForm
                formController={formController}
                parentName="vehicle"
                initialValues={initialValues.vehicle}
                onBack={() => setEnableAddVehicle(false)}
              />}
            </>)}
            <Space />
            <SaveCancelRow
              width={width}
              offsetLeft={left}
              onCancel={() => resetForm({ values: initialValues })}
              isSubmitting={isSubmitting}
              isValid={isValid}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
