import { AppointmentFormValues } from "../Forms/AppointmentForm";
import { applySpec, prop, path } from "ramda";
import { technicianCopySpec } from "./technicianCopySpec";
import { flow } from "fp-ts/lib/function";

export const appointmentDetailsSpec = (formData: AppointmentFormValues) => {
  return applySpec<AppointmentFormValues>({
    job: prop("job"),
    technician: path(["technician", "id"]),
    allDay: prop("allDay"),
    startDate: prop("startDate"),
    isFixed: prop("isFixed"),
    endDate: prop("endDate"),
    timeZone: prop("timeZone"),
    timeWindow: {
      startTimeWindow: prop("startTimeWindow"),
      endTimeWindow: prop("endTimeWindow"),
    },
    subject: prop("subject"),
    technicianCopy: flow(prop("technician"), technicianCopySpec),
    overrideOutsideServiceZone: prop("overrideOutsideServiceZone"),
    availabilitySnapshotId: prop("availabilitySnapshotId"),
    appointmentSnapshotId: prop("appointmentSnapshotId"),
  })(formData);
};
