import gql from "graphql-tag";

export const viocStoreFields = `
  id
  storeNumber
  address
  city
  state
  zip
`;

export const GET_ALL_VIOC_STORES = gql`query getAllViocStores($filter: JSON) {
  getAllViocStores(filter: $filter) {
      ${viocStoreFields}
  }
}`;
