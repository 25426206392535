import axios from "axios";

interface NHTSADecodeVinFlatResponse {
  Count?: number;
  Message?: string;
  SearchCriteria?: string;
  Results?: Array<{
    Make: string;
    MakeID: string;
    Manufacturer: string;
    ManufacturerId: string;
    Model: string;
    ModelYear: string;
    VIN: string;
    VehicleType: string;
  }>;
}

let cache: { [key: string]: NHTSADecodeVinFlatResponse } = {};

// NHTSA Public Vehicle API
// https://vpic.nhtsa.dot.gov/api/Home
export const nhtsaDecodeVinFlat = async ({
  vin,
  modelYear,
}: {
  vin: string;
  modelYear?: string;
}): Promise<NHTSADecodeVinFlatResponse> => {
  const baseUrl = "https://vpic.nhtsa.dot.gov/api";
  const key = `${vin}${modelYear ? `-${modelYear}` : ""}`;

  if (cache[key]) return cache[key];

  let requestUrl = baseUrl + `/vehicles/DecodeVinValues/${vin}?format=json`;

  if (modelYear) {
    requestUrl += `&modelyear=${modelYear}`;
  }

  const response = await axios.get<NHTSADecodeVinFlatResponse>(requestUrl);

  const responseData = response?.data;

  if (responseData?.Results?.length) {
    cache[key] = responseData;
  }

  return responseData;
};
