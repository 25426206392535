import { useTheme, Theme, FormControl, InputLabel, makeStyles, MenuItem, Select, Chip } from "@material-ui/core";
import { useField } from "formik";
import React from "react";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, value: string[], theme: Theme) {
  return {
    fontWeight: value.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
export const TagsSelectField = ({ label, name, options }: { label: string; name: string; options: string[] }) => {
  const [field] = useField(name);
  const theme = useTheme();
  const classes = useStyles();
  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="demo-multiple-chip-label" style={{ zIndex: 1 }}>
        {label}
      </InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-mutiple-chip"
        {...field}
        multiple
        value={field.value ?? ""}
        onChange={field.onChange}
        // input={<Input id="select-multiple-chip" />}
        renderValue={(selected: string[]) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}
      >
        {options.map((name) => (
          <MenuItem key={name} value={name} style={getStyles(name, field.value, theme)}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
