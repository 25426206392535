import * as React from "react";
import { useEffect, useState } from "react";
import { TextField } from "../FormFields/TextField";
import { padLifeOptions } from "../FormFields/padLifeOptions";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { SelectField } from "../FormFields/SelectField";
import { AutoCompleteCheckboxes } from "../FormFields/AutoCompleteCheckboxes";
import FormLabel from "@material-ui/core/FormLabel";
import { getMakesByYear, getModelsByYearAndMake, getYears } from "../../lib/quoteFormUtil";
import { useFormikContext } from "formik";
import { AutoCompleteSelectField } from "../FormFields/AutoCompleteSelectField";
import { US_STATES } from "../../lib/constants";
import { Button } from "../Buttons/Button";
import { CircularProgress, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { debounce } from "lodash";
import { NuModal } from "../NuModal";
import { useVinDecoder } from "../../hooks/useVinDecoder";
import { useMutation, useQuery } from "@apollo/client";
import { GET_VEHICLE_SERVICE_EXCEPTION } from "../../graphql/queries/getVehicleServiceException";
import { Mutation, MutationUpsertVehicleArgs, Query, QueryGetVehicleArgs, QueryGetVehiclesArgs, VehicleInput, VehicleType } from "../../generated/nest-graphql";
import { Alert } from "../Alert";
import classNames from "classnames";
import { VehicleDetailsForm, VehicleDetailsFormValues } from "../Forms/VehicleDetailsForm";
import { GET_VEHICLE, GET_VEHICLES } from "../../graphql/queries/getVehicles";
import { JobDetailsFormValues } from "../Forms/JobDetailsForm";
import { convertVehicleTypeToFormValues } from "../Vehicles/VehicleEditView";
import { UPSERT_VEHICLE } from "../../graphql/mutations/upsertVehicle";
import { useShowError, useShowSuccess } from "../../redux/slices/snackbar";
import { ContactDetailsFormValues } from "../Forms/ContactDetailsForm";
import { AddVehicle } from "../Vehicles/AddVehicle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { GET_JOB } from "../../graphql/queries/getJob";

export const vehicleBrakeSymptomOptions = [
  "Squeaking",
  "Grinding",
  "Shaking/Vibrating",
  "Mushy/Soft Brake Pedal",
  "Stiff Brake Pedal",
  "Fluid Leak in Front or Rear",
  "Other",
];

export type VehicleInfoValues = {
  make?: string;
  model?: string;
  year?: string;
  licensePlate?: string;
  licensePlateState?: string;
  vin?: string;
  odometer?: string;
  frontRotorsDiscardThicknessInches?: number;
  rearRotorsDiscardThicknessInches?: number;
  frontPadLife?: string;
  rearPadLife?: string;
  frontBrakeSymptoms?: string[];
  rearBrakeSymptoms?: string[];
  customerExpectation?: string;
  additionalNotes?: string;
  extraInfo?: string;
  wheelTorque?: Number;
  symptoms?: string;
  partsLeadTimeInDays?: number;
};

interface VehicleData {
  make: string;
  model: string;
  year: string;
}
const toOptionalFixed = (value: number, digits: number) => `${Number.parseFloat(value.toFixed(digits))}`;
export const DiscardThicknessField: React.FC<{
  label: string;
  name: string;
  className?: string;
}> = ({ label, name, className }) => {
  const { values } = useFormikContext<VehicleInfoValues | VehicleDetailsFormValues>();
  const valueInches = values && values[name] ? values[name] : 0;
  const valueInchesFormatted = valueInches ? `${toOptionalFixed(valueInches, 8)} in` : null;
  const valueMm = valueInches ? valueInches * 25.4 : null;
  const valueMmFormatted = valueMm ? `${toOptionalFixed(valueMm, 8)} mm` : null;
  return (
    <div className={classNames("flex flex-col", className)}>
      <TextField
        name={name}
        label={label}
        type="number"
        inputProps={{
          endAdornment: <div>inches</div>,
        }}
      />
      <p>{`${valueInchesFormatted || ""}${valueInchesFormatted && valueMmFormatted ? "  -  " : ""}${valueMmFormatted || ""
        }`}</p>
    </div>
  );
};

export const VehicleInfoFormSection: React.FC<{ 
  defaultExpanded?: boolean, 
  contactId?: string, 
  jobId?: string, 
  disableVehicleSelection?: boolean,
  onNew?: ()=>void,
  onCancel?: ()=>void,
}> = ({ defaultExpanded = true, 
  contactId,
  jobId, 
  disableVehicleSelection = false,
  onNew,
  onCancel,
}) => {

  //TO DO: Add this as one prop function
  const { setFieldValue: setJobFieldValue, values: jobValues, submitForm } = useFormikContext<JobDetailsFormValues>();
  const { setFieldValue: setContactDetailsFieldValue, values: _ } = useFormikContext<ContactDetailsFormValues>();

  const showSuccess = useShowSuccess();
  const showError = useShowError();
  const [enableAddVehicle, setEnableAddVehicle] = useState(false);

  const { data: jobData } = useQuery<Query>(GET_JOB, {
    variables: {
      id: jobId,
    },
    skip: !jobId,
  });

  // OLD VIN LOGIC
  /*const [isVinWarningOpen, setIsVinWarningOpen] = useState(false);

  const {
    vinInfo,
    loading: loadingVinInfo,
    errorMessage: errorMessageVin,
    setErrorMessage: setErrorVin,
    fetchVinInfo,
    setVinInfo,
  } = useVinDecoder();

  const hasVehicleData = !!values.year && !!values.make && !!values.model;
  const { data: vehicleExceptionData } = useQuery<Query>(GET_VEHICLE_SERVICE_EXCEPTION, {
    variables: {
      getVehicleServiceExceptionInput: {
        year: Number(values.year),
        make: values.make,
        model: values.model,
      },
    },
    skip: !hasVehicleData,
  });


  const isExceptionVehicle = hasVehicleData && !!vehicleExceptionData?.getVehicleServiceException?.VehicleServiceExceptionRuleId;

  const onChangeVin = (vin: string) => {
    if (vin.length === 17) {
      fetchVinInfo({ vin });
      return;
    }
    setVinInfo(null);
    setErrorVin(null);
  };
  const onChangeVinDebounced = debounce(onChangeVin, 500);

  const doesVinInfoMatchesVehicleData = (vinInfo: VehicleData, dataToCompare: VehicleData) => {
    return (
      vinInfo.make.toLowerCase() === dataToCompare.make.toLowerCase() &&
      vinInfo.model.toLowerCase() === dataToCompare.model.toLowerCase() &&
      vinInfo.year === dataToCompare.year
    );
  };

  useEffect(() => {
    if (!vinInfo || !values.make || !values.model || !values.year) return;
    if (
      !doesVinInfoMatchesVehicleData(vinInfo, {
        make: values.make || "",
        model: values.model || "",
        year: values.year || "",
      })
    ) {
      setIsVinWarningOpen(true);
    }
  }, [vinInfo]);*/

  const finalContactId = jobValues?.contact?.id || contactId || '';

  const { data, refetch, loading } = useQuery<Query, QueryGetVehiclesArgs>(GET_VEHICLES, {
    variables: {
      contactId: finalContactId,
    },
    skip: !finalContactId,
  });

  const contactVechiles = loading && !finalContactId ? [] : data?.getVehicles?.map(vehicle => ({
    label: `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.subModel || ''}`,
    value: vehicle.id
  }));
  const vehicleId = jobValues?.vehicleId || jobData?.getJob?.vehicleId;
  const [upsertVehicle] = useMutation<Mutation, MutationUpsertVehicleArgs>(UPSERT_VEHICLE);
  const { data: selectedVehicleData, loading: loadingSelectedVehicle } = useQuery<Query, QueryGetVehicleArgs>(GET_VEHICLE, {
    variables: {
      id: vehicleId,
    },
    skip: !vehicleId,
  });
  const selectedVehicle = selectedVehicleData?.getVehicle;
  const initialValues: VehicleDetailsFormValues = !loadingSelectedVehicle && selectedVehicle ? convertVehicleTypeToFormValues(selectedVehicle) : undefined;


  const setJobVehicle = (vehicleId: string) => {
    setJobFieldValue("vehicleId", vehicleId)
    setContactDetailsFieldValue("vehicleId", vehicleId)
  }

  useEffect(() => {
    if (selectedVehicle) {
      const { year, make, model, subModel } = selectedVehicle;
      setJobFieldValue("jobName", `${year || ''} ${make || ''} ${model || ''} ${subModel || ''}`);
      setJobVehicle(selectedVehicle.id);
      submitForm();
    }
  }, [selectedVehicle]);

  const onSave = async (values: VehicleDetailsFormValues) => {
    const isNewVehchicle = !values.id;
    const inputValues = {
      ...values,
      contactId: finalContactId,
      wheelTorque: String(values.wheelTorque),
    } as unknown as VehicleInput;
    try {
      const { data: newVehicle } = await upsertVehicle({
        variables: {
          vehicleInputData: inputValues,
        }
      });
      showSuccess({ message: "Vehicle data successfully saved" });

      if (isNewVehchicle) {
        setJobVehicle(newVehicle.upsertVehicle.id);
        showSuccess({ message: "Vehicle set as new job vehicle" });
        await submitForm();
      }
    } catch (e) {
      showError({ message: "Error saving vehicle" });
    }
  };

  const handleNew = ()=>{
    if(onNew){
      onNew();
    }
    setEnableAddVehicle(true);
  }


  const onBack = async () => {
    if(onCancel){
      onCancel();
    }
    setEnableAddVehicle(false);
    await refetch();
  }

  return (<>
    {loading && <div className="flex flex-row justify-center mt-6"><FontAwesomeIcon icon={faSpinner as any} spin={true} /></div>}
    {!loading && (
      <div className="mt-6">
        {enableAddVehicle ? (<>
          <AddVehicle contactId={contactId} onBack={onBack} onSave={onSave} />
        </>
        ) : (
          <>
            <div className="flex flex-row">
              <div className="grow w-10/12">
                {!!contactVechiles?.length && (
                  <SelectField
                    name={"vehicleId"}
                    label={"Job Vehicle"}
                    options={contactVechiles}
                    disabled={disableVehicleSelection}
                  />
                )}

              </div>
              {!disableVehicleSelection && (
                <div className="mx-2 min-w-4">
                  <Button onClick={handleNew}>+ Add Vehicle</Button>
                </div>
              )}
            </div>

            {vehicleId && !loadingSelectedVehicle && (
              <VehicleDetailsForm
                initialValues={initialValues}
                onSave={disableVehicleSelection? undefined : onSave}
              />
            )}
          </>
        )}
      </div>
    )}
  </>

  );
};


