import React from "react";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import { findAppointmentIndexTime } from "./functions";
import { AppointmentEvent } from "./AppointmentEvent";
import { TravelDistanceEvent } from "./TravelDistanceEvent";
import { Appointment } from "../../generated/nest-graphql";
import { defaultTo } from "ramda";
import { DateTime } from "luxon";

export const useStyles = makeStyles({
  root: {
    minWidth: 200,
    padding: 1,
    border: "2px solid #a9a9a9",
  },
});

const getDestinationData = (
  originAppointment: Appointment,
  destinationAppointment: Appointment
): {
  address: string;
  label: string | null;
} | null => {
  if (destinationAppointment?.job?.serviceLocation) {
    return {
      address: destinationAppointment?.job?.serviceLocation,
      label: null,
    };
  }

  const timeZone = originAppointment?.timeZone ?? "America/Chicago";
  const startTimeISO = originAppointment?.startDate
    ? DateTime.fromISO(originAppointment.startDate, { zone: timeZone }).toISOTime().slice(0, 5)
    : null;
  const techHome = originAppointment?.technician?.homeAddress ?? null;
  if (startTimeISO && startTimeISO >= "13:00" && techHome) {
    return {
      address: techHome,
      label: "Technician Home",
    };
  }

  return null;
};

export const DriveTimeDistanceTableCell: React.FC<{
  appointments: Appointment[];
  time: string;
}> = ({ appointments, time }) => {
  const classes = useStyles();
  const index = findAppointmentIndexTime(time, appointments);
  if (index === -1) {
    return <TableCell className={classes.root} />;
  }

  const originAppointment: Appointment | null = defaultTo(null)(appointments[index]);
  const destinationAppointment: Appointment | null = defaultTo(null)(appointments[index + 1]);

  const origin = originAppointment?.job?.serviceLocation ?? null;

  const destination = getDestinationData(originAppointment, destinationAppointment);

  return (
    <TableCell className={classes.root}>
      <AppointmentEvent appointment={originAppointment} />
      {destination && (
        <TravelDistanceEvent origin={origin} destination={destination?.address} destinationLabel={destination?.label} />
      )}
    </TableCell>
  );
};
