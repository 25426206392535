interface ServiceObject {
  title: string;
  services?: string[];
}

export const padLifeMiles = {
  front: 35000,
  rear: 45000,
};

export const defaultMilesPerYear = 14000

export const mappedSnapshotData: [string[], ServiceObject][] = [
  [
    ["frontPads", "frontPadsV2"],
    {
      title: "Front Pads",
      services: ["616060f7cafe02eccc346a2f"],
    },
  ],
  [
    ["frontRotors"],
    {
      title: "Front Rotors",
      services: ["616060f7cafe02eccc346a3b", "616060f7cafe02eccc346a43"],
    },
  ],
  [
    ["rearPads", "rearPadsV2"],
    {
      title: "Rear Pads",
      services: ["616060f7cafe02eccc346a35"],
    },
  ],
  [
    ["rearRotors"],
    {
      title: "Rear Rotors",
      services: ["616060f7cafe02eccc346a3f", "616060f7cafe02eccc346a4a"],
    },
  ],
  [
    ["brakeFluid"],
    {
      title: "Brake Fluid",
      services: ["6336023ce45d8f01f2bd14a6", "63360307a14bd90f43081f98", "659dac77894649c5ac5929c6"],
    },
  ],
  [
    ["rearShoes"],
    {
      title: "Rear Shoes",
      services: ["616060f7cafe02eccc346a58"],
    },
  ],
  [
    ["rearDrums"],
    {
      title: "Rear Drums",
      services: ["616060f7cafe02eccc346a51"],
    },
  ],
  [
    ["frontLeftCaliper"],
    {
      title: "Front Left Caliper",
    },
  ],
  [
    ["frontRightCaliper"],
    {
      title: "Front Right Caliper",
    },
  ],
  [
    ["rearLeftCaliper"],
    {
      title: "Rear Left Caliper",
    },
  ],
  [
    ["rearRightCaliper"],
    {
      title: "Rear Right Caliper",
    },
  ],
  [
    ["frontLeftHubAssemblyBearing"],
    {
      title: "Front Left Hub Assembly/Bearing",
    },
  ],
  [
    ["frontRightHubAssemblyBearing"],
    {
      title: "Front Right Hub Assembly/Bearing",
    },
  ],
  [
    ["rearLeftHubAssemblyBearing"],
    {
      title: "Rear Left Hub Assembly/Bearing",
    },
  ],
  [
    ["rearRightHubAssemblyBearing"],
    {
      title: "Rear Right Hub Assembly/Bearing",
    },
  ],
];

export const GOOD_CONDITION_OPTION = "Good condition";

export const PADS_CONDITION_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Glazing",
  "Uneven Wear",
  "Rust",
  "Cracks",
  "Discoloration",
  "Grinding",
];

export const ROTORS_CONDITION_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Grooving",
  "Uneven Wear/Rust lip",
  "Rust",
  "Cracks",
  "Heat spots",
  "Grinding",
  "Warped",
  "Glazing",
];

export const SHOES_CONDITIONS_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Squeaking/rubbing/scraping noises during road test",
  "Extreme rust",
  "Out-of-round drum",
];

export const DRUMS_CONDITIONS_OPTIONS = [
  GOOD_CONDITION_OPTION,
  "Squeaking/rubbing/scraping noises during road test",
  "Extreme rust",
  "Out-of-round drum",
];

export const INSPECTION_COLOR_GRADES = ["Red", "Yellow", "Green"];
