import React from "react";
import { TabItem } from "../TabView/TabItem";
import { useQuery } from "@apollo/client";
import { Query, QueryGetContactArgs } from "../../generated/nest-graphql";
import { GET_FIRST_NAME_LAST_NAME } from "../../graphql/queries/getFirstNameLastName";
import { path } from "ramda";
import { ContactDetailsContainer } from "./ContactDetailsContainer";

export const ContactsTabView: React.FC<{ contactId: string }> = ({ contactId }) => {
  const nameQuery = useQuery<Query, QueryGetContactArgs>(GET_FIRST_NAME_LAST_NAME, {
    variables: {
      // @ts-ignore
      id: contactId,
    },
  });
  return (
    <div>
      <div className={"my-4 text-3xl px-4"}>
        {`${path(["data", "getContact", "firstName"])(nameQuery)} ${path(["data", "getContact", "lastName"])(
          nameQuery
        )}`}
      </div>
      <ul className="flex border-b">
        <TabItem href={`/contacts/${contactId}`}>Contact</TabItem>
        <TabItem href={`/contacts/${contactId}?tab=estimates`}>Estimates</TabItem>
        <TabItem href={`/contacts/${contactId}?tab=jobs`}>Jobs</TabItem>
        <TabItem href={`/contacts/${contactId}?tab=vehicles`}>Vehicles</TabItem>
        <TabItem href={`/contacts/${contactId}?tab=invoices`}>Invoices</TabItem>
        <TabItem href={`/contacts/${contactId}?tab=payments`}>Payments</TabItem>
        <TabItem href={`/contacts/${contactId}?tab=files`}>Files</TabItem>
        <TabItem href={`/contacts/${contactId}?tab=credit`}>Credit Cards</TabItem>
      </ul>
      <ContactDetailsContainer contactId={contactId} />
    </div>
  );
};
