import gql from "graphql-tag";
import { preJobCheckListV2Field, preJobChecklistField } from "./getJob";

export const jobListFields = `
            id
            jobName
            market
            type
            contact {
                id
                firstName
                lastName
            }
            serviceLocation
            partsInfo {
                partsOrdered
            }
            createdBy
            createdAt
            updatedAt
            status
            jobNumber
            market
            vehicleId
            vehicle{
                vin
                odometer
                id
                make
                model
                year
                licensePlate
                licensePlateState
            }
            invoice{
                items{
                    name
                    isInEstimate
                }
                invoiceNumber
                issuedDate
            }
            services{
                id
                service
                name
                inEstimate
             }
            ${preJobCheckListV2Field}
            ${preJobChecklistField}
            invoice {
                services {
                    id
                    service
                    name
                }
            }
            appointment {
                id
                isFixed
                subject
                timeZone
                allDay
                technician {
                    firstName
                    lastName
                    id
                }
                startDate
                endDate
            }
`;

export const GET_JOBS_PAGINATED_V2 = gql`query getJobsPaginatedV2($paginatedQueryInput: PaginatedQueryInput!) {
    getJobsPaginatedV2(paginatedQueryInput: $paginatedQueryInput) {
        edges {
            ${jobListFields}
        }
        pageInfo {
            hasNextPage
            offset
            numRecords
        }
    }
}`;
