import { MarketFormValues } from "../Forms/MarketForm";
import { applySpec, prop, path } from "ramda";
import { CreateMarketInput } from "../../generated/nest-graphql";
import { flow } from "fp-ts/lib/function";
import { Optional } from "monocle-ts";
import * as Option from "fp-ts/Option";

export const marketSpec = (val: MarketFormValues) => {
  return applySpec<CreateMarketInput>({
    name: prop("name"),
    timeZone: prop("timeZone"),
    phoneNumber: prop("phoneNumber"),
    email: prop("email"),
    address: prop("address"),
    businessID: prop("businessID"),
    marketStatus: prop("marketStatus"),
    activeServiceAreaGeoJson: flow(
      Optional.fromNullableProp<MarketFormValues>()("activeServiceAreaGeoJson").getOption,
      Option.map(JSON.parse),
      Option.toNullable
    ),
    marketAreaGeoJson: flow(
      Optional.fromNullableProp<MarketFormValues>()("marketAreaGeoJson").getOption,
      Option.map(JSON.parse),
      Option.toNullable
    ),
    activeServiceArea: flow(
      Optional.fromNullableProp<MarketFormValues>()("activeServiceAreaGeoJson").getOption,
      Option.map(JSON.parse),
      Option.map(path(["features", 0, "geometry"])),
      Option.toNullable
    ),
    marketArea: flow(
      Optional.fromNullableProp<MarketFormValues>()("marketAreaGeoJson").getOption,
      Option.map(JSON.parse),
      Option.map(path(["features", 0, "geometry"])),
      Option.toNullable
    ),
    salesTaxType: prop("salesTaxType"),
    partsTaxRate: prop("partsTaxRate"),
    laborTaxRate: prop("laborTaxRate"),
  })(val);
};
