import { gql } from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** All currencies are stored as Numbers */
  Currency: any;
  /** Date custom scalar type */
  Date: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Action = {
  __typename?: "Action";
  name?: Maybe<Scalars["String"]>;
};

export type Activity = {
  __typename?: "Activity";
  actor?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  objectId?: Maybe<Scalars["String"]>;
  objectType?: Maybe<Scalars["String"]>;
  verb?: Maybe<Scalars["JSON"]>;
};

export type AirtableServiceInfoInput = {
  serviceId?: InputMaybe<Scalars["String"]>;
  servicePrice?: InputMaybe<Scalars["String"]>;
};

export type AppVersion = {
  __typename?: "AppVersion";
  createdAt?: Maybe<Scalars["Date"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  version?: Maybe<Scalars["String"]>;
};

export type Appointment = {
  __typename?: "Appointment";
  activityFeed?: Maybe<Array<Maybe<Activity>>>;
  allDay?: Maybe<Scalars["Boolean"]>;
  endDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  isFixed?: Maybe<Scalars["Boolean"]>;
  job?: Maybe<Job>;
  overrideOutsideServiceZone?: Maybe<Scalars["Boolean"]>;
  partsDueDate?: Maybe<Scalars["Date"]>;
  partsOrderedNotificationSent?: Maybe<Scalars["Boolean"]>;
  startDate?: Maybe<Scalars["Date"]>;
  subject?: Maybe<Scalars["String"]>;
  technician?: Maybe<Technician>;
  timeWindow?: Maybe<TimeWindow>;
  timeZone?: Maybe<Scalars["String"]>;
};

export enum AppointmentAddressType {
  Home = "HOME",
  HomePartsStore = "HOME_PARTS_STORE",
  ServiceAreaAnchor = "SERVICE_AREA_ANCHOR",
  ServiceLocation = "SERVICE_LOCATION",
}

export type AppointmentInput = {
  allDay?: InputMaybe<Scalars["Boolean"]>;
  appointmentSnapshotId?: InputMaybe<Scalars["String"]>;
  availabilitySnapshotId?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["Date"]>;
  isFixed?: InputMaybe<Scalars["Boolean"]>;
  job?: InputMaybe<Scalars["ID"]>;
  overrideOutsideServiceZone?: InputMaybe<Scalars["Boolean"]>;
  partsDueDate?: InputMaybe<Scalars["Date"]>;
  startDate?: InputMaybe<Scalars["Date"]>;
  subject?: InputMaybe<Scalars["String"]>;
  technician: Scalars["ID"];
  technicianCopy?: InputMaybe<Scalars["JSON"]>;
  timeWindow?: InputMaybe<TimeWindowInput>;
  timeZone?: InputMaybe<Scalars["String"]>;
  vehicleInfo?: InputMaybe<VehicleInfoInput>;
};

export type AppointmentSuggestion = {
  __typename?: "AppointmentSuggestion";
  appointmentSnapshotId?: Maybe<Scalars["String"]>;
  arrivalAddressType?: Maybe<AppointmentAddressType>;
  arrivalServiceLocation?: Maybe<Scalars["String"]>;
  availabilitySnapshotId?: Maybe<Scalars["String"]>;
  departureAddressType?: Maybe<AppointmentAddressType>;
  departureServiceLocation?: Maybe<Scalars["String"]>;
  driveTimes?: Maybe<DriveTimes>;
  endDate?: Maybe<Scalars["Date"]>;
  market?: Maybe<Market>;
  score?: Maybe<Scalars["Float"]>;
  scores?: Maybe<Scores>;
  serviceLocation?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["Date"]>;
  straightLineDistanceInMilesArrival?: Maybe<Scalars["Float"]>;
  straightLineDistanceInMilesDeparture?: Maybe<Scalars["Float"]>;
  subject?: Maybe<Scalars["String"]>;
  technician?: Maybe<Technician>;
  technicianExistingAppointments?: Maybe<Array<Maybe<ExistingAppointment>>>;
  timeCategory?: Maybe<AppointmentTimeCategory>;
  timeWindow?: Maybe<TimeWindow>;
};

export enum AppointmentTimeCategory {
  FirstOfDay = "FIRST_OF_DAY",
  FirstOfDayEarly = "FIRST_OF_DAY_EARLY",
  LastOfDay = "LAST_OF_DAY",
  Regular = "REGULAR",
}

export type AvailabilitySnapshotRes = {
  __typename?: "AvailabilitySnapshotRes";
  availabilitySnapshotId?: Maybe<Scalars["String"]>;
  error?: Maybe<Scalars["String"]>;
};

export type AvailabilityWindow = {
  __typename?: "AvailabilityWindow";
  arrivalRange?: Maybe<StartTimeRange>;
  startRange?: Maybe<StartTimeRange>;
};

export type BrakePadsListItem = {
  __typename?: "BrakePadsListItem";
  condition?: Maybe<Scalars["String"]>;
  padThickness?: Maybe<PadThickness>;
};

export type BrakePadsListItemInput = {
  condition?: InputMaybe<Scalars["String"]>;
  padThickness?: InputMaybe<PadThicknessInput>;
};

export type CalculatePossibleEjiPriceInfoInput = {
  calculateAllServices?: InputMaybe<Scalars["Boolean"]>;
  discounts?: InputMaybe<Array<InputMaybe<EjiDiscountInput>>>;
  marketName: Scalars["String"];
  pricingConfig?: InputMaybe<EjiPricingConfigInput>;
  promoCodes?: InputMaybe<Array<InputMaybe<PromoCodeInput>>>;
  services: Array<InputMaybe<PossibleEjiServiceInput>>;
  taxable: Scalars["Boolean"];
};

export type CatalogueDaysOfWeek = {
  __typename?: "CatalogueDaysOfWeek";
  friday?: Maybe<Scalars["Currency"]>;
  monday?: Maybe<Scalars["Currency"]>;
  saturday?: Maybe<Scalars["Currency"]>;
  sunday?: Maybe<Scalars["Currency"]>;
  thursday?: Maybe<Scalars["Currency"]>;
  tuesday?: Maybe<Scalars["Currency"]>;
  wednesday?: Maybe<Scalars["Currency"]>;
};

export type CatalogueDrums = {
  __typename?: "CatalogueDrums";
  laborHours?: Maybe<Scalars["Float"]>;
  notes?: Maybe<Scalars["String"]>;
  performanceOption?: Maybe<CatalogueItemOption>;
  premiumOption?: Maybe<CatalogueItemOption>;
  standardOption?: Maybe<CatalogueItemOption>;
};

export type CatalogueItemOption = {
  __typename?: "CatalogueItemOption";
  basePrice?: Maybe<Scalars["Currency"]>;
  brand?: Maybe<Scalars["String"]>;
  commission?: Maybe<Scalars["Currency"]>;
  marketAdjustedPrice?: Maybe<Scalars["Currency"]>;
  partsCost?: Maybe<Scalars["Currency"]>;
};

export type CatalogueLeadTime = {
  __typename?: "CatalogueLeadTime";
  nextDay?: Maybe<Scalars["Currency"]>;
  oneToTwoDays?: Maybe<Scalars["Currency"]>;
  sameDay?: Maybe<Scalars["Currency"]>;
  twoPlusDays?: Maybe<Scalars["Currency"]>;
};

export type CataloguePads = {
  __typename?: "CataloguePads";
  OEMMaterial?: Maybe<Scalars["String"]>;
  laborHours?: Maybe<Scalars["Float"]>;
  notes?: Maybe<Scalars["String"]>;
  performanceOption?: Maybe<CatalogueItemOption>;
  premiumOption?: Maybe<CatalogueItemOption>;
  standardOption?: Maybe<CatalogueItemOption>;
};

export type CatalogueRotors = {
  __typename?: "CatalogueRotors";
  alternativePricing?: Maybe<Scalars["Currency"]>;
  laborHours?: Maybe<Scalars["Float"]>;
  notes?: Maybe<Scalars["String"]>;
  premiumOption?: Maybe<CatalogueItemOption>;
  standardOption?: Maybe<CatalogueItemOption>;
};

export type CatalogueShoes = {
  __typename?: "CatalogueShoes";
  laborHours?: Maybe<Scalars["Float"]>;
  notes?: Maybe<Scalars["String"]>;
  performanceOption?: Maybe<CatalogueItemOption>;
  premiumOption?: Maybe<CatalogueItemOption>;
  standardOption?: Maybe<CatalogueItemOption>;
};

export type CheckListV2BrakeFluidInfo = {
  __typename?: "CheckListV2BrakeFluidInfo";
  colorGrade?: Maybe<Scalars["String"]>;
  level?: Maybe<Scalars["String"]>;
  ppm?: Maybe<Scalars["String"]>;
};

export type CheckListV2BrakeFluidInfoInput = {
  colorGrade?: InputMaybe<Scalars["String"]>;
  level?: InputMaybe<Scalars["String"]>;
  ppm?: InputMaybe<Scalars["String"]>;
};

export type CheckListV2DrumsInfo = {
  __typename?: "CheckListV2DrumsInfo";
  colorGrade?: Maybe<Scalars["String"]>;
  condition?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type CheckListV2DrumsInfoInput = {
  colorGrade?: InputMaybe<Scalars["String"]>;
  condition?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CheckListV2InspectedServices = {
  __typename?: "CheckListV2InspectedServices";
  frontLeftCaliper?: Maybe<CheckListV2InspectedServicesItem>;
  frontLeftHubAssemblyBearing?: Maybe<CheckListV2InspectedServicesItem>;
  frontRightCaliper?: Maybe<CheckListV2InspectedServicesItem>;
  frontRightHubAssemblyBearing?: Maybe<CheckListV2InspectedServicesItem>;
  rearLeftCaliper?: Maybe<CheckListV2InspectedServicesItem>;
  rearLeftHubAssemblyBearing?: Maybe<CheckListV2InspectedServicesItem>;
  rearRightCaliper?: Maybe<CheckListV2InspectedServicesItem>;
  rearRightHubAssemblyBearing?: Maybe<CheckListV2InspectedServicesItem>;
};

export type CheckListV2InspectedServicesInput = {
  frontLeftCaliper?: InputMaybe<CheckListV2InspectedServicesItemInput>;
  frontLeftHubAssemblyBearing?: InputMaybe<CheckListV2InspectedServicesItemInput>;
  frontRightCaliper?: InputMaybe<CheckListV2InspectedServicesItemInput>;
  frontRightHubAssemblyBearing?: InputMaybe<CheckListV2InspectedServicesItemInput>;
  rearLeftCaliper?: InputMaybe<CheckListV2InspectedServicesItemInput>;
  rearLeftHubAssemblyBearing?: InputMaybe<CheckListV2InspectedServicesItemInput>;
  rearRightCaliper?: InputMaybe<CheckListV2InspectedServicesItemInput>;
  rearRightHubAssemblyBearing?: InputMaybe<CheckListV2InspectedServicesItemInput>;
};

export type CheckListV2InspectedServicesItem = {
  __typename?: "CheckListV2InspectedServicesItem";
  colorGrade?: Maybe<Scalars["String"]>;
  included?: Maybe<Scalars["Boolean"]>;
  notes?: Maybe<Scalars["String"]>;
};

export type CheckListV2InspectedServicesItemInput = {
  colorGrade?: InputMaybe<Scalars["String"]>;
  included?: InputMaybe<Scalars["Boolean"]>;
  notes?: InputMaybe<Scalars["String"]>;
};

export type CheckListV2PadsInfo = {
  __typename?: "CheckListV2PadsInfo";
  condition?: Maybe<Array<Maybe<Scalars["String"]>>>;
  innerPadThickness?: Maybe<Scalars["String"]>;
  outerPadThickness?: Maybe<Scalars["String"]>;
  padsThickness?: Maybe<Scalars["String"]>;
};

export type CheckListV2PadsInfoInput = {
  condition?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  innerPadThickness?: InputMaybe<Scalars["String"]>;
  outerPadThickness?: InputMaybe<Scalars["String"]>;
  padsThickness?: InputMaybe<Scalars["String"]>;
};

export type CheckListV2RotorsInfo = {
  __typename?: "CheckListV2RotorsInfo";
  condition?: Maybe<Array<Maybe<Scalars["String"]>>>;
  thicknessInches?: Maybe<Scalars["String"]>;
};

export type CheckListV2RotorsInfoInput = {
  condition?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  thicknessInches?: InputMaybe<Scalars["String"]>;
};

export type CheckListV2ShoesInfo = {
  __typename?: "CheckListV2ShoesInfo";
  colorGrade?: Maybe<Scalars["String"]>;
  condition?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type CheckListV2ShoesInfoInput = {
  colorGrade?: InputMaybe<Scalars["String"]>;
  condition?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CloseZeroDollarInvoiceInput = {
  invoiceId: Scalars["ID"];
};

export type Contact = {
  __typename?: "Contact";
  activityFeed?: Maybe<Array<Maybe<Activity>>>;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  files?: Maybe<Array<Maybe<File>>>;
  firstName?: Maybe<Scalars["String"]>;
  fleet?: Maybe<FleetInfo>;
  fullName?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  lastName?: Maybe<Scalars["String"]>;
  lead?: Maybe<Lead>;
  mHelpId?: Maybe<Scalars["String"]>;
  oldMongoId?: Maybe<Scalars["String"]>;
  outreachMedium?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  referral?: Maybe<Referral>;
  stripeCustomer?: Maybe<Scalars["JSON"]>;
  stripePaymentMethods?: Maybe<Array<Maybe<Scalars["JSON"]>>>;
  taxExemptEin?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export type ContactConnection = {
  __typename?: "ContactConnection";
  edges: Array<Contact>;
  pageInfo: PageInfo;
};

export type ContactUsInput = {
  businessName?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  message: Scalars["String"];
  numberOfVehicles?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

export enum ConvertExperiment {
  SuccessPageSocialProof = "successPageSocialProof",
}

export type Coupon = {
  __typename?: "Coupon";
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["String"]>;
  discount: EjiDiscount;
  name: Scalars["String"];
  totalRedemptions?: Maybe<Scalars["Int"]>;
  valid: Scalars["Boolean"];
};

export type CouponInput = {
  createdAt?: InputMaybe<Scalars["Date"]>;
  createdBy?: InputMaybe<Scalars["String"]>;
  discount: EjiDiscountInput;
  name: Scalars["String"];
  totalRedemptions?: InputMaybe<Scalars["Int"]>;
  valid: Scalars["Boolean"];
};

export type CreateAirtableJobInput = {
  airtableServiceInfosInput?: InputMaybe<Array<InputMaybe<AirtableServiceInfoInput>>>;
  createJobInput?: InputMaybe<CreateJobInput>;
};

export type CreateAvailabilitySnapshotInput = {
  address: Scalars["String"];
  contactId?: InputMaybe<Scalars["String"]>;
  estimateId?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  laborTime: Scalars["Float"];
  startTime: Scalars["Date"];
};

export type CreateEstimateInput = {
  callForPartsTicketNumber?: InputMaybe<Scalars["String"]>;
  contact: Scalars["ID"];
  discounts?: InputMaybe<Array<InputMaybe<EjiDiscountInput>>>;
  estimateNotes?: InputMaybe<Scalars["String"]>;
  issuedDate?: InputMaybe<Scalars["Date"]>;
  items?: InputMaybe<Array<InputMaybe<ItemInput>>>;
  market?: InputMaybe<Scalars["String"]>;
  partsLeadTimeInDays?: InputMaybe<Scalars["Int"]>;
  partsNotes?: InputMaybe<Scalars["String"]>;
  pricingConfig?: InputMaybe<EjiPricingConfigInput>;
  privateNotes?: InputMaybe<Scalars["String"]>;
  promoCodes?: InputMaybe<Array<InputMaybe<PromoCodeInput>>>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceCatalogueUsed?: InputMaybe<Scalars["String"]>;
  serviceLocation?: InputMaybe<Scalars["String"]>;
  serviceLocationNotes?: InputMaybe<Scalars["String"]>;
  services?: InputMaybe<Array<InputMaybe<EjiServiceInput>>>;
  status: Scalars["String"];
  taxable?: InputMaybe<Scalars["Boolean"]>;
  vehicleInfo?: InputMaybe<VehicleInfoInput>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
};

export type CreateFeedbackInput = {
  appName: Scalars["String"];
  feedback: Scalars["String"];
};

export type CreateFileInput = {
  caption?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  contact: Scalars["ID"];
  fileName: Scalars["String"];
  jobId?: InputMaybe<Scalars["ID"]>;
  path: Scalars["String"];
  share?: InputMaybe<Scalars["Boolean"]>;
  url: Scalars["String"];
};

export type CreateInquiryRequestInput = {
  additionalNotes?: InputMaybe<Scalars["String"]>;
  address?: InputMaybe<Scalars["String"]>;
  campaignQueryParams?: InputMaybe<Scalars["JSON"]>;
  context?: InputMaybe<Scalars["String"]>;
  convertExperimentsData?: InputMaybe<Scalars["JSON"]>;
  customerExpectation?: InputMaybe<Scalars["String"]>;
  discardThickness?: InputMaybe<Scalars["Float"]>;
  email?: InputMaybe<Scalars["String"]>;
  extraFields?: InputMaybe<Scalars["JSON"]>;
  extraInfo?: InputMaybe<Scalars["String"]>;
  fleet?: InputMaybe<FleetInfoInput>;
  frontBrakeSymptoms?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  frontPadLife?: InputMaybe<Scalars["String"]>;
  frontRotorsDiscardThicknessInches?: InputMaybe<Scalars["Float"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  hearAboutUs?: InputMaybe<Scalars["String"]>;
  leadFormTestVariants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  licensePlate?: InputMaybe<Scalars["String"]>;
  licensePlateState?: InputMaybe<Scalars["String"]>;
  make?: InputMaybe<Scalars["String"]>;
  market?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  odometer?: InputMaybe<Scalars["String"]>;
  outreachMedium?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  preferredContactMethod?: InputMaybe<Scalars["String"]>;
  queryParams?: InputMaybe<Scalars["JSON"]>;
  rearBrakeSymptoms?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  rearPadLife?: InputMaybe<Scalars["String"]>;
  rearRotorsDiscardThicknessInches?: InputMaybe<Scalars["Float"]>;
  referrerSource?: InputMaybe<Scalars["String"]>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceUrgency?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  symptoms?: InputMaybe<Scalars["String"]>;
  testVariants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  timeZone?: InputMaybe<Scalars["String"]>;
  vehicleId?: InputMaybe<Scalars["ID"]>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
  vin?: InputMaybe<Scalars["String"]>;
  windowUrl?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type CreateInvoiceInput = {
  contact?: InputMaybe<Scalars["ID"]>;
  customerMessage?: InputMaybe<Scalars["String"]>;
  discounts?: InputMaybe<Array<InputMaybe<EjiDiscountInput>>>;
  dueDate?: InputMaybe<Scalars["Date"]>;
  estimate?: InputMaybe<Scalars["ID"]>;
  issuedDate?: InputMaybe<Scalars["Date"]>;
  items?: InputMaybe<Array<InputMaybe<ItemInput>>>;
  job?: InputMaybe<Scalars["ID"]>;
  market?: InputMaybe<Scalars["String"]>;
  preJobCheckList?: InputMaybe<PreJobCheckListInput>;
  preJobCheckListV2?: InputMaybe<PreJobCheckListV2Input>;
  pricingConfig?: InputMaybe<EjiPricingConfigInput>;
  privateNotes?: InputMaybe<Scalars["String"]>;
  proceedingWithRepairs?: InputMaybe<Scalars["Boolean"]>;
  promoCodes?: InputMaybe<Array<InputMaybe<PromoCodeInput>>>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceCatalogueUsed?: InputMaybe<Scalars["String"]>;
  serviceFollowUp?: InputMaybe<ServiceFollowUp>;
  serviceLocation?: InputMaybe<Scalars["String"]>;
  services?: InputMaybe<Array<InputMaybe<EjiServiceInput>>>;
  status: Scalars["String"];
  taxable?: InputMaybe<Scalars["Boolean"]>;
  technician?: InputMaybe<Scalars["ID"]>;
  vehicleInfo?: InputMaybe<VehicleInfoInput>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
};

export type CreateJobInput = {
  appointmentInfo?: InputMaybe<AppointmentInput>;
  contact?: InputMaybe<Scalars["ID"]>;
  contactCopy?: InputMaybe<Scalars["JSON"]>;
  description?: InputMaybe<Scalars["String"]>;
  discounts?: InputMaybe<Array<InputMaybe<EjiDiscountInput>>>;
  earlyNotice?: InputMaybe<Scalars["Boolean"]>;
  estimate?: InputMaybe<Scalars["ID"]>;
  items?: InputMaybe<Array<InputMaybe<ItemInput>>>;
  jobCausedDiagnostic?: InputMaybe<Scalars["ID"]>;
  jobCausedWarrantyCheck?: InputMaybe<Scalars["ID"]>;
  jobName?: InputMaybe<Scalars["String"]>;
  jobNotes?: InputMaybe<Scalars["String"]>;
  jobViocBrakeRepair?: InputMaybe<Scalars["ID"]>;
  jobViocInspection?: InputMaybe<Scalars["ID"]>;
  locationType?: InputMaybe<Scalars["String"]>;
  market?: InputMaybe<Scalars["String"]>;
  partsInfo?: InputMaybe<PartsInfoInput>;
  partsLeadTimeInDays?: InputMaybe<Scalars["Int"]>;
  partsStore?: InputMaybe<Scalars["ID"]>;
  preJobCheckList?: InputMaybe<PreJobCheckListInput>;
  preJobCheckListV2?: InputMaybe<PreJobCheckListV2Input>;
  pricingConfig?: InputMaybe<EjiPricingConfigInput>;
  privateNotes?: InputMaybe<Scalars["String"]>;
  promoCodes?: InputMaybe<Array<InputMaybe<PromoCodeInput>>>;
  reasonRescheduled?: InputMaybe<Scalars["String"]>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceCallReason?: InputMaybe<Scalars["String"]>;
  serviceCallReasonExtraInfo?: InputMaybe<Scalars["String"]>;
  serviceCatalogueUsed?: InputMaybe<Scalars["String"]>;
  serviceLocation?: InputMaybe<Scalars["String"]>;
  serviceLocationNotes?: InputMaybe<Scalars["String"]>;
  serviceLocationZip?: InputMaybe<Scalars["String"]>;
  services?: InputMaybe<Array<InputMaybe<EjiServiceInput>>>;
  status: Scalars["String"];
  symptomDiagnosisCategory?: InputMaybe<Scalars["String"]>;
  taxable?: InputMaybe<Scalars["Boolean"]>;
  testVariants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  type?: InputMaybe<Scalars["String"]>;
  vehicleId?: InputMaybe<Scalars["String"]>;
  vehicleInfo?: InputMaybe<VehicleInfoInput>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
  warrantyCheckReason?: InputMaybe<Scalars["String"]>;
  warrantyCheckReasonExtraInfo?: InputMaybe<Scalars["String"]>;
  warrantyCheckTechReason?: InputMaybe<Scalars["String"]>;
  warrantyCheckTechReasonExtraInfo?: InputMaybe<Scalars["String"]>;
  withdrawExtraInfo?: InputMaybe<Scalars["String"]>;
  withdrawReason?: InputMaybe<Scalars["String"]>;
  zendeskTags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type CreateLeadInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName: Scalars["String"];
  fleet?: InputMaybe<FleetInfoInput>;
  lastName: Scalars["String"];
  lead: LeadInput;
  outreachMedium?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  preferredContactMethod?: InputMaybe<Scalars["String"]>;
  referredById?: InputMaybe<Scalars["String"]>;
  stripeCustomer?: InputMaybe<Scalars["JSON"]>;
  stripePaymentMethods?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  taxExemptEin?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type CreateMarketInput = {
  activeServiceArea?: InputMaybe<Scalars["JSON"]>;
  activeServiceAreaGeoJson?: InputMaybe<Scalars["JSON"]>;
  address?: InputMaybe<Scalars["String"]>;
  businessID?: InputMaybe<Scalars["String"]>;
  drumsPricingVariance?: InputMaybe<MarketPricingVarianceInput>;
  email?: InputMaybe<Scalars["String"]>;
  laborRate?: InputMaybe<Scalars["Currency"]>;
  laborTaxRate?: InputMaybe<Scalars["Float"]>;
  marketArea?: InputMaybe<Scalars["JSON"]>;
  marketAreaGeoJson?: InputMaybe<Scalars["JSON"]>;
  marketStatus?: InputMaybe<MarketStatusEnum>;
  name: Scalars["String"];
  padsPricingVariance?: InputMaybe<MarketPricingVarianceInput>;
  partsTaxRate?: InputMaybe<Scalars["Float"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  rotorsPricingVariance?: InputMaybe<MarketPricingVarianceInput>;
  salesTaxType?: InputMaybe<Scalars["String"]>;
  shoesPricingVariance?: InputMaybe<MarketPricingVarianceInput>;
  timeZone?: InputMaybe<Scalars["String"]>;
  variance?: InputMaybe<MarketVarianceInput>;
  vendorUnitCostMultipliers?: InputMaybe<Array<InputMaybe<VendorUnitCostMultiplierInput>>>;
};

export type CreatePaymentInput = {
  amount: Scalars["Currency"];
  contact?: InputMaybe<Scalars["ID"]>;
  createdBy?: InputMaybe<Scalars["String"]>;
  customer?: InputMaybe<Scalars["ID"]>;
  discountTotal?: InputMaybe<Scalars["Currency"]>;
  feesTotal?: InputMaybe<Scalars["Currency"]>;
  finalFeesTotal?: InputMaybe<Scalars["Currency"]>;
  finalLaborTotal?: InputMaybe<Scalars["Currency"]>;
  finalPartsTotal?: InputMaybe<Scalars["Currency"]>;
  finalSubTotal?: InputMaybe<Scalars["Currency"]>;
  invoice?: InputMaybe<Scalars["ID"]>;
  invoicePrice?: InputMaybe<Scalars["Currency"]>;
  job?: InputMaybe<Scalars["ID"]>;
  laborCost?: InputMaybe<Scalars["Currency"]>;
  laborTax?: InputMaybe<Scalars["Currency"]>;
  laborTotal?: InputMaybe<Scalars["Currency"]>;
  memo?: InputMaybe<Scalars["String"]>;
  partsCost?: InputMaybe<Scalars["Currency"]>;
  partsTax?: InputMaybe<Scalars["Currency"]>;
  partsTotal?: InputMaybe<Scalars["Currency"]>;
  payer?: InputMaybe<Scalars["String"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]>;
  paymentMethod: Scalars["String"];
  receivedDate: Scalars["Date"];
  refNumber?: InputMaybe<Scalars["String"]>;
  sendReceipt: Scalars["Boolean"];
  source?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
  stripePaymentMethod?: InputMaybe<Scalars["ID"]>;
  subTotal?: InputMaybe<Scalars["Currency"]>;
  tip?: InputMaybe<Scalars["Currency"]>;
  totalTax?: InputMaybe<Scalars["Currency"]>;
  transactionId?: InputMaybe<Scalars["String"]>;
  type: Scalars["String"];
};

export type CreatePaymentInputHoldCard = {
  amount: Scalars["Currency"];
  contact: Scalars["ID"];
  discountTotal?: InputMaybe<Scalars["Currency"]>;
  feesTotal?: InputMaybe<Scalars["Currency"]>;
  finalFeesTotal?: InputMaybe<Scalars["Currency"]>;
  finalLaborTotal?: InputMaybe<Scalars["Currency"]>;
  finalPartsTotal?: InputMaybe<Scalars["Currency"]>;
  finalSubTotal?: InputMaybe<Scalars["Currency"]>;
  invoice?: InputMaybe<Scalars["ID"]>;
  invoicePrice?: InputMaybe<Scalars["Currency"]>;
  job: Scalars["ID"];
  laborCost?: InputMaybe<Scalars["Currency"]>;
  laborTax?: InputMaybe<Scalars["Currency"]>;
  laborTotal?: InputMaybe<Scalars["Currency"]>;
  memo?: InputMaybe<Scalars["String"]>;
  partsCost?: InputMaybe<Scalars["Currency"]>;
  partsTax?: InputMaybe<Scalars["Currency"]>;
  partsTotal?: InputMaybe<Scalars["Currency"]>;
  payer?: InputMaybe<Scalars["String"]>;
  paymentIntentId?: InputMaybe<Scalars["String"]>;
  paymentMethod?: InputMaybe<Scalars["String"]>;
  receivedDate?: InputMaybe<Scalars["Date"]>;
  refNumber?: InputMaybe<Scalars["String"]>;
  sendReceipt?: InputMaybe<Scalars["Boolean"]>;
  source?: InputMaybe<Scalars["String"]>;
  status: Scalars["String"];
  subTotal?: InputMaybe<Scalars["Currency"]>;
  tip?: InputMaybe<Scalars["Currency"]>;
  totalTax?: InputMaybe<Scalars["Currency"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type CreatePaymentIntentInput = {
  amount: Scalars["Currency"];
  invoiceId: Scalars["String"];
  paymentMethodTypes: Array<InputMaybe<Scalars["String"]>>;
};

export type CreateProductInput = {
  amount: Scalars["Currency"];
  description?: InputMaybe<Scalars["String"]>;
  laborCost: Scalars["Currency"];
  name: Scalars["String"];
  partsCost: Scalars["Currency"];
  status?: InputMaybe<Scalars["String"]>;
};

export type CreateProductSetInput = {
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  products: Array<Scalars["ID"]>;
};

export type CreateSelfScheduleRequest = {
  address: Scalars["String"];
  appointmentSubject?: InputMaybe<Scalars["String"]>;
  contactUpdates?: InputMaybe<SelfScheduleContactUpdate>;
  earlyNotice?: InputMaybe<Scalars["Boolean"]>;
  estimateId?: InputMaybe<Scalars["String"]>;
  identifier: Scalars["String"];
  jobId: Scalars["String"];
  laborTime?: InputMaybe<Scalars["Int"]>;
  locationType?: InputMaybe<Scalars["String"]>;
  startTimeRange: StartTimeRangeInput;
  vehicleInfo?: InputMaybe<SelfScheduleVehicleInfo>;
};

export type CreateTechnicianInput = {
  confirmPassword: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  homeAddress?: InputMaybe<Scalars["String"]>;
  homePartsStore?: InputMaybe<Scalars["ID"]>;
  is1099?: InputMaybe<Scalars["Boolean"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isEllegibleToViocInspection?: InputMaybe<Scalars["Boolean"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]>;
  lastName: Scalars["String"];
  market: Scalars["String"];
  onWayMessage?: InputMaybe<Scalars["String"]>;
  password: Scalars["String"];
  payMultiplier?: InputMaybe<Scalars["Float"]>;
  phoneNumber: Scalars["String"];
  preferences?: InputMaybe<TechnicianPreferencesInput>;
  role: Scalars["ID"];
  roleCopy?: InputMaybe<Scalars["JSON"]>;
  schedulingDays?: InputMaybe<Array<InputMaybe<SchedulingDayInput>>>;
  serviceAreaAnchorAddress?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  whenIWorkUserId?: InputMaybe<Scalars["String"]>;
};

export type CreateUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  password: Scalars["String"];
};

export type CreateViocInspectionInquiryRequestInput = {
  address?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  extraFields?: InputMaybe<Scalars["JSON"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  make?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  queryParams?: InputMaybe<Scalars["JSON"]>;
  windowUrl?: InputMaybe<Scalars["String"]>;
  year?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type CreateViocInspectionInquiryRequestResult = {
  __typename?: "CreateViocInspectionInquiryRequestResult";
  contactId?: Maybe<Scalars["String"]>;
  inquiryRequestId?: Maybe<Scalars["String"]>;
  jobId?: Maybe<Scalars["String"]>;
};

export type CreateViocLeadInput = {
  extraFields?: InputMaybe<Scalars["JSON"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  queryParams?: InputMaybe<Scalars["JSON"]>;
  windowUrl?: InputMaybe<Scalars["String"]>;
};

export type CreateViocSelfScheduleRequest = {
  VIOCSymptoms?: InputMaybe<Scalars["Boolean"]>;
  address: Scalars["String"];
  appointmentSubject?: InputMaybe<Scalars["String"]>;
  contactUpdates?: InputMaybe<SelfScheduleContactUpdate>;
  estimateId?: InputMaybe<Scalars["String"]>;
  jobId: Scalars["String"];
  laborTime?: InputMaybe<Scalars["Int"]>;
  startTimeRange: StartTimeRangeInput;
  vehicleInfo?: InputMaybe<SelfScheduleVehicleInfo>;
};

export type CustomerContact = {
  __typename?: "CustomerContact";
  id?: Maybe<Scalars["ID"]>;
};

export type CustomerGetInvoiceResult = CustomerInvoice | InvoicePaidResult;

export type CustomerInvoice = {
  __typename?: "CustomerInvoice";
  amountPaid?: Maybe<Scalars["Currency"]>;
  balanceDue?: Maybe<Scalars["Currency"]>;
  contact?: Maybe<Contact>;
  dueDate?: Maybe<Scalars["Date"]>;
  id: Scalars["ID"];
  invoiceNumber?: Maybe<Scalars["String"]>;
  issuedDate?: Maybe<Scalars["Date"]>;
  items?: Maybe<Array<Maybe<Item>>>;
  market?: Maybe<Scalars["String"]>;
  serviceLocation?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  subTotal?: Maybe<Scalars["Currency"]>;
  total?: Maybe<Scalars["Currency"]>;
};

export type CustomerJob = {
  __typename?: "CustomerJob";
  id?: Maybe<Scalars["ID"]>;
};

export type DiagnosticsQuestionAnswer = {
  __typename?: "DiagnosticsQuestionAnswer";
  answer?: Maybe<Scalars["String"]>;
  question: Scalars["String"];
  relatedSymptoms?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type DiagnosticsQuestionAnswerInput = {
  answer?: InputMaybe<Scalars["String"]>;
  question: Scalars["String"];
  relatedSymptoms?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type DiscountTireContactUsInput = {
  appointmentRequestDate?: InputMaybe<Scalars["Date"]>;
  dtAccountNumber?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  inquiry: Scalars["String"];
  metro: Scalars["String"];
  name: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type DiscountTireContactUsResult = {
  __typename?: "DiscountTireContactUsResult";
  appointmentRequestDate?: Maybe<Scalars["Date"]>;
  dtAccountNumber?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  inquiry?: Maybe<Scalars["String"]>;
  metro?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type DistanceData = {
  __typename?: "DistanceData";
  distance?: Maybe<Scalars["JSON"]>;
  duration?: Maybe<Scalars["JSON"]>;
  status?: Maybe<Scalars["String"]>;
};

export type DriveTime = {
  __typename?: "DriveTime";
  destination?: Maybe<Scalars["String"]>;
  destinationType?: Maybe<Scalars["String"]>;
  distanceInMeters?: Maybe<Scalars["Float"]>;
  durationInSeconds?: Maybe<Scalars["Float"]>;
  durationWithTrafficInSeconds?: Maybe<Scalars["Float"]>;
  origin?: Maybe<Scalars["String"]>;
  readableDistance?: Maybe<Scalars["String"]>;
  readableDuration?: Maybe<Scalars["String"]>;
  readableDurationWithTraffic?: Maybe<Scalars["String"]>;
};

export type DriveTimes = {
  __typename?: "DriveTimes";
  arrival?: Maybe<DriveTime>;
  departure?: Maybe<DriveTime>;
};

export type EjiDiscount = {
  __typename?: "EJIDiscount";
  amount: Scalars["Float"];
  reason: Scalars["String"];
  total?: Maybe<Scalars["Currency"]>;
  type: Scalars["String"];
};

export type EjiDiscountInput = {
  amount: Scalars["Float"];
  reason: Scalars["String"];
  type: Scalars["String"];
};

export type EjiItem = {
  __typename?: "EJIItem";
  behavior: Scalars["String"];
  category: Scalars["String"];
  customerPrice: Scalars["Currency"];
  id: Scalars["ID"];
  marketLaborRateUsed?: Maybe<Scalars["Currency"]>;
  marketVendorUnitCostMultiplierUsed?: Maybe<Scalars["Float"]>;
  notes?: Maybe<Scalars["String"]>;
  orderItem?: Maybe<PartOrderItem>;
  overriddenCustomerPrice: Scalars["Boolean"];
  productSelection?: Maybe<ProductSelection>;
  serviceProductId: Scalars["ID"];
  type: Scalars["String"];
  units?: Maybe<Scalars["Float"]>;
  vendorUnitCost?: Maybe<Scalars["Currency"]>;
};

export type EjiItemInput = {
  behavior: Scalars["String"];
  category: Scalars["String"];
  customerPrice: Scalars["Currency"];
  marketLaborRateUsed?: InputMaybe<Scalars["Currency"]>;
  marketVendorUnitCostMultiplierUsed?: InputMaybe<Scalars["Float"]>;
  notes?: InputMaybe<Scalars["String"]>;
  orderItem?: InputMaybe<PartOrderItemInput>;
  overriddenCustomerPrice: Scalars["Boolean"];
  productSelection?: InputMaybe<ProductSelectionInput>;
  serviceProductId: Scalars["ID"];
  type: Scalars["String"];
  units?: InputMaybe<Scalars["Float"]>;
  vendorUnitCost?: InputMaybe<Scalars["Currency"]>;
};

export type EjiPriceInfo = {
  __typename?: "EJIPriceInfo";
  amountDue?: Maybe<Scalars["Currency"]>;
  combinedDiscountTotal?: Maybe<Scalars["Currency"]>;
  discountTotal?: Maybe<Scalars["Currency"]>;
  discounts?: Maybe<Array<Maybe<EjiDiscount>>>;
  feesTotal?: Maybe<Scalars["Currency"]>;
  finalFeesTotal?: Maybe<Scalars["Currency"]>;
  finalLaborTotal?: Maybe<Scalars["Currency"]>;
  finalPartsTotal?: Maybe<Scalars["Currency"]>;
  finalSubTotal?: Maybe<Scalars["Currency"]>;
  laborTax?: Maybe<Scalars["Currency"]>;
  laborTotal?: Maybe<Scalars["Currency"]>;
  partsTax?: Maybe<Scalars["Currency"]>;
  partsTotal?: Maybe<Scalars["Currency"]>;
  pricingConfig?: Maybe<EjiPricingConfig>;
  promoCodeDiscountTotal?: Maybe<Scalars["Currency"]>;
  promoCodes?: Maybe<Array<Maybe<PromoCode>>>;
  subTotal?: Maybe<Scalars["Currency"]>;
  totalTax?: Maybe<Scalars["Currency"]>;
};

export type EjiPricingConfig = {
  __typename?: "EJIPricingConfig";
  laborRate?: Maybe<Scalars["Currency"]>;
  vendorUnitCostMultipliers?: Maybe<Array<Maybe<VendorUnitCostMultiplier>>>;
};

export type EjiPricingConfigInput = {
  laborRate?: InputMaybe<Scalars["Currency"]>;
  vendorUnitCostMultipliers?: InputMaybe<Array<InputMaybe<VendorUnitCostMultiplierInput>>>;
};

export type EjiService = {
  __typename?: "EJIService";
  createdAt?: Maybe<Scalars["Date"]>;
  customerPrice: Scalars["Currency"];
  id: Scalars["ID"];
  inEstimate: Scalars["Boolean"];
  items: Array<Maybe<EjiItem>>;
  name: Scalars["String"];
  service: Scalars["ID"];
  technicianPay: Scalars["Currency"];
  updatedAt?: Maybe<Scalars["Date"]>;
};

export type EjiServiceInput = {
  inEstimate: Scalars["Boolean"];
  items: Array<InputMaybe<EjiItemInput>>;
  name: Scalars["String"];
  service: Scalars["ID"];
  technicianPay: Scalars["Currency"];
};

export type Estimate = {
  __typename?: "Estimate";
  activityFeed?: Maybe<Array<Maybe<Activity>>>;
  callForPartsTicketNumber?: Maybe<Scalars["String"]>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["String"]>;
  estimateNotes?: Maybe<Scalars["String"]>;
  estimateNumber?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  issuedDate?: Maybe<Scalars["Date"]>;
  items?: Maybe<Array<Maybe<Item>>>;
  job?: Maybe<Job>;
  market?: Maybe<Scalars["String"]>;
  partsLeadTimeInDays?: Maybe<Scalars["Int"]>;
  partsNotes?: Maybe<Scalars["String"]>;
  price?: Maybe<Scalars["Currency"]>;
  priceInfo?: Maybe<EjiPriceInfo>;
  privateNotes?: Maybe<Scalars["String"]>;
  requestedServices?: Maybe<Array<Maybe<Scalars["String"]>>>;
  serviceCatalogueUsed?: Maybe<Scalars["String"]>;
  serviceLocation?: Maybe<Scalars["String"]>;
  serviceLocationNotes?: Maybe<Scalars["String"]>;
  services?: Maybe<Array<Maybe<EjiService>>>;
  status: Scalars["String"];
  taxable?: Maybe<Scalars["Boolean"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  vehicleInfo?: Maybe<VehicleInfo>;
  vehicleSymptoms?: Maybe<Array<Maybe<VehicleSymptom>>>;
};

export type EstimatesConnection = {
  __typename?: "EstimatesConnection";
  edges: Array<Estimate>;
  pageInfo?: Maybe<PageInfo>;
};

export type Event = {
  __typename?: "Event";
  data?: Maybe<Scalars["JSON"]>;
  error?: Maybe<Scalars["JSON"]>;
  event: Scalars["String"];
};

export type ExistingAppointment = {
  __typename?: "ExistingAppointment";
  endDate?: Maybe<Scalars["Date"]>;
  serviceLocation?: Maybe<Scalars["String"]>;
  startDate?: Maybe<Scalars["Date"]>;
};

export type File = {
  __typename?: "File";
  caption?: Maybe<Scalars["String"]>;
  category?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  fileName?: Maybe<Scalars["String"]>;
  jobId?: Maybe<Scalars["ID"]>;
  path?: Maybe<Scalars["String"]>;
  share?: Maybe<Scalars["Boolean"]>;
  signedUrl?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  url?: Maybe<Scalars["String"]>;
};

export type FileInput = {
  caption?: InputMaybe<Scalars["String"]>;
  category?: InputMaybe<Scalars["String"]>;
  fileName: Scalars["String"];
  jobId?: InputMaybe<Scalars["ID"]>;
  path: Scalars["String"];
  share?: InputMaybe<Scalars["Boolean"]>;
  url: Scalars["String"];
};

export type FleetInfo = {
  __typename?: "FleetInfo";
  name?: Maybe<Scalars["String"]>;
};

export type FleetInfoInput = {
  name?: InputMaybe<Scalars["String"]>;
};

export type FrontInput = {
  id: Scalars["String"];
};

export type GenerateJobDiagnosticsSummaryInput = {
  jobId: Scalars["ID"];
};

export type GenerateServicesInput = {
  jobId?: InputMaybe<Scalars["String"]>;
  make?: InputMaybe<Scalars["String"]>;
  market: Scalars["String"];
  model?: InputMaybe<Scalars["String"]>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
  year?: InputMaybe<Scalars["String"]>;
};

export type GenerateServicesResult = {
  __typename?: "GenerateServicesResult";
  events?: Maybe<Array<Maybe<Event>>>;
  priceResult?: Maybe<PossibleEjiPriceResult>;
};

export type GeocodeInput = {
  lat?: InputMaybe<Scalars["Float"]>;
  lng?: InputMaybe<Scalars["Float"]>;
};

export type GetAQuoteInput = {
  email?: InputMaybe<Scalars["String"]>;
  fullName?: InputMaybe<Scalars["String"]>;
  hearAboutUs?: InputMaybe<Scalars["String"]>;
  leadFormTestVariants?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  make?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  queryParams?: InputMaybe<Scalars["JSON"]>;
  referrerSource?: InputMaybe<Scalars["String"]>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceUrgency?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  symptoms?: InputMaybe<Scalars["String"]>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
  year?: InputMaybe<Scalars["String"]>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type GetAQuoteResult = {
  __typename?: "GetAQuoteResult";
  contactId?: Maybe<Scalars["ID"]>;
  inquiryRequestId?: Maybe<Scalars["ID"]>;
  jobId: Scalars["ID"];
  selfBookingData: SelfBookingData;
};

export type GetAllPossibleServicesInput = {
  inEstimate?: InputMaybe<Scalars["Boolean"]>;
};

export type GetAppointmentSuggestionsInput = {
  address: Scalars["String"];
  contactId?: InputMaybe<Scalars["String"]>;
  endTime: Scalars["Date"];
  estimateId?: InputMaybe<Scalars["String"]>;
  jobCustomerType?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  laborTime: Scalars["Float"];
  startTime: Scalars["Date"];
  technicianPartsStoreId?: InputMaybe<Scalars["String"]>;
};

export type GetAvailabilityInput = {
  address: Scalars["String"];
  contactId?: InputMaybe<Scalars["String"]>;
  estimateId?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  laborTime: Scalars["Float"];
  partsLeadTimeInDays?: InputMaybe<Scalars["Int"]>;
  startTimeRange: StartTimeRangeInput;
  zip?: InputMaybe<Scalars["String"]>;
};

export type GetAvailabilityRes = {
  __typename?: "GetAvailabilityRes";
  availabilitySnapshotId?: Maybe<Scalars["String"]>;
  availableWindows: Array<Maybe<AvailabilityWindow>>;
  market?: Maybe<Scalars["String"]>;
  possibleAppointmentStartTimes: Array<Maybe<StartTimeRange>>;
  timeZone?: Maybe<Scalars["String"]>;
};

export type GetDefaultServicesInput = {
  make?: InputMaybe<Scalars["String"]>;
  market: Scalars["String"];
  model?: InputMaybe<Scalars["String"]>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
  year?: InputMaybe<Scalars["String"]>;
};

export type GetPossibleServiceInput = {
  inEstimate?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
};

export type GetVehicleServiceExceptionInput = {
  make: Scalars["String"];
  model: Scalars["String"];
  submodel?: InputMaybe<Scalars["String"]>;
  year: Scalars["Int"];
};

export type GetViocAvailabilityInput = {
  address: Scalars["String"];
  contactId?: InputMaybe<Scalars["String"]>;
  estimateId?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  laborTime: Scalars["Float"];
  zip?: InputMaybe<Scalars["String"]>;
};

export type GetViocAvailabilityRes = {
  __typename?: "GetViocAvailabilityRes";
  market?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["String"]>;
};

export type InquiryRequest = {
  __typename?: "InquiryRequest";
  additionalNotes?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  context?: Maybe<Scalars["String"]>;
  convertExperimentsData?: Maybe<Scalars["JSON"]>;
  customerExpectation?: Maybe<Scalars["String"]>;
  discardThickness?: Maybe<Scalars["Float"]>;
  email?: Maybe<Scalars["String"]>;
  extraInfo?: Maybe<Scalars["String"]>;
  fleet?: Maybe<FleetInfo>;
  frontBrakeSymptoms?: Maybe<Array<Maybe<Scalars["String"]>>>;
  frontPadLife?: Maybe<Scalars["String"]>;
  frontRotorsDiscardThicknessInches?: Maybe<Scalars["Float"]>;
  fullName?: Maybe<Scalars["String"]>;
  hearAboutUs?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  licensePlate?: Maybe<Scalars["String"]>;
  licensePlateState?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  odometer?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  queryParams?: Maybe<Scalars["JSON"]>;
  rearBrakeSymptoms?: Maybe<Array<Maybe<Scalars["String"]>>>;
  rearPadLife?: Maybe<Scalars["String"]>;
  rearRotorsDiscardThicknessInches?: Maybe<Scalars["Float"]>;
  referrerSource?: Maybe<Scalars["String"]>;
  requestedServices?: Maybe<Array<Maybe<Scalars["String"]>>>;
  serviceUrgency?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  symptoms?: Maybe<Scalars["String"]>;
  vehicleSymptoms?: Maybe<Array<Maybe<VehicleSymptom>>>;
  vin?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};

export type InquiryRequestV2 = {
  __typename?: "InquiryRequestV2";
  customerContact?: Maybe<CustomerContact>;
  inquiryRequest?: Maybe<InquiryRequest>;
};

export type Invoice = {
  __typename?: "Invoice";
  activityFeed?: Maybe<Array<Maybe<Activity>>>;
  amountPaid?: Maybe<Scalars["Currency"]>;
  balanceDue?: Maybe<Scalars["Currency"]>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["String"]>;
  customerMessage?: Maybe<Scalars["String"]>;
  dueDate?: Maybe<Scalars["Date"]>;
  estimate?: Maybe<Estimate>;
  id: Scalars["ID"];
  invoiceNumber?: Maybe<Scalars["String"]>;
  issuedDate?: Maybe<Scalars["Date"]>;
  items?: Maybe<Array<Maybe<Item>>>;
  job?: Maybe<Job>;
  laborCost?: Maybe<Scalars["Currency"]>;
  laborTax?: Maybe<Scalars["Currency"]>;
  mHelpId?: Maybe<Scalars["String"]>;
  market?: Maybe<Scalars["String"]>;
  partsCost?: Maybe<Scalars["Currency"]>;
  partsTax?: Maybe<Scalars["Currency"]>;
  preJobCheckList?: Maybe<PreJobCheckList>;
  preJobCheckListV2?: Maybe<PreJobCheckListV2>;
  priceInfo?: Maybe<EjiPriceInfo>;
  privateNotes?: Maybe<Scalars["String"]>;
  proceedingWithRepairs?: Maybe<Scalars["Boolean"]>;
  requestedServices?: Maybe<Array<Maybe<Scalars["String"]>>>;
  serviceCatalogueUsed?: Maybe<Scalars["String"]>;
  serviceFollowUp?: Maybe<ServiceFollowUpInfo>;
  serviceLocation?: Maybe<Scalars["String"]>;
  services?: Maybe<Array<Maybe<EjiService>>>;
  status: Scalars["String"];
  subTotal?: Maybe<Scalars["Currency"]>;
  taxable?: Maybe<Scalars["Boolean"]>;
  technician?: Maybe<Technician>;
  total?: Maybe<Scalars["Currency"]>;
  totalTax?: Maybe<Scalars["Currency"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  vehicleInfo?: Maybe<VehicleInfo>;
  vehicleSymptoms?: Maybe<Array<Maybe<VehicleSymptom>>>;
};

export type InvoiceConnection = {
  __typename?: "InvoiceConnection";
  edges: Array<Invoice>;
  pageInfo?: Maybe<PageInfo>;
};

export type InvoicePaidResult = {
  __typename?: "InvoicePaidResult";
  id: Scalars["ID"];
  status: Scalars["String"];
};

export type Item = {
  __typename?: "Item";
  amount: Scalars["Currency"];
  createdAt?: Maybe<Scalars["Date"]>;
  description?: Maybe<Scalars["String"]>;
  isInEstimate?: Maybe<Scalars["Boolean"]>;
  laborCost: Scalars["Currency"];
  name: Scalars["String"];
  partNumber?: Maybe<Scalars["String"]>;
  partsCost: Scalars["Currency"];
  partsStore?: Maybe<PartsStore>;
  product?: Maybe<Scalars["ID"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  vendorPartsCost?: Maybe<Scalars["Currency"]>;
};

export type ItemInput = {
  amount: Scalars["Currency"];
  description?: InputMaybe<Scalars["String"]>;
  isInEstimate?: InputMaybe<Scalars["Boolean"]>;
  laborCost: Scalars["Currency"];
  name: Scalars["String"];
  partNumber?: InputMaybe<Scalars["String"]>;
  partsCost: Scalars["Currency"];
  partsStore?: InputMaybe<Scalars["ID"]>;
  product?: InputMaybe<Scalars["ID"]>;
  vendorPartsCost?: InputMaybe<Scalars["Currency"]>;
};

export type Job = {
  __typename?: "Job";
  activityFeed?: Maybe<Array<Maybe<Activity>>>;
  appointment?: Maybe<Appointment>;
  contact?: Maybe<Contact>;
  contactPaidInvoices?: Maybe<Scalars["Int"]>;
  convertExperiments?: Maybe<JobConvertExperiments>;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  diagnosticsQuestionsAnswers?: Maybe<Array<Maybe<DiagnosticsQuestionAnswer>>>;
  earlyNotice?: Maybe<Scalars["Boolean"]>;
  estimate?: Maybe<Estimate>;
  estimateSentAt?: Maybe<Scalars["Date"]>;
  feedback?: Maybe<JobFeedback>;
  id: Scalars["ID"];
  invoice?: Maybe<Invoice>;
  items?: Maybe<Array<Maybe<Item>>>;
  jobCausedDiagnostic?: Maybe<Job>;
  jobCausedWarrantyCheck?: Maybe<Job>;
  jobName?: Maybe<Scalars["String"]>;
  jobNumber?: Maybe<Scalars["String"]>;
  jobViocBrakeRepair?: Maybe<Job>;
  jobViocInspection?: Maybe<Job>;
  locationType?: Maybe<Scalars["String"]>;
  mHelpId?: Maybe<Scalars["String"]>;
  market?: Maybe<Scalars["String"]>;
  partsInfo?: Maybe<PartsInfo>;
  partsLeadTimeInDays?: Maybe<Scalars["Int"]>;
  partsOrderingIssuesSurvey?: Maybe<PartsOrderingIssuesSurvey>;
  partsOrderingIssuesSurveys?: Maybe<Array<Maybe<PartsOrderingIssuesSurvey>>>;
  partsStore?: Maybe<PartsStore>;
  pipedriveDealId?: Maybe<Scalars["String"]>;
  postJobChecklist?: Maybe<PostJobChecklist>;
  preJobCheckList?: Maybe<PreJobCheckList>;
  preJobCheckListV2?: Maybe<PreJobCheckListV2>;
  priceInfo?: Maybe<EjiPriceInfo>;
  privateNotes?: Maybe<Scalars["String"]>;
  requestedServices?: Maybe<Array<Maybe<Scalars["String"]>>>;
  selfBookingIdentifier?: Maybe<Scalars["String"]>;
  serviceCallReason?: Maybe<Scalars["String"]>;
  serviceCallReasonExtraInfo?: Maybe<Scalars["String"]>;
  serviceCatalogueUsed?: Maybe<Scalars["String"]>;
  serviceLocation?: Maybe<Scalars["String"]>;
  serviceLocationGeocode?: Maybe<Scalars["JSON"]>;
  serviceLocationNotes?: Maybe<Scalars["String"]>;
  serviceLocationZip?: Maybe<Scalars["String"]>;
  services?: Maybe<Array<Maybe<EjiService>>>;
  status: Scalars["String"];
  symptomDiagnosisCategory?: Maybe<Scalars["String"]>;
  taxable?: Maybe<Scalars["Boolean"]>;
  testVariants?: Maybe<Array<Maybe<Scalars["String"]>>>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  vehicle?: Maybe<VehicleType>;
  vehicleId?: Maybe<Scalars["ID"]>;
  vehicleInfo?: Maybe<VehicleInfo>;
  vehicleSymptoms?: Maybe<Array<Maybe<VehicleSymptom>>>;
  viocStore?: Maybe<ViocStore>;
  warrantyCheckReason?: Maybe<Scalars["String"]>;
  warrantyCheckReasonExtraInfo?: Maybe<Scalars["String"]>;
  warrantyCheckTechReason?: Maybe<Scalars["String"]>;
  warrantyCheckTechReasonExtraInfo?: Maybe<Scalars["String"]>;
  withdrawExtraInfo?: Maybe<Scalars["String"]>;
  withdrawReason?: Maybe<Scalars["String"]>;
  withdrawnAt?: Maybe<Scalars["Date"]>;
  zendeskTags?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type JobAndInvoiceResult = {
  __typename?: "JobAndInvoiceResult";
  invoice?: Maybe<Invoice>;
  job?: Maybe<Job>;
};

export type JobConvertExperiments = {
  __typename?: "JobConvertExperiments";
  successPageSocialProof?: Maybe<Scalars["String"]>;
};

export type JobFeedback = {
  __typename?: "JobFeedback";
  additionalComments?: Maybe<Scalars["String"]>;
  doneBetter?: Maybe<Array<Maybe<Scalars["String"]>>>;
  rating?: Maybe<Scalars["Int"]>;
};

export type JobFeedbackInput = {
  additionalComments?: InputMaybe<Scalars["String"]>;
  doneBetter?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  rating?: InputMaybe<Scalars["Int"]>;
};

export type JobsConnection = {
  __typename?: "JobsConnection";
  edges: Array<Job>;
  pageInfo?: Maybe<PageInfo>;
};

export type Lead = {
  __typename?: "Lead";
  address?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  hearAboutUs?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["ID"]>;
  market?: Maybe<Scalars["String"]>;
  requestedServices?: Maybe<Array<Maybe<Scalars["String"]>>>;
  serviceUrgency?: Maybe<Scalars["String"]>;
  source?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  vehicleInfo?: Maybe<VehicleInfo>;
  vehicleSymptoms?: Maybe<Array<Maybe<VehicleSymptom>>>;
  zip?: Maybe<Scalars["String"]>;
};

export type LeadInput = {
  additionalData?: InputMaybe<Scalars["JSON"]>;
  address?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["Date"]>;
  hearAboutUs?: InputMaybe<Scalars["String"]>;
  market?: InputMaybe<Scalars["String"]>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceUrgency?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  vehicleInfo: VehicleInfoInput;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type Location = {
  __typename?: "Location";
  coordinates?: Maybe<Array<Maybe<Scalars["Float"]>>>;
  type?: Maybe<Scalars["String"]>;
};

export type Market = {
  __typename?: "Market";
  activeServiceArea?: Maybe<Scalars["JSON"]>;
  activeServiceAreaGeoJson?: Maybe<Scalars["JSON"]>;
  address?: Maybe<Scalars["String"]>;
  businessID?: Maybe<Scalars["String"]>;
  drumsPricingVariance?: Maybe<MarketPricingVariance>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  laborRate?: Maybe<Scalars["Currency"]>;
  laborTaxRate?: Maybe<Scalars["Float"]>;
  marketArea?: Maybe<Scalars["JSON"]>;
  marketAreaGeoJson?: Maybe<Scalars["JSON"]>;
  marketStatus?: Maybe<MarketStatusEnum>;
  name: Scalars["String"];
  padsPricingVariance?: Maybe<MarketPricingVariance>;
  partsTaxRate?: Maybe<Scalars["Float"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  rotorsPricingVariance?: Maybe<MarketPricingVariance>;
  salesTaxType?: Maybe<Scalars["String"]>;
  shoesPricingVariance?: Maybe<MarketPricingVariance>;
  timeZone?: Maybe<Scalars["String"]>;
  variance?: Maybe<MarketVariance>;
  vendorUnitCostMultipliers?: Maybe<Array<Maybe<VendorUnitCostMultiplier>>>;
};

export type MarketDayVariance = {
  __typename?: "MarketDayVariance";
  friday?: Maybe<Scalars["Currency"]>;
  monday?: Maybe<Scalars["Currency"]>;
  saturday?: Maybe<Scalars["Currency"]>;
  sunday?: Maybe<Scalars["Currency"]>;
  thursday?: Maybe<Scalars["Currency"]>;
  tuesday?: Maybe<Scalars["Currency"]>;
  wednesday?: Maybe<Scalars["Currency"]>;
};

export type MarketDayVarianceInput = {
  friday?: InputMaybe<Scalars["Currency"]>;
  monday?: InputMaybe<Scalars["Currency"]>;
  saturday?: InputMaybe<Scalars["Currency"]>;
  sunday?: InputMaybe<Scalars["Currency"]>;
  thursday?: InputMaybe<Scalars["Currency"]>;
  tuesday?: InputMaybe<Scalars["Currency"]>;
  wednesday?: InputMaybe<Scalars["Currency"]>;
};

export type MarketLeadTimeVariance = {
  __typename?: "MarketLeadTimeVariance";
  nextDay?: Maybe<Scalars["Currency"]>;
  oneToTwoDays?: Maybe<Scalars["Currency"]>;
  sameDay?: Maybe<Scalars["Currency"]>;
  twoPlusDays?: Maybe<Scalars["Currency"]>;
};

export type MarketLeadTimeVarianceInput = {
  nextDay?: InputMaybe<Scalars["Currency"]>;
  oneToTwoDays?: InputMaybe<Scalars["Currency"]>;
  sameDay?: InputMaybe<Scalars["Currency"]>;
  twoPlusDays?: InputMaybe<Scalars["Currency"]>;
};

export type MarketPricingVariance = {
  __typename?: "MarketPricingVariance";
  performanceOption?: Maybe<Scalars["Currency"]>;
  premiumOption?: Maybe<Scalars["Currency"]>;
  standardOption?: Maybe<Scalars["Currency"]>;
};

export type MarketPricingVarianceInput = {
  performanceOption?: InputMaybe<Scalars["Currency"]>;
  premiumOption?: InputMaybe<Scalars["Currency"]>;
  standardOption?: InputMaybe<Scalars["Currency"]>;
};

export enum MarketStatusEnum {
  Active = "Active",
  Inactive = "Inactive",
  Test = "Test",
}

export type MarketVariance = {
  __typename?: "MarketVariance";
  daysOfWeek?: Maybe<MarketDayVariance>;
  drums?: Maybe<MarketPricingVariance>;
  leadTime?: Maybe<MarketLeadTimeVariance>;
  pads?: Maybe<MarketPricingVariance>;
  rotors?: Maybe<MarketPricingVariance>;
  shoes?: Maybe<MarketPricingVariance>;
};

export type MarketVarianceInput = {
  daysOfWeek?: InputMaybe<MarketDayVarianceInput>;
  drums?: InputMaybe<MarketPricingVarianceInput>;
  leadTime?: InputMaybe<MarketLeadTimeVarianceInput>;
  pads?: InputMaybe<MarketPricingVarianceInput>;
  rotors?: InputMaybe<MarketPricingVarianceInput>;
  shoes?: InputMaybe<MarketPricingVarianceInput>;
};

export type Mutation = {
  __typename?: "Mutation";
  addContactPaymentMethod?: Maybe<Contact>;
  addCreditCardByOneTimeId: Scalars["Boolean"];
  cancelPaymentIntent?: Maybe<Payment>;
  capturePaymentIntent?: Maybe<Scalars["JSON"]>;
  catchAll?: Maybe<Scalars["JSON"]>;
  closeZeroDollarInvoice?: Maybe<JobAndInvoiceResult>;
  contactUs?: Maybe<Scalars["String"]>;
  createAirtableJob?: Maybe<Job>;
  createAppointment?: Maybe<Appointment>;
  createAvailabilitySnapshot?: Maybe<AvailabilitySnapshotRes>;
  createCardSetupIntent?: Maybe<Scalars["JSON"]>;
  createCardSetupIntentByOneTimeId?: Maybe<Scalars["JSON"]>;
  createConfirmedPaymentIntent?: Maybe<PaymentIntent>;
  createEstimate: Estimate;
  createFeedback?: Maybe<Scalars["String"]>;
  createFile?: Maybe<Array<Maybe<File>>>;
  createInquiryRequest?: Maybe<InquiryRequest>;
  createInquiryRequestV2?: Maybe<InquiryRequestV2>;
  createInvoice?: Maybe<Invoice>;
  createJob?: Maybe<Job>;
  createLeadFromInvoice?: Maybe<Scalars["JSON"]>;
  createMarket: Market;
  createOneTimeIdentifierLink: Scalars["String"];
  createOpsManager?: Maybe<User>;
  createPartsStore: PartsStore;
  createPayment: Payment;
  createPaymentIntent: PaymentIntent;
  createProduct?: Maybe<Product>;
  createProductSet?: Maybe<ProductSet>;
  createPromoCode?: Maybe<PromoCode>;
  createPromoUser?: Maybe<PromoUser>;
  createReschedule?: Maybe<Reschedule>;
  createRescheduleV2?: Maybe<RescheduleResultV2>;
  createSelfScheduleRequest?: Maybe<StatusError>;
  createTechnician: Technician;
  createTechnicianCurrentAppVersion?: Maybe<TechnicianCurrentAppVersion>;
  createViocInspectionInquiryRequest?: Maybe<CreateViocInspectionInquiryRequestResult>;
  createViocLead?: Maybe<ViocLead>;
  createViocSelfScheduleRequest?: Maybe<ViocSelfScheduleStatusError>;
  customerWebappCreatePaymentIntent?: Maybe<PaymentIntent>;
  deleteAppointment: Scalars["ID"];
  deleteContactPaymentMethod?: Maybe<Contact>;
  deleteFile?: Maybe<Array<Maybe<File>>>;
  deleteInvoice: Scalars["ID"];
  discountTireContactUs?: Maybe<DiscountTireContactUsResult>;
  fleetContactUs?: Maybe<Scalars["String"]>;
  generateGenericPresignedUrl?: Maybe<Scalars["String"]>;
  generatePresignedUrl?: Maybe<Scalars["String"]>;
  getAQuote?: Maybe<GetAQuoteResult>;
  getTechnicianRoles?: Maybe<Array<Maybe<TechnicianRole>>>;
  getVinFromLicensePlate?: Maybe<Scalars["String"]>;
  holdStripeCard?: Maybe<PaymentIntent>;
  opsAdminCreateInquiryRequest?: Maybe<OpsAdminCreateInquiryRequestResult>;
  performAction: PerformActionResult;
  privateCreateLead?: Maybe<Contact>;
  requestBuyNowPayLater?: Maybe<Scalars["JSON"]>;
  sendAddContactCardSms?: Maybe<Scalars["JSON"]>;
  sendEstimateForJob?: Maybe<Job>;
  sendReceiptWithoutPaymentCheck?: Maybe<Scalars["JSON"]>;
  setContactDefaultPaymentMethod?: Maybe<Contact>;
  updateAppointment: Appointment;
  updateContact?: Maybe<Contact>;
  updateDiagnostics?: Maybe<Job>;
  updateEstimate?: Maybe<Estimate>;
  updateInquiryWithConvertExperimentsData?: Maybe<InquiryRequest>;
  updateInvoice?: Maybe<Invoice>;
  updateJob: Job;
  updateJobConvertExperimentsByInquiryId?: Maybe<Job>;
  updateMarket: Market;
  updateMyLocation: Technician;
  updatePartsStore?: Maybe<PartsStore>;
  updateProduct?: Maybe<Product>;
  updateProductSet?: Maybe<ProductSet>;
  updatePromoCode?: Maybe<PromoCode>;
  updateSelfScheduledJobVehicleInformation?: Maybe<UpdateSelfScheduledJobVehicleInformationResponse>;
  updateTechnician?: Maybe<Technician>;
  upsertVehicle?: Maybe<VehicleType>;
  validatePromoCode?: Maybe<PromoCode>;
};

export type MutationAddContactPaymentMethodArgs = {
  contactId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type MutationAddCreditCardByOneTimeIdArgs = {
  identifier: Scalars["String"];
  paymentMethodId: Scalars["ID"];
};

export type MutationCancelPaymentIntentArgs = {
  paymentId: Scalars["ID"];
};

export type MutationCapturePaymentIntentArgs = {
  amount: Scalars["Currency"];
  idempotencyKey?: InputMaybe<Scalars["String"]>;
  invoiceId: Scalars["ID"];
  paymentIntentId: Scalars["ID"];
};

export type MutationCatchAllArgs = {
  catchAllInput: Scalars["JSON"];
};

export type MutationCloseZeroDollarInvoiceArgs = {
  closeZeroDollarInvoiceInput: CloseZeroDollarInvoiceInput;
};

export type MutationContactUsArgs = {
  contactUsInput: ContactUsInput;
};

export type MutationCreateAirtableJobArgs = {
  createAirtableJobInput: CreateAirtableJobInput;
};

export type MutationCreateAppointmentArgs = {
  createAppointmentInput: AppointmentInput;
};

export type MutationCreateAvailabilitySnapshotArgs = {
  createAvailabilitySnapshotInput: CreateAvailabilitySnapshotInput;
};

export type MutationCreateCardSetupIntentArgs = {
  contactId: Scalars["ID"];
  nameOnCard?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateCardSetupIntentByOneTimeIdArgs = {
  identifier: Scalars["String"];
};

export type MutationCreateConfirmedPaymentIntentArgs = {
  createPaymentInput: CreatePaymentInput;
  idempotencyKey?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateEstimateArgs = {
  createEstimateInput: CreateEstimateInput;
};

export type MutationCreateFeedbackArgs = {
  createFeedbackInput: CreateFeedbackInput;
};

export type MutationCreateFileArgs = {
  createFileInput: CreateFileInput;
};

export type MutationCreateInquiryRequestArgs = {
  createInquiryRequestInput: CreateInquiryRequestInput;
};

export type MutationCreateInquiryRequestV2Args = {
  createInquiryRequestInput: CreateInquiryRequestInput;
};

export type MutationCreateInvoiceArgs = {
  createInvoiceInput: CreateInvoiceInput;
};

export type MutationCreateJobArgs = {
  createJobInput: CreateJobInput;
};

export type MutationCreateLeadFromInvoiceArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationCreateMarketArgs = {
  createMarketInput: CreateMarketInput;
};

export type MutationCreateOneTimeIdentifierLinkArgs = {
  contactId: Scalars["ID"];
};

export type MutationCreateOpsManagerArgs = {
  createUserInput: CreateUserInput;
};

export type MutationCreatePartsStoreArgs = {
  partsStoreInput: PartsStoreInput;
};

export type MutationCreatePaymentArgs = {
  createPaymentInput: CreatePaymentInput;
};

export type MutationCreatePaymentIntentArgs = {
  createPaymentInput: CreatePaymentInput;
  idempotencyKey?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateProductArgs = {
  createProductInput: CreateProductInput;
};

export type MutationCreateProductSetArgs = {
  createProductSetInput: CreateProductSetInput;
};

export type MutationCreatePromoCodeArgs = {
  createPromoCodeInput: PromoCodeInput;
};

export type MutationCreatePromoUserArgs = {
  promoUserInput: PromoUserInput;
};

export type MutationCreateRescheduleArgs = {
  rescheduleInput: RescheduleInput;
};

export type MutationCreateRescheduleV2Args = {
  rescheduleInput: RescheduleInputV2;
};

export type MutationCreateSelfScheduleRequestArgs = {
  createSelfScheduleRequest?: InputMaybe<CreateSelfScheduleRequest>;
};

export type MutationCreateTechnicianArgs = {
  createTechnicianInput: CreateTechnicianInput;
};

export type MutationCreateTechnicianCurrentAppVersionArgs = {
  version?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateViocInspectionInquiryRequestArgs = {
  createViocInspectionInquiryRequestInput: CreateViocInspectionInquiryRequestInput;
};

export type MutationCreateViocLeadArgs = {
  createViocLeadInput: CreateViocLeadInput;
};

export type MutationCreateViocSelfScheduleRequestArgs = {
  createViocSelfScheduleRequest?: InputMaybe<CreateViocSelfScheduleRequest>;
};

export type MutationCustomerWebappCreatePaymentIntentArgs = {
  createPaymentIntentInput: CreatePaymentIntentInput;
  idempotencyKey?: InputMaybe<Scalars["String"]>;
};

export type MutationDeleteAppointmentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteContactPaymentMethodArgs = {
  contactId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type MutationDeleteFileArgs = {
  contact: Scalars["ID"];
  fileName: Scalars["String"];
};

export type MutationDeleteInvoiceArgs = {
  id: Scalars["ID"];
};

export type MutationDiscountTireContactUsArgs = {
  discountTireContactUsInput?: InputMaybe<DiscountTireContactUsInput>;
};

export type MutationFleetContactUsArgs = {
  contactUsInput: ContactUsInput;
};

export type MutationGenerateGenericPresignedUrlArgs = {
  path: Scalars["String"];
};

export type MutationGeneratePresignedUrlArgs = {
  customerId: Scalars["ID"];
  fileName: Scalars["String"];
};

export type MutationGetAQuoteArgs = {
  getAQuoteInput: GetAQuoteInput;
};

export type MutationGetVinFromLicensePlateArgs = {
  licensePlate?: InputMaybe<Scalars["String"]>;
  stateCode?: InputMaybe<Scalars["String"]>;
};

export type MutationHoldStripeCardArgs = {
  createPaymentInputHoldCard: CreatePaymentInputHoldCard;
};

export type MutationOpsAdminCreateInquiryRequestArgs = {
  createInquiryRequestInput: CreateInquiryRequestInput;
};

export type MutationPerformActionArgs = {
  performActionInput: PerformActionInput;
};

export type MutationPrivateCreateLeadArgs = {
  createLeadInput: CreateLeadInput;
};

export type MutationRequestBuyNowPayLaterArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationSendAddContactCardSmsArgs = {
  contactId: Scalars["ID"];
};

export type MutationSendEstimateForJobArgs = {
  sendEstimateForJobInput: SendEstimateForJobInput;
};

export type MutationSendReceiptWithoutPaymentCheckArgs = {
  invoiceId: Scalars["ID"];
};

export type MutationSetContactDefaultPaymentMethodArgs = {
  contactId: Scalars["ID"];
  paymentMethodId: Scalars["ID"];
};

export type MutationUpdateAppointmentArgs = {
  id: Scalars["ID"];
  updateAppointmentInput: AppointmentInput;
};

export type MutationUpdateContactArgs = {
  id: Scalars["ID"];
  updateContactInput: UpdateContactInput;
};

export type MutationUpdateDiagnosticsArgs = {
  updateDiagnosticsInput: UpdateDiagnosticsInput;
};

export type MutationUpdateEstimateArgs = {
  id: Scalars["ID"];
  updateEstimateInput: UpdateEstimateInput;
};

export type MutationUpdateInquiryWithConvertExperimentsDataArgs = {
  updateInquiryWithConvertExperimentsDataInput: UpdateInquiryWithConvertExperimentsDataInput;
};

export type MutationUpdateInvoiceArgs = {
  id: Scalars["ID"];
  updateInvoiceInput: UpdateInvoiceInput;
};

export type MutationUpdateJobArgs = {
  id: Scalars["ID"];
  updateJobInput: UpdateJobInput;
};

export type MutationUpdateJobConvertExperimentsByInquiryIdArgs = {
  updateJobConvertExperimentsByInquiryIdInput: UpdateJobConvertExperimentsByInquiryIdInput;
};

export type MutationUpdateMarketArgs = {
  id: Scalars["ID"];
  updateMarketInput: UpdateMarketInput;
};

export type MutationUpdateMyLocationArgs = {
  updateLocationInput: UpdateLocationInput;
};

export type MutationUpdatePartsStoreArgs = {
  id: Scalars["ID"];
  partsStoreInput: PartsStoreInput;
};

export type MutationUpdateProductArgs = {
  id: Scalars["ID"];
  updateProductInput: UpdateProductInput;
};

export type MutationUpdateProductSetArgs = {
  id: Scalars["ID"];
  updateProductSetInput: UpdateProductSetInput;
};

export type MutationUpdatePromoCodeArgs = {
  updatePromoCodeInput: UpdatePromoCodeInput;
};

export type MutationUpdateSelfScheduledJobVehicleInformationArgs = {
  updateSelfScheduledJobVehicleInformationInput: UpdateSelfScheduledJobVehicleInformationInput;
};

export type MutationUpdateTechnicianArgs = {
  id: Scalars["ID"];
  updateTechnicianInput: UpdateTechnicianInput;
};

export type MutationUpsertVehicleArgs = {
  vehicleInputData?: InputMaybe<VehicleInput>;
};

export type MutationValidatePromoCodeArgs = {
  code: Scalars["String"];
  jobId?: InputMaybe<Scalars["String"]>;
};

export type Named = {
  __typename?: "Named";
  name?: Maybe<Scalars["String"]>;
};

export type OdometerHistoryInput = {
  include?: InputMaybe<Scalars["Boolean"]>;
  mileage?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Date"]>;
  updatedBy?: InputMaybe<Scalars["String"]>;
};

export type OdometerHistoryType = {
  __typename?: "OdometerHistoryType";
  include?: Maybe<Scalars["Boolean"]>;
  mileage?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  updatedBy?: Maybe<Scalars["String"]>;
};

export type OilChangeDate = {
  __typename?: "OilChangeDate";
  date?: Maybe<Scalars["Date"]>;
  unavailable?: Maybe<Scalars["Boolean"]>;
};

export type OilChangeDateInput = {
  date?: InputMaybe<Scalars["Date"]>;
  unavailable?: InputMaybe<Scalars["Boolean"]>;
};

export type OilChangeMileage = {
  __typename?: "OilChangeMileage";
  mileage?: Maybe<Scalars["String"]>;
  unavailable?: Maybe<Scalars["Boolean"]>;
};

export type OilChangeMileageInput = {
  mileage?: InputMaybe<Scalars["String"]>;
  unavailable?: InputMaybe<Scalars["Boolean"]>;
};

export type OilLifeListItem = {
  __typename?: "OilLifeListItem";
  estimatedNextOilChangeTimeline?: Maybe<Scalars["String"]>;
  lastOilChangeDate?: Maybe<OilChangeDate>;
  nextOilChangeDate?: Maybe<OilChangeDate>;
  nextOilChangeMileage?: Maybe<OilChangeMileage>;
};

export type OilLifeListItemInput = {
  estimatedNextOilChangeTimeline?: InputMaybe<Scalars["String"]>;
  lastOilChangeDate?: InputMaybe<OilChangeDateInput>;
  nextOilChangeDate?: InputMaybe<OilChangeDateInput>;
  nextOilChangeMileage?: InputMaybe<OilChangeMileageInput>;
};

export type OneTimeIdentifierValidStatus = {
  __typename?: "OneTimeIdentifierValidStatus";
  message?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["Boolean"]>;
};

export type OpsAdminCreateInquiryRequestResult = {
  __typename?: "OpsAdminCreateInquiryRequestResult";
  contact?: Maybe<Contact>;
  inquiryRequest?: Maybe<InquiryRequest>;
  serviceRequest?: Maybe<Job>;
};

export type PadThickness = {
  __typename?: "PadThickness";
  thickness?: Maybe<Scalars["String"]>;
  unavailable?: Maybe<Scalars["Boolean"]>;
};

export type PadThicknessInput = {
  thickness?: InputMaybe<Scalars["String"]>;
  unavailable?: InputMaybe<Scalars["Boolean"]>;
};

export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]>;
  hasNextPage: Scalars["Boolean"];
  numRecords?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  prevCursor?: Maybe<Scalars["String"]>;
};

export type PaginatedQueryInput = {
  filter?: InputMaybe<Scalars["JSON"]>;
  limit: Scalars["Int"];
  skip: Scalars["Int"];
  sort?: InputMaybe<Scalars["JSON"]>;
};

export type Part = {
  __typename?: "Part";
  id: Scalars["ID"];
  label: Scalars["String"];
  partId: Scalars["Int"];
  productName: Scalars["String"];
  type: Scalars["String"];
  vendorUnitCost: Scalars["Currency"];
};

export type PartOrderItem = {
  __typename?: "PartOrderItem";
  isOrdered?: Maybe<Scalars["Boolean"]>;
  partNumber?: Maybe<Scalars["String"]>;
  partsStore?: Maybe<PartsStore>;
};

export type PartOrderItemInput = {
  isOrdered?: InputMaybe<Scalars["Boolean"]>;
  partNumber?: InputMaybe<Scalars["String"]>;
  partsStore?: InputMaybe<Scalars["ID"]>;
};

export type PartsInfo = {
  __typename?: "PartsInfo";
  callForPartsTicketNumber?: Maybe<Scalars["String"]>;
  cancellationInitiated?: Maybe<Scalars["Boolean"]>;
  cancellationTimestamp?: Maybe<Scalars["Date"]>;
  partsLocation?: Maybe<Scalars["String"]>;
  partsNotes?: Maybe<Scalars["String"]>;
  partsOrderNumber?: Maybe<Scalars["String"]>;
  partsOrdered?: Maybe<Scalars["Boolean"]>;
  partsOrderedTimestamp?: Maybe<Scalars["Date"]>;
  partsOrderingIssue?: Maybe<Scalars["String"]>;
  reasonRescheduled?: Maybe<Scalars["String"]>;
};

export type PartsInfoInput = {
  callForPartsTicketNumber?: InputMaybe<Scalars["String"]>;
  cancellationInitiated?: InputMaybe<Scalars["Boolean"]>;
  cancellationTimestamp?: InputMaybe<Scalars["Date"]>;
  partsLocation?: InputMaybe<Scalars["String"]>;
  partsNotes?: InputMaybe<Scalars["String"]>;
  partsOrderNumber?: InputMaybe<Scalars["String"]>;
  partsOrdered?: InputMaybe<Scalars["Boolean"]>;
  partsOrderedTimestamp?: InputMaybe<Scalars["Date"]>;
  partsOrderingIssue?: InputMaybe<Scalars["String"]>;
};

export type PartsOrderingIssuesSurvey = {
  __typename?: "PartsOrderingIssuesSurvey";
  additionalDetails?: Maybe<Scalars["String"]>;
  completed?: Maybe<Scalars["Boolean"]>;
  partsCorrect?: Maybe<Scalars["Boolean"]>;
  partsIssues?: Maybe<Array<Maybe<Scalars["String"]>>>;
  partsStores?: Maybe<Array<Maybe<Scalars["String"]>>>;
  submittedBy?: Maybe<Scalars["String"]>;
};

export type PartsOrderingIssuesSurveyInput = {
  additionalDetails?: InputMaybe<Scalars["String"]>;
  completed?: InputMaybe<Scalars["Boolean"]>;
  partsCorrect?: InputMaybe<Scalars["Boolean"]>;
  partsIssues?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  partsStores?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  submittedBy?: InputMaybe<Scalars["String"]>;
};

export type PartsStore = {
  __typename?: "PartsStore";
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  deactivated?: Maybe<Scalars["Boolean"]>;
  distanceFromReference?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  market?: Maybe<Market>;
  name: Scalars["String"];
  state?: Maybe<Scalars["String"]>;
  storeNumber: Scalars["String"];
  updatedAt?: Maybe<Scalars["Date"]>;
  vendor?: Maybe<Scalars["String"]>;
  zipcode?: Maybe<Scalars["String"]>;
};

export type PartsStoreInput = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  deactivated?: InputMaybe<Scalars["Boolean"]>;
  market?: InputMaybe<Scalars["ID"]>;
  name: Scalars["String"];
  state?: InputMaybe<Scalars["String"]>;
  storeNumber: Scalars["String"];
  vendor?: InputMaybe<Scalars["String"]>;
  zipcode?: InputMaybe<Scalars["String"]>;
};

export type Payment = {
  __typename?: "Payment";
  amount?: Maybe<Scalars["Currency"]>;
  contact?: Maybe<Contact>;
  createdAt?: Maybe<Scalars["Date"]>;
  createdBy?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  invoice?: Maybe<Invoice>;
  invoicePrice?: Maybe<Scalars["Currency"]>;
  job?: Maybe<Job>;
  laborCost?: Maybe<Scalars["Currency"]>;
  laborTax?: Maybe<Scalars["Currency"]>;
  partsCost?: Maybe<Scalars["Currency"]>;
  partsTax?: Maybe<Scalars["Currency"]>;
  payer?: Maybe<Scalars["String"]>;
  paymentIntentId?: Maybe<Scalars["String"]>;
  paymentMethod?: Maybe<Scalars["String"]>;
  paymentNumber?: Maybe<Scalars["String"]>;
  receivedDate?: Maybe<Scalars["Date"]>;
  source?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  subTotal?: Maybe<Scalars["Currency"]>;
  tip?: Maybe<Scalars["Currency"]>;
  totalTax?: Maybe<Scalars["Currency"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export type PaymentConnection = {
  __typename?: "PaymentConnection";
  edges?: Maybe<Array<Maybe<Payment>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PaymentIntent = {
  __typename?: "PaymentIntent";
  amount?: Maybe<Scalars["Int"]>;
  amount_capturable?: Maybe<Scalars["Int"]>;
  amount_received?: Maybe<Scalars["Int"]>;
  application_fee_amount?: Maybe<Scalars["Int"]>;
  canceled_at?: Maybe<Scalars["Int"]>;
  cancellation_reason?: Maybe<Scalars["JSON"]>;
  capture_method?: Maybe<Scalars["JSON"]>;
  charges?: Maybe<Scalars["JSON"]>;
  client_secret?: Maybe<Scalars["String"]>;
  confirmation_method?: Maybe<Scalars["JSON"]>;
  created?: Maybe<Scalars["Int"]>;
  currency?: Maybe<Scalars["String"]>;
  customer?: Maybe<Scalars["JSON"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  invoice?: Maybe<Scalars["JSON"]>;
  invoicePrice?: Maybe<Scalars["Int"]>;
  job?: Maybe<Scalars["JSON"]>;
  last_payment_error?: Maybe<Scalars["JSON"]>;
  livemode?: Maybe<Scalars["Boolean"]>;
  metadata?: Maybe<Scalars["JSON"]>;
  next_action?: Maybe<Scalars["JSON"]>;
  object?: Maybe<Scalars["JSON"]>;
  on_behalf_of?: Maybe<Scalars["JSON"]>;
  payment_method?: Maybe<Scalars["JSON"]>;
  payment_method_options?: Maybe<Scalars["JSON"]>;
  payment_method_types?: Maybe<Array<Maybe<Scalars["String"]>>>;
  receipt_email?: Maybe<Scalars["String"]>;
  review?: Maybe<Scalars["JSON"]>;
  setup_future_usage?: Maybe<Scalars["JSON"]>;
  shipping?: Maybe<Scalars["JSON"]>;
  source?: Maybe<Scalars["String"]>;
  statement_descriptor?: Maybe<Scalars["String"]>;
  statement_descriptor_suffix?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["JSON"]>;
  transfer_data?: Maybe<Scalars["JSON"]>;
  transfer_group?: Maybe<Scalars["String"]>;
};

export type PerformActionInput = {
  actionName: Scalars["String"];
  entityId: Scalars["String"];
  entityName: Scalars["String"];
};

export type PerformActionResult = Contact | Estimate | Invoice | Job;

export type PossibleEjiItem = {
  __typename?: "PossibleEJIItem";
  behavior: Scalars["String"];
  category: Scalars["String"];
  customerPrice?: Maybe<Scalars["Currency"]>;
  marketLaborRateUsed?: Maybe<Scalars["Currency"]>;
  marketVendorUnitCostMultiplierUsed?: Maybe<Scalars["Float"]>;
  notes?: Maybe<Scalars["String"]>;
  orderItem?: Maybe<PossiblePartOrderItem>;
  overriddenCustomerPrice: Scalars["Boolean"];
  productSelection?: Maybe<PossibleProductSelection>;
  serviceProductId: Scalars["ID"];
  type: Scalars["String"];
  units?: Maybe<Scalars["Float"]>;
  vendorUnitCost?: Maybe<Scalars["Currency"]>;
};

export type PossibleEjiItemInput = {
  behavior: Scalars["String"];
  category: Scalars["String"];
  customerPrice?: InputMaybe<Scalars["Currency"]>;
  marketLaborRateUsed?: InputMaybe<Scalars["Currency"]>;
  marketVendorUnitCostMultiplierUsed?: InputMaybe<Scalars["Float"]>;
  notes?: InputMaybe<Scalars["String"]>;
  orderItem?: InputMaybe<PartOrderItemInput>;
  overriddenCustomerPrice: Scalars["Boolean"];
  productSelection?: InputMaybe<ProductSelectionInput>;
  serviceProductId: Scalars["ID"];
  type: Scalars["String"];
  units?: InputMaybe<Scalars["Float"]>;
  vendorUnitCost?: InputMaybe<Scalars["Currency"]>;
};

export type PossibleEjiPriceResult = {
  __typename?: "PossibleEJIPriceResult";
  ejiPriceInfo: EjiPriceInfo;
  ejiServices: Array<Maybe<PossibleEjiService>>;
};

export type PossibleEjiService = {
  __typename?: "PossibleEJIService";
  customerPrice?: Maybe<Scalars["Currency"]>;
  inEstimate: Scalars["Boolean"];
  items?: Maybe<Array<Maybe<PossibleEjiItem>>>;
  name: Scalars["String"];
  service: Scalars["ID"];
  technicianPay?: Maybe<Scalars["Currency"]>;
};

export type PossibleEjiServiceInput = {
  inEstimate: Scalars["Boolean"];
  items: Array<InputMaybe<PossibleEjiItemInput>>;
  name: Scalars["String"];
  service: Scalars["ID"];
  technicianPay: Scalars["Currency"];
};

export type PossiblePartOrderItem = {
  __typename?: "PossiblePartOrderItem";
  isOrdered?: Maybe<Scalars["Boolean"]>;
  partNumber?: Maybe<Scalars["String"]>;
  partsStore?: Maybe<PartsStore>;
};

export type PossibleProductSelection = {
  __typename?: "PossibleProductSelection";
  name?: Maybe<Scalars["String"]>;
  part?: Maybe<Part>;
};

export type PostJobChecklist = {
  __typename?: "PostJobChecklist";
  additionalDetails?: Maybe<Scalars["String"]>;
  dashboardLights?: Maybe<Scalars["String"]>;
  estimatedTimeline?: Maybe<Scalars["Date"]>;
  finalRecomendation?: Maybe<Scalars["String"]>;
  lugnuts?: Maybe<Scalars["String"]>;
  needOutstandingRepair?: Maybe<Scalars["Boolean"]>;
  notes?: Maybe<Scalars["String"]>;
  otherConcerns?: Maybe<Scalars["String"]>;
  recommendedServices?: Maybe<Array<Maybe<Scalars["String"]>>>;
  testDriveExpirience?: Maybe<Scalars["String"]>;
  wheelStuds?: Maybe<Scalars["String"]>;
};

export type PostJobChecklistInput = {
  additionalDetails?: InputMaybe<Scalars["String"]>;
  dashboardLights?: InputMaybe<Scalars["String"]>;
  estimatedTimeline?: InputMaybe<Scalars["Date"]>;
  finalRecomendation?: InputMaybe<Scalars["String"]>;
  lugnuts?: InputMaybe<Scalars["String"]>;
  needOutstandingRepair?: InputMaybe<Scalars["Boolean"]>;
  notes?: InputMaybe<Scalars["String"]>;
  otherConcerns?: InputMaybe<Scalars["String"]>;
  recommendedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  testDriveExpirience?: InputMaybe<Scalars["String"]>;
  wheelStuds?: InputMaybe<Scalars["String"]>;
};

export type PreJobCheckList = {
  __typename?: "PreJobCheckList";
  alternator?: Maybe<PreJobCheckListItems>;
  brakeDrums?: Maybe<PreJobCheckListItems>;
  brakeFluid?: Maybe<PreJobCheckListItems>;
  brakeShoes?: Maybe<PreJobCheckListItems>;
  cabinAirFilter?: Maybe<PreJobCheckListItems>;
  calipers?: Maybe<PreJobCheckListItems>;
  carBattery?: Maybe<PreJobCheckListItems>;
  controlArms?: Maybe<PreJobCheckListItems>;
  dashboardLights?: Maybe<PreJobCheckListItems>;
  driveBelt?: Maybe<PreJobCheckListItems>;
  engineAirFilter?: Maybe<PreJobCheckListItems>;
  frontPads?: Maybe<PreJobCheckListItems>;
  frontPadsV2?: Maybe<BrakePadsListItem>;
  frontRotors?: Maybe<PreJobCheckListItems>;
  headLights?: Maybe<PreJobCheckListItems>;
  lugnuts?: Maybe<PreJobCheckListItems>;
  odometerReading?: Maybe<PreJobCheckListItems>;
  oilLife?: Maybe<OilLifeListItem>;
  otherBrakeParts?: Maybe<PreJobCheckListItems>;
  rearPads?: Maybe<PreJobCheckListItems>;
  rearPadsV2?: Maybe<BrakePadsListItem>;
  rearRotors?: Maybe<PreJobCheckListItems>;
  shocksStruts?: Maybe<PreJobCheckListItems>;
  sparkPlugs?: Maybe<PreJobCheckListItems>;
  studs?: Maybe<PreJobCheckListItems>;
  tailLights?: Maybe<PreJobCheckListItems>;
  tireTread?: Maybe<TireTreadListItem>;
  wheelHubs?: Maybe<PreJobCheckListItems>;
  wiperBlades?: Maybe<PreJobCheckListItems>;
  wiperFluid?: Maybe<PreJobCheckListItems>;
};

export type PreJobCheckListInput = {
  alternator?: InputMaybe<PreJobCheckListItemsInput>;
  brakeDrums?: InputMaybe<PreJobCheckListItemsInput>;
  brakeFluid?: InputMaybe<PreJobCheckListItemsInput>;
  brakeShoes?: InputMaybe<PreJobCheckListItemsInput>;
  cabinAirFilter?: InputMaybe<PreJobCheckListItemsInput>;
  calipers?: InputMaybe<PreJobCheckListItemsInput>;
  carBattery?: InputMaybe<PreJobCheckListItemsInput>;
  controlArms?: InputMaybe<PreJobCheckListItemsInput>;
  dashboardLights?: InputMaybe<PreJobCheckListItemsInput>;
  driveBelt?: InputMaybe<PreJobCheckListItemsInput>;
  engineAirFilter?: InputMaybe<PreJobCheckListItemsInput>;
  frontPads?: InputMaybe<PreJobCheckListItemsInput>;
  frontPadsV2?: InputMaybe<BrakePadsListItemInput>;
  frontRotors?: InputMaybe<PreJobCheckListItemsInput>;
  headLights?: InputMaybe<PreJobCheckListItemsInput>;
  lugnuts?: InputMaybe<PreJobCheckListItemsInput>;
  odometerReading?: InputMaybe<PreJobCheckListItemsInput>;
  oilLife?: InputMaybe<OilLifeListItemInput>;
  otherBrakeParts?: InputMaybe<PreJobCheckListItemsInput>;
  rearPads?: InputMaybe<PreJobCheckListItemsInput>;
  rearPadsV2?: InputMaybe<BrakePadsListItemInput>;
  rearRotors?: InputMaybe<PreJobCheckListItemsInput>;
  shocksStruts?: InputMaybe<PreJobCheckListItemsInput>;
  sparkPlugs?: InputMaybe<PreJobCheckListItemsInput>;
  studs?: InputMaybe<PreJobCheckListItemsInput>;
  tailLights?: InputMaybe<PreJobCheckListItemsInput>;
  tireTread?: InputMaybe<TireTreadListItemInput>;
  wheelHubs?: InputMaybe<PreJobCheckListItemsInput>;
  wiperBlades?: InputMaybe<PreJobCheckListItemsInput>;
  wiperFluid?: InputMaybe<PreJobCheckListItemsInput>;
};

export type PreJobCheckListItems = {
  __typename?: "PreJobCheckListItems";
  additionalInfo?: Maybe<Scalars["String"]>;
  partCondition?: Maybe<Scalars["String"]>;
};

export type PreJobCheckListItemsInput = {
  additionalInfo?: InputMaybe<Scalars["String"]>;
  partCondition?: InputMaybe<Scalars["String"]>;
};

export type PreJobCheckListV2 = {
  __typename?: "PreJobCheckListV2";
  brakeFluid?: Maybe<CheckListV2BrakeFluidInfo>;
  frontPads?: Maybe<CheckListV2PadsInfo>;
  frontRotors?: Maybe<CheckListV2RotorsInfo>;
  inspectedServices?: Maybe<CheckListV2InspectedServices>;
  odometer?: Maybe<Scalars["String"]>;
  preExistingConcernsNotes?: Maybe<Scalars["String"]>;
  privateNotes?: Maybe<Scalars["String"]>;
  rearDrums?: Maybe<CheckListV2DrumsInfo>;
  rearPads?: Maybe<CheckListV2PadsInfo>;
  rearRotors?: Maybe<CheckListV2RotorsInfo>;
  rearShoes?: Maybe<CheckListV2ShoesInfo>;
  usesRearShoesAndDrums?: Maybe<Scalars["Boolean"]>;
};

export type PreJobCheckListV2Input = {
  brakeFluid?: InputMaybe<CheckListV2BrakeFluidInfoInput>;
  frontPads?: InputMaybe<CheckListV2PadsInfoInput>;
  frontRotors?: InputMaybe<CheckListV2RotorsInfoInput>;
  inspectedServices?: InputMaybe<CheckListV2InspectedServicesInput>;
  licensePlate?: InputMaybe<Scalars["String"]>;
  licensePlateState?: InputMaybe<Scalars["String"]>;
  odometer?: InputMaybe<Scalars["String"]>;
  preExistingConcernsNotes?: InputMaybe<Scalars["String"]>;
  privateNotes?: InputMaybe<Scalars["String"]>;
  rearDrums?: InputMaybe<CheckListV2DrumsInfoInput>;
  rearPads?: InputMaybe<CheckListV2PadsInfoInput>;
  rearRotors?: InputMaybe<CheckListV2RotorsInfoInput>;
  rearShoes?: InputMaybe<CheckListV2ShoesInfoInput>;
  usesRearShoesAndDrums?: InputMaybe<Scalars["Boolean"]>;
};

export type Product = {
  __typename?: "Product";
  amount: Scalars["Currency"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  laborCost: Scalars["Currency"];
  name: Scalars["String"];
  partsCost: Scalars["Currency"];
  status?: Maybe<Scalars["String"]>;
};

export type ProductInput = {
  amount: Scalars["Currency"];
  description?: InputMaybe<Scalars["String"]>;
  laborCost: Scalars["Currency"];
  name: Scalars["String"];
  partsCost: Scalars["Currency"];
  status?: InputMaybe<Scalars["String"]>;
};

export type ProductSelection = {
  __typename?: "ProductSelection";
  name?: Maybe<Scalars["String"]>;
  part?: Maybe<Part>;
};

export type ProductSelectionInput = {
  name?: InputMaybe<Scalars["String"]>;
  part?: InputMaybe<Scalars["ID"]>;
};

export type ProductSet = {
  __typename?: "ProductSet";
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  products?: Maybe<Array<Maybe<Product>>>;
};

export type PromoCode = {
  __typename?: "PromoCode";
  code: Scalars["String"];
  combinable?: Maybe<Scalars["Boolean"]>;
  coupon: Coupon;
  createdBy?: Maybe<Scalars["String"]>;
  expiry?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["ID"]>;
  name: Scalars["String"];
  restrictions?: Maybe<Restriction>;
  startDate?: Maybe<Scalars["Date"]>;
  status?: Maybe<Scalars["String"]>;
  totalRedemptions?: Maybe<Scalars["Int"]>;
};

export type PromoCodeInput = {
  code: Scalars["String"];
  combinable?: InputMaybe<Scalars["Boolean"]>;
  coupon: CouponInput;
  expiry?: InputMaybe<Scalars["Date"]>;
  name: Scalars["String"];
  restrictions?: InputMaybe<RestrictionInput>;
  startDate?: InputMaybe<Scalars["Date"]>;
  status?: InputMaybe<Scalars["String"]>;
  totalRedemptions?: InputMaybe<Scalars["Int"]>;
};

export type PromoUser = {
  __typename?: "PromoUser";
  phoneNumber: Scalars["String"];
  promoContent: Scalars["String"];
  queryParams?: Maybe<Scalars["JSON"]>;
};

export type PromoUserInput = {
  phoneNumber: Scalars["String"];
  promoContent: Scalars["String"];
  queryParams?: InputMaybe<Scalars["JSON"]>;
};

export type Query = {
  __typename?: "Query";
  calculatePossibleEJIPriceInfo?: Maybe<PossibleEjiPriceResult>;
  customerGetInvoice?: Maybe<CustomerGetInvoiceResult>;
  generateJobDiagnosticsSummary?: Maybe<Scalars["String"]>;
  generateServices?: Maybe<GenerateServicesResult>;
  getActions: Array<Maybe<Action>>;
  getAllPossibleServices?: Maybe<Array<Maybe<PossibleEjiService>>>;
  getAllViocStores?: Maybe<Array<Maybe<ViocStore>>>;
  getAppointment?: Maybe<Appointment>;
  getAppointmentConflicts?: Maybe<Array<Maybe<Appointment>>>;
  getAppointmentCountByRange: Scalars["Int"];
  getAppointmentSuggestions?: Maybe<Array<Maybe<AppointmentSuggestion>>>;
  getAppointments?: Maybe<Array<Maybe<Appointment>>>;
  getAvailability?: Maybe<GetAvailabilityRes>;
  getContact: Contact;
  getContactCountByRange: Scalars["Int"];
  getContactExists: Scalars["Boolean"];
  getContacts: ContactConnection;
  getContactsPaginated: ContactConnection;
  getDefaultServices?: Maybe<PossibleEjiPriceResult>;
  getDriveTime?: Maybe<Array<Maybe<DistanceData>>>;
  getEstimate: Estimate;
  getEstimates: EstimatesConnection;
  getEstimatesPaginated: EstimatesConnection;
  getFiles?: Maybe<Array<Maybe<File>>>;
  getHelloWorld?: Maybe<Scalars["String"]>;
  getInvoice: Invoice;
  getInvoicePublic: Invoice;
  getInvoiceSalesByRange: Scalars["Currency"];
  getInvoices: InvoiceConnection;
  getInvoicesPaginated: InvoiceConnection;
  getJob: Job;
  getJobCountByRange: Scalars["Int"];
  getJobPublic: Job;
  getJobReschedules?: Maybe<Array<Maybe<Reschedule>>>;
  getJobs: JobsConnection;
  getJobsPaginated: JobsConnection;
  getJobsPaginatedV2: JobsConnection;
  getMakes?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getMarket?: Maybe<Market>;
  getMarketForAddress?: Maybe<Market>;
  getMarketForZipcode?: Maybe<Market>;
  getMarkets?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getMarketsV2?: Maybe<Array<Maybe<Market>>>;
  getModels?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getMyAppointments?: Maybe<Array<Maybe<Appointment>>>;
  getPartCatalogue?: Maybe<Array<Maybe<Part>>>;
  getPartsStore?: Maybe<PartsStore>;
  getPartsStores?: Maybe<Array<Maybe<PartsStore>>>;
  getPayment: Payment;
  getPayments: PaymentConnection;
  getPaymentsPaginated: PaymentConnection;
  getPossibleService?: Maybe<PossibleEjiService>;
  getProduct: Product;
  getProductSet: ProductSet;
  getProductSets: Array<ProductSet>;
  getProducts: Array<Product>;
  getPromoCode?: Maybe<PromoCode>;
  getPromoCodeCoupon?: Maybe<PromoCode>;
  getPromoCodes?: Maybe<Array<Maybe<PromoCode>>>;
  getReviews: ReviewConnection;
  getReviewsMetaData?: Maybe<ReviewMetaData>;
  getServiceCatalogue?: Maybe<Array<Maybe<Service>>>;
  getServiceCatalogueItem?: Maybe<ServiceCatalogueItem>;
  getSubModels?: Maybe<Array<Maybe<Scalars["String"]>>>;
  getTechnician: Technician;
  getTechnicianCurrentAppVersion?: Maybe<AppVersion>;
  getTechnicianRoles?: Maybe<Array<TechnicianRole>>;
  getTechnicians: Array<Technician>;
  getTechniciansHomePartsStores?: Maybe<Array<Maybe<PartsStore>>>;
  getVehicle: VehicleType;
  getVehicleServiceException?: Maybe<VehicleServiceException>;
  getVehicles?: Maybe<Array<Maybe<VehicleType>>>;
  getViocAvailability?: Maybe<GetViocAvailabilityRes>;
  oneTimeIdValid: OneTimeIdentifierValidStatus;
  search: SearchResult;
  searchContacts: Array<Maybe<Contact>>;
  searchProducts: Array<Maybe<Product>>;
  searchTechnicians: Array<Maybe<Technician>>;
  technicianGetMe: Technician;
};

export type QueryCalculatePossibleEjiPriceInfoArgs = {
  calculatePossibleEJIPriceInfoInput: CalculatePossibleEjiPriceInfoInput;
};

export type QueryCustomerGetInvoiceArgs = {
  id: Scalars["ID"];
};

export type QueryGenerateJobDiagnosticsSummaryArgs = {
  generateJobDiagnosticsSummaryInput: GenerateJobDiagnosticsSummaryInput;
};

export type QueryGenerateServicesArgs = {
  generateServicesInput: GenerateServicesInput;
};

export type QueryGetActionsArgs = {
  entityName?: InputMaybe<Scalars["String"]>;
};

export type QueryGetAllPossibleServicesArgs = {
  getAllPossibleServicesInput: GetAllPossibleServicesInput;
};

export type QueryGetAllViocStoresArgs = {
  filter?: InputMaybe<Scalars["JSON"]>;
};

export type QueryGetAppointmentArgs = {
  id: Scalars["ID"];
};

export type QueryGetAppointmentConflictsArgs = {
  endRange: Scalars["Date"];
  filter?: InputMaybe<Scalars["JSON"]>;
  startRange: Scalars["Date"];
};

export type QueryGetAppointmentCountByRangeArgs = {
  endRange: Scalars["Date"];
  startRange: Scalars["Date"];
};

export type QueryGetAppointmentSuggestionsArgs = {
  getAppointmentSuggestionsInput: GetAppointmentSuggestionsInput;
};

export type QueryGetAppointmentsArgs = {
  endRange: Scalars["Date"];
  filter?: InputMaybe<Scalars["JSON"]>;
  startRange: Scalars["Date"];
};

export type QueryGetAvailabilityArgs = {
  getAvailabilityInput: GetAvailabilityInput;
};

export type QueryGetContactArgs = {
  id: Scalars["ID"];
};

export type QueryGetContactCountByRangeArgs = {
  endRange: Scalars["Date"];
  startRange: Scalars["Date"];
};

export type QueryGetContactExistsArgs = {
  id: Scalars["ID"];
};

export type QueryGetContactsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetContactsPaginatedArgs = {
  paginatedQueryInput: PaginatedQueryInput;
};

export type QueryGetDefaultServicesArgs = {
  getDefaultServicesInput: GetDefaultServicesInput;
};

export type QueryGetDriveTimeArgs = {
  addresses: Array<InputMaybe<Scalars["String"]>>;
};

export type QueryGetEstimateArgs = {
  id: Scalars["ID"];
};

export type QueryGetEstimatesArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["JSON"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetEstimatesPaginatedArgs = {
  paginatedQueryInput: PaginatedQueryInput;
};

export type QueryGetFilesArgs = {
  contactId: Scalars["ID"];
};

export type QueryGetInvoiceArgs = {
  id: Scalars["ID"];
};

export type QueryGetInvoicePublicArgs = {
  id: Scalars["ID"];
};

export type QueryGetInvoiceSalesByRangeArgs = {
  endRange: Scalars["Date"];
  startRange: Scalars["Date"];
};

export type QueryGetInvoicesArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["JSON"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<Scalars["JSON"]>;
};

export type QueryGetInvoicesPaginatedArgs = {
  paginatedQueryInput: PaginatedQueryInput;
};

export type QueryGetJobArgs = {
  id: Scalars["ID"];
};

export type QueryGetJobCountByRangeArgs = {
  endRange: Scalars["Date"];
  startRange: Scalars["Date"];
};

export type QueryGetJobPublicArgs = {
  id: Scalars["ID"];
};

export type QueryGetJobReschedulesArgs = {
  jobNumber: Scalars["String"];
};

export type QueryGetJobsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["JSON"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetJobsPaginatedArgs = {
  paginatedQueryInput: PaginatedQueryInput;
};

export type QueryGetJobsPaginatedV2Args = {
  paginatedQueryInput: PaginatedQueryInput;
};

export type QueryGetMakesArgs = {
  year: Scalars["String"];
};

export type QueryGetMarketArgs = {
  id: Scalars["ID"];
};

export type QueryGetMarketForAddressArgs = {
  address: Scalars["String"];
};

export type QueryGetMarketForZipcodeArgs = {
  zipcode: Scalars["String"];
};

export type QueryGetModelsArgs = {
  make: Scalars["String"];
  year: Scalars["String"];
};

export type QueryGetMyAppointmentsArgs = {
  endRange: Scalars["Date"];
  filter?: InputMaybe<Scalars["JSON"]>;
  startRange: Scalars["Date"];
};

export type QueryGetPartsStoreArgs = {
  id: Scalars["ID"];
};

export type QueryGetPartsStoresArgs = {
  filter?: InputMaybe<Scalars["JSON"]>;
};

export type QueryGetPaymentArgs = {
  id: Scalars["ID"];
};

export type QueryGetPaymentsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<Scalars["JSON"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetPaymentsPaginatedArgs = {
  paginatedQueryInput: PaginatedQueryInput;
};

export type QueryGetPossibleServiceArgs = {
  getPossibleServiceInput: GetPossibleServiceInput;
};

export type QueryGetProductArgs = {
  id: Scalars["ID"];
};

export type QueryGetProductSetArgs = {
  id: Scalars["ID"];
};

export type QueryGetPromoCodeArgs = {
  code?: InputMaybe<Scalars["String"]>;
};

export type QueryGetReviewsArgs = {
  cursor?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
};

export type QueryGetServiceCatalogueItemArgs = {
  make: Scalars["String"];
  market: Scalars["String"];
  model: Scalars["String"];
  subModel: Scalars["String"];
  year: Scalars["String"];
};

export type QueryGetSubModelsArgs = {
  make: Scalars["String"];
  model: Scalars["String"];
  year: Scalars["String"];
};

export type QueryGetTechnicianArgs = {
  id: Scalars["ID"];
};

export type QueryGetTechniciansArgs = {
  filter?: InputMaybe<Scalars["JSON"]>;
};

export type QueryGetTechniciansHomePartsStoresArgs = {
  filter?: InputMaybe<Scalars["JSON"]>;
  referencePoint?: InputMaybe<GeocodeInput>;
};

export type QueryGetVehicleArgs = {
  id: Scalars["ID"];
};

export type QueryGetVehicleServiceExceptionArgs = {
  getVehicleServiceExceptionInput: GetVehicleServiceExceptionInput;
};

export type QueryGetVehiclesArgs = {
  contactId: Scalars["ID"];
};

export type QueryGetViocAvailabilityArgs = {
  getViocAvailabilityInput: GetViocAvailabilityInput;
};

export type QueryOneTimeIdValidArgs = {
  identifier: Scalars["String"];
};

export type QuerySearchArgs = {
  query: Scalars["String"];
};

export type QuerySearchContactsArgs = {
  query: Scalars["String"];
  sort?: InputMaybe<Scalars["JSON"]>;
};

export type QuerySearchProductsArgs = {
  query: Scalars["String"];
  sort?: InputMaybe<Scalars["JSON"]>;
};

export type QuerySearchTechniciansArgs = {
  query: Scalars["String"];
  sort?: InputMaybe<Scalars["JSON"]>;
};

export type Referral = {
  __typename?: "Referral";
  createdAt?: Maybe<Scalars["Date"]>;
  metadata?: Maybe<Scalars["JSON"]>;
  nubrakesReferralCode?: Maybe<Scalars["String"]>;
  referralId?: Maybe<Scalars["String"]>;
  referrer?: Maybe<Scalars["String"]>;
  shareUrl?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
};

export type Reschedule = {
  __typename?: "Reschedule";
  jobNumber: Scalars["String"];
  newDates: RescheduleDates;
  originalDates: RescheduleDates;
  rescheduleFiledBy: Scalars["String"];
  rescheduleReason: Scalars["String"];
};

export type RescheduleDates = {
  __typename?: "RescheduleDates";
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
};

export type RescheduleDatesInput = {
  endDate: Scalars["Date"];
  startDate: Scalars["Date"];
};

export type RescheduleInput = {
  jobNumber: Scalars["String"];
  newDates: RescheduleDatesInput;
  originalDates: RescheduleDatesInput;
  rescheduleReason: Scalars["String"];
};

export type RescheduleInputV2 = {
  appointmentId: Scalars["ID"];
  appointmentUpdates: AppointmentInput;
  jobNumber: Scalars["String"];
  newDates: RescheduleDatesInput;
  originalDates: RescheduleDatesInput;
  rescheduleReason: Scalars["String"];
};

export type RescheduleResultV2 = {
  __typename?: "RescheduleResultV2";
  appointment: Appointment;
  jobNumber: Scalars["String"];
  newDates: RescheduleDates;
  originalDates: RescheduleDates;
  rescheduleFiledBy: Scalars["String"];
  rescheduleReason: Scalars["String"];
};

export type Restriction = {
  __typename?: "Restriction";
  customer?: Maybe<Scalars["String"]>;
  customerType?: Maybe<Scalars["String"]>;
  firstTransaction?: Maybe<Scalars["Boolean"]>;
  market?: Maybe<Scalars["String"]>;
  maxRedemptions?: Maybe<Scalars["Int"]>;
  minimumAmount?: Maybe<Scalars["Int"]>;
  minimumItemCount?: Maybe<Scalars["Int"]>;
  product?: Maybe<Scalars["String"]>;
  service?: Maybe<Scalars["String"]>;
  vehicleType?: Maybe<Scalars["String"]>;
};

export type RestrictionInput = {
  customer?: InputMaybe<Scalars["String"]>;
  customerType?: InputMaybe<Scalars["String"]>;
  firstTransaction?: InputMaybe<Scalars["Boolean"]>;
  market?: InputMaybe<Scalars["String"]>;
  maxRedemptions?: InputMaybe<Scalars["Int"]>;
  minimumAmount?: InputMaybe<Scalars["Int"]>;
  minimumItemCount?: InputMaybe<Scalars["Int"]>;
  product?: InputMaybe<Scalars["String"]>;
  service?: InputMaybe<Scalars["String"]>;
  vehicleType?: InputMaybe<Scalars["String"]>;
};

export type Review = {
  __typename?: "Review";
  branch?: Maybe<Scalars["String"]>;
  comment: Scalars["String"];
  dateCreated: Scalars["Date"];
  rating: Scalars["String"];
  reviewType: Scalars["String"];
  reviewerName: Scalars["String"];
};

export type ReviewConnection = {
  __typename?: "ReviewConnection";
  edges: Array<Review>;
  pageInfo: PageInfo;
};

export type ReviewMetaData = {
  __typename?: "ReviewMetaData";
  rating?: Maybe<Scalars["String"]>;
  reviewCount?: Maybe<Scalars["Int"]>;
};

export type SchedulingDay = {
  __typename?: "SchedulingDay";
  dayOfWeek?: Maybe<Scalars["String"]>;
  endTime?: Maybe<Scalars["String"]>;
  startTime?: Maybe<Scalars["String"]>;
};

export type SchedulingDayInput = {
  dayOfWeek?: InputMaybe<Scalars["String"]>;
  endTime?: InputMaybe<Scalars["String"]>;
  startTime?: InputMaybe<Scalars["String"]>;
};

export type Scores = {
  __typename?: "Scores";
  driveTime?: Maybe<Scalars["Float"]>;
  numJobsOnDay?: Maybe<Scalars["Float"]>;
  startTime?: Maybe<Scalars["Float"]>;
};

export type SearchResult = {
  __typename?: "SearchResult";
  contacts?: Maybe<Array<Maybe<Contact>>>;
  estimates?: Maybe<Array<Maybe<Estimate>>>;
};

export type SelfBookingData = {
  __typename?: "SelfBookingData";
  eligibility: Scalars["String"];
  events?: Maybe<Array<Maybe<Event>>>;
  selfSchedulingLink?: Maybe<Scalars["String"]>;
};

export type SelfScheduleContactUpdate = {
  contactId?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
};

export type SelfScheduleResponseData = {
  __typename?: "SelfScheduleResponseData";
  selfScheduleVehicleInfoUpdateIdentifier?: Maybe<Scalars["String"]>;
};

export type SelfScheduleVehicleInfo = {
  licensePlate?: InputMaybe<Scalars["String"]>;
  licensePlateState?: InputMaybe<Scalars["String"]>;
  vin?: InputMaybe<Scalars["String"]>;
};

export type SendEstimateForJobInput = {
  jobId: Scalars["ID"];
};

export type Service = {
  __typename?: "Service";
  allowOnSiteAddOn?: Maybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  products?: Maybe<Array<Maybe<ServiceProduct>>>;
  technicianPay?: Maybe<Scalars["Currency"]>;
};

export type ServiceCatalogueItem = {
  __typename?: "ServiceCatalogueItem";
  daysOfWeek?: Maybe<CatalogueDaysOfWeek>;
  drums?: Maybe<CatalogueDrums>;
  frontPads?: Maybe<CataloguePads>;
  frontRotors?: Maybe<CatalogueRotors>;
  id: Scalars["ID"];
  leadTime?: Maybe<CatalogueLeadTime>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  rearPads?: Maybe<CataloguePads>;
  rearRotors?: Maybe<CatalogueRotors>;
  shoes?: Maybe<CatalogueShoes>;
  subModel?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type ServiceFollowUp = {
  followUpNeeded?: InputMaybe<Scalars["Boolean"]>;
  outreachDate?: InputMaybe<Scalars["Date"]>;
  outreachNotes?: InputMaybe<Scalars["String"]>;
  outreachTimeline?: InputMaybe<Scalars["String"]>;
};

export type ServiceFollowUpInfo = {
  __typename?: "ServiceFollowUpInfo";
  dateLeadCreated?: Maybe<Scalars["Date"]>;
  followUpNeeded?: Maybe<Scalars["Boolean"]>;
  outreachDate?: Maybe<Scalars["Date"]>;
  outreachNotes?: Maybe<Scalars["String"]>;
  outreachTimeline?: Maybe<Scalars["String"]>;
  pipedriveDealId?: Maybe<Scalars["String"]>;
};

export type ServiceProduct = {
  __typename?: "ServiceProduct";
  behavior: Scalars["String"];
  category: Scalars["String"];
  defaultCustomerPrice?: Maybe<Scalars["Currency"]>;
  defaultPartId?: Maybe<Scalars["Int"]>;
  defaultUnits?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  type: Scalars["String"];
};

export type StartTimeRange = {
  __typename?: "StartTimeRange";
  endTime: Scalars["Date"];
  numberOfPossibleTechnicians?: Maybe<Scalars["JSON"]>;
  startTime: Scalars["Date"];
  technicians?: Maybe<Scalars["JSON"]>;
};

export type StartTimeRangeInput = {
  endTime: Scalars["Date"];
  startTime: Scalars["Date"];
};

export type StatusError = {
  __typename?: "StatusError";
  data?: Maybe<SelfScheduleResponseData>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type Technician = {
  __typename?: "Technician";
  auth0Id?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  homeAddress?: Maybe<Scalars["String"]>;
  homePartsStore?: Maybe<PartsStore>;
  id: Scalars["ID"];
  is1099?: Maybe<Scalars["Boolean"]>;
  isActive?: Maybe<Scalars["Boolean"]>;
  isEllegibleToViocInspection?: Maybe<Scalars["Boolean"]>;
  isVirtual?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  location?: Maybe<Location>;
  market?: Maybe<Scalars["String"]>;
  marketRef?: Maybe<Market>;
  numSlots?: Maybe<Scalars["Int"]>;
  onWayMessage?: Maybe<Scalars["String"]>;
  payMultiplier?: Maybe<Scalars["Float"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  preferences?: Maybe<TechnicianPreferences>;
  role?: Maybe<TechnicianRole>;
  schedulingDays?: Maybe<Array<Maybe<SchedulingDay>>>;
  serviceAreaAnchorAddress?: Maybe<Scalars["String"]>;
  timeZone?: Maybe<Scalars["String"]>;
  version?: Maybe<Scalars["String"]>;
  whenIWorkUserId?: Maybe<Scalars["String"]>;
};

export type TechnicianCurrentAppVersion = {
  __typename?: "TechnicianCurrentAppVersion";
  version?: Maybe<Scalars["String"]>;
};

export type TechnicianPreferences = {
  __typename?: "TechnicianPreferences";
  receiveSMSNotifications?: Maybe<Scalars["Boolean"]>;
};

export type TechnicianPreferencesInput = {
  receiveSMSNotifications?: InputMaybe<Scalars["Boolean"]>;
};

export type TechnicianRole = {
  __typename?: "TechnicianRole";
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  payMultiplier?: Maybe<Scalars["Float"]>;
};

export type TimeWindow = {
  __typename?: "TimeWindow";
  endTimeWindow?: Maybe<Scalars["Date"]>;
  startTimeWindow?: Maybe<Scalars["Date"]>;
};

export type TimeWindowInput = {
  endTimeWindow: Scalars["Date"];
  startTimeWindow: Scalars["Date"];
};

export type TireTreadDepth = {
  __typename?: "TireTreadDepth";
  depth?: Maybe<Scalars["String"]>;
  unavailable?: Maybe<Scalars["Boolean"]>;
};

export type TireTreadDepthInput = {
  depth?: InputMaybe<Scalars["String"]>;
  unavailable?: InputMaybe<Scalars["Boolean"]>;
};

export type TireTreadListItem = {
  __typename?: "TireTreadListItem";
  frontLeft?: Maybe<TireTreadDepth>;
  frontRight?: Maybe<TireTreadDepth>;
  rearLeft?: Maybe<TireTreadDepth>;
  rearRight?: Maybe<TireTreadDepth>;
};

export type TireTreadListItemInput = {
  frontLeft?: InputMaybe<TireTreadDepthInput>;
  frontRight?: InputMaybe<TireTreadDepthInput>;
  rearLeft?: InputMaybe<TireTreadDepthInput>;
  rearRight?: InputMaybe<TireTreadDepthInput>;
};

export type UpdateContactInput = {
  createdBy?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  fleet?: InputMaybe<FleetInfoInput>;
  lastName?: InputMaybe<Scalars["String"]>;
  lead?: InputMaybe<UpdateLeadInput>;
  outreachMedium?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  stripeCustomer?: InputMaybe<Scalars["JSON"]>;
  stripePaymentMethods?: InputMaybe<Array<InputMaybe<Scalars["JSON"]>>>;
  taxExemptEin?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
};

export type UpdateDiagnosticsInput = {
  inquiryRequestId: Scalars["ID"];
  questionsAnswers?: InputMaybe<Array<InputMaybe<DiagnosticsQuestionAnswerInput>>>;
};

export type UpdateEstimateInput = {
  callForPartsTicketNumber?: InputMaybe<Scalars["String"]>;
  contact?: InputMaybe<Scalars["ID"]>;
  createdBy?: InputMaybe<Scalars["String"]>;
  discounts?: InputMaybe<Array<InputMaybe<EjiDiscountInput>>>;
  estimateNotes?: InputMaybe<Scalars["String"]>;
  issuedDate?: InputMaybe<Scalars["Date"]>;
  items?: InputMaybe<Array<InputMaybe<ItemInput>>>;
  market?: InputMaybe<Scalars["String"]>;
  partsLeadTimeInDays?: InputMaybe<Scalars["Int"]>;
  partsNotes?: InputMaybe<Scalars["String"]>;
  privateNotes?: InputMaybe<Scalars["String"]>;
  promoCodes?: InputMaybe<Array<InputMaybe<PromoCodeInput>>>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceLocation?: InputMaybe<Scalars["String"]>;
  serviceLocationNotes?: InputMaybe<Scalars["String"]>;
  services?: InputMaybe<Array<InputMaybe<EjiServiceInput>>>;
  status?: InputMaybe<Scalars["String"]>;
  taxable?: InputMaybe<Scalars["Boolean"]>;
  vehicleInfo?: InputMaybe<VehicleInfoInput>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
};

export type UpdateInquiryWithConvertExperimentsDataInput = {
  convertExperimentsData?: InputMaybe<Scalars["JSON"]>;
  inquiryRequestId?: InputMaybe<Scalars["String"]>;
};

export type UpdateInvoiceInput = {
  contact?: InputMaybe<Scalars["ID"]>;
  createdBy?: InputMaybe<Scalars["String"]>;
  customerMessage?: InputMaybe<Scalars["String"]>;
  discounts?: InputMaybe<Array<InputMaybe<EjiDiscountInput>>>;
  dueDate?: InputMaybe<Scalars["Date"]>;
  estimate?: InputMaybe<Scalars["ID"]>;
  issuedDate?: InputMaybe<Scalars["Date"]>;
  items?: InputMaybe<Array<InputMaybe<ItemInput>>>;
  job?: InputMaybe<Scalars["ID"]>;
  market?: InputMaybe<Scalars["String"]>;
  preJobCheckList?: InputMaybe<PreJobCheckListInput>;
  preJobCheckListV2?: InputMaybe<PreJobCheckListV2Input>;
  privateNotes?: InputMaybe<Scalars["String"]>;
  promoCodes?: InputMaybe<Array<InputMaybe<PromoCodeInput>>>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceCatalogueUsed?: InputMaybe<Scalars["String"]>;
  serviceFollowUp?: InputMaybe<ServiceFollowUp>;
  serviceLocation?: InputMaybe<Scalars["String"]>;
  services?: InputMaybe<Array<InputMaybe<EjiServiceInput>>>;
  status?: InputMaybe<Scalars["String"]>;
  taxable?: InputMaybe<Scalars["Boolean"]>;
  technician?: InputMaybe<Scalars["ID"]>;
  vehicleInfo?: InputMaybe<VehicleInfoInput>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
};

export type UpdateJobConvertExperimentsByInquiryIdInput = {
  experiment?: InputMaybe<ConvertExperiment>;
  inquiryRequestId?: InputMaybe<Scalars["String"]>;
  variant?: InputMaybe<Scalars["String"]>;
};

export type UpdateJobInput = {
  VIOCSymptoms?: InputMaybe<Scalars["Boolean"]>;
  appointment?: InputMaybe<Scalars["ID"]>;
  contact?: InputMaybe<Scalars["ID"]>;
  contactCopy?: InputMaybe<Scalars["JSON"]>;
  createdBy?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  discounts?: InputMaybe<Array<InputMaybe<EjiDiscountInput>>>;
  earlyNotice?: InputMaybe<Scalars["Boolean"]>;
  estimate?: InputMaybe<Scalars["ID"]>;
  feedback?: InputMaybe<JobFeedbackInput>;
  items?: InputMaybe<Array<InputMaybe<ItemInput>>>;
  jobCausedDiagnostic?: InputMaybe<Scalars["ID"]>;
  jobCausedWarrantyCheck?: InputMaybe<Scalars["ID"]>;
  jobName?: InputMaybe<Scalars["String"]>;
  jobNotes?: InputMaybe<Scalars["String"]>;
  jobViocBrakeRepair?: InputMaybe<Scalars["ID"]>;
  jobViocInspection?: InputMaybe<Scalars["ID"]>;
  locationType?: InputMaybe<Scalars["String"]>;
  market?: InputMaybe<Scalars["String"]>;
  partsInfo?: InputMaybe<PartsInfoInput>;
  partsLeadTimeInDays?: InputMaybe<Scalars["Int"]>;
  partsOrderingIssuesSurvey?: InputMaybe<PartsOrderingIssuesSurveyInput>;
  partsOrderingIssuesSurveys?: InputMaybe<Array<InputMaybe<PartsOrderingIssuesSurveyInput>>>;
  partsStore?: InputMaybe<Scalars["ID"]>;
  postJobChecklist?: InputMaybe<PostJobChecklistInput>;
  preJobCheckList?: InputMaybe<PreJobCheckListInput>;
  preJobCheckListV2?: InputMaybe<PreJobCheckListV2Input>;
  privateNotes?: InputMaybe<Scalars["String"]>;
  promoCodes?: InputMaybe<Array<InputMaybe<PromoCodeInput>>>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceCallReason?: InputMaybe<Scalars["String"]>;
  serviceCallReasonExtraInfo?: InputMaybe<Scalars["String"]>;
  serviceLocation?: InputMaybe<Scalars["String"]>;
  serviceLocationNotes?: InputMaybe<Scalars["String"]>;
  serviceLocationZip?: InputMaybe<Scalars["String"]>;
  services?: InputMaybe<Array<InputMaybe<EjiServiceInput>>>;
  status?: InputMaybe<Scalars["String"]>;
  symptomDiagnosisCategory?: InputMaybe<Scalars["String"]>;
  taxable?: InputMaybe<Scalars["Boolean"]>;
  type?: InputMaybe<Scalars["String"]>;
  vehicleId?: InputMaybe<Scalars["ID"]>;
  vehicleInfo?: InputMaybe<VehicleInfoInput>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
  viocStore?: InputMaybe<Scalars["ID"]>;
  warrantyCheckReason?: InputMaybe<Scalars["String"]>;
  warrantyCheckReasonExtraInfo?: InputMaybe<Scalars["String"]>;
  warrantyCheckTechReason?: InputMaybe<Scalars["String"]>;
  warrantyCheckTechReasonExtraInfo?: InputMaybe<Scalars["String"]>;
  withdrawExtraInfo?: InputMaybe<Scalars["String"]>;
  withdrawReason?: InputMaybe<Scalars["String"]>;
  withdrawnAt?: InputMaybe<Scalars["Date"]>;
  zendeskTags?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

export type UpdateLeadInput = {
  address?: InputMaybe<Scalars["String"]>;
  hearAboutUs?: InputMaybe<Scalars["String"]>;
  market?: InputMaybe<Scalars["String"]>;
  requestedServices?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  serviceUrgency?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  vehicleInfo?: InputMaybe<VehicleInfoInput>;
  vehicleSymptoms?: InputMaybe<Array<InputMaybe<VehicleSymptomInput>>>;
  zip?: InputMaybe<Scalars["String"]>;
};

export type UpdateLocationInput = {
  coordinates: Array<Scalars["Float"]>;
};

export type UpdateMarketInput = {
  activeServiceArea?: InputMaybe<Scalars["JSON"]>;
  activeServiceAreaGeoJson?: InputMaybe<Scalars["JSON"]>;
  address?: InputMaybe<Scalars["String"]>;
  businessID?: InputMaybe<Scalars["String"]>;
  drumsPricingVariance?: InputMaybe<MarketPricingVarianceInput>;
  email?: InputMaybe<Scalars["String"]>;
  laborRate?: InputMaybe<Scalars["Currency"]>;
  laborTaxRate?: InputMaybe<Scalars["Float"]>;
  marketArea?: InputMaybe<Scalars["JSON"]>;
  marketAreaGeoJson?: InputMaybe<Scalars["JSON"]>;
  marketStatus?: InputMaybe<MarketStatusEnum>;
  name?: InputMaybe<Scalars["String"]>;
  padsPricingVariance?: InputMaybe<MarketPricingVarianceInput>;
  partsTaxRate?: InputMaybe<Scalars["Float"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  rotorsPricingVariance?: InputMaybe<MarketPricingVarianceInput>;
  salesTaxType?: InputMaybe<Scalars["String"]>;
  shoesPricingVariance?: InputMaybe<MarketPricingVarianceInput>;
  timeZone?: InputMaybe<Scalars["String"]>;
  variance?: InputMaybe<MarketVarianceInput>;
  vendorUnitCostMultipliers?: InputMaybe<Array<InputMaybe<VendorUnitCostMultiplierInput>>>;
};

export type UpdateProductInput = {
  amount?: InputMaybe<Scalars["Currency"]>;
  description?: InputMaybe<Scalars["String"]>;
  laborCost?: InputMaybe<Scalars["Currency"]>;
  name?: InputMaybe<Scalars["String"]>;
  partsCost?: InputMaybe<Scalars["Currency"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type UpdateProductSetInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  products?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
};

export type UpdatePromoCodeInput = {
  code: Scalars["String"];
  combinable?: InputMaybe<Scalars["Boolean"]>;
  coupon?: InputMaybe<CouponInput>;
  createdBy?: InputMaybe<Scalars["String"]>;
  expiry?: InputMaybe<Scalars["Date"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
  restrictions?: InputMaybe<Array<InputMaybe<RestrictionInput>>>;
  status?: InputMaybe<Scalars["String"]>;
  totalRedemptions?: InputMaybe<Scalars["Int"]>;
};

export type UpdateSelfScheduledJobVehicleInformationInput = {
  jobId: Scalars["ID"];
  selfScheduleVehicleInfoUpdateIdentifier: Scalars["String"];
  vehicleInfo: SelfScheduleVehicleInfo;
};

export type UpdateSelfScheduledJobVehicleInformationResponse = {
  __typename?: "UpdateSelfScheduledJobVehicleInformationResponse";
  jobId?: Maybe<Scalars["ID"]>;
};

export type UpdateTechnicianInput = {
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  homeAddress?: InputMaybe<Scalars["String"]>;
  homePartsStore?: InputMaybe<Scalars["ID"]>;
  is1099?: InputMaybe<Scalars["Boolean"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  isEllegibleToViocInspection?: InputMaybe<Scalars["Boolean"]>;
  isVirtual?: InputMaybe<Scalars["Boolean"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  location?: InputMaybe<UpdateLocationInput>;
  market?: InputMaybe<Scalars["String"]>;
  onWayMessage?: InputMaybe<Scalars["String"]>;
  payMultiplier?: InputMaybe<Scalars["Float"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  preferences?: InputMaybe<TechnicianPreferencesInput>;
  role?: InputMaybe<Scalars["ID"]>;
  roleCopy?: InputMaybe<Scalars["JSON"]>;
  schedulingDays?: InputMaybe<Array<InputMaybe<SchedulingDayInput>>>;
  serviceAreaAnchorAddress?: InputMaybe<Scalars["String"]>;
  timeZone?: InputMaybe<Scalars["String"]>;
  version?: InputMaybe<Scalars["String"]>;
  whenIWorkUserId?: InputMaybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
};

export type VehicleInfo = {
  __typename?: "VehicleInfo";
  additionalNotes?: Maybe<Scalars["String"]>;
  customerExpectation?: Maybe<Scalars["String"]>;
  discardThickness?: Maybe<Scalars["Float"]>;
  extraInfo?: Maybe<Scalars["String"]>;
  frontBrakeSymptoms?: Maybe<Array<Maybe<Scalars["String"]>>>;
  frontPadLife?: Maybe<Scalars["String"]>;
  frontRotorsDiscardThicknessInches?: Maybe<Scalars["Float"]>;
  licensePlate?: Maybe<Scalars["String"]>;
  licensePlateState?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  odometer?: Maybe<Scalars["String"]>;
  rearBrakeSymptoms?: Maybe<Array<Maybe<Scalars["String"]>>>;
  rearPadLife?: Maybe<Scalars["String"]>;
  rearRotorsDiscardThicknessInches?: Maybe<Scalars["Float"]>;
  symptoms?: Maybe<Scalars["String"]>;
  vin?: Maybe<Scalars["String"]>;
  wheelTorque?: Maybe<Scalars["Float"]>;
  year?: Maybe<Scalars["String"]>;
};

export type VehicleInfoInput = {
  additionalNotes?: InputMaybe<Scalars["String"]>;
  customerExpectation?: InputMaybe<Scalars["String"]>;
  discardThickness?: InputMaybe<Scalars["Float"]>;
  extraInfo?: InputMaybe<Scalars["String"]>;
  frontBrakeSymptoms?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  frontPadLife?: InputMaybe<Scalars["String"]>;
  frontRotorsDiscardThicknessInches?: InputMaybe<Scalars["Float"]>;
  licensePlate?: InputMaybe<Scalars["String"]>;
  licensePlateState?: InputMaybe<Scalars["String"]>;
  make?: InputMaybe<Scalars["String"]>;
  model?: InputMaybe<Scalars["String"]>;
  odometer?: InputMaybe<Scalars["String"]>;
  rearBrakeSymptoms?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  rearPadLife?: InputMaybe<Scalars["String"]>;
  rearRotorsDiscardThicknessInches?: InputMaybe<Scalars["Float"]>;
  symptoms?: InputMaybe<Scalars["String"]>;
  vin?: InputMaybe<Scalars["String"]>;
  wheelTorque?: InputMaybe<Scalars["Float"]>;
  year?: InputMaybe<Scalars["String"]>;
};

export type VehicleInput = {
  contactId: Scalars["ID"];
  frontRotorsDiscardThicknessInches?: InputMaybe<Scalars["Float"]>;
  hasDrumBrakes?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  inactive?: InputMaybe<Scalars["Boolean"]>;
  licensePlate?: InputMaybe<Scalars["String"]>;
  licensePlateState?: InputMaybe<Scalars["String"]>;
  make: Scalars["String"];
  model: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  notes?: InputMaybe<Scalars["String"]>;
  odometer?: InputMaybe<Scalars["String"]>;
  odometerHistory?: InputMaybe<Array<InputMaybe<OdometerHistoryInput>>>;
  parkingBrake?: InputMaybe<Scalars["String"]>;
  rearRotorsDiscardThicknessInches?: InputMaybe<Scalars["Float"]>;
  subModel?: InputMaybe<Scalars["String"]>;
  vin?: InputMaybe<Scalars["String"]>;
  wheelTorque?: InputMaybe<Scalars["String"]>;
  year: Scalars["String"];
};

export type VehicleServiceException = {
  __typename?: "VehicleServiceException";
  VehicleServiceExceptionRuleId?: Maybe<Scalars["String"]>;
  attributes?: Maybe<Scalars["JSON"]>;
  vehicleMake?: Maybe<Scalars["String"]>;
  vehicleModel?: Maybe<Scalars["String"]>;
  vehicleSubmodel?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["Int"]>;
};

export type VehicleSymptom = {
  __typename?: "VehicleSymptom";
  axle?: Maybe<Scalars["String"]>;
  symptom?: Maybe<Scalars["String"]>;
};

export type VehicleSymptomInput = {
  axle?: InputMaybe<Scalars["String"]>;
  symptom?: InputMaybe<Scalars["String"]>;
};

export type VehicleTechnicalInfo = {
  __typename?: "VehicleTechnicalInfo";
  frontRotorsDiscardThicknessInches?: Maybe<Scalars["Float"]>;
  hasDrumBrakes?: Maybe<Scalars["Boolean"]>;
  parkingBrake?: Maybe<Scalars["String"]>;
  rearRotorsDiscardThicknessInches?: Maybe<Scalars["Float"]>;
  wheelTorque?: Maybe<Scalars["String"]>;
};

export type VehicleType = {
  __typename?: "VehicleType";
  contactId?: Maybe<Scalars["ID"]>;
  createdAt?: Maybe<Scalars["Date"]>;
  id?: Maybe<Scalars["String"]>;
  inactive?: Maybe<Scalars["Boolean"]>;
  lastNotice?: Maybe<Scalars["Date"]>;
  licensePlate?: Maybe<Scalars["String"]>;
  licensePlateState?: Maybe<Scalars["String"]>;
  make?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  odometer?: Maybe<Scalars["String"]>;
  odometerHistory?: Maybe<Array<Maybe<OdometerHistoryType>>>;
  subModel?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["Date"]>;
  vehicleTechnicalInfo?: Maybe<VehicleTechnicalInfo>;
  vin?: Maybe<Scalars["String"]>;
  year?: Maybe<Scalars["String"]>;
};

export type VendorUnitCostMultiplier = {
  __typename?: "VendorUnitCostMultiplier";
  endRangePrice?: Maybe<Scalars["Currency"]>;
  multiplier?: Maybe<Scalars["Float"]>;
  startRangePrice?: Maybe<Scalars["Currency"]>;
};

export type VendorUnitCostMultiplierInput = {
  endRangePrice?: InputMaybe<Scalars["Currency"]>;
  multiplier?: InputMaybe<Scalars["Float"]>;
  startRangePrice?: InputMaybe<Scalars["Currency"]>;
};

export type ViocLead = {
  __typename?: "ViocLead";
  extraFields?: Maybe<Scalars["JSON"]>;
  id: Scalars["ID"];
  phoneNumber?: Maybe<Scalars["String"]>;
  queryParams?: Maybe<Scalars["JSON"]>;
  windowUrl?: Maybe<Scalars["String"]>;
};

export type ViocSelfScheduleResponseData = {
  __typename?: "ViocSelfScheduleResponseData";
  selfScheduleVehicleInfoUpdateIdentifier?: Maybe<Scalars["String"]>;
};

export type ViocSelfScheduleStatusError = {
  __typename?: "ViocSelfScheduleStatusError";
  data?: Maybe<ViocSelfScheduleResponseData>;
  errors?: Maybe<Array<Maybe<Scalars["String"]>>>;
  status?: Maybe<Array<Maybe<Scalars["String"]>>>;
};

export type ViocStore = {
  __typename?: "ViocStore";
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  isActive?: Maybe<Scalars["Boolean"]>;
  state?: Maybe<Scalars["String"]>;
  storeNumber?: Maybe<Scalars["String"]>;
  zip?: Maybe<Scalars["String"]>;
};
