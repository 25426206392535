import * as React from "react";
import { useState } from "react";
import { NuModal } from "../NuModal";
import { Button } from "../Buttons/Button";
import { AppointmentReadOnlySection } from "./AppointmentFormSection";
import { AppointmentFormValues } from "./AppointmentForm";
import { cond, isEmpty, isNil, keys } from "ramda";
import { useToggle } from "../../hooks/useToggle";
import { DateTime, Settings } from "luxon";
import SuggestionFormSection from "./SuggestionFormSection";
import {
  EjiService,
  Mutation,
  MutationCreateAvailabilitySnapshotArgs,
  PartsStore,
  PossibleEjiService,
} from "../../generated/nest-graphql";
import { Alert } from "../Alert";
import { doesNotExist } from "../../commonFunctions";
import { useMutation } from "@apollo/client";
import { CREATE_AVAILABILITY_SNAPSHOT } from "../../graphql/mutations/createAvailabilitySnapshot";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { useField } from "formik";

export const generateUnableCreateAppointmentWarning = (missingLocation: boolean, missingServices: boolean) =>
  cond([
    [() => missingLocation && missingServices, () => "Please add Services and a Service Location"],
    [() => missingLocation, () => "Please add a Service Location"],
    [() => missingServices, () => "Please add Services"],
  ])();

type AppointmentFormWithModalProps = {
  appointment: any;
  parentSetFieldValue: any;
  initialValues: AppointmentFormValues;
  earlyNotice?: boolean
  market: string;
  parentValidateForm: any;
  timeZone: string;
  serviceLocation: string;
  partsOrdered: boolean;
  itemPartsStores: PartsStore[];
  services: PossibleEjiService[] | EjiService[];
  contactId?: string;
  estimateId?: string;
  jobId: string;
  jobType?: string;
  jobCustomerType: "Fleet" | "Consumer";
};
export const AppointmentFormWithModal: React.FC<AppointmentFormWithModalProps> = ({
  appointment,
  parentSetFieldValue,
  initialValues,
  parentValidateForm,
  market,
  timeZone,
  partsOrdered,
  serviceLocation,
  itemPartsStores,
  jobId,
  jobType,
  services,
  contactId,
  estimateId,
  jobCustomerType,
  earlyNotice,
}) => {
  Settings.defaultZoneName = timeZone;
  const [modalOpen, , toggleModal] = useToggle();
  const [noVinModalIsOpen, , toggleNoVinModal] = useToggle();
  const [loading, setLoading] = useState(false);
  const [createAvailabilitySnapshot] = useMutation<Mutation, MutationCreateAvailabilitySnapshotArgs>(
    CREATE_AVAILABILITY_SNAPSHOT
  );
  const [serviceLocationOutOfServiceArea, setServiceLocationOutOfServiceArea] = useState(false);

  const [field] = useField("vin");
  const vin = field?.value;
  const hasVinWithValidLength = vin?.length === 17;

  // eslint-disable-next-line

  const onClick = async () => {
    const parentErrors = await parentValidateForm();
    if (keys(parentErrors).length === 0) {
      setLoading(true);
      const res = await createAvailabilitySnapshot({
        variables: {
          createAvailabilitySnapshotInput: {
            address: serviceLocation,
            startTime: DateTime.local().startOf("day").toJSDate(),
            laborTime: 60,
            contactId,
            estimateId,
            jobId,
          },
        },
      });
      if (res.data.createAvailabilitySnapshot?.error === "OutOfServiceAreaError") {
        setServiceLocationOutOfServiceArea(true);
      } else {
        setServiceLocationOutOfServiceArea(false);
      }
      toggleModal();
      setLoading(false);
    }
  };

  const scheduledDurationInitialValue = () => {
    const endDate = DateTime.fromJSDate(appointment.endDate);
    const startDate = DateTime.fromJSDate(appointment.startDate);
    return Math.round(endDate.diff(startDate, ["minutes"]).minutes);
  };
  return (
    <>
      {!appointment &&
        (loading ? (
          <FontAwesomeIcon icon={faSpinner as any} spin={true} />
        ) : (
          <Button type={"button"} onClick={onClick} disabled={isNil(serviceLocation) || isEmpty(serviceLocation)}>
            Add Appointment
          </Button>
        ))}
      {(doesNotExist(serviceLocation) || doesNotExist(services)) && (
        <Alert severity="warning" style={{ margin: "10px 0" }}>
          {generateUnableCreateAppointmentWarning(doesNotExist(serviceLocation), doesNotExist(services))}
        </Alert>
      )}
      {appointment && <AppointmentReadOnlySection appointment={appointment} onEditClick={toggleModal} parentSetFieldValue={parentSetFieldValue}/>}
      <NuModal isOpen={modalOpen} maxWidth="md" title={appointment ? "Edit Appointment" : "Add Appointment"}>
        {serviceLocationOutOfServiceArea && (
          <Alert severity="warning" style={{ margin: "10px 0" }}>
            {`Service Location is outside of a serviceable market (${market}). You will not be able to run "Get Recommendations".`}
          </Alert>
        )}
        <SuggestionFormSection
          isFixed={appointment?.isFixed}
          jobCustomerType={jobCustomerType}
          appointmentId={appointment?.id}
          scheduledDate={appointment?.startDate}
          scheduledDuration={appointment ? scheduledDurationInitialValue() : null}
          scheduledTechnician={appointment?.technician ?? null}
          onCancel={toggleModal}
          market={market}
          jobType={jobType}
          serviceLocation={serviceLocation}
          timeZone={timeZone}
          contactId={contactId}
          estimateId={estimateId}
          onSubmit={({
            allDay,
            endDate,
            startDate,
            endTimeWindow,
            startTimeWindow,
            subject,
            isFixed,
            technician,
            timeZone,
            availabilitySnapshotId,
            appointmentSnapshotId,
          }: AppointmentFormValues) => {
            if (!hasVinWithValidLength && jobType !== 'Vioc Inspection') {
              toggleNoVinModal();
              return;
            }
            parentSetFieldValue("appointmentInfo", {
              allDay: allDay ?? initialValues.allDay,
              endDate,
              isFixed,
              startTimeWindow,
              endTimeWindow,
              startDate,
              subject: subject ?? initialValues.subject,
              technician,
              timeZone,
              availabilitySnapshotId,
              appointmentSnapshotId,
            });
            if (!partsOrdered) {
              // TODO: might need to do a service catalogue used check here
              itemPartsStores.forEach((partsStore, idx) => {
                parentSetFieldValue(`items.${idx}.partsStore`, technician?.homePartsStore);
              });
              services?.forEach((service, serviceIdx) => {
                service.items?.forEach((item, itemIdx) => {
                  if (item.category === "Part") {
                    parentSetFieldValue(
                      `services.${serviceIdx}.items.${itemIdx}.orderItem.partsStore`,
                      technician?.homePartsStore
                    );
                  }
                });
              });
            }
            toggleModal();
          }}
        />
      </NuModal>
      <NuModal isOpen={noVinModalIsOpen} title="No valid VIN was provided">
        <div>
          <p>Please provide a valid VIN number before saving the appointment</p>
          <Button className="mt-4 float-right" type={"button"} onClick={toggleNoVinModal}>
            OK
          </Button>
        </div>
      </NuModal>
    </>
  );
};
