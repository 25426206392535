import React from "react";
import { cond, contains, defaultTo, T } from "ramda";
import { ContactDetails } from "./ContactDetails";
import { ContactsEstimates } from "./ContactsEstimates";
import { ContactsJobs } from "./ContactsJobs";
import { ContactsInvoices } from "./ContactsInvoices";
import { ContactsPayments } from "./ContactsPayments";
import { ContactsFiles } from "./ContactsFiles";
import { ContactCreditCards } from "./ContactCreditCards";
import { useQueryParams } from "../../hooks/useQueryParams";
import { ContactsVehicles } from "./ContactsVehicles";

export const ContactDetailsContainer: React.FC<{ contactId: string }> = ({ contactId }) => {
  const queryParams = useQueryParams();
  const tab = defaultTo("", queryParams.get("tab"));
  return (
    <div className={"p-4"}>
      {cond([
        [contains("estimates"), () => <ContactsEstimates contactId={contactId} />],
        [contains("jobs"), () => <ContactsJobs contactId={contactId} />],
        [contains("vehicles"), () => <ContactsVehicles contactId={contactId} />],
        [contains("invoices"), () => <ContactsInvoices contactId={contactId} />],
        [contains("payments"), () => <ContactsPayments contactId={contactId} />],
        [contains("files"), () => <ContactsFiles contactId={contactId} />],
        [contains("credit"), () => <ContactCreditCards contactId={contactId} />],
        [T, () => <ContactDetails contactId={contactId} />],
      ])(tab)}
    </div>
  );
};
