import gql from "graphql-tag";

export const technicianQueryReturnFields = `
        auth0Id
        id
        firstName
        lastName
        email
        payMultiplier
        phoneNumber
        market
        role {
            id
            name
            payMultiplier
        }
        isActive
        timeZone
        homePartsStore {
            id
            name
            storeNumber
            vendor
        }
        schedulingDays {
            dayOfWeek
            startTime
            endTime
        }
        onWayMessage
        serviceAreaAnchorAddress
        homeAddress
        isVirtual
        isEllegibleToViocInspection
        is1099
        `;

export const GET_TECHNICIAN = gql`query getTechnician($id: ID!) {
    getTechnician(id: $id) {
        ${technicianQueryReturnFields}
    }
}`;
