import * as React from "react";
import { CancelButton } from "./Buttons/CancelButton";
import { SubmitButton } from "./Buttons/SubmitButton";
import { DeleteButton } from "./Buttons/DeleteButton";

type SaveCancelRowProps = {
  width: number;
  onCancel: any;
  onDelete: any;
  isSubmitting: boolean;
  isValid: boolean;
  offsetLeft?: number;
};

export const SaveDeleteCancelRow: React.FC<SaveCancelRowProps> = ({
  onDelete,
  isValid,
  isSubmitting,
  onCancel,
  width,
  offsetLeft = 256,
}) => {
  return (
    <div
      className="bg-black"
      style={{
        position: "fixed",
        width,
        left: offsetLeft,
        bottom: 16,
        zIndex: 10,
      }}
    >
      <div className="flex flex-row justify-between">
        <div>
          <CancelButton onClick={onCancel} type={"reset"}>
            Cancel
          </CancelButton>
        </div>
        {onDelete && (
          <div>
            <DeleteButton onClick={onDelete} type={"reset"}>
              Delete
            </DeleteButton>
          </div>
        )}
        <div>
          <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
            Save
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};
