import gql from "graphql-tag";
import { ejiPriceInfoFields } from "./calculatePossibleEJIPriceInfo";
import { vehicleSymptomFields } from "./getContactDetails";
import { itemsFields } from "./getEstimate";
import { partDetailFields } from "./getPartCatalogue";
import { partsStoreDetailsFields } from "./getPartsStore";

export const invoiceDetailsFields = `
id
invoiceNumber
mHelpId
taxable
market
laborCost
partsCost
partsTax
laborTax
totalTax
job {
id
status
}
        status
        subTotal
        activityFeed {
            id
            actor
            verb
            objectId
            createdAt
        }
        vehicleInfo {
            frontPadLife
            licensePlate
            licensePlateState
            make
            model
            odometer
            frontRotorsDiscardThicknessInches
            rearRotorsDiscardThicknessInches
            rearPadLife
            vin
            year
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
            symptoms
        }
        vehicleSymptoms {
            ${vehicleSymptomFields}
        }
        requestedServices
        technician {
            id
            firstName
            lastName
            market
            isActive
        }
        contact {
            id
            firstName
            lastName
            email
            taxExemptEin
        }
        amountPaid
        balanceDue
        createdAt
        updatedAt
        issuedDate
        dueDate
        ${itemsFields}
        priceInfo{
            ${ejiPriceInfoFields}
        }
        estimate {
            id
        }
        serviceLocation
        privateNotes
        customerMessage
        serviceCatalogueUsed
        services {
            id
            service
            name
            inEstimate
            customerPrice
            technicianPay
            items {
                id
                serviceProductId
                productSelection {
                    name
                    part {
                        ${partDetailFields}
                    }
                }
                type
                units
                overriddenCustomerPrice
                customerPrice
                vendorUnitCost
                behavior
                category
                notes
                orderItem {
                    partsStore {
                        ${partsStoreDetailsFields}
                    }
                    isOrdered
                    partNumber
                }
                marketVendorUnitCostMultiplierUsed
                marketLaborRateUsed
            }
            updatedAt
            createdAt
        }`;

export const GET_INVOICE = gql`query getInvoice($id: ID!) {
    getInvoice(id: $id) {
        ${invoiceDetailsFields}
    }
}`;
