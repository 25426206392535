import { Column } from "react-table";
import { Market, MarketStatusEnum, Query } from "../../generated/nest-graphql";
import { Link } from "react-router-dom";
import React, { useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_MARKETS } from "../../graphql/queries/getMarkets";
import { SelectableEntityTable } from "../TableViewsPages/SelectableEntityTable";
import { AddToolbarButton } from "../Table/AddToolbarButton";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { filterDropDownStyles } from "../../material-ui-styles";

const marketStatusFilterOptions = ["All", ...Object.values(MarketStatusEnum)];

const MarketColumns: Column<Market>[] = [
  {
    Header: "Id",
    accessor: "id",
    Cell: (props) => {
      return (
        <Link className={"text-primary"} to={`/markets/${props.cell.value}`}>
          {props.cell.value}
        </Link>
      );
    },
  },
  { Header: "Name", accessor: "name" },
  { Header: "Timezone", accessor: "timeZone" },
  { Header: "Status", accessor: "marketStatus" },
];

export const MarketsTable = () => {
  const { data } = useQuery<Query>(GET_MARKETS);
  const getMarketsData = data?.getMarketsV2;
  const classes = filterDropDownStyles();
  const [marketStatusFilter, setMarketStatusFilter] = useState<MarketStatusEnum | "All">("All");
  const markets = useMemo(() => {
    if (!Array.isArray(getMarketsData)) return [];
    if (marketStatusFilter === "All") {
      return getMarketsData;
    } else {
      return getMarketsData.filter((market) => market.marketStatus === marketStatusFilter);
    }
  }, [getMarketsData, marketStatusFilter]);
  if (!markets) return null;
  return (
    <div>
      <div className={"grid grid-cols-4 gap-4"}>
        <Box style={{ width: 300 }}>
          <FormControl className={classes.root} variant="filled">
            <InputLabel>Status Filter</InputLabel>
            <Select
              label="Status"
              value={marketStatusFilter}
              onChange={(e) => {
                setMarketStatusFilter(e.target.value as MarketStatusEnum | "All");
              }}
            >
              {marketStatusFilterOptions.map((val, i) => (
                <MenuItem key={i} value={val}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>
      <SelectableEntityTable
        data={markets}
        columns={MarketColumns}
        numRecords={markets.length}
        title={"Markets"}
        endAdornments={<AddToolbarButton link={"/add/markets"} title={"Market"} />}
      />
    </div>
  );
};
