export type SymptomDiagnosisCategory =
  | "Grinding (Rotors)"
  | "Grinding (Drums)"
  | "Squeaking"
  | "Shaking"
  | "Need New Pads"
  | "Need New Rotors"
  | "Soft Pedal"
  | "Caliper"
  | "Squeaking/ Need Shoes"
  | "Something Else"
  | "General Maintenance";

export type PillData = {
  label: string;
  severity: 1 | 2 | 3;
};

export type SymptomData = {
  description: string;
  symptoms: {
    description: string;
    pills: PillData[];
  };
  diagnosis: {
    description: string;
    pills: PillData[];
  };
};

export const symptomDiagnosisCategories: SymptomDiagnosisCategory[] = [
  "Grinding (Rotors)",
  "Grinding (Drums)",
  "Squeaking",
  "Shaking",
  "Need New Pads",
  "Need New Rotors",
  "Soft Pedal",
  "Caliper",
  "Squeaking/ Need Shoes",
  "Something Else",
  "General Maintenance",
];

export const symptomDiagnosisData: Record<SymptomDiagnosisCategory, SymptomData> = {
  "Grinding (Rotors)": {
    description:
      "Grinding occurs when your pads wear all the way through until they are metal on metal with the rotors. Replacing pads and rotors is usually recommended.",
    symptoms: {
      description:
        "Grinding occurs when brake pads wear through to be metal on metal against the rotor, often leaving grooves. It'll be noticeable while braking and get worse with time.",
      pills: [
        {
          label: "Severe",
          severity: 3,
        },
      ],
    },
    diagnosis: {
      description:
        "Recommend replacing the pads and rotors where the noise is coming from to ensure fresh pad material makes contact with a smooth rotor surface.",
      pills: [
        {
          label: "Today",
          severity: 3,
        },
      ],
    },
  },
  Squeaking: {
    description:
      "Squeaking occurs when the brake pad wears down to their wear indicator. Replacing the brake pads is usually recommended.",
    symptoms: {
      description:
        "Squeaking occurs when the brake pads wear down far enough that the metal wear indicator makes contact with the rotor. It's a built-in mechanism to alert you that they need replacing.",
      pills: [
        {
          label: "At Risk",
          severity: 2,
        },
      ],
    },
    diagnosis: {
      description:
        "Recommend replacing the brake pads wherever the noise is coming from. Brake pads are replaced on both sides (left and right) to ensure even stopping power.",
      pills: [
        {
          label: "Replace Soon",
          severity: 2,
        },
      ],
    },
  },
  Shaking: {
    description:
      "Vibrating occurs when the rotors have warped, meaning the pads mesh with an uneven surface when braking. Replacing the brake pads and rotors is usually recommended.",
    symptoms: {
      description:
        "Shaking occurs when the rotors are warped. When you apply the brakes, the pads mesh with an uneven surface, often noticeable at higher speeds.",
      pills: [
        {
          label: "Severe",
          severity: 3,
        },
      ],
    },
    diagnosis: {
      description:
        "Recommend replacing the pads and rotors together wherever the rotors are warped. This ensures a fresh pad material makes contact with a smooth, flat rotor surface.",
      pills: [
        {
          label: "Today",
          severity: 3,
        },
      ],
    },
  },
  "Need New Pads": {
    description:
      "Brake pads need replacing every 30-60k miles. You may notice some squeaking or a slightly softer pedal than usual.",
    symptoms: {
      description:
        "Brake pads are designed to wear down but need replacing at around 4mm thickness. Usually you'll notice some symptoms when they do.",
      pills: [
        {
          label: "At Risk",
          severity: 2,
        },
      ],
    },
    diagnosis: {
      description:
        "Replacing the brake pads will provide more responsive braking and return your pad thicnkess to a safe specification.",
      pills: [
        {
          label: "Replace Soon",
          severity: 2,
        },
      ],
    },
  },
  "Need New Rotors": {
    description:
      "Your rotors will need replacing if they are grooved, warped or below thickness specification. We recommend replacing pads and rotors together to ensure safe braking.",
    symptoms: {
      description:
        "Rotors will wear down over time; grooves, heatspots, and other signs of visual wear may present or the thickness will be below specification.",
      pills: [
        {
          label: "Severe",
          severity: 3,
        },
      ],
    },
    diagnosis: {
      description:
        "Replacing brake pads and rotors together ensures safe braking with fresh pad material making contact with a smooth rotor surface.",
      pills: [
        {
          label: "Replace Soon",
          severity: 2,
        },
      ],
    },
  },
  "Soft Pedal": {
    description:
      "A soft pedal usually indicates that the pads are worn. When you brake, the pedal has to travel further than normal.",
    symptoms: {
      description:
        "A soft pedal most often indicates that the brake pads are low; it's important to take care of the issue before it worsens.",
      pills: [
        {
          label: "At Risk",
          severity: 2,
        },
      ],
    },
    diagnosis: {
      description:
        "Replacing the brake pads will restore responsivness to the brake pedal and the pads to a safe specification. It's important to take care of this issue before it worsens.",
      pills: [
        {
          label: "Replace Soon",
          severity: 2,
        },
      ],
    },
  },
  Caliper: {
    description:
      "Your caliper(s) will likely need replacing if there is leaking around the wheel or you are pulling to one side when braking. Doing so will ensure a correctly sealed hydraulic system and even distrubution of braking power.",
    symptoms: {
      description:
        "Faulty calipers will typically result in your vehicle pulling to one side, leaking around the wheel or total loss of brake pressure in extreme cases.",
      pills: [
        {
          label: "Severe",
          severity: 3,
        },
      ],
    },
    diagnosis: {
      description:
        "Replacing a caliper will ensure that even pressure is applied through the brake system. It's likely you'll need to replace the pads and rotors if they're also worn.",
      pills: [
        {
          label: "Today",
          severity: 3,
        },
      ],
    },
  },
  "Squeaking/ Need Shoes": {
    description:
      "Squeaking usually occurs when the brake shoes wear down. Replacing the brake shoes (including the hardware) is recommended.",
    symptoms: {
      description:
        "Squeaking occurs when the brake shoes wear down far enough or when the hardware no longer has enough tension.",
      pills: [
        {
          label: "At Risk",
          severity: 2,
        },
      ],
    },
    diagnosis: {
      description:
        "Replacing the shoes and hardware will ensure sufficient thickness on the shoes and correct hardware holding them in place.",
      pills: [
        {
          label: "Replace Soon",
          severity: 2,
        },
      ],
    },
  },

  "Grinding (Drums)": {
    description:
      "Grinding usually occurs when your shoes wear all the way through until they are metal on metal with the drums. Replacing shoes and drums is recommended.",
    symptoms: {
      description:
        "Grinding occurs when brake shoes wear through and become metal on metal against the drum. It may be noticeable while braking and get worse with time.",
      pills: [
        {
          label: "Severe",
          severity: 3,
        },
      ],
    },
    diagnosis: {
      description:
        "Recommend replacing the shoes (including the hardware) and drums together. This ensures a fresh friction material makes contact with a smooth drum surface.",
      pills: [
        {
          label: "Today",
          severity: 3,
        },
      ],
    },
  },
  "Something Else": {
    description:
      "We'll be equipped with all the brake parts required for general maintenance. Typically, you'll need brake pad (and possibly rotor) replacement every 30-60k miles.",
    symptoms: {
      description:
        "Whatever the issue, we'll be on hand to provide you with total clarity as to what your symptoms mean and what your options are.",
      pills: [
        {
          label: "At Risk",
          severity: 2,
        },
      ],
    },
    diagnosis: {
      description:
        "Most customers experiencing brake issues will require pads and/ or rotors. Your tech will have the parts with him on the day.",
      pills: [
        {
          label: "Replace Soon",
          severity: 2,
        },
      ],
    },
  },
  "General Maintenance": {
    description:
      "Taking care of your general maintenance in line with your vehicle manufacturer's guidelines will help your vehicle run more efficiently, for longer. It comes around quite often, all the more reason not to leave your home or office and have us come to you!",
    symptoms: {
      description:
        "General maintenance is usually scheduled in time or mileage intervals. Your vehicle may alert you to it with a warning light also.",
      pills: [],
    },
    diagnosis: {
      description:
        "Staying up to date with the OEM recommendation ensures more efficient driving for longer and reduces the risk of more expensive damage in the future.",
      pills: [],
    },
  },
};
