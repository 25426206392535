import { useLazyQuery } from "@apollo/client";
import {
  GetAppointmentSuggestionsInput,
  Query,
  QueryGetAppointmentSuggestionsArgs,
  Technician,
} from "../../generated/nest-graphql";
import { GET_APPOINTMENT_SUGGESTIONS } from "../../graphql/queries/getAppointmentSuggestions";
import React from "react";
import { SuggestionForm, SuggestionFormValues } from "../../components/Forms/SuggestionForm";
import { AppointmentOptions } from "./AppointmentOptions";
import { applySpec, prop } from "ramda";
import { BookingDetailsForm } from "../../components/Forms/BookingDetailsForm";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { technicianCopySpec } from "../specs/technicianCopySpec";
import { calcTimeWindowForAppointment } from "../../lib/functions";
import { useField } from "formik";

const laborTimes = [30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180];
const queryLengths = [1, 2, 3, 4, 5, 6, 7];

type SuggestionFormSectionTypes = {
  appointmentId?: string;
  scheduledDate?: Date;
  isFixed: boolean;
  scheduledDuration?: number;
  scheduledTechnician?: Technician;
  onSubmit: any;
  onCancel: any;
  market: string;
  jobId?: any;
  jobType?: string;
  shouldShowTechniciansNames?: boolean;
  serviceLocation: string;
  timeZone: string;
  jobName?: string;
  contactId?: string;
  estimateId?: string;
  daysToQuery?: number;
  isSubmittingForm?: boolean;
  jobCustomerType: `Fleet` | "Consumer";
};

export const suggestionFormSpec = (formData: GetAppointmentSuggestionsInput) =>
  applySpec({
    address: prop("address"),
    laborTime: prop("laborTime"),
    startTime: prop("startTime"),
    endTime: prop("endTime"),
  })(formData);

const isTodayOrPast = (inputDate: DateTime) => {
  return inputDate.get("day") === DateTime.now().get("day") || inputDate < DateTime.now();
};

const BookingDetailsFormValidationSchema = Yup.object().shape({
  startDate: Yup.date().required("Required"),
  laborTime: Yup.number().required("Required"),
  technician: Yup.object().required("Required"),
  rescheduleReason: Yup.string().nullable(),
  availabilitySnapshotId: Yup.string().nullable(),
  appointmentSnapshotId: Yup.string().nullable(),
});

const SuggestionFormSection = ({
  appointmentId,
  scheduledDate,
  scheduledDuration,
  scheduledTechnician,
  onSubmit,
  onCancel,
  isFixed,
  market,
  jobId,
  jobType,
  shouldShowTechniciansNames,
  serviceLocation,
  timeZone,
  jobName,
  contactId,
  estimateId,
  isSubmittingForm,
  daysToQuery,
  jobCustomerType,
}: SuggestionFormSectionTypes) => {
  const [getAppointmentSuggestions, { data, error, called, loading, variables: suggestionToolInputs }] = useLazyQuery<
    Query,
    QueryGetAppointmentSuggestionsArgs
  >(GET_APPOINTMENT_SUGGESTIONS, {
    fetchPolicy: "no-cache",
  });
  const [isUsingSuggestion, setIsUsingSuggestion] = React.useState(false);
  const [partsStoreField] = useField("partsStore");
  const partsStoreId = partsStoreField?.value?.id ?? partsStoreField?.value?._id ?? undefined;
  const partsStoreName = partsStoreField?.value?.name ?? undefined;
  const getSuggestions = async ({
    address,
    startTime,
    laborTime,
    daysToQuery,
    showOnlyForJobPartsStore,
  }: SuggestionFormValues) => {
    const luxonStartTime = DateTime.fromJSDate(startTime);
    const adjustedStartTime = isTodayOrPast(luxonStartTime) ? DateTime.now() : luxonStartTime.startOf("day");
    const adjustedInputs: GetAppointmentSuggestionsInput = {
      address,
      laborTime,
      startTime: adjustedStartTime.toJSDate(),
      endTime: adjustedStartTime
        .plus({ days: daysToQuery - 1 })
        .endOf("day")
        .toJSDate(),
      contactId: contactId,
      estimateId: estimateId,
      jobId,
      jobCustomerType,
      technicianPartsStoreId: showOnlyForJobPartsStore && partsStoreId ? partsStoreId : undefined,
    };
    await getAppointmentSuggestions({
      variables: {
        getAppointmentSuggestionsInput: adjustedInputs,
      },
    });
  };
  const {
    control,
    handleSubmit,
    setValue,
    formState: { dirtyFields, isValid, isSubmitting },
  } = useForm({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      startDate: scheduledDate ?? null,
      laborTime: scheduledDuration ?? 60,
      technician: scheduledTechnician ?? null,
      isFixed: isFixed,
      rescheduleReason: null,
      availabilitySnapshotId: "",
      appointmentSnapshotId: "",
    },
    resolver: yupResolver(BookingDetailsFormValidationSchema),
  });

  const onClickUseSuggestion = (suggestion) => {
    setIsUsingSuggestion(true);
    setValue("startDate", DateTime.fromISO(suggestion.startDate).setZone(suggestion.market.timeZone).toJSDate());
    setValue(
      "laborTime",
      DateTime.fromISO(suggestion.endDate).diff(DateTime.fromISO(suggestion.startDate), ["minutes"]).minutes
    );
    setValue("technician", suggestion.technician, { shouldValidate: true });
    setValue("availabilitySnapshotId", suggestion.availabilitySnapshotId);
    setValue("appointmentSnapshotId", suggestion.appointmentSnapshotId);
  };

  const resetSnapshot = () => {
    setValue("appointmentSnapshotId", "");
  };

  const submitAppointment = ({
    startDate,
    laborTime,
    technician,
    rescheduleReason,
    availabilitySnapshotId,
    appointmentSnapshotId,
    isFixed,
  }) => {
    const { startTimeWindow, endTimeWindow } = calcTimeWindowForAppointment(isFixed, startDate, timeZone);
    onSubmit({
      job: jobId ?? null,
      technician,
      allDay: false,
      isFixed,
      rescheduleReason,
      startDate,
      endDate: DateTime.fromJSDate(startDate).plus({ minutes: laborTime }).toJSDate(),
      startTimeWindow,
      endTimeWindow,
      technicianCopy: technicianCopySpec(technician),
      subject: jobName ?? null,
      timeZone,
      availabilitySnapshotId,
      appointmentSnapshotId,
    });
  };

  return (
    <>
      {/* Hiding appointment suggestions tool for VIOC jobs */}
      {jobType === "Vioc Inspection" ? null : (
        <div className="border-2 p-4 mb-4">
          <div className="mb-4 text-lg">Appointment Recommendations</div>
          <SuggestionForm
            initialValues={{
              address: serviceLocation,
              laborTime: laborTimes.includes(scheduledDuration) ? scheduledDuration : 60,
              startTime: scheduledDate ?? new Date(),
              daysToQuery: 2,
              showOnlyForJobPartsStore: !!partsStoreId,
            }}
            bookingControl={control}
            onSubmit={getSuggestions}
            queryLengths={queryLengths}
            laborTimes={laborTimes}
            partsStoreId={partsStoreId}
            partsStoreName={partsStoreName}
          />
          <AppointmentOptions
            suggestions={data?.getAppointmentSuggestions ?? []}
            isFixed={control?._fields?.isFixed?._f?.value}
            timeZone={timeZone}
            error={error}
            called={called}
            loading={loading}
            onClick={onClickUseSuggestion}
            suggestionToolInputs={suggestionToolInputs}
          />
        </div>
      )}
      <BookingDetailsForm
        appointmentId={appointmentId}
        showIsFixed={jobType === "Vioc Inspection"}
        scheduledDate={scheduledDate ?? null}
        isUsingSuggestion={isUsingSuggestion}
        onManuallyChangeValue={() => setIsUsingSuggestion(false)}
        jobId={jobId}
        isFixed={isFixed}
        control={control}
        dirtyFields={dirtyFields}
        isSubmitting={isSubmitting || isSubmittingForm}
        isValid={isValid}
        setValue={setValue}
        resetSnapshot={resetSnapshot}
        onSubmit={(event) => {
          event.preventDefault?.();
          event.stopPropagation?.();
          return handleSubmit(submitAppointment)(event);
        }}
        onCancel={onCancel}
        market={market}
        laborTimes={laborTimes}
        timeZone={timeZone}
      />
    </>
  );
};
export default SuggestionFormSection;
