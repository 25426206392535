import React from "react";
import { useField } from "formik";
import { Query, TechnicianRole } from "../../generated/nest-graphql";
import { defaultTo, map, prop, propOr } from "ramda";
import { useQuery } from "@apollo/client";
import { GET_TECHNICIAN_ROLES } from "../../graphql/queries/getTechnicianRoles";
import { flow } from "fp-ts/lib/function";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { ErrorTextField } from "./ErrorTextField";

type TechnicianRoleOption = {
  label: string;
  value: TechnicianRole;
};

export type TechnicianRoleFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  postOnChange?: (role: TechnicianRole) => any;
};

export const TechnicianRoleSelectField = ({
  name,
  label,
  required = false,
  postOnChange,
}: TechnicianRoleFieldProps) => {
  const [field, meta, helpers] = useField(name);
  const { data } = useQuery<Query, {}>(GET_TECHNICIAN_ROLES);
  const technicianRolesToOption = (technicianRole: TechnicianRole) => {
    const { name } = technicianRole;
    return {
      label: `${name}`,
      value: technicianRole,
    };
  };
  const technicianRolesOptions = flow(propOr([], "getTechnicianRoles"), map(technicianRolesToOption))(data);
  const filterOptions = createFilterOptions<{
    label: string;
    value: TechnicianRole;
  }>({
    matchFrom: "start",
    stringify: (option) => {
      return option.label;
    },
  });
  const onChange = (_: object, value: any) => {
    helpers.setValue(prop("value", value));
    if (postOnChange) postOnChange(prop("value", value));
  };
  if (!data || technicianRolesOptions.length === 0) return null;
  return (
    <Autocomplete<TechnicianRoleOption>
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        return defaultTo("", option.label);
      }}
      value={field.value ? technicianRolesToOption(field.value) : { value: null, label: "" }}
      options={technicianRolesOptions}
      onChange={onChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          error={!!meta.error}
          {...params}
          label={label}
          required={required}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
          }}
          helperText={<>{meta.error && <ErrorTextField displayOnSpan={true} fieldName={field.name} />}</>}
        />
      )}
    />
  );
};
