import React from "react";
import { useParams } from "react-router-dom";
import { VehicleEditView } from "../../components/Vehicles/VehicleEditView";

const VehicleDetailPage: React.FC<{}> = () => {
  const params = useParams();
  // @ts-ignore
  const vehicleId = params.id;
  return (
    <div>
       <VehicleEditView vehicleId={vehicleId}/>
    </div>
  );
};

export default VehicleDetailPage;