import gql from "graphql-tag";
import { ejiPriceInfoFields } from "./calculatePossibleEJIPriceInfo";
import { vehicleSymptomFields } from "./getContactDetails";
import { partDetailFields } from "./getPartCatalogue";
import { partsStoreDetailsFields } from "./getPartsStore";

export const itemsFields = `items {
        product
        partsStore {
            ${partsStoreDetailsFields}
        }
        name
        isInEstimate
        amount
        description
        partNumber
        laborCost
        partsCost
        vendorPartsCost
        createdAt
        updatedAt
    }`;

export const estimateDetailsFields = `id
        estimateNumber
        issuedDate
        taxable
        ${itemsFields}
        market
        price
        serviceLocation
        privateNotes
        estimateNotes
        serviceLocationNotes
        status
        vehicleInfo {
            frontPadLife
            licensePlate
            licensePlateState
            make
            model
            odometer
            frontRotorsDiscardThicknessInches
            rearRotorsDiscardThicknessInches
            rearPadLife
            vin
            year
            extraInfo
            frontBrakeSymptoms
            rearBrakeSymptoms
            customerExpectation
            additionalNotes
            symptoms
        }
        vehicleSymptoms {
            ${vehicleSymptomFields}
        }
        requestedServices
        contact {
            id
            firstName
            lastName
            email
            timeZone
            taxExemptEin
        }
        activityFeed {
            actor
            id
            objectId
            objectType
            verb
            createdAt
        }
        createdAt
        serviceCatalogueUsed
        services {
            id
            service
            name
            inEstimate
            customerPrice
            technicianPay
            items {
                id
                serviceProductId
                productSelection {
                    name
                    part {
                        ${partDetailFields}
                    }
                }
                type
                units
                overriddenCustomerPrice
                customerPrice
                vendorUnitCost
                behavior
                category
                notes
                orderItem {
                    partsStore {
                        ${partsStoreDetailsFields}
                    }
                    isOrdered
                    partNumber
                }
                marketVendorUnitCostMultiplierUsed
                marketLaborRateUsed
            }
            updatedAt
            createdAt
        }
        partsLeadTimeInDays
        callForPartsTicketNumber
        partsNotes
        priceInfo {
            ${ejiPriceInfoFields}
        }`;

export const GET_ESTIMATE = gql`query getEstimate($id: ID!) {
    getEstimate(id: $id) {
        ${estimateDetailsFields}
    }
}
`;
