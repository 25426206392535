import React from "react";
import { default as TextField } from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { SubmitButton } from "../Buttons/SubmitButton";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { LocationSearchInputRHF } from "../FormFields/LocationSearchInputRHF";
import { DateInputRHF } from "../FormFields/DateInputRHF";
import { CheckBoxFieldRHF } from "../FormFields/CheckBoxField";

export type SuggestionFormValues = {
  address: string;
  laborTime: number;
  startTime: Date;
  daysToQuery: number;
  showOnlyForJobPartsStore?: boolean;
};

const SuggestionFormValidationSchema = Yup.object().shape({
  address: Yup.string().required("Required"),
  laborTime: Yup.number().required("Required"),
  startTime: Yup.date().required("Required"),
  daysToQuery: Yup.number().required("Required"),
});

export const SuggestionForm: React.FC<{
  onSubmit: any;
  initialValues: SuggestionFormValues;
  laborTimes: number[];
  queryLengths: number[];
  partsStoreId?: string;
  partsStoreName?: string;
  bookingControl?: any
}> = ({ onSubmit, initialValues, laborTimes, queryLengths, partsStoreId, partsStoreName, bookingControl }) => {
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(SuggestionFormValidationSchema),
  });

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault?.();
        event.stopPropagation?.();
        return handleSubmit(onSubmit)(event);
      }}
      className="grid grid-rows-2 gap-4"
    >
      <div className="grid grid-cols-4 gap-4">
        <Controller
          control={control}
          name="address"
          render={({ field: { value, onChange }, fieldState }) => (
            <LocationSearchInputRHF
              value={value}
              error={fieldState.error}
              label="Appointment Address"
              required
              valueOnChange={(val) => onChange(val ? val.description : val)}
            />
          )}
        />
        <Controller
          control={control}
          name="laborTime"
          render={({ field }) => (
            <TextField select label="Job Duration" id="laborTime" required variant="outlined" {...field}>
              {laborTimes.map((duration, idx) => (
                <MenuItem key={idx} value={duration}>
                  {duration}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
        <Controller
          control={control}
          name="daysToQuery"
          render={({ field }) => (
            <TextField select label="Days To Query" id="daysToQuery" required variant="outlined" {...field}>
              {queryLengths.map((duration, idx) => (
                <MenuItem key={idx} value={duration}>
                  {duration}
                </MenuItem>
              ))}
            </TextField>
          )}
        />

        <Controller
          control={control}
          name="startTime"
          render={({ field: { value, onChange }, fieldState }) => (
            <DateInputRHF
              value={value}
              required={true}
              label="Target Date"
              error={fieldState.error}
              onChange={onChange}
            />
          )}
        />
      </div>
      <div className="grid grid-cols-3 gap-4 justify-items-start">
        <Controller
          control={bookingControl}
          name={"isFixed"}
          render={({ field }) => (
            <CheckBoxFieldRHF name={field.name} label={"Is Fixed"} onChange={field.onChange} value={field.value} />
          )}
        />
        {partsStoreId ? (
          <div className="justify-self-stretch col-span-2 flex flex-col">
            <Controller
              control={control}
              name="showOnlyForJobPartsStore"
              render={({ field: { value, onChange, name }, fieldState }) => (
                <CheckBoxFieldRHF
                  disabled={!partsStoreId}
                  name={name}
                  label={`Show only technicians with the current parts store as their home store${
                    partsStoreName ? ` (${partsStoreName})` : ""
                  }`}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </div>
        ) : null}
        <div className="justify-self-stretch col-start-3 flex flex-col">
          <SubmitButton isSubmitting={isSubmitting} isValid={isValid}>
            Get Recommendations
          </SubmitButton>
        </div>
      </div>
    </form>
  );
};
