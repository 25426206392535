import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { FilterSelectField } from "../FormFields/FilterSelectField";
import { Form, Formik } from "formik";
import { FilterSelectFieldV2 } from "../FormFields/FilterSelectFieldV2";
import { useMarkets } from "../../hooks/useMarkets";
import { TechnicianSelect } from "../FormFields/TechnicianSelect";
import { DateInput } from "../FormFields/DateInput";
import { DebouncedTextField as TextField } from "../FormFields/DebouncedTextField";
import { Button } from "../Buttons/Button";
import { defaultTo, pluck } from "ramda";
import {
  endOfToday,
  endOfTomorrow,
  endOfYesterday,
  startOfDay,
  startOfToday,
  startOfTomorrow,
  startOfYesterday,
} from "date-fns";
import { QuickRangeSelect } from "../FormFields/QuickRangeSelect";
import { JOB_STATUS_MASTER_LIST } from "../../lib/constants";

export type JobsTableFilterFormValues = {
  startRange: Date;
  endRange: Date;
  type: string;
  status: string;
  technician: string;
  market: string;
  partsOrdered: boolean | null;
  jobNumber: string;
};

export const JobsTableFilter: React.FC<{
  initialValues: JobsTableFilterFormValues;
  onSubmit: any;
}> = ({ initialValues, onSubmit }) => {
  const markets = useMarkets();

  return (
    <Formik<JobsTableFilterFormValues> initialValues={initialValues} onSubmit={onSubmit}>
      {({ resetForm, submitForm, setFieldValue, values }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          submitForm();
        }, [submitForm, values]);
        return (
          <Form>
            <Box p={2} m={"auto"} display="flex" flexDirection="row">
              <div className={"grid grid-cols-4 gap-4"}>
                <Box>
                  <FilterSelectField
                    label={"Status"}
                    options={[
                      "New",
                      "New: Scheduled",
                      "New: Scheduling Request Sent",
                      "Open",
                      ...JOB_STATUS_MASTER_LIST,
                    ]}
                    name={"status"}
                  />
                </Box>
                <Box>
                  <FilterSelectField
                    label={"Type"}
                    options={[
                      "Brake Repair Service",
                      "Service Call",
                      "Diagnostic",
                      "Pre-Job Diagnostic",
                      "Warranty Check",
                      "Vioc Inspection",
                    ]}
                    name={"type"}
                  />
                </Box>
                <Box>
                  <FilterSelectField
                    label={"Market"}
                    options={defaultTo([], markets && pluck("name", markets))}
                    name={"market"}
                  />
                </Box>
                <Box>
                  <FilterSelectFieldV2
                    label={"Parts Ordered"}
                    options={[
                      { label: "None", value: null },
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: { $in: [false, null] },
                      },
                    ]}
                    name={"partsOrdered"}
                  />
                </Box>
                <Box style={{ width: 300 }}>
                  <TechnicianSelect market={""} name={"technician"} label={"Technician"} />
                </Box>
                <DateInput name={"startRange"} label={"Start Range"} dateModifier={startOfDay} />
                <DateInput name={"endRange"} label={"End Range"} dateModifier={startOfDay} />
                <QuickRangeSelect
                  label={"Quick Range Select"}
                  name={""}
                  options={[
                    {
                      label: "Today",
                      value: {
                        startRange: startOfToday(),
                        endRange: endOfToday(),
                      },
                    },
                    {
                      label: "Tomorrow",
                      value: {
                        startRange: startOfTomorrow(),
                        endRange: endOfTomorrow(),
                      },
                    },
                    {
                      label: "Yesterday",
                      value: {
                        startRange: startOfYesterday(),
                        endRange: endOfYesterday(),
                      },
                    },
                  ]}
                />
                <TextField name={"jobNumber"} label={"Job Number"} />
                <Button
                  onClick={() => {
                    resetForm({
                      touched: {
                        technician: false,
                        market: false,
                        status: false,
                        partsOrdered: false,
                        type: null,
                      },
                      values: {
                        type: null,
                        startRange: null,
                        endRange: null,
                        status: null,
                        partsOrdered: null,
                        market: null,
                        technician: null,
                        jobNumber: null,
                      },
                    });
                  }}
                >
                  Reset
                </Button>
              </div>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
