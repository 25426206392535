import * as React from "react";
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Mutation, MutationCreateFileArgs, Query, QueryGetFilesArgs } from "../../generated/nest-graphql";
import { GET_FILES } from "../../graphql/queries/getFiles";
import { FilesTable } from "../Files/FilesTable";
import { Button } from "../Buttons/Button";
import Modal from "react-modal";
import { FileForm } from "../Files/FileForm";
import { CREATE_FILE } from "../../graphql/mutations/createFile";
import { NuModal } from "../NuModal";

export const ContactsFiles: React.FC<{ contactId: string }> = ({ contactId }) => {
  const { data, startPolling, stopPolling } = useQuery<Query, QueryGetFilesArgs>(GET_FILES, {
    variables: {
      contactId,
    },
    nextFetchPolicy: "cache-and-network",
  });
  const [createFile] = useMutation<Mutation, MutationCreateFileArgs>(CREATE_FILE);
  const [isOpen, setIsOpen] = useState(false);
  Modal.setAppElement("#root");
  const filesCount = data?.getFiles?.length;
  React.useEffect(() => {
    if (filesCount) {
      startPolling(1000 * 300); // 300s = 5min (signed url expiration)
    } else {
      stopPolling();
    }
  }, [filesCount]);
  React.useEffect(() => {
    return () => {
      stopPolling();
    };
  }, []);
  if (!data) return null;
  return (
    <div>
      <div className="flex flex-col items-end">
        <Button onClick={() => setIsOpen(true)}>Upload File</Button>
      </div>
      <FilesTable files={data.getFiles} contactId={contactId} />
      <NuModal isOpen={isOpen}>
        <FileForm
          contactId={contactId}
          initialValues={{
            caption: "",
            fileName: "",
            url: "",
          }}
          onCancel={() => setIsOpen(false)}
          onSubmit={async (values, helper) => {
            await createFile({
              variables: {
                createFileInput: {
                  contact: contactId,
                  path: `contacts/${contactId}/${values.fileName}`,
                  url: values.url,
                  share: false,
                  fileName: `${values.fileName}`,
                  caption: values.caption,
                },
              },
            });
            window.location.reload();
          }}
        />
      </NuModal>
    </div>
  );
};
