export const carData = [
  {
    year: 2024,
    make: "Mercedes-Benz",
    models:
      "EQS AMG,GLE63 AMG S,Sprinter 2500,GLC300,AMG GT 63,GLS580,SL55 AMG,C43 AMG,GLE450e,Maybach S680,EQS 580,GLS450,EQB 350,S500,C300,EQS 450 SUV,GLE580,EQS 450+,GLC43 AMG,EQE 350+ SUV,EQE 350+,EQE 500,AMG GT 55,GLE350,EQS 580 SUV,S580e,Maybach GLS600,EQS 450+ SUV,EQB 250,EQS 450,EQB 300,SL63 AMG,EQE 350,EQE 500 SUV,Maybach S580,E450,GLA250,Maybach EQS 680 SUV,GLA35 AMG,GLE53 AMG,GLB35 AMG,GLB250,E350,S580,EQE 350 SUV,SL43 AMG,GLS63 AMG,EQE AMG,S63 AMG E Performance",
  },
  {
    year: 2024,
    make: "Kia",
    models: "Sportage,Telluride,Carnival,Soul,EV9,Seltos,EV6,Niro EV,Niro,K5",
  },
  {
    year: 2024,
    make: "Hyundai",
    models:
      "Palisade,Tucson,Ioniq 6,Venue,Sonata,Santa Cruz,Santa Fe,Grand i10,Kona,Elantra N,Ioniq 5,Nexo,Kona Electric,Creta",
  },
  {
    year: 2024,
    make: "GMC",
    models:
      "Hummer EV SUV,Sierra 1500,Sierra 2500 HD,Yukon XL,Terrain,Yukon,Savana 2500,Hummer EV Pickup,Canyon,Sierra EV",
  },
  {
    year: 2024,
    make: "Toyota",
    models:
      "GR Corolla,Sienna,Crown,Corolla Cross,Tacoma,Highlander,Camry,Land Cruiser,Prius Prime,Corolla,Tundra,bZ4X,Sequoia,Prius AWD-e,Grand Highlander,RAV4,GR86,Prius,RAV4 Prime,Mirai,GR Supra",
  },
  {
    year: 2024,
    make: "Honda",
    models: "HR-V,Civic,Prologue,Pilot,CR-V,BR-V,Ridgeline,Passport,Odyssey,Accord",
  },
  {
    year: 2024,
    make: "Chevrolet",
    models:
      "Silverado 1500,Equinox,Silverado 2500 HD,Malibu,Tracker,Tahoe,Suburban,Bolt EV,Blazer,LCF 3500HG,Trailblazer,Express 2500,Aveo,Camaro,Traverse,Blazer EV,Colorado,Corvette,Montana,Trax,Silverado EV,LCF 6500XD,Onix,Equinox EV,Groove,Captiva,Bolt EUV",
  },
  {
    year: 2024,
    make: "Audi",
    models:
      "A3 Quattro,Q8 e-tron Sportback,Q8 e-tron,A4 allroad,S4,Q7,Q5 PHEV,SQ7,e-tron GT,A4 Quattro,A5 Quattro,Q8,Q4 e-tron Sportback,Q4 e-tron,A6 Quattro,S5,SQ8 e-tron,S5 Sportback,A3,Q3,RS7 Sportback,RS e-tron GT,S3,S8,Q5 Sportback,SQ5,A8 Quattro,A7 Sportback,Q5,SQ8 e-tron Sportback,A6 allroad,RS5,RS3,A5 Sportback,SQ8,RS5 Sportback,RS6 Avant,SQ5 Sportback",
  },
  {
    year: 2024,
    make: "Buick",
    models: "Enclave,Encore GX,Envision,Envista",
  },
  {
    year: 2024,
    make: "Jeep",
    models:
      "Grand Wagoneer L,Wagoneer,Grand Cherokee,Renegade,Gladiator,Grand Cherokee L,Grand Wagoneer,Wrangler,Compass,Wagoneer L",
  },
  {
    year: 2024,
    make: "Acura",
    models: "Integra,RDX,TLX,MDX,ZDX",
  },
  {
    year: 2024,
    make: "Ford",
    models:
      "Expedition,Bronco Sport,Bronco,Territory,F-250 Super Duty,F-150,Explorer,Mustang,Ranger,E-Transit,F-150 Lightning,Edge,F-750,Escape,Maverick,Transit-250,Transit-150,Police Interceptor Utility,Mustang Mach-E",
  },
  {
    year: 2024,
    make: "Lincoln",
    models: "Navigator,Nautilus,Corsair",
  },
  {
    year: 2024,
    make: "Maserati",
    models: "Grecale,MC20,GranTurismo,Levante,Ghibli",
  },
  {
    year: 2024,
    make: "Nissan",
    models: "GT-R,Sentra,LEAF,Altima,Frontier,Versa,ARIYA,Z,Rogue,Pathfinder,Murano,TITAN,Kicks,TITAN XD",
  },
  {
    year: 2024,
    make: "Subaru",
    models: "Forester,WRX,Crosstrek,Legacy,Solterra,BRZ,Outback,Impreza",
  },
  {
    year: 2024,
    make: "Volvo",
    models: "VNR,VNL,S60,XC40,C40 Recharge,VHD,VAH",
  },
  {
    year: 2024,
    make: "Cadillac",
    models: "LYRIQ,CT4,Escalade ESV,XT5,XT6,Escalade,XT4,CT5",
  },
  {
    year: 2024,
    make: "Porsche",
    models: "Cayenne,Macan,718 Boxster,911,718 Cayman,Taycan,718 Spyder",
  },
  {
    year: 2024,
    make: "McLaren",
    models: "Artura,750S",
  },
  {
    year: 2024,
    make: "Volkswagen",
    models: "ID.4,Jetta,GTI,Atlas,Tiguan,Taos,Atlas Cross Sport,Golf R,T-Cross",
  },
  {
    year: 2024,
    make: "Alfa Romeo",
    models: "Giulia,Stelvio,Tonale",
  },
  {
    year: 2024,
    make: "Genesis",
    models: "GV80,GV60,GV70,G90,Electrified GV70,Electrified G80",
  },
  {
    year: 2024,
    make: "Ram",
    models: "2500,1500,ProMaster 1500,ProMaster 2500,ProMaster Rapid",
  },
  {
    year: 2024,
    make: "BMW",
    models:
      "M3,X4,iX1,430i xDrive,X5,i4,430i xDrive Gran Coupe,430i,X3,M2,840i xDrive,X1,M4,M8,220i Gran Coupe,750e xDrive,228i Gran Coupe,230i xDrive,840i xDrive Gran Coupe,M235i xDrive Gran Coupe,M440i,M440i xDrive Gran Coupe,M850i xDrive,128ti,220i,M8 Gran Coupe,iX3,M135i,330i,i7,iX,XM,540i xDrive,M340i xDrive,M440i xDrive,230i,X6,840i,330e xDrive,330i xDrive,i5,Alpina XB7,M240i,330e,Z4,228i xDrive Gran Coupe,X7,Alpina B8 Gran Coupe,M240i xDrive,M440i Gran Coupe,760i xDrive,430i Gran Coupe,M850i xDrive Gran Coupe,320i,M340i,530i xDrive,840i Gran Coupe,740i,118i",
  },
  {
    year: 2024,
    make: "Chrysler",
    models: "Grand Caravan,Pacifica,Voyager",
  },
  {
    year: 2024,
    make: "Lexus",
    models:
      "LS500,RX450h+,UX250h,LC500,ES250,RX350,GX550,IS300,ES350,TX500h,LX600,RZ450e,RX500h,RX350h,TX350,LS500h,NX450h+,NX350,ES300h,NX350h,LC500h,IS350,IS500,NX250,TX550h+",
  },
  {
    year: 2024,
    make: "Mitsubishi",
    models: "Outlander Sport,RVR,Mirage G4,Outlander,Outlander PHEV,Eclipse Cross,Mirage",
  },
  {
    year: 2024,
    make: "Mazda",
    models: "CX-5,CX-90,3,CX-30,MX-30 EV,CX-50,3 Sport",
  },
  {
    year: 2024,
    make: "Jaguar",
    models: "F-Pace,XF,F-Type,E-Pace,I-Pace",
  },
  {
    year: 2024,
    make: "Land Rover",
    models:
      "Discovery,Range Rover Sport,Discovery Sport,Defender 130,Range Rover Velar,Range Rover Evoque,Range Rover,Defender 90",
  },
  {
    year: 2024,
    make: "Bentley",
    models: "Continental,Flying Spur,Bentayga EWB",
  },
  {
    year: 2024,
    make: "Lucid",
    models: "Air",
  },
  {
    year: 2024,
    make: "Renault",
    models: "Oroch,KWID,Stepway,Koleos,Duster,Logan",
  },
  {
    year: 2024,
    make: "Mini",
    models: "Cooper,Cooper Clubman,Cooper Countryman",
  },
  {
    year: 2024,
    make: "INFINITI",
    models: "QX60,QX80,QX55",
  },
  {
    year: 2024,
    make: "Dodge",
    models: "Durango,Hornet,Attitude",
  },
  {
    year: 2024,
    make: "Fiat",
    models: "Fastback,500X",
  },
  {
    year: 2024,
    make: "Aston Martin",
    models: "DBX,Valhalla,Valour,DBX707,DB12,DBS",
  },
  {
    year: 2024,
    make: "Rivian",
    models: "R1T,R1S",
  },
  {
    year: 2024,
    make: "Lotus",
    models: "Emira",
  },
  {
    year: 2024,
    make: "Ferrari",
    models: "296 GTB,296 GTS,Roma,Purosangue",
  },
  {
    year: 2024,
    make: "Lamborghini",
    models: "Revuelto,Urus",
  },
  {
    year: 2024,
    make: "Polestar",
    models: "Polestar 2,Polestar 3",
  },
  {
    year: 2024,
    make: "Rolls-Royce",
    models: "Spectre",
  },
  {
    year: 2023,
    make: "Acura",
    models: "Integra,MDX,RDX,TLX",
  },
  {
    year: 2023,
    make: "Alfa Romeo",
    models: "Giulia,Stelvio,Tonale",
  },
  {
    year: 2023,
    make: "Aston Martin",
    models: "DB11,DBS,DBX,DBX707,Vantage",
  },
  {
    year: 2023,
    make: "Audi",
    models:
      "A3,A3 Quattro,A4 allroad,A4 Quattro,A5 Quattro,A5 Sportback,A6 allroad,A6 Quattro,A7 Sportback,A8 Quattro,e-tron GT,e-tron Quattro,e-tron S,e-tron S Sportback,e-tron Sportback,Q3,Q5,Q5 Sportback,Q7,Q8,R8,RS e-tron GT,RS Q8,RS3,RS5,RS5 Sportback,RS7 Sportback,S3,S4,S5,S6,S7 Sportback,S8,SQ5 Sportback,SQ7,SQ8,TT Quattro,TT RS Quattro,TTS Quattro",
  },
  {
    year: 2023,
    make: "Bentley",
    models: "Bentayga EWB,Continental,Flying Spur,Bentayga",
  },
  {
    year: 2023,
    make: "BMW",
    models:
      "118i,128ti,220i,220i Gran Coupe,230i,330e,330e xDrive,330i,330i xDrive,430i,430i Gran Coupe,430i xDrive Gran Coupe,440i Gran Coupe,530e,530i,740i,760i xDrive,840i,840i Gran Coupe,840i xDrive,840i xDrive Gran Coupe,Alpina B8 Gran Coupe,Alpina XB7,i4,i7,iX,iX xDrive40,iX xDrive50,M135i,M235i xDrive Gran Coupe,M240i,M240i xDrive,M3,M340i,M340i xDrive,M4,M440i,M440i xDrive,M440i xDrive Gran Coupe,M5,M550i xDrive,M760i xDrive,M8,M8 Gran Coupe,M850i xDrive,M850i xDrive Gran Coupe,X1,X2,X3,X4,X5,X6,X7,Z4,430i xDrive",
  },
  {
    year: 2023,
    make: "Buick",
    models: "Enclave,Encore GX,Envision",
  },
  {
    year: 2023,
    make: "Cadillac",
    models: "CT4,CT5,Escalade,Escalade ESV,LYRIQ,XT4,XT5,XT6",
  },
  {
    year: 2023,
    make: "Chevrolet",
    models:
      "Blazer,Bolt EUV,Bolt EV,Camaro,Captiva,Colorado,Corvette,Equinox,Express 2500,Grove,Malibu,Onix,S10 Max,Silverado 1500,Silverado 2500 HD,Suburban,Tahoe,Tornado Van,Tracker,Trailblazer,Traverse",
  },
  {
    year: 2023,
    make: "Chrysler",
    models: "300,Pacifica",
  },
  {
    year: 2023,
    make: "Dodge",
    models: "Durango,Challenger,Hornet",
  },
  {
    year: 2023,
    make: "Ferrari",
    models: "296 GTB,296 GTS,812 GTS,Portofino M,Roma,SF90 Spider,SF90 Stradale",
  },
  {
    year: 2023,
    make: "Fiat",
    models: "Pulse",
  },
  {
    year: 2023,
    make: "Ford",
    models:
      "Bronco,Bronco Sport,E-Transit,Edge,Escape,Expedition,Explorer,F-150,F-150 Lightning,F-250 Super Duty,Maverick,Mustang Mach-E,Police Interceptor Utility,Ranger,Transit Connect,Transit-250",
  },
  {
    year: 2023,
    make: "Genesis",
    models: "G70,G80,GV60,GV70,GV80",
  },
  {
    year: 2023,
    make: "GMC",
    models: "Acadia,Canyon,Hummer EV,Savana 2500,Sierra 1500,Sierra 2500 HD,Terrain,Yukon,Yukon XL",
  },
  {
    year: 2023,
    make: "Honda",
    models: "Civic,CR-V,HR-V,Odyssey,Passport,Pilot,Ridgeline",
  },
  {
    year: 2023,
    make: "Hyundai",
    models:
      "Elantra,Elantra N,Grand i10,Ioniq 5,Kona,Kona Electric,Kona N,Nexo,Palisade,Santa Cruz,Santa Fe,Sonata,Tucson,Venue",
  },
  {
    year: 2023,
    make: "INFINITI",
    models: "Q50,QX50,QX55,QX60,QX80",
  },
  {
    year: 2023,
    make: "Toyota",
    models:
      "HV507,4Runner,bZ4X,Camry,Corolla,Corolla Cross,GR Corolla,GR Supra,GR86,Highlander,Mirai,RAV4,RAV4 Prime,Sequoia,Sienna,Tacoma,Tundra,Venza,Crown",
  },
  {
    year: 2023,
    make: "Nissan",
    models:
      "Quashqai  S,Altima,Ariya,Armada,Frontier,Kicks,LEAF,Maxima,Pathfinder,Rogue,Sentra,Titan,Titan XD,Versa,Z,Kicks e-Power",
  },
  {
    year: 2023,
    make: "Jaguar",
    models: "E-Pace,F-Pace,F-Type,I-Pace,XF",
  },
  {
    year: 2023,
    make: "Jeep",
    models: "Cherokee,Compass,Gladiator,Grand Cherokee,Grand Cherokee L,Grand Wagoneer,Renegade,Wagoneer,Wrangler",
  },
  {
    year: 2023,
    make: "Kia",
    models: "Carnival,EV6,Forte,Forte5,K5,Niro,Seltos,Sorento,Soul,Sportage,Stinger,Telluride",
  },
  {
    year: 2023,
    make: "Lamborghini",
    models: "Huracan,Urus",
  },
  {
    year: 2023,
    make: "Land Rover",
    models:
      "Defender 110,Defender 130,Defender 90,Discovery,Discovery Sport,Range Rover,Range Rover Evoque,Range Rover Sport,Range Rover Velar",
  },
  {
    year: 2023,
    make: "Lexus",
    models: "ES250,GX460,IS300,IS350,IS500,LX600,NX250,NX350,NX350h,NX450h+,RZ450e,UXh,LS500h",
  },
  {
    year: 2023,
    make: "Lincoln",
    models: "Aviator,Nautilus,Navigator",
  },
  {
    year: 2023,
    make: "Lotus",
    models: "Emira",
  },
  {
    year: 2023,
    make: "Lucid",
    models: "Air",
  },
  {
    year: 2023,
    make: "Maserati",
    models: "Ghibli,Grecale,Levante,MC20,Quattroporte",
  },
  {
    year: 2023,
    make: "Mazda",
    models: "2,CX-30,CX-5,CX-50,MX-30 EV,3 Sport,3",
  },
  {
    year: 2023,
    make: "McLaren",
    models: "Artura",
  },
  {
    year: 2023,
    make: "Mercedes-Benz",
    models:
      "AMG GT 63,AMG GT 63 S,C300,C43 AMG,C63 AMG,CLS450,CLS53 AMG,EQB 250,EQB 300,EQB 350,EQS 450+,EQS 53 AMG,EQS 580,GLA250,GLA35 AMG,GLA45 AMG,GLB250,GLB35 AMG,GLE53 AMG,GLE63 AMG S,Maybach S580,Maybach S680,Metris,S500,S580,S580e,SL55 AMG,SL63 AMG,Sprinter 1500,Sprinter 2500,E450,E53 AMG,G63 AMG,CLA45 AMG",
  },
  {
    year: 2023,
    make: "Mini",
    models: "Cooper,Cooper Clubman,Cooper Countryman",
  },
  {
    year: 2023,
    make: "Mitsubishi",
    models: "Eclipse Cross,L200,Mirage,Mirage G4,Outlander,Xpander",
  },
  {
    year: 2023,
    make: "Polestar",
    models: "Polestar 2",
  },
  {
    year: 2023,
    make: "Porsche",
    models: "718 Boxster,718 Cayman,718 Spyder,911,Cayenne,Macan,Panamera,Taycan",
  },
  {
    year: 2023,
    make: "Ram",
    models: "1500 Classic,ProMaster 1500,ProMaster 2500,2500,1500",
  },
  {
    year: 2023,
    make: "Renault",
    models: "Kangoo,KWID,Oroch",
  },
  {
    year: 2023,
    make: "Rivian",
    models: "R1S,R1T,RCV-500,RCV-700",
  },
  {
    year: 2023,
    make: "Rolls-Royce",
    models: "Cullinan,Ghost,Phantom",
  },
  {
    year: 2023,
    make: "Subaru",
    models: "Ascent,BRZ,Crosstrek,Forester,Impreza,Legacy,Outback,Solterra,WRX",
  },
  {
    year: 2023,
    make: "Tesla",
    models: "3,S,X,Y",
  },
  {
    year: 2023,
    make: "Volkswagen",
    models: "Arteon,Atlas,Atlas Cross Sport,Golf R,GTI,ID.4,Jetta,Taos,Tiguan",
  },
  {
    year: 2023,
    make: "Volvo",
    models: "C40 Recharge,S60,S90,V60,V60 Cross Country,VHD,VNL,VNR,XC40,XC40 Recharge,XC60,XC90",
  },
  {
    year: 2022,
    make: "Acura",
    models: "NSX,TLX,MDX",
  },
  {
    year: 2022,
    make: "Alfa Romeo",
    models: "Giulia,Stelvio",
  },
  {
    year: 2022,
    make: "Aston Martin",
    models: "DBX,Vantage",
  },
  {
    year: 2022,
    make: "Audi",
    models:
      "Q5 PHEV,SQ7,SQ5,SQ8,S7 Sportback,RS6 Avant,Q5,RS5,SQ5 Sportback,RS e-tron GT,Q8,RS5 Sportback,Q7,A6 allroad,e-tron GT,R8,e-tron Sportback,RS Q8,Q5 Sportback,e-tron Quattro,A7 Sportback",
  },
  {
    year: 2022,
    make: "Bentley",
    models: "Continental,Flying Spur,Bentayga",
  },
  {
    year: 2022,
    make: "BMW",
    models:
      "750Li xDrive,320i,M440i xDrive,M5,M440i xDrive Gran Coupe,X1,330e,M760Li xDrive,430i Gran Coupe,540i xDrive,Z4,M340i xDrive,745e xDrive,M850i xDrive Gran Coupe,M4,530e,430i xDrive Gran Coupe,M760i xDrive,M340i,745e,128ti,M135i,530e xDrive,i3s,M850i xDrive,530i xDrive,M8,330i,M8 Gran Coupe,530i,745Le xDrive,X4,750i xDrive,840i xDrive Gran Coupe,M235i xDrive Gran Coupe,330e xDrive,X5,430i,118i,X7,840i xDrive,228i xDrive Gran Coupe,iX,M440i Gran Coupe,M550i xDrive,Alpina B7,840i Gran Coupe,740i,550i,740i xDrive,X2,840i,540i,X3,Alpina XB7,X6,220i Gran Coupe,M440i",
  },
  {
    year: 2022,
    make: "Buick",
    models: "Enclave,Encore,Encore GX,Envision",
  },
  {
    year: 2022,
    make: "Cadillac",
    models: "CT4,Escalade,XT6,XT5,XT4,CT5,Escalade ESV",
  },
  {
    year: 2022,
    make: "Chevrolet",
    models:
      "Trax,Spark,Bolt EUV,Trailblazer,Silverado 1500,Traverse,Bolt EV,Tahoe,Blazer,Corvette,Silverado 2500 HD,Suburban,Captiva,Camaro,Malibu,Silverado 3500 HD,Colorado,Equinox",
  },
  {
    year: 2022,
    make: "Chrysler",
    models: "Pacifica,Voyager",
  },
  {
    year: 2022,
    make: "Ford",
    models:
      "Ranger,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Edge,Bronco Sport,Escape,F-250 Super Duty,Mustang,EcoSport,F-150 Lightning,F-350 Super Duty,Mustang Mach-E,Maverick,F-150",
  },
  {
    year: 2022,
    make: "Genesis",
    models: "G80,GV80,GV70,G70",
  },
  {
    year: 2022,
    make: "GMC",
    models: "Sierra 1500,Yukon,Canyon,Acadia,Yukon XL,Sierra 2500 HD,Terrain",
  },
  {
    year: 2022,
    make: "Honda",
    models: "Insight,Ridgeline,Odyssey,Clarity,Accord,CR-V,Passport,Civic",
  },
  {
    year: 2022,
    make: "Hyundai",
    models:
      "Tucson,Kona,Venue,Kona N,Ioniq 5,Veloster,Elantra,Kona Electric,Palisade,Santa Fe,Santa Cruz,Sonata,Accent,Nexo,Veloster N",
  },
  {
    year: 2022,
    make: "Infiniti",
    models: "QX55,Q50,Q60,QX80,QX50",
  },
  {
    year: 2022,
    make: "Jaguar",
    models: "F-Pace,I-Pace,F-Type,E-Pace",
  },
  {
    year: 2022,
    make: "Jeep",
    models: "Grand Cherokee L,Cherokee,Wagoneer,Wrangler,Renegade,Grand Wagoneer,Gladiator",
  },
  {
    year: 2022,
    make: "Kia",
    models: "Forte5,Carnival,Sorento,Soul,Stinger,K5,EV6,Telluride,Forte,Seltos,Sportage,Rio",
  },
  {
    year: 2022,
    make: "Lamborghini",
    models: "Urus",
  },
  {
    year: 2022,
    make: "Land Rover",
    models: "Discovery,Range Rover Sport,Discovery Sport,Range Rover Evoque",
  },
  {
    year: 2022,
    make: "Lexus",
    models: "LC500,ES250,LC500h,LS500,LS500h,IS350,IS300,UX200,RX450h,RX350L,RX450hL,IS500,RX350,UX250h",
  },
  {
    year: 2022,
    make: "Lincoln",
    models: "Nautilus,Navigator,Corsair,Aviator",
  },
  {
    year: 2022,
    make: "Mazda",
    models: "CX-30,CX-5",
  },
  {
    year: 2022,
    make: "McLaren",
    models: "765LT,Artura,GT",
  },
  {
    year: 2022,
    make: "Mercedes-Benz",
    models:
      "A35 AMG,GLB35 AMG,GLE580,EQC400,GLS63 AMG,GLE450,Maybach GLS600,AMG GT 43,GLB250,GLE53 AMG,GLE63 AMG S,GLS450,G550,GLA35 AMG,AMG GT 63,CLS450,AMG GT 53,GLA250,GLA45 AMG,C300,G63 AMG,CLA250,Maybach S680,CLS53 AMG,CLA45 AMG,CLA35 AMG,GLS580,GLE350,AMG GT 63 S",
  },
  {
    year: 2022,
    make: "Mini",
    models: "Cooper Clubman,Cooper Countryman,Cooper",
  },
  {
    year: 2022,
    make: "Mitsubishi",
    models: "Mirage G4,Outlander Sport,Outlander,Eclipse Cross",
  },
  {
    year: 2022,
    make: "Nissan",
    models: "Leaf,Sentra,Frontier,Ariya,GT-R,Armada,Altima,Kicks,Rogue,Maxima,Pathfinder",
  },
  {
    year: 2022,
    make: "Peugeot",
    models: "5008,2008,Rifter,Manager,Landtrek,208,301,Partner,Expert",
  },
  {
    year: 2022,
    make: "Porsche",
    models: "718 Cayman,Taycan,Cayenne,718 Boxster,718 Spyder,911",
  },
  {
    year: 2022,
    make: "Ram",
    models: "1500",
  },
  {
    year: 2022,
    make: "Rolls-Royce",
    models: "Ghost",
  },
  {
    year: 2022,
    make: "Subaru",
    models: "Forester,Outback,BRZ,Ascent,Legacy",
  },
  {
    year: 2022,
    make: "Toyota",
    models:
      "Sienna,Prius Prime,GR Supra,Corolla Cross,Mirai,Highlander,Prius,GR 86,Prius AWD-e,Venza,4Runner,Camry,RAV4 Prime",
  },
  {
    year: 2022,
    make: "Volkswagen",
    models: "Atlas Cross Sport,ID.4,Taos,Passat,Golf R,Tiguan,Arteon,Jetta",
  },
  {
    year: 2022,
    make: "Volvo",
    models: "V60 Cross Country,XC90",
  },
  {
    year: 2021,
    make: "Acura",
    models: "NSX,ILX,RDX,TLX",
  },
  {
    year: 2021,
    make: "Alfa Romeo",
    models: "Giulietta,Giulia,Stelvio",
  },
  {
    year: 2021,
    make: "Aston Martin",
    models: "DBS,Vantage,DB11,DBX",
  },
  {
    year: 2021,
    make: "Audi",
    models:
      "Q2,Q3,A6 Quattro,S5,Q7,TTS Quattro,TT RS Quattro,A6 allroad,R8,A6,A4 Quattro,TT Quattro,e-tron Sportback,A5 Quattro,RS Q8,Q5 Sportback,e-tron Quattro,A7 Sportback,S5 Sportback,Q5 PHEV,SQ7,SQ5,S6,A4 allroad,SQ8,S4,A8 Quattro,S7 Sportback,RS6 Avant,S8,Q5,A1,RS5,A5,A4,RS7 Sportback,SQ5 Sportback,Q8,A5 Sportback,RS Q3,RS5 Sportback",
  },
  {
    year: 2021,
    make: "Bentley",
    models: "Bentayga,Continental,Flying Spur",
  },
  {
    year: 2021,
    make: "BMW",
    models:
      "430i,M2,M240i,430i xDrive,118i,X7,840i xDrive,228i xDrive Gran Coupe,M3,M550i xDrive,M240i xDrive,Alpina B7,840i Gran Coupe,740i,550i,740i xDrive,230i,X2,840i,540i,X3,Alpina XB7,X6,M440i,230i xDrive,440i Gran Coupe,750Li xDrive,320i,i3,M440i xDrive,M5,X1,330e,M760Li xDrive,330i xDrive,540i xDrive,Z4,M340i xDrive,745e xDrive,M850i xDrive Gran Coupe,220i,M4,530e,M760i xDrive,M340i,745e,M135i,530e xDrive,i3s,M850i xDrive,530i xDrive,120i,330i,M8 Gran Coupe,530i,745Le xDrive,420i Gran Coupe,X4,750i xDrive,840i xDrive Gran Coupe,M235i xDrive Gran Coupe,330e xDrive,X5",
  },
  {
    year: 2021,
    make: "Bugatti",
    models: "Divo,Chiron",
  },
  {
    year: 2021,
    make: "Buick",
    models: "Envision,Enclave,Encore,Encore GX",
  },
  {
    year: 2021,
    make: "Cadillac",
    models: "CT5,Escalade ESV,CT4,Escalade,XT6,XT5,XT4",
  },
  {
    year: 2021,
    make: "Chevrolet",
    models:
      "Cheyenne,Malibu,Tracker,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,Trax,Spark,Trailblazer,Silverado 1500,Traverse,Bolt EV,Tahoe,Blazer,Beat,Corvette,Aveo,Silverado 2500 HD,Suburban,Cavalier,Onix,Camaro",
  },
  {
    year: 2021,
    make: "Chrysler",
    models: "Voyager,Grand Caravan,300,Pacifica",
  },
  {
    year: 2021,
    make: "Dodge",
    models: "Durango,Attitude,Challenger,Charger",
  },
  {
    year: 2021,
    make: "Ferrari",
    models: "Roma,F8 Spider,Portofino,488 Pista Spider,F8 Tributo,488 Pista,SF90 Stradale,812 GTS,812 Superfast",
  },
  {
    year: 2021,
    make: "Fiat",
    models: "Mobi,500X,Argo",
  },
  {
    year: 2021,
    make: "Ford",
    models:
      "F-350 Super Duty,Transit-150,GT,Lobo,Bronco,Figo,Mustang Mach-E,F-150,Transit Custom,Transit-250,Ranger,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Edge,Bronco Sport,Escape,F-250 Super Duty,Mustang,EcoSport,Transit",
  },
  {
    year: 2021,
    make: "Freightliner",
    models: "Sprinter 3500XD,Sprinter 2500",
  },
  {
    year: 2021,
    make: "Genesis",
    models: "G90,G70,G80,GV80",
  },
  {
    year: 2021,
    make: "GMC",
    models: "Sierra 2500 HD,Sierra,Terrain,Sierra 1500,Yukon,Canyon,Savana 2500,Acadia,Yukon XL",
  },
  {
    year: 2021,
    make: "Honda",
    models: "Pilot,City,Civic,Insight,Ridgeline,Odyssey,Clarity,BR-V,Accord,CR-V,Passport,HR-V",
  },
  {
    year: 2021,
    make: "Hyundai",
    models:
      "Sonata,Accent,Grand i10,Nexo,Veloster N,Tucson,Kona,Venue,Veloster,Elantra,Kona Electric,Creta,Ioniq,Palisade,Santa Fe",
  },
  {
    year: 2021,
    make: "Infiniti",
    models: "QX80,QX50,Q50,Q60",
  },
  {
    year: 2021,
    make: "JAC",
    models: "EJ4,X250,ESEI 1,J7,SEI 7,J4,SEI 4,ESEI 2,Sunray,E Sunray,SEI 2,ESEI 4,SEI 3",
  },
  {
    year: 2021,
    make: "Jaguar",
    models: "F-Type,E-Pace,F-Pace,XE,XF,I-Pace",
  },
  {
    year: 2021,
    make: "Jeep",
    models: "Renegade,Gladiator,Grand Cherokee,Grand Cherokee L,Cherokee,Compass,Wrangler",
  },
  {
    year: 2021,
    make: "JMC",
    models: "Vigus",
  },
  {
    year: 2021,
    make: "Karma",
    models: "Revero",
  },
  {
    year: 2021,
    make: "Kia",
    models: "Forte,Sportage,Sedona,Rio,Forte5,Niro EV,Sorento,Soul,Niro,Seltos,Stinger,K5,Telluride,Soul EV",
  },
  {
    year: 2021,
    make: "Lamborghini",
    models: "Huracan,Urus,Aventador",
  },
  {
    year: 2021,
    make: "Land Rover",
    models: "Range Rover Evoque,Range Rover,Discovery,Range Rover Sport,Discovery Sport,Range Rover Velar",
  },
  {
    year: 2021,
    make: "Lexus",
    models:
      "RX350L,ES350,RX450hL,ES300h,RC300,RX350,RC F,UX250h,LC500,LX570,ES250,LC500h,NX300h,LS500,NX300,RC350,LS500h,IS350,IS300,GX460,UX200,RX450h",
  },
  {
    year: 2021,
    make: "Lincoln",
    models: "Corsair,Aviator,Nautilus,Navigator",
  },
  {
    year: 2021,
    make: "Lotus",
    models: "Evora GT",
  },
  {
    year: 2021,
    make: "Maserati",
    models: "Levante,Ghibli,Quattroporte",
  },
  {
    year: 2021,
    make: "Mazda",
    models: "MX-5,6,CX-3,3 Sport,MX-5 Miata,2,CX-30,CX-5,3,CX-9",
  },
  {
    year: 2021,
    make: "McLaren",
    models: "570GT,720S,GT,600LT,540C,765LT,570S",
  },
  {
    year: 2021,
    make: "Mercedes-Benz",
    models:
      "G500,S500,E350,GLA250,GLA45 AMG,C300,GLE53 AMG,GLB200,C63 AMG,G63 AMG,CLA250,AMG GT R Pro,GLC300e,GLC63 AMG S,CLS53 AMG,CLA45 AMG,Sprinter 2500,CLA35 AMG,GLS580,E63 AMG,A250,GLC300,GLE350,AMG GT 63 S,AMG GT C,AMG GT,A35 AMG,S63 AMG,E63 AMG S,GLB35 AMG,GLE580,EQC400,A220,GLS63 AMG,GLC43 AMG,C43 AMG,E300,S450L,GLE450,A200,Maybach GLS600,Metris,AMG GT 43,Sprinter 3500XD,Maybach S580,E350e,E53 AMG,GLA200,GLB250,GLE63 AMG S,S580,GLS450,GLC63 AMG,G550,E450,Sprinter 1500,AMG GT S,GLA35 AMG,C63 AMG S,AMG GT 63,CLS450,AMG GT R,S560,GLC350e,E200,AMG GT 53,CLA200,A45 AMG S",
  },
  {
    year: 2021,
    make: "MG",
    models: "MG5,ZS,HS",
  },
  {
    year: 2021,
    make: "Mini",
    models: "Cooper,Cooper Clubman,Cooper Countryman",
  },
  {
    year: 2021,
    make: "Mitsubishi",
    models: "L200,Outlander PHEV,Mirage,Mirage G4,RVR,Outlander Sport",
  },
  {
    year: 2021,
    make: "Nissan",
    models:
      "Armada,Altima,March,NV200,Kicks,Rogue,Maxima,Titan XD,Versa,Titan,Leaf,NV1500,370Z,Pathfinder,Sentra,X-Trail,Frontier,NV2500,Murano,NV350 Urvan,Rogue Sport,V-Drive,GT-R,Qashqai,NP300",
  },
  {
    year: 2021,
    make: "Peugeot",
    models: "3008,208,301,Partner,Expert,5008,2008,308,Rifter,Manager,Landtrek,Traveller",
  },
  {
    year: 2021,
    make: "Polestar",
    models: "Polestar 2,Polestar 1",
  },
  {
    year: 2021,
    make: "Porsche",
    models: "911,718 Cayman,Taycan,Cayenne,718 Boxster,Panamera,718 Spyder,Macan",
  },
  {
    year: 2021,
    make: "Ram",
    models: "ProMaster City,1500,3500,2500,ProMaster Rapid,ProMaster 1500,ProMaster 2500,1500 Classic",
  },
  {
    year: 2021,
    make: "Rolls-Royce",
    models: "Cullinan,Wraith,Phantom,Dawn,Ghost",
  },
  {
    year: 2021,
    make: "Subaru",
    models: "Legacy,Crosstrek,Impreza,WRX STI,Forester,Outback,Ascent,XV,WRX",
  },
  {
    year: 2021,
    make: "Suzuki",
    models: "Vitara,S-Cross,Ignis,Ertiga,Swift,Ciaz",
  },
  {
    year: 2021,
    make: "Toyota",
    models:
      "Venza,RAV4,Sequoia,4Runner,Avalon,Camry,RAV4 Prime,Prius C,Sienna,Prius Prime,GR Supra,Hilux,Hiace,Supra,Yaris,Mirai,Tacoma,Highlander,Avanza,C-HR,Corolla,Land Cruiser,Prius,Tundra,Prius AWD-e",
  },
  {
    year: 2021,
    make: "Volkswagen",
    models:
      "Polo,Tiguan,Vento,Atlas,Arteon,T-Cross,Saveiro,Atlas Cross Sport,ID.4,GTI,Virtus,Golf,Taos,Passat,Crafter Van,Transporter,Jetta,Cross Sport",
  },
  {
    year: 2021,
    make: "Volvo",
    models: "V60,V90 Cross Country,V60 Cross Country,S90,XC40,XC60,V90,XC90,S60",
  },
  {
    year: 2020,
    make: "Acura",
    models: "ILX,RDX,MDX,TLX,RLX,NSX",
  },
  {
    year: 2020,
    make: "Alfa Romeo",
    models: "Giulietta,Giulia,Stelvio,4C",
  },
  {
    year: 2020,
    make: "Aston Martin",
    models: "DBS,Vantage,DB11",
  },
  {
    year: 2020,
    make: "Audi",
    models:
      "A8 Quattro,S7 Sportback,S8,Q5,A1,A4,Q8,S7,A5 Sportback,S3,RS3,Q2,Q3,A6 Quattro,A3 Quattro,S5,Q7,A6 allroad,R8,A4 Quattro,TT Quattro,TTS Quattro,TT RS Quattro,e-tron Sportback,A5 Quattro,RS Q8,e-tron Quattro,A7 Sportback,S5 Sportback,A3,Q5 PHEV,SQ7,Q2 Quattro,SQ5,S6,A4 allroad,SQ8,S4",
  },
  {
    year: 2020,
    make: "Bentley",
    models: "Flying Spur,Continental,Mulsanne,Bentayga",
  },
  {
    year: 2020,
    make: "BMW",
    models:
      "530e,M760i xDrive,M340i,745e,M135i,530e xDrive,i3s,M850i xDrive,530i xDrive,120i,330i,M8 Gran Coupe,530i,745Le xDrive,420i Gran Coupe,520i,X4,440i xDrive,750i xDrive,840i xDrive Gran Coupe,M235i xDrive Gran Coupe,X5,430i,M2,M240i,430i xDrive,118i,X7,840i xDrive,228i xDrive Gran Coupe,M550i xDrive,M240i xDrive,430i Gran Coupe,Alpina B7,840i Gran Coupe,740i,740i xDrive,230i,X2,430i xDrive Gran Coupe,840i,540i,420i,X3,X6,230i xDrive,440i,440i Gran Coupe,750Li xDrive,320i,M8,i8,i3,M5,X1,330e,M760Li xDrive,330i xDrive,440i xDrive Gran Coupe,540i xDrive,M340i xDrive,745e xDrive,M850i xDrive Gran Coupe,220i,M4,Z4",
  },
  {
    year: 2020,
    make: "Bugatti",
    models: "Chiron,Divo",
  },
  {
    year: 2020,
    make: "Buick",
    models: "Encore GX,Regal TourX,Envision,Regal Sportback,Enclave,Encore",
  },
  {
    year: 2020,
    make: "Cadillac",
    models: "Escalade,XT6,XT5,XT4,CT6,CT5,Escalade ESV,CT4",
  },
  {
    year: 2020,
    make: "Chevrolet",
    models:
      "Beat,Corvette,Aveo,Silverado 2500 HD,Suburban,Cavalier,Sonic,Camaro,Cheyenne,Malibu,Tornado,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,Trax,Spark,Silverado 1500,Traverse,Bolt EV,Tahoe,Impala,Blazer",
  },
  {
    year: 2020,
    make: "Chrysler",
    models: "300,Pacifica,Voyager",
  },
  {
    year: 2020,
    make: "Dodge",
    models: "Attitude,Journey,Challenger,Charger,Neon,Durango,Grand Caravan",
  },
  {
    year: 2020,
    make: "Ferrari",
    models:
      "812 GTS,812 Superfast,Roma,F8 Spider,Portofino,GTC4Lusso,488 Pista Spider,F8 Tributo,GTC4Lusso T,488 Pista,SF90 Stradale",
  },
  {
    year: 2020,
    make: "Fiat",
    models: "500X,Ducato,500L,Uno,124 Spider,Mobi,Palio",
  },
  {
    year: 2020,
    make: "Ford",
    models:
      "Escape,F-250 Super Duty,Mustang,EcoSport,Transit,Fusion,Police Responder Hybrid,F-350 Super Duty,Transit-150,Lobo,Figo,GT,SSV Plug-In Hybrid,F-150,Transit Custom,Transit-250,Ranger,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Edge",
  },
  {
    year: 2020,
    make: "Freightliner",
    models: "Sprinter 1500,Sprinter 3500XD,Sprinter 2500",
  },
  {
    year: 2020,
    make: "Genesis",
    models: "G90,G70,G80",
  },
  {
    year: 2020,
    make: "GMC",
    models: "Acadia,Yukon XL,Sierra 2500 HD,Sierra,Terrain,Sierra 1500,Yukon,Canyon,Savana 2500",
  },
  {
    year: 2020,
    make: "Honda",
    models: "Odyssey,Clarity,BR-V,Accord,CR-V,Passport,Fit,HR-V,Pilot,City,Civic,Insight,Ridgeline",
  },
  {
    year: 2020,
    make: "Hyundai",
    models:
      "Veloster,Elantra,Kona Electric,Creta,Ioniq,Palisade,Santa Fe,H300,Starex,Sonata,Accent,Grand i10,Nexo,Veloster N,Tucson,Kona,Venue,Elantra GT",
  },
  {
    year: 2020,
    make: "Infiniti",
    models: "Q60,QX60,QX80,QX50,QX70,Q50",
  },
  {
    year: 2020,
    make: "JAC",
    models: "SEI 2,ESEI 4,SEI 3,EJ4,ESEI 1,SEI 7,J4,SEI 4,ESEI 2",
  },
  {
    year: 2020,
    make: "Jaguar",
    models: "XE,XF,I-Pace,F-Type,E-Pace,F-Pace",
  },
  {
    year: 2020,
    make: "Jeep",
    models: "Cherokee,Compass,Wrangler,Renegade,Gladiator,Grand Cherokee",
  },
  {
    year: 2020,
    make: "JMC",
    models: "Vigus",
  },
  {
    year: 2020,
    make: "Karma",
    models: "Revero",
  },
  {
    year: 2020,
    make: "Kia",
    models:
      "Sorento,Soul,Niro,Stinger,Telluride,Soul EV,Seltos,Optima,Cadenza,Forte,Sportage,K900,Sedona,Rio,Forte5,Niro EV",
  },
  {
    year: 2020,
    make: "Lamborghini",
    models: "Aventador,Huracan,Urus",
  },
  {
    year: 2020,
    make: "Land Rover",
    models: "Range Rover Sport,Discovery Sport,Range Rover Velar,Range Rover Evoque,Range Rover,Discovery",
  },
  {
    year: 2020,
    make: "Lexus",
    models:
      "LS500,NX300,RC350,LS500h,IS350,IS300,GX460,UX200,RX450h,RX350L,GS F,ES350,RX450hL,ES300h,GS350,RC300,RX350,RC F,UX250h,LC500,LX570,LC500h,NX300h",
  },
  {
    year: 2020,
    make: "Lincoln",
    models: "Continental,Nautilus,Navigator,Corsair,Aviator,MKZ",
  },
  {
    year: 2020,
    make: "Lotus",
    models: "Evora GT",
  },
  {
    year: 2020,
    make: "Maserati",
    models: "Ghibli,Quattroporte,Levante",
  },
  {
    year: 2020,
    make: "Mazda",
    models: "CX-5,3,CX-9,MX-5,6,CX-3,3 Sport,MX-5 Miata,2,CX-30",
  },
  {
    year: 2020,
    make: "McLaren",
    models: "600LT,GT,570S,570GT,720S",
  },
  {
    year: 2020,
    make: "Mercedes-Benz",
    models:
      "V220d,SLC300,S600L,E53 AMG,GLA200,S560L,Sprinter 2500,GLB250,GLS450,GLC63 AMG,G550,E450,AMG GT S,C63 AMG S,AMG GT 63,CLS450,AMG GT R,S560,GLC350e,SL450,V250,E200,AMG GT 53,GLE450,CLA200,G500,SLC180,Maybach S650,GLE43 AMG,E350,S65 AMG,C300,GLA250,C63 AMG,SLC43 AMG,G63 AMG,CLA250,Sprinter 1500,AMG GT R Pro,GLE63 AMG,GLC63 AMG S,S450,C200,SL550,CLS53 AMG,CLA45 AMG,CLA35 AMG,GLS580,E63 AMG,A250,GLE53 AMG,GLC300,GLE350,AMG GT 63 S,AMG GT C,Maybach S560,AMG GT,E43 AMG,Sprinter,S560e,A35 AMG,S63 AMG,E63 AMG S,GLE580,Sprinter 3500XD,A220,GLC43 AMG,C43 AMG,E300,S450L,A200,Metris",
  },
  {
    year: 2020,
    make: "MG",
    models: "HS,MG5,ZS",
  },
  {
    year: 2020,
    make: "Mini",
    models: "Cooper Clubman,Cooper Countryman,Cooper",
  },
  {
    year: 2020,
    make: "Mitsubishi",
    models: "Mirage,Mirage G4,RVR,Outlander Sport,Eclipse Cross,L200,Montero Sport,Outlander PHEV,Outlander",
  },
  {
    year: 2020,
    make: "Nissan",
    models:
      "X-Trail,Frontier,NV2500,Murano,NV350 Urvan,Rogue Sport,V-Drive,GT-R,Qashqai,Pathfinder,NP300,Armada,Altima,NP300 Frontier,March,NV200,Kicks,Rogue,Maxima,Titan XD,Versa,Titan,Leaf,NV1500,370Z,Sentra",
  },
  {
    year: 2020,
    make: "Peugeot",
    models: "Traveller,3008,208,301,Partner,Expert,5008,2008,308,Rifter,Manager",
  },
  {
    year: 2020,
    make: "Polestar",
    models: "Polestar 1",
  },
  {
    year: 2020,
    make: "Porsche",
    models: "718 Boxster,Panamera,718 Spyder,Macan,911,718 Cayman,Taycan,Cayenne",
  },
  {
    year: 2020,
    make: "Ram",
    models: "1500 Classic,ProMaster City,1500,3500,2500,ProMaster Rapid,ProMaster 1500,ProMaster 2500",
  },
  {
    year: 2020,
    make: "Rolls-Royce",
    models: "Phantom,Dawn,Ghost,Cullinan,Wraith",
  },
  {
    year: 2020,
    make: "Subaru",
    models: "Forester,Outback,Ascent,XV,WRX,Legacy,Crosstrek,BRZ,Impreza,WRX STI",
  },
  {
    year: 2020,
    make: "Suzuki",
    models: "Ignis,Ertiga,Swift,Ciaz,Vitara,S-Cross",
  },
  {
    year: 2020,
    make: "Toyota",
    models:
      "Tacoma,Highlander,Avanza,Yaris R,C-HR,Corolla,Land Cruiser,Prius,Tundra,Prius AWD-e,RAV4,Sequoia,4Runner,Avalon,Camry,86,Prius C,Sienna,Prius Prime,GR Supra,Hilux,Hiace,Yaris,Mirai",
  },
  {
    year: 2020,
    make: "Volkswagen",
    models:
      "GTI,Virtus,Golf,Passat,Caddy,Transporter,Jetta,Polo,Tiguan,Vento,Atlas,Amarok,Arteon,Gol,T-Cross,Crafter,Saveiro,Atlas Cross Sport,e-Golf",
  },
  {
    year: 2020,
    make: "Volvo",
    models: "XC60,V90,XC90,S60,V60,V90 Cross Country,V60 Cross Country,S90,XC40",
  },
  {
    year: 2019,
    make: "Acura",
    models: "NSX,ILX,RDX,MDX,TLX,RLX",
  },
  {
    year: 2019,
    make: "Alfa Romeo",
    models: "4C,Giulietta,Mito,Giulia,Stelvio",
  },
  {
    year: 2019,
    make: "Aston Martin",
    models: "Vantage,DB11,Rapide,DBS",
  },
  {
    year: 2019,
    make: "Audi",
    models:
      "A4 Quattro,TT Quattro,RS5 Sportback,A5 Quattro,e-tron Quattro,A7 Sportback,S5 Sportback,RS5,A5,A3,Q2 Quattro,SQ5,TTS Quattro,A4 allroad,S4,A8 Quattro,TT RS Quattro,Q5,A4,Q8,A5 Sportback,S3,RS3,Q2,Q3,A6 Quattro,A3 Quattro,S5,Q7",
  },
  {
    year: 2019,
    make: "Bentley",
    models: "Bentayga,Flying Spur,Continental,Mulsanne",
  },
  {
    year: 2019,
    make: "BMW",
    models:
      "740i xDrive,230i,X2,430i xDrive Gran Coupe,740e xDrive,540i,420i,X3,X6,650i Gran Coupe,230i xDrive,440i,440i Gran Coupe,640i xDrive Gran Coupe,750Li xDrive,i3,M5,X1,M760Li xDrive,330i xDrive,340i GT xDrive,440i xDrive Gran Coupe,650i xDrive Gran Coupe,540i xDrive,220i,M4,Z4,530e,330i GT xDrive,M760i xDrive,Alpina B6 xDrive Gran Coupe,530e xDrive,i3s,640i Gran Coupe,M850i xDrive,530i xDrive,120i,i8,330i,530i,420i Gran Coupe,520i,X4,440i xDrive,640i xDrive Gran Turismo,M140i,750i,750i xDrive,X5,430i,M2,M240i,430i xDrive,118i,X7,M550i xDrive,M240i xDrive,430i Gran Coupe,M6 Gran Coupe,750Li,Alpina B7,740i",
  },
  {
    year: 2019,
    make: "Buick",
    models: "Regal Sportback,Enclave,Cascada,Encore,LaCrosse,Regal TourX,Envision",
  },
  {
    year: 2019,
    make: "Cadillac",
    models: "ATS,Escalade ESV,CTS,Escalade,XT5,XT4,CT6,XTS",
  },
  {
    year: 2019,
    make: "Chevrolet",
    models:
      "Tornado,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,Trax,Spark,Silverado 1500 LD,Silverado 1500,Traverse,Bolt EV,Tahoe,Impala,Blazer,Beat,Volt,Corvette,Aveo,Silverado 2500 HD,Suburban,Cavalier,Sonic,Cruze,Camaro,Cheyenne,Malibu",
  },
  {
    year: 2019,
    make: "Chrysler",
    models: "300,Pacifica",
  },
  {
    year: 2019,
    make: "Dodge",
    models: "Durango,Grand Caravan,Attitude,Journey,Challenger,Charger",
  },
  {
    year: 2019,
    make: "Ferrari",
    models: "Portofino,GTC4Lusso,488 Pista Spider,488 GTB,488 Pista,812 Superfast,GTC4Lusso T,488 Spider",
  },
  {
    year: 2019,
    make: "Fiat",
    models: "Mobi,500,Palio,500X,Ducato,500L,Uno,124 Spider",
  },
  {
    year: 2019,
    make: "Ford",
    models:
      "Flex,SSV Plug-In Hybrid,F-150,Transit-250,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Transit Custom,Edge,Fiesta,Lobo,Escape,F-250 Super Duty,Mustang,Ranger,EcoSport,Transit,Fusion,Police Responder Hybrid,F-350 Super Duty,Transit-150,Taurus,Police Interceptor Sedan,Figo,GT",
  },
  {
    year: 2019,
    make: "Freightliner",
    models: "Sprinter 3500XD,Sprinter 2500,Sprinter 1500",
  },
  {
    year: 2019,
    make: "Genesis",
    models: "G90,G70,G80",
  },
  {
    year: 2019,
    make: "GMC",
    models: "Sierra,Terrain,Sierra 1500,Yukon,Canyon,Savana 2500,Acadia,Sierra 1500 Limited,Yukon XL,Sierra 2500 HD",
  },
  {
    year: 2019,
    make: "Honda",
    models: "Pilot,City,Civic,Insight,Ridgeline,Odyssey,Clarity,BR-V,Accord,CR-V,Passport,Fit,HR-V",
  },
  {
    year: 2019,
    make: "Hyundai",
    models:
      "Sonata,Accent,Grand i10,Nexo,Veloster N,Kona,Elantra GT,Veloster,Elantra,Kona Electric,Creta,Ioniq,Santa Fe,H300,Starex,Tucson,Santa Fe XL",
  },
  {
    year: 2019,
    make: "Infiniti",
    models: "QX50,QX70,Q50,Q60,Q70,Q70L,QX60,QX30,QX80",
  },
  {
    year: 2019,
    make: "JAC",
    models: "SEI 3,SEI 7,J4,SEI 2",
  },
  {
    year: 2019,
    make: "Jaguar",
    models: "E-Pace,XJ,F-Pace,XE,XF,XJR575,I-Pace,F-Type",
  },
  {
    year: 2019,
    make: "Jeep",
    models: "Grand Cherokee,Cherokee,Compass,Wrangler,Renegade",
  },
  {
    year: 2019,
    make: "Karma",
    models: "Revero",
  },
  {
    year: 2019,
    make: "Kia",
    models: "Sportage,K900,Sedona,Rio,Niro EV,Sorento,Soul,Niro,Stinger,Soul EV,Optima,Cadenza,Forte",
  },
  {
    year: 2019,
    make: "Lamborghini",
    models: "Urus,Aventador,Huracan",
  },
  {
    year: 2019,
    make: "Land Rover",
    models: "Discovery,Range Rover Sport,Discovery Sport,Range Rover Velar,Range Rover Evoque,Range Rover",
  },
  {
    year: 2019,
    make: "Lexus",
    models:
      "RC300,RX350,RC F,UX250h,LC500,LX570,LC500h,NX300h,LS500,NX300,RC350,LS500h,IS350,IS300,GX460,UX200,GS300,RX450h,RX350L,GS F,ES350,RX450hL,ES300h,GS350",
  },
  {
    year: 2019,
    make: "Lincoln",
    models: "Continental,MKZ,Nautilus,MKC,Navigator,MKT",
  },
  {
    year: 2019,
    make: "Lotus",
    models: "Evora",
  },
  {
    year: 2019,
    make: "Maserati",
    models: "Levante,GranCabrio,GranTurismo,Ghibli,Quattroporte",
  },
  {
    year: 2019,
    make: "Mazda",
    models: "3 Sport,MX-5 Miata,2,CX-5,3,CX-9,MX-5,6,CX-3",
  },
  {
    year: 2019,
    make: "McLaren",
    models: "720S,600LT,570S,GT,570GT",
  },
  {
    year: 2019,
    make: "Mercedes-Benz",
    models:
      "GLC63 AMG S,S450,C200,SL550,CLS53 AMG,GLE500e,CLA45 AMG,A250,GLC300,GLE350,GLE63 AMG S,AMG GT 63 S,AMG GT C,Maybach S560,AMG GT,E43 AMG,Sprinter,E250,S560e,S63 AMG,E63 AMG S,A220,GLC43 AMG,C43 AMG,E300,S450L,Sprinter 1500,A200,Metris,SL63 AMG,V220d,SLC300,GLE500,S600L,GLA45 AMG,E53 AMG,GLA200,S560L,GLE400,GLS450,GLC63 AMG,G550,C180,E450,AMG GT S,C63 AMG S,AMG GT 63,CLS450,AMG GT R,S560,GLS550,GLC350e,SL450,V250,E200,AMG GT 53,CLA200,Sprinter 3500XD,G500,SL65 AMG,SLC180,Maybach S650,GLE43 AMG,S65 AMG,Sprinter 2500,C300,GLA250,C63 AMG,GLS63 AMG,SLC43 AMG,B250,G63 AMG,SL500,CLA250,GLS500,GLE450,GLE63 AMG",
  },
  {
    year: 2019,
    make: "Mini",
    models: "Cooper Clubman,Cooper Countryman,Cooper",
  },
  {
    year: 2019,
    make: "Mitsubishi",
    models: "L200,Montero Sport,Montero,Outlander PHEV,Outlander,Mirage,Mirage G4,RVR,Outlander Sport,Eclipse Cross",
  },
  {
    year: 2019,
    make: "Nissan",
    models:
      "Maxima,Titan XD,Note,Versa,Micra,Titan,Leaf,NV1500,370Z,Sentra,X-Trail,Frontier,NV2500,Murano,NV350 Urvan,Rogue Sport,GT-R,Qashqai,Pathfinder,NP300,Armada,Altima,NP300 Frontier,March,NV200,Versa Note,Kicks,Rogue",
  },
  {
    year: 2019,
    make: "Peugeot",
    models: "Expert,5008,2008,308,Manager,Traveller,3008,208,301,Partner",
  },
  {
    year: 2019,
    make: "Porsche",
    models: "911,718 Cayman,Cayenne,718 Boxster,Panamera,Macan",
  },
  {
    year: 2019,
    make: "Ram",
    models: "1500,3500,2500,ProMaster Rapid,ProMaster 1500,ProMaster 2500,1500 Classic,ProMaster City",
  },
  {
    year: 2019,
    make: "Rolls-Royce",
    models: "Cullinan,Wraith,Phantom,Ghost,Dawn",
  },
  {
    year: 2019,
    make: "Smart",
    models: "EQ fortwo",
  },
  {
    year: 2019,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2019,
    make: "Subaru",
    models: "Crosstrek,BRZ,Impreza,WRX STI,Forester,Outback,Ascent,XV,WRX,Legacy",
  },
  {
    year: 2019,
    make: "Suzuki",
    models: "Vitara,S-Cross,Ignis,Ertiga,Swift,Ciaz",
  },
  {
    year: 2019,
    make: "Toyota",
    models:
      "86,Prius C,Sienna,Prius Prime,Hilux,Hiace,Yaris,Mirai,Yaris R,Tacoma,Highlander,Avanza,C-HR,Corolla,Land Cruiser,Prius,Tundra,Prius AWD-e,RAV4,Sequoia,4Runner,Avalon,Camry",
  },
  {
    year: 2019,
    make: "Volkswagen",
    models:
      "Atlas,Amarok,Golf R,Arteon,Gol,Saveiro,Crafter Van,Teramont,Beetle,e-Golf,GTI,Golf SportWagen,Golf,Passat,Caddy,Transporter,Jetta,Golf Alltrack,Polo,Tiguan,Vento",
  },
  {
    year: 2019,
    make: "Volvo",
    models: "V60,V90 Cross Country,V60 Cross Country,S90,XC40,XC60,V90,XC90,S60,V40",
  },
  {
    year: 2018,
    make: "Acura",
    models: "RDX,MDX,TLX,RLX,NSX,ILX",
  },
  {
    year: 2018,
    make: "Alfa Romeo",
    models: "Stelvio,4C,Giulietta,Mito,Giulia",
  },
  {
    year: 2018,
    make: "Aston Martin",
    models: "Vantage,DB11,Vanquish,Rapide",
  },
  {
    year: 2018,
    make: "Audi",
    models:
      "A5 Sportback,S3,S4,Q3 Quattro,Q2,Q3,A3 Sportback e-tron,A7 Quattro,A6 Quattro,A3 Quattro,S5,RS7,RS Q3,Q7,S6,A4 Quattro,TT Quattro,A5 Quattro,S5 Sportback,RS3,TT,S8,A1,RS5,A5,A3,S7,A6,Q2 Quattro,SQ5,A4 allroad,A8 Quattro,TT RS Quattro,Q5,A4,TTS Quattro,R8",
  },
  {
    year: 2018,
    make: "Bentley",
    models: "Mulsanne,Bentayga,Continental,Flying Spur",
  },
  {
    year: 2018,
    make: "BMW",
    models:
      "650i xDrive,M760Li xDrive,440i xDrive,640i xDrive Gran Turismo,M140i,750i,750i xDrive,550i xDrive,340i,X5,430i,M2,320i xDrive,M240i,430i xDrive,118i,M760i xDrive,320i,i8,M6,M550i xDrive,330e,M240i xDrive,430i Gran Coupe,M6 Gran Coupe,750Li,Alpina B7,740i,650i,740i xDrive,230i,X2,430i xDrive Gran Coupe,640i xDrive,740e xDrive,318i,540i,420i,X3,M3,X6,650i Gran Coupe,230i xDrive,440i,440i Gran Coupe,640i xDrive Gran Coupe,750Li xDrive,i3,M5,540d xDrive,640i,X1,330i xDrive,340i GT xDrive,440i xDrive Gran Coupe,650i xDrive Gran Coupe,540i xDrive,328d,220i,M4,530e,330i GT xDrive,Alpina B6 xDrive Gran Coupe,328d xDrive,530e xDrive,i3s,640i Gran Coupe,530i xDrive,120i,330i,530i,420i Gran Coupe,520i,340i xDrive,X4",
  },
  {
    year: 2018,
    make: "Bugatti",
    models: "Chiron",
  },
  {
    year: 2018,
    make: "Buick",
    models: "Regal TourX,Envision,Regal Sportback,Enclave,Cascada,Encore,LaCrosse",
  },
  {
    year: 2018,
    make: "Cadillac",
    models: "CT6,XTS,ATS,Escalade ESV,CTS,Escalade,XT5",
  },
  {
    year: 2018,
    make: "Chevrolet",
    models:
      "Sonic,Cruze,Camaro,Cheyenne,Malibu,City Express,Tornado,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,LCF 3500,Trax,Spark,Silverado 1500,Traverse,Bolt EV,Tahoe,Impala,Silverado 2500,Beat,Volt,Corvette,Aveo,Silverado 2500 HD,Suburban,Cavalier",
  },
  {
    year: 2018,
    make: "Chrysler",
    models: "Pacifica,300",
  },
  {
    year: 2018,
    make: "Dodge",
    models: "Challenger,Charger,Vision,Durango,Grand Caravan,Attitude,Journey,Neon",
  },
  {
    year: 2018,
    make: "Ferrari",
    models: "488 Spider,California T,GTC4Lusso T,Portofino,GTC4Lusso,488 GTB,812 Superfast",
  },
  {
    year: 2018,
    make: "Fiat",
    models: "124 Spider,Mobi,500,Palio,500X,Ducato,500L,Uno",
  },
  {
    year: 2018,
    make: "Ford",
    models:
      "EcoSport,C-Max,Transit,Fusion,F-350 Super Duty,Transit-150,Focus,Ranger,Taurus,Police Interceptor Sedan,Figo,GT,Flex,F-150,Special Service Police Sedan,Transit-250,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Transit Custom,Edge,Fiesta,Lobo,Escape,F-250 Super Duty,Mustang",
  },
  {
    year: 2018,
    make: "Freightliner",
    models: "Sprinter 2500,Sprinter 3500XD",
  },
  {
    year: 2018,
    make: "Genesis",
    models: "G90,G80",
  },
  {
    year: 2018,
    make: "GMC",
    models: "Sierra 2500 HD,Sierra,Terrain,Sierra 1500,Yukon,Canyon,Savana 2500,Acadia,Yukon XL",
  },
  {
    year: 2018,
    make: "Honda",
    models: "Pilot,City,Civic,Ridgeline,Odyssey,Clarity,BR-V,Accord,CR-V,Fit,HR-V",
  },
  {
    year: 2018,
    make: "Hyundai",
    models:
      "Santa Fe,H300,H200,Tucson,Santa Fe XL,Sonata,Accent,Santa Fe Sport,Grand i10,Kona,Elantra GT,Elantra,Creta,Ioniq",
  },
  {
    year: 2018,
    make: "Infiniti",
    models: "QX60,QX30,QX80,QX70,Q50,Q60,Q70,Q70L",
  },
  {
    year: 2018,
    make: "JAC",
    models: "SEI 2,SEI 3,J4",
  },
  {
    year: 2018,
    make: "Jaguar",
    models: "F-Type,E-Pace,XJ,F-Pace,XE,XF,XJR575",
  },
  {
    year: 2018,
    make: "Jeep",
    models: "Wrangler,Renegade,Wrangler JK,Grand Cherokee,Cherokee,Compass",
  },
  {
    year: 2018,
    make: "Karma",
    models: "Revero",
  },
  {
    year: 2018,
    make: "Kia",
    models: "Soul EV,Optima,Forte5,Cadenza,Forte,Sportage,Sedona,Rio,Sorento,Soul,Niro,Stinger",
  },
  {
    year: 2018,
    make: "Lamborghini",
    models: "Centenario,Huracan,Urus,Aventador,Veneno",
  },
  {
    year: 2018,
    make: "Land Rover",
    models: "Range Rover Velar,Range Rover Evoque,Range Rover,Discovery,Range Rover Sport,Discovery Sport",
  },
  {
    year: 2018,
    make: "Lexus",
    models:
      "GS300,RX450h,RX350L,GS F,ES350,RX450hL,ES300h,GS350,RC300,RX350,RC F,LC500,LX570,LC500h,NX300h,NX300,LS500,RC350,LS500h,IS350,IS300,GS450h,GX460",
  },
  {
    year: 2018,
    make: "Lincoln",
    models: "Navigator,MKT,MKX,MKZ,Continental,MKC",
  },
  {
    year: 2018,
    make: "Lotus",
    models: "Evora",
  },
  {
    year: 2018,
    make: "Maserati",
    models: "Quattroporte,Levante,GranTurismo,Ghibli",
  },
  {
    year: 2018,
    make: "Mazda",
    models: "MX-5,6,CX-3,MX-5 Miata,2,3 Sport,CX-5,3,CX-9",
  },
  {
    year: 2018,
    make: "McLaren",
    models: "570S,570GT,720S",
  },
  {
    year: 2018,
    make: "Mercedes-Benz",
    models:
      "AMG GT R,S560,GLS550,C350e,GLC350e,SL450,V250,E300,E200,GLE550,CLA200,G65 AMG,G500,SL65 AMG,SLC180,Maybach S650,GLE43 AMG,S65 AMG,S600L,CLS400,C300,GLA250,C63 AMG,GLA180,GLS63 AMG,SLC43 AMG,B250,G63 AMG,SL500,CLA250,S400L,E400,GLE63 AMG,GLC63 AMG S,S500,S450,CLS500,C200,SL550,CLA45 AMG,GLC300,GLE350,GLE63 AMG S,AMG GT C,Maybach S560,G550 4x4,AMG GT,E43 AMG,Sprinter,E250,Sprinter 2500,S63 AMG,E63 AMG S,CLS63 AMG S,A45 AMG,GLE500,GLC43 AMG,C43 AMG,GLC250,GLE500e,A200,Metris,SL63 AMG,V220d,SLC300,GLA45 AMG,GLA200,CLS550,GLE400,GLS450,GLC63 AMG,GLE550e,G550,S500L,C180,C250,AMG GT S,Sprinter 3500XD,C63 AMG S",
  },
  {
    year: 2018,
    make: "Mini",
    models: "Cooper Clubman,Cooper Countryman,Cooper",
  },
  {
    year: 2018,
    make: "Mitsubishi",
    models: "Eclipse Cross,L200,Montero Sport,Montero,Outlander PHEV,Outlander,Mirage,Mirage G4,RVR,Outlander Sport",
  },
  {
    year: 2018,
    make: "Nissan",
    models:
      "Qashqai,Pathfinder,NP300,Armada,Altima,NP300 Frontier,March,NV200,Versa Note,Kicks,Rogue,Maxima,Titan XD,Note,Versa,NT400 Cabstar,Micra,Titan,Leaf,NV1500,370Z,Tiida,Sentra,X-Trail,Frontier,NV2500,Murano,NV350 Urvan,Rogue Sport,GT-R",
  },
  {
    year: 2018,
    make: "Peugeot",
    models: "Traveller,208,301,Partner,Expert,3008,308,Manager",
  },
  {
    year: 2018,
    make: "Porsche",
    models: "911,718 Cayman,Cayenne,718 Boxster,Panamera,Macan",
  },
  {
    year: 2018,
    make: "Ram",
    models: "ProMaster City,1500,3500,2500,ProMaster Rapid,ProMaster 1500,ProMaster 2500",
  },
  {
    year: 2018,
    make: "Rolls-Royce",
    models: "Ghost,Dawn,Wraith,Phantom",
  },
  {
    year: 2018,
    make: "Smart",
    models: "Forfour,Fortwo",
  },
  {
    year: 2018,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2018,
    make: "Subaru",
    models: "XV,WRX,Legacy,Crosstrek,BRZ,Impreza,WRX STI,Forester,Outback",
  },
  {
    year: 2018,
    make: "Suzuki",
    models: "Swift,Ciaz,Vitara,S-Cross,Ignis",
  },
  {
    year: 2018,
    make: "Toyota",
    models:
      "Yaris iA,RAV4,Sequoia,4Runner,Avalon,Camry,86,Prius V,Prius C,Sienna,Prius Prime,Hilux,Hiace,Yaris,Mirai,Yaris R,Tacoma,Highlander,Avanza,C-HR,Corolla,Land Cruiser,Corolla iM,Prius,Tundra",
  },
  {
    year: 2018,
    make: "Volkswagen",
    models:
      "Caddy,Transporter,Touareg,Jetta,Crafter,Golf Alltrack,Polo,Tiguan,Vento,Atlas,Amarok,Golf R,Gol,Saveiro,Tiguan Limited,Crafter Van,Beetle,e-Golf,GTI,Golf SportWagen,Golf,Up!,Passat",
  },
  {
    year: 2018,
    make: "Volvo",
    models: "S60,V40 Cross Country,V40,V60,V90 Cross Country,V60 Cross Country,S90,XC60,S60 Cross Country,V90,XC90",
  },
  {
    year: 2017,
    make: "Acura",
    models: "ILX,RDX,MDX,TLX,RLX,NSX",
  },
  {
    year: 2017,
    make: "Alfa Romeo",
    models: "Stelvio,Giulietta,Mito,Giulia,4C",
  },
  {
    year: 2017,
    make: "Aston Martin",
    models: "Vanquish,Rapide,V8 Vantage,V12 Vantage,DB11",
  },
  {
    year: 2017,
    make: "Audi",
    models:
      "SQ5,A4 allroad,RS3,A8 Quattro,A7,Q5,A4,R8,S3,Q3 Quattro,Q3,A3 Sportback e-tron,A7 Quattro,A6 Quattro,A3 Quattro,TTS Quattro,S5,RS7,RS Q3,Q7,S6,S4,A4 Quattro,TT Quattro,A5 Quattro,TT,S8,A1,A3,S7,A6",
  },
  {
    year: 2017,
    make: "Bentley",
    models: "Continental,Flying Spur,Mulsanne,Bentayga",
  },
  {
    year: 2017,
    make: "BMW",
    models:
      "640i,X1,330i xDrive,340i GT xDrive,440i xDrive Gran Coupe,740Le xDrive,650i xDrive Gran Coupe,540i xDrive,328d,220i,M4,330i GT xDrive,Alpina B6 xDrive Gran Coupe,535i GT,328d xDrive,535i GT xDrive,550i GT xDrive,640i Gran Coupe,530i xDrive,120i,330i,530i,340i xDrive,X4,650i xDrive,440i xDrive,M140i,750i,750i xDrive,340i,X5,430i,M2,320i xDrive,M240i,430i xDrive,320i,M6,330e,M760Li xDrive,M240i xDrive,430i Gran Coupe,M6 Gran Coupe,750Li,Alpina B7,740i,650i,740i xDrive,230i,430i xDrive Gran Coupe,640i xDrive,740e xDrive,M760i xDrive,540i,420i,X3,M3,X6,650i Gran Coupe,230i xDrive,440i,440i Gran Coupe,640i xDrive Gran Coupe,750Li xDrive,i8,i3,M5",
  },
  {
    year: 2017,
    make: "Buick",
    models: "LaCrosse,Verano,Envision,Regal,Enclave,Cascada,Encore",
  },
  {
    year: 2017,
    make: "Cadillac",
    models: "CTS,Escalade,XT5,CT6,XTS,ATS,Escalade ESV",
  },
  {
    year: 2017,
    make: "Chevrolet",
    models:
      "Traverse,Bolt EV,Tahoe,Impala,Silverado 2500,Volt,Spark,Corvette,Aveo,Silverado 2500 HD,Suburban,Caprice,Spark Classic,Sonic,Cruze,Camaro,Cheyenne,Malibu,City Express,SS,Tornado,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,S10,LCF 3500,Trax,LCF 3500HD,Silverado 1500",
  },
  {
    year: 2017,
    make: "Chrysler",
    models: "200,300,Pacifica",
  },
  {
    year: 2017,
    make: "Dodge",
    models: "Attitude,Journey,Neon,Challenger,Charger,Vision,Viper,Durango,Grand Caravan",
  },
  {
    year: 2017,
    make: "Ferrari",
    models: "488 Spider,California T,F12 Berlinetta,GTC4Lusso,LaFerrari,F12tdf,488 GTB",
  },
  {
    year: 2017,
    make: "Fiat",
    models: "Palio,500X,Ducato,500L,Uno,124 Spider,Mobi,500",
  },
  {
    year: 2017,
    make: "Ford",
    models:
      "Expedition,Police Interceptor Utility,Explorer,Transit Custom,Edge,Fiesta,Lobo,Escape,F-250 Super Duty,Mustang,EcoSport,C-Max,Transit,Fusion,F-350 Super Duty,Transit-150,Focus,Taurus,Police Interceptor Sedan,Figo,GT,Flex,F-150,Special Service Police Sedan,Transit-250,Ranger,Transit Connect",
  },
  {
    year: 2017,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2017,
    make: "Genesis",
    models: "G80,G90",
  },
  {
    year: 2017,
    make: "GMC",
    models: "Savana 2500,Acadia,Yukon XL,Sierra 2500 HD,Acadia Limited,Sierra,Terrain,Sierra 1500,Yukon,Canyon",
  },
  {
    year: 2017,
    make: "Honda",
    models: "Ridgeline,Odyssey,Clarity,Accord,CR-V,Fit,HR-V,Pilot,City,Civic",
  },
  {
    year: 2017,
    make: "Hyundai",
    models:
      "Elantra GT,Elantra,Azera,Creta,Ioniq,Santa Fe,H300,H200,HD55,Tucson,Santa Fe XL,Sonata,Veloster,Accent,Santa Fe Sport,Grand i10",
  },
  {
    year: 2017,
    make: "Infiniti",
    models: "Q50,Q60,Q70,Q70L,QX50,QX60,QX30,QX80,QX70",
  },
  {
    year: 2017,
    make: "JAC",
    models: "SEI 2,SEI 3",
  },
  {
    year: 2017,
    make: "Jaguar",
    models: "F-Pace,XE,XF,XJR,F-Type,XJ",
  },
  {
    year: 2017,
    make: "Jeep",
    models: "Cherokee,Compass,Wrangler,Renegade,Patriot,Grand Cherokee",
  },
  {
    year: 2017,
    make: "Karma",
    models: "Revero",
  },
  {
    year: 2017,
    make: "Kia",
    models: "Rio,Rondo,Sorento,Soul,Niro,Soul EV,K900,Optima,Forte Koup,Forte5,Cadenza,Forte,Sportage,Sedona",
  },
  {
    year: 2017,
    make: "Lamborghini",
    models: "Aventador,Centenario,Huracan",
  },
  {
    year: 2017,
    make: "Land Rover",
    models: "Discovery,Range Rover Sport,Discovery Sport,Range Rover Evoque,Range Rover",
  },
  {
    year: 2017,
    make: "Lexus",
    models:
      "NX300h,RC350,IS350,IS300,GS450h,GX460,RX450h,NX200t,CT200h,LS460,RC200t,GS F,ES350,IS200t,ES300h,GS350,RC300,RX350,RC F,LX570,GS200t",
  },
  {
    year: 2017,
    make: "Lincoln",
    models: "MKZ,MKC,Navigator,Continental,MKT,MKX",
  },
  {
    year: 2017,
    make: "Lotus",
    models: "Evora",
  },
  {
    year: 2017,
    make: "Maserati",
    models: "GranCabrio,GranTurismo,Ghibli,Quattroporte,Levante",
  },
  {
    year: 2017,
    make: "Mazda",
    models: "3 Sport,CX-5,3,CX-9,5,6,CX-3,MX-5 Miata,MX-5,2",
  },
  {
    year: 2017,
    make: "McLaren",
    models: "540C,675LT,570S,570GT,650S",
  },
  {
    year: 2017,
    make: "Mercedes-Benz",
    models:
      "E500,GLS350d,SLC200,B250e,GLC43 AMG,C43 AMG,S550,GLC250,A200,Maybach S600,Metris,SL63 AMG,V220d,S600,G550 4x4,SLC300,GLA45 AMG,GLA200,CLS550,GLE400,GLS450,GLE550e,G550,GLS500,Maybach S550,S500L,S63L AMG,C180,C250,AMG GT S,C63 AMG S,GLS550,C350e,SL450,V250,E300,E200,GLE550,A250,CLA200,G65 AMG,G500,SL65 AMG,SLC180,GLE43 AMG,CLA180,S65 AMG,S600L,S65L AMG,CLS400,C300,GLE300d,GLE450 AMG,GLA250,C63 AMG,GLA180,GLS63 AMG,SLC43 AMG,B250,G63 AMG,SL500,CLA250,A45,S400L,E400,GLE63 AMG,E550,S500,CLS500,C200,SL550,CLA45 AMG,GLC300,GLE350,GLE63 AMG S,S550e,AMG GT,E43 AMG,Sprinter,E250,Sprinter 2500,S63 AMG,CLS63 AMG S,A45 AMG,GLE500",
  },
  {
    year: 2017,
    make: "Mini",
    models: "Cooper Clubman,Cooper Countryman,Cooper",
  },
  {
    year: 2017,
    make: "Mitsubishi",
    models: "i-MiEV,Outlander,Mirage,Mirage G4,Lancer,RVR,Outlander Sport,L200,Montero",
  },
  {
    year: 2017,
    make: "Mobility Ventures",
    models: "MV-1",
  },
  {
    year: 2017,
    make: "Nissan",
    models:
      "370Z,Tiida,Sentra,X-Trail,Frontier,Quest,NV2500,Murano,NV350 Urvan,Rogue Sport,Tsuru,GT-R,Qashqai,Pathfinder,NP300,Armada,Altima,NP300 Frontier,March,NV200,Versa Note,Kicks,Rogue,Maxima,Titan XD,Note,Juke,Versa,NT400 Cabstar,Micra,Titan,Leaf,NV1500",
  },
  {
    year: 2017,
    make: "Peugeot",
    models: "208 GT,Manager,3008,508,208,301,Partner,308",
  },
  {
    year: 2017,
    make: "Porsche",
    models: "Cayenne,718 Boxster,Panamera,Macan,911,718 Cayman",
  },
  {
    year: 2017,
    make: "Ram",
    models: "ProMaster Rapid,ProMaster 1500,ProMaster 2500,ProMaster City,1500,3500,2500",
  },
  {
    year: 2017,
    make: "Rolls-Royce",
    models: "Wraith,Ghost,Dawn,Phantom",
  },
  {
    year: 2017,
    make: "Smart",
    models: "Forfour,Fortwo",
  },
  {
    year: 2017,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2017,
    make: "Subaru",
    models: "WRX STI,Forester,Outback,WRX,Legacy,Crosstrek,BRZ,Impreza",
  },
  {
    year: 2017,
    make: "Suzuki",
    models: "Ignis,Grand Vitara,Swift,Ciaz,Vitara,S-Cross",
  },
  {
    year: 2017,
    make: "Toyota",
    models:
      "Yaris,Mirai,Tacoma,Highlander,Avanza,Corolla,Land Cruiser,Corolla iM,Prius,Tundra,Yaris iA,RAV4,Sequoia,4Runner,Avalon,Camry,86,Prius V,Prius C,Sienna,Prius Prime,Hilux,Hiace,Yaris R",
  },
  {
    year: 2017,
    make: "Volkswagen",
    models:
      "Beetle,e-Golf,Tiguan Limited,GTI,CC,Golf SportWagen,Golf,Up!,Passat,Transporter,Touareg,Jetta,Crafter,Golf R,Golf Alltrack,CrossFox,Polo,Tiguan,Vento,Amarok,Gol,Caddy,Saveiro,Crafter Van",
  },
  {
    year: 2017,
    make: "Volvo",
    models: "XC60,S60 Cross Country,XC90,S60,V40 Cross Country,V40,V60,V90 Cross Country,V60 Cross Country,S90",
  },
  {
    year: 2016,
    make: "Acura",
    models: "RLX,ILX,RDX,MDX,TLX",
  },
  {
    year: 2016,
    make: "Alfa Romeo",
    models: "4C,Giulietta,Mito",
  },
  {
    year: 2016,
    make: "Aston Martin",
    models: "V8 Vantage,V12 Vantage,Vanquish,DB9,Rapide",
  },
  {
    year: 2016,
    make: "Audi",
    models:
      "A8,RS7,Q7,S6,A4 Quattro,TT Quattro,TTS Quattro,A5 Quattro,TT,S8,A1,A3,S7,A6,SQ5,A4 allroad,S4,A8 Quattro,A7,Q5,A4,R8,S3,allroad,Q3 Quattro,RS5,Q3,A5,A3 Sportback e-tron,A7 Quattro,A6 Quattro,A3 Quattro,S5",
  },
  {
    year: 2016,
    make: "Bentley",
    models: "Continental,Mulsanne,Flying Spur",
  },
  {
    year: 2016,
    make: "BMW",
    models:
      "330e,M6 Gran Coupe,750Li,740i,Alpina B6 Gran Coupe,650i,640i xDrive,420i,X3,M3,X6,428i xDrive Gran Coupe,228i xDrive,228i,650i Gran Coupe,ActiveHybrid 5,528i xDrive,535i xDrive,640i xDrive Gran Coupe,i8,420i Gran Coupe,520i,i3,M5,640i,X1,650i xDrive Gran Coupe,435i xDrive,328i,328d,550i xDrive,220i,M4,428i Gran Coupe,M235i xDrive,428i xDrive,Alpina B6 xDrive Gran Coupe,335i GT xDrive,535i GT,328d xDrive,535d xDrive,535i GT xDrive,550i GT xDrive,535i,550i,640i Gran Coupe,328i xDrive,120i,435i,328i GT xDrive,535d,330i,340i xDrive,435i Gran Coupe,X4,Z4,650i xDrive,750i,750i xDrive,340i,M135i,X5,428i,M2,320i xDrive,320i,528i,M235i,435i xDrive Gran Coupe,M6",
  },
  {
    year: 2016,
    make: "Buick",
    models: "Envision,Regal,Enclave,Cascada,Encore,LaCrosse,Verano",
  },
  {
    year: 2016,
    make: "Cadillac",
    models: "XTS,ATS,Escalade ESV,CTS,Escalade,SRX,ELR,CT6",
  },
  {
    year: 2016,
    make: "Chevrolet",
    models:
      "Malibu Limited,City Express,SS,Tornado,Spark EV,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,S10,LCF 3500,Trax,LCF 3500HD,Silverado 1500,Traverse,Tahoe,Impala,Cruze Limited,Volt,Suburban,Spark,Corvette,Aveo,Silverado 2500 HD,Caprice,Spark Classic,Sonic,Cruze,Camaro,Silverado 2500,Cheyenne,Malibu,Impala Limited",
  },
  {
    year: 2016,
    make: "Chrysler",
    models: "200,300,Town & Country",
  },
  {
    year: 2016,
    make: "Dodge",
    models: "Viper,Durango,Grand Caravan,Attitude,Journey,Challenger,Charger,Vision,Dart",
  },
  {
    year: 2016,
    make: "Ferrari",
    models: "F12tdf,488 GTB,LaFerrari,FF,488 Spider,California T,F12 Berlinetta",
  },
  {
    year: 2016,
    make: "Fiat",
    models: "500,500X,500L,Uno,Palio,Ducato",
  },
  {
    year: 2016,
    make: "Ford",
    models:
      "Police Interceptor Sedan,Figo,Flex,EcoSport,Transit Custom,F-150,Transit-250,Ranger,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Edge,Fiesta,Lobo,Escape,Special Service Police Sedan,F-250 Super Duty,F-350,Mustang,C-Max,Transit,Fusion,F-350 Super Duty,Transit-150,Focus,Taurus",
  },
  {
    year: 2016,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2016,
    make: "GMC",
    models: "Sierra,Terrain,Sierra 1500,Yukon,Canyon,Savana 2500,Acadia,Yukon XL,Sierra 2500 HD",
  },
  {
    year: 2016,
    make: "Honda",
    models: "Pilot,Civic,Odyssey,Accord,CR-V,Fit,HR-V,CR-Z,City",
  },
  {
    year: 2016,
    make: "Hyundai",
    models:
      "H200,HD55,Tucson,Santa Fe XL,Sonata,Veloster,Genesis,Accent,Santa Fe Sport,Grand i10,Elantra GT,Equus,Elantra,Azera,Genesis Coupe,Santa Fe,H300",
  },
  {
    year: 2016,
    make: "Infiniti",
    models: "QX80,QX70,Q50,Q60,Q70,Q70L,QX50,QX60",
  },
  {
    year: 2016,
    make: "Jaguar",
    models: "XJ,XE,XF,XJR,F-Type",
  },
  {
    year: 2016,
    make: "Jeep",
    models: "Patriot,Grand Cherokee,Cherokee,Compass,Wrangler,Renegade",
  },
  {
    year: 2016,
    make: "Kia",
    models: "Forte5,Sportage,Cadenza,Forte,Sedona,Rio,Rondo,Sorento,Soul,Soul EV,K900,Optima,Forte Koup",
  },
  {
    year: 2016,
    make: "Lamborghini",
    models: "Huracan,Aventador",
  },
  {
    year: 2016,
    make: "Land Rover",
    models: "Range Rover,Defender,Discovery,LR4,Range Rover Sport,Discovery Sport,Range Rover Evoque",
  },
  {
    year: 2016,
    make: "Lexus",
    models:
      "ES300h,GS350,RC300,RX350,RC F,LS600h,LX570,GS200t,NX300h,RC350,IS350,IS300,GS450h,GX460,RX450h,NX200t,CT200h,LS460,RC200t,GS F,ES350,IS200t",
  },
  {
    year: 2016,
    make: "Lincoln",
    models: "MKS,MKT,MKX,MKZ,MKC,Navigator",
  },
  {
    year: 2016,
    make: "Lotus",
    models: "Evora",
  },
  {
    year: 2016,
    make: "Maserati",
    models: "GranCabrio,GranTurismo,Ghibli,Quattroporte",
  },
  {
    year: 2016,
    make: "Mazda",
    models: "6,CX-3,MX-5 Miata,5,MX-5,2,CX-5,3,CX-9,3 Sport",
  },
  {
    year: 2016,
    make: "McLaren",
    models: "675LT,650S,570S",
  },
  {
    year: 2016,
    make: "Mercedes-Benz",
    models:
      "GLA180,B250,G63 AMG,SL500,GL63 AMG,CLA250,S400L,E400,Sprinter 2500,E63 AMG,GL450,GL550,GLE63 AMG,C63 AMG S,E550,C200,SLK200,SL550,E300,GL350,CLA45 AMG,CLA200,GLC300,GLE350,GLE63 AMG S,S550e,SL65 AMG,SLK350,E250,S63 AMG,A180,CLS63 AMG S,A45 AMG,GLE500,GLA250,B250e,S550,Maybach S600,Metris,C450 AMG,E500,SLK300,SL63 AMG,V220d,S500,S600,GLA45 AMG,GLA200,SL400,CLS550,GLE400,GLE550e,G550,S63L AMG,C180,GLE350d,C250,AMG GT S,C350e,SLK55 AMG,E350,Sprinter,E200,GLE550,A200,G65 AMG,C63 AMG,G500,CLA180,CLS500,S65 AMG,S600L,S65L AMG,CLS400,E63 AMG S,C300,GLE300d,GLE450 AMG",
  },
  {
    year: 2016,
    make: "Mini",
    models: "Cooper,Cooper Paceman,Cooper Clubman,Cooper Countryman",
  },
  {
    year: 2016,
    make: "Mitsubishi",
    models: "L200,i-MiEV,Outlander,Mirage,Lancer,RVR,Outlander Sport,Montero",
  },
  {
    year: 2016,
    make: "Mobility Ventures",
    models: "MV-1",
  },
  {
    year: 2016,
    make: "Nissan",
    models:
      "March,NV200,Versa Note,Rogue,Maxima,Titan XD,Note,Juke,Versa,Micra,Leaf,NV1500,370Z,Tiida,Sentra,Frontier,Quest,NV2500,Murano,NV350 Urvan,Tsuru,GT-R,Pathfinder,NP300,X-Trail,Altima,NP300 Frontier",
  },
  {
    year: 2016,
    make: "Peugeot",
    models: "301,Partner,308,208 GT,Manager,RCZ,3008,508,2008,208",
  },
  {
    year: 2016,
    make: "Porsche",
    models: "911,Boxster,Cayenne,Cayman,Panamera,Macan",
  },
  {
    year: 2016,
    make: "Ram",
    models: "1500,3500,ProMaster 1500,ProMaster 2500,2500,ProMaster City",
  },
  {
    year: 2016,
    make: "Rolls-Royce",
    models: "Phantom,Wraith,Dawn,Ghost",
  },
  {
    year: 2016,
    make: "Scion",
    models: "tC,iA,iM,FR-S",
  },
  {
    year: 2016,
    make: "Smart",
    models: "Fortwo,Forfour",
  },
  {
    year: 2016,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2016,
    make: "Subaru",
    models: "Legacy,Crosstrek,BRZ,Impreza,XV,WRX STI,Forester,Outback,WRX",
  },
  {
    year: 2016,
    make: "Suzuki",
    models: "Kizashi,Vitara,S-Cross,Grand Vitara,Swift,Ciaz",
  },
  {
    year: 2016,
    make: "Toyota",
    models:
      "Avalon,Camry,Prius V,Prius C,Sienna,Hiace,Venza,Yaris,Mirai,Tacoma,Highlander,Yaris R,Corolla,Land Cruiser,Prius,Tundra,Hilux,RAV4,Sequoia,4Runner,Avanza",
  },
  {
    year: 2016,
    make: "Volkswagen",
    models:
      "Tiguan,Vento,Amarok,Gol,Saveiro,Crafter Van,Caddy,Beetle,e-Golf,GTI,CC,Nuevo Gol,Golf SportWagen,Golf,Up!,Passat,Eos,Transporter,Touareg,Jetta,Crafter,Golf R,CrossFox,Polo",
  },
  {
    year: 2016,
    make: "Volvo",
    models: "S80,V40,V60,V60 Cross Country,XC60,S60 Cross Country,XC70,XC90,S60,V40 Cross Country",
  },
  {
    year: 2015,
    make: "Acura",
    models: "RDX,MDX,TLX,RLX,ILX",
  },
  {
    year: 2015,
    make: "Alfa Romeo",
    models: "Mito,4C,Giulietta",
  },
  {
    year: 2015,
    make: "Aston Martin",
    models: "V8 Vantage,V12 Vantage,Vanquish,DB9,Rapide",
  },
  {
    year: 2015,
    make: "Audi",
    models:
      "Q5,A3 Quattro,A4,R8,S3,allroad,Q3 Quattro,RS5,Q3,A5,A7 Quattro,A6 Quattro,S5,A8,RS7,Q7,S6,A4 Quattro,TT Quattro,A5 Quattro,S8,A1,A3,S7,A6,SQ5,TTS Quattro,A4 allroad,S4,A8 Quattro",
  },
  {
    year: 2015,
    make: "Bentley",
    models: "Continental,Mulsanne,Flying Spur",
  },
  {
    year: 2015,
    make: "BMW",
    models:
      "ActiveHybrid 7,550i,640i Gran Coupe,328i xDrive,335i xDrive,435i,Alpina B7,328i GT xDrive,i8,535d,760Li,435i Gran Coupe,X4,Z4,650i xDrive,ActiveHybrid 3,750i,750i xDrive,M135i,X5,428i,320i xDrive,750Li xDrive,320i,Alpina B7L,740Li,Alpina B7 xDrive,528i,M235i,435i xDrive Gran Coupe,M6,M6 Gran Coupe,750Li,Alpina B7L xDrive,740i,650i,640i xDrive,118i,X3,M3,X6,428i xDrive Gran Coupe,228i xDrive,228i,650i Gran Coupe,ActiveHybrid 5,528i xDrive,535i xDrive,640i xDrive Gran Coupe,740Ld xDrive,520i,i3,M5,640i,X1,650i xDrive Gran Coupe,435i xDrive,550i GT,328i,328d,550i xDrive,220i,M4,428i Gran Coupe,M235i xDrive,740Li xDrive,428i xDrive,Alpina B6 xDrive Gran Coupe,335i GT xDrive,535i GT,328d xDrive,535d xDrive,335i,535i GT xDrive,550i GT xDrive,535i",
  },
  {
    year: 2015,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2015,
    make: "Buick",
    models: "Verano,Regal,Enclave,Encore,LaCrosse",
  },
  {
    year: 2015,
    make: "Cadillac",
    models: "XTS,ELR,ATS,Escalade ESV,CTS,Escalade,SRX",
  },
  {
    year: 2015,
    make: "Chevrolet",
    models:
      "Spark,Corvette,Aveo,Silverado 2500 HD,Caprice,Sonic,Cruze,Camaro,Silverado 2500,Malibu,Impala Limited,Cheyenne,City Express,SS,Tornado,Spark EV,Express 2500,Express 3500,Silverado 3500 HD,Equinox,Captiva Sport,Trax,Matiz,Silverado 1500,Traverse,Tahoe,Impala,Volt,Colorado,Suburban",
  },
  {
    year: 2015,
    make: "Chrysler",
    models: "300,Town & Country,200",
  },
  {
    year: 2015,
    make: "Dodge",
    models: "Challenger,Charger,Vision,Dart,Viper,Durango,Grand Caravan,Attitude,Journey",
  },
  {
    year: 2015,
    make: "Ferrari",
    models: "California T,LaFerrari,F12 Berlinetta,458 Spider,458 Speciale,458 Italia,FF",
  },
  {
    year: 2015,
    make: "Fiat",
    models: "500L,Uno,Palio,Ducato,500",
  },
  {
    year: 2015,
    make: "Ford",
    models:
      "Escape,Special Service Police Sedan,F-250 Super Duty,F-350,Mustang,C-Max,Transit,Fusion,Lobo,F-350 Super Duty,Transit-150,Focus,Taurus,Police Interceptor Sedan,Flex,Fiesta Ikon,EcoSport,Transit Custom,F-150,Transit-250,Ranger,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Edge,Fiesta",
  },
  {
    year: 2015,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2015,
    make: "GMC",
    models: "Yukon XL,Sierra 2500 HD,Sierra,Terrain,Sierra 1500,Yukon,Canyon,Savana 2500,Acadia",
  },
  {
    year: 2015,
    make: "Honda",
    models: "Crosstour,Fit,CR-Z,City,Pilot,Civic,Odyssey,Accord,CR-V",
  },
  {
    year: 2015,
    make: "Hyundai",
    models:
      "Elantra,Azera,Genesis Coupe,Santa Fe,Tucson,Santa Fe XL,Sonata,Veloster,Genesis,Accent,Santa Fe Sport,Grand i10,Elantra GT,Equus,ix35",
  },
  {
    year: 2015,
    make: "Infiniti",
    models: "Q70,Q70L,QX50,QX60,Q40,QX80,QX70,Q50,Q60",
  },
  {
    year: 2015,
    make: "Jaguar",
    models: "XJ,XJR,F-Type,XK,XKR,XKR-S,XFR-S,XF,XFR",
  },
  {
    year: 2015,
    make: "Jeep",
    models: "Compass,Wrangler,Renegade,Patriot,Grand Cherokee,Cherokee",
  },
  {
    year: 2015,
    make: "Kia",
    models: "Soul EV,K900,Optima,Forte Koup,Forte5,Sportage,Cadenza,Forte,Sedona,Rio,Rondo,Sorento,Soul",
  },
  {
    year: 2015,
    make: "Lamborghini",
    models: "Huracan,Aventador",
  },
  {
    year: 2015,
    make: "Land Rover",
    models: "Range Rover Evoque,LR2,Range Rover,Defender,Discovery,LR4,Range Rover Sport,Discovery Sport",
  },
  {
    year: 2015,
    make: "Lexus",
    models:
      "IS350,GS450h,GX460,RX450h,NX200t,CT200h,LS460,ES350,ES300h,GS350,RX350,RC F,LS600h,LX570,IS250,NX300h,RC350",
  },
  {
    year: 2015,
    make: "Lincoln",
    models: "MKC,Navigator,MKS,MKT,MKX,MKZ",
  },
  {
    year: 2015,
    make: "Maserati",
    models: "Ghibli,Quattroporte,GranCabrio,GranTurismo",
  },
  {
    year: 2015,
    make: "Mazda",
    models: "5,CX-5,3,CX-9,6,3 Sport,MX-5 Miata,MX-5,2",
  },
  {
    year: 2015,
    make: "McLaren",
    models: "P1,650S",
  },
  {
    year: 2015,
    make: "Mercedes-Benz",
    models:
      "G550,GLK250,C180,C250,SLK55 AMG,E350,GLK300,E200,A200,G65 AMG,GL500,C63 AMG,CLA180,S400,CLS500,S65 AMG,Sprinter,GLA45 AMG,CLS400,C400,E63 AMG S,C300,ML350,B250,G63 AMG,SL500,GL63 AMG,CLA250,E400,E63 AMG,GL450,ML550,GL550,S550e,E550,SLK250,C200,S500L,SLK200,SL550,E300,GL350,CLA45 AMG,SLS AMG,CLA200,G500,SL65 AMG,SLK350,GLK350,Viano,E250,B180,ML250,S63 AMG,A180,CLS63 AMG S,A45 AMG,C63 AMG S,GLA250,C350,ML500,S550,ML400,Sprinter 2500,E500,SL63 AMG,S500,S600,GLA200,B Electric Drive,ML63 AMG,SL400,CLS550",
  },
  {
    year: 2015,
    make: "Mini",
    models: "Cooper Countryman,Cooper,Cooper Paceman",
  },
  {
    year: 2015,
    make: "Mitsubishi",
    models: "Lancer,RVR,Outlander Sport,Montero,Montero Sport,ASX,L200,Outlander,Mirage",
  },
  {
    year: 2015,
    make: "Mobility Ventures",
    models: "MV-1",
  },
  {
    year: 2015,
    make: "Nissan",
    models:
      "Murano,NV350 Urvan,Xterra,GT-R,Pathfinder,NP300,X-Trail,Altima,Titan,March,NV200,Versa Note,Rogue,Rogue Select,Maxima,Note,Juke,Versa,Micra,Leaf,NV1500,370Z,Tiida,Sentra,Armada,Frontier,Tsuru,Quest,NV2500",
  },
  {
    year: 2015,
    make: "Peugeot",
    models: "RCZ,3008,508,2008,208,301,Partner,208 GT,Manager",
  },
  {
    year: 2015,
    make: "Porsche",
    models: "Macan,911,Boxster,Cayenne,Cayman,Panamera,918 Spyder",
  },
  {
    year: 2015,
    make: "Ram",
    models: "2500,ProMaster City,C/V,1500,3500,ProMaster 1500,ProMaster 2500",
  },
  {
    year: 2015,
    make: "Rolls-Royce",
    models: "Ghost,Wraith,Phantom",
  },
  {
    year: 2015,
    make: "Scion",
    models: "xB,FR-S,tC,iQ",
  },
  {
    year: 2015,
    make: "Smart",
    models: "Fortwo",
  },
  {
    year: 2015,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2015,
    make: "Subaru",
    models: "WRX,Legacy,XV Crosstrek,BRZ,Impreza,XV,WRX STI,Forester,Outback",
  },
  {
    year: 2015,
    make: "Suzuki",
    models: "Grand Vitara,Swift,Kizashi,S-Cross",
  },
  {
    year: 2015,
    make: "Toyota",
    models:
      "Corolla,Land Cruiser,Prius,Tundra,Hilux,Prius Plug-In,RAV4,Sequoia,4Runner,Avalon,Camry,Prius V,Prius C,Sienna,Hiace,Venza,Yaris,Tacoma,Highlander,Avanza",
  },
  {
    year: 2015,
    make: "Volkswagen",
    models:
      "Passat,Eos,Caddy,Transporter,Touareg,Jetta,Golf R,CrossFox,Polo,Tiguan,Vento,Amarok,Saveiro,Crafter Van,Clasico,Beetle,e-Golf,GTI,CC,Nuevo Gol,Golf SportWagen,Golf",
  },
  {
    year: 2015,
    make: "Volvo",
    models: "S60,V40 Cross Country,S80,V40,V60,V60 Cross Country,XC60,XC70",
  },
  {
    year: 2014,
    make: "Acura",
    models: "TSX,ILX,RDX,MDX,RLX,TL",
  },
  {
    year: 2014,
    make: "Alfa Romeo",
    models: "Giulietta",
  },
  {
    year: 2014,
    make: "Aston Martin",
    models: "Vanquish,DB9,Rapide,V8 Vantage",
  },
  {
    year: 2014,
    make: "Audi",
    models:
      "S8,A1,A3,S7,A6,SQ5,A4 allroad,S4,A8 Quattro,TTS Quattro,Q5,A4,R8,S3,allroad,TT RS Quattro,TT,RS5,Q3,A5,A7 Quattro,A6 Quattro,S5,RS7,Q7,S6,A4 Quattro,TT Quattro,A5 Quattro",
  },
  {
    year: 2014,
    make: "Bentley",
    models: "Flying Spur,Continental,Mulsanne",
  },
  {
    year: 2014,
    make: "BMW",
    models:
      "228i,650i Gran Coupe,ActiveHybrid 5,528i xDrive,535i xDrive,640i xDrive Gran Coupe,520i,i3,M5,640i,X1,435i xDrive,550i GT,328i,328d,550i xDrive,220i,235i,740Li xDrive,428i xDrive,335i GT xDrive,535i GT,328d xDrive,535d xDrive,335i,535i GT xDrive,550i GT xDrive,535i,ActiveHybrid 7,550i,640i Gran Coupe,328i xDrive,335i xDrive,435i,Alpina B7,328i GT xDrive,535d,760Li,Z4,650i xDrive,ActiveHybrid 3,650i xDrive Gran Coupe,750i,750i xDrive,M135i,X5,428i,320i xDrive,750Li xDrive,320i,i8,Alpina B7L,740Li,Alpina B7 xDrive,528i,M235i,M6,M6 Gran Coupe,750Li,Alpina B7L xDrive,740i,650i,640i xDrive,118i,X3,X6",
  },
  {
    year: 2014,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2014,
    make: "Buick",
    models: "Enclave,Encore,LaCrosse,Verano,Regal",
  },
  {
    year: 2014,
    make: "Cadillac",
    models: "CTS,ELR,Escalade,SRX,XTS,ATS,Escalade ESV",
  },
  {
    year: 2014,
    make: "Chevrolet",
    models:
      "Captiva Sport,Trax,Matiz,Silverado 1500,Traverse,Orlando,Tahoe,Impala,Express 1500,Volt,Suburban,Spark,Corvette,Aveo,Silverado 2500 HD,Caprice,Sonic,Cruze,Colorado,Camaro,Silverado 2500,Malibu,Impala Limited,Cheyenne,SS,Tornado,Spark EV,Suburban 1500,Express 2500,Express 3500,Silverado 3500 HD,Equinox",
  },
  {
    year: 2014,
    make: "Chrysler",
    models: "200,300,Town & Country",
  },
  {
    year: 2014,
    make: "Dodge",
    models: "Attitude,Journey,Challenger,Charger,Dart,Durango,Grand Caravan,Avenger,i10",
  },
  {
    year: 2014,
    make: "Ferrari",
    models: "458 Italia,FF,LaFerrari,F12 Berlinetta,458 Spider,California",
  },
  {
    year: 2014,
    make: "Fiat",
    models: "Strada,500,500L,Uno,Palio,Ducato",
  },
  {
    year: 2014,
    make: "Ford",
    models:
      "Econoline Van,Ranger,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Econoline Wagon,Edge,Fiesta,Special Service Police Sedan,Escape,F-250 Super Duty,F-350,Mustang,C-Max,Transit,Fusion,Lobo,E-150,F-350 Super Duty,Focus,Taurus,Police Interceptor Sedan,E-250,Flex,Fiesta Ikon,EcoSport,Transit Custom,F-150",
  },
  {
    year: 2014,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2014,
    make: "GMC",
    models: "Sierra 1500,Yukon,Savana 2500,Savana 1500,Acadia,Yukon XL 1500,Sierra 2500 HD,Sierra,Terrain",
  },
  {
    year: 2014,
    make: "Honda",
    models: "Odyssey,Accord,CR-V,Crosstour,Ridgeline,Fit,City,CR-Z,Insight,Pilot,Civic",
  },
  {
    year: 2014,
    make: "Hyundai",
    models:
      "Elantra Coupe,Santa Fe XL,Elantra GT,Equus,Elantra,Azera,Genesis Coupe,Santa Fe,Tucson,Sonata,Veloster,Genesis,Accent,Santa Fe Sport",
  },
  {
    year: 2014,
    make: "Infiniti",
    models: "Q50,Q60,Q70,QX50,QX60,QX80,QX70",
  },
  {
    year: 2014,
    make: "Jaguar",
    models: "XKR,XKR-S,XFR-S,XF,XFR,XJ,XJR,F-Type,XK",
  },
  {
    year: 2014,
    make: "Jeep",
    models: "Cherokee,Compass,Wrangler,Patriot,Grand Cherokee",
  },
  {
    year: 2014,
    make: "Kia",
    models: "Sedona,Rio,Rondo,Sorento,Soul,Optima,Forte Koup,Forte5,Sportage,Cadenza,Forte",
  },
  {
    year: 2014,
    make: "Lamborghini",
    models: "Aventador,Gallardo",
  },
  {
    year: 2014,
    make: "Land Rover",
    models: "Discovery,LR4,Range Rover Sport,Range Rover Evoque,LR2,Range Rover,Defender",
  },
  {
    year: 2014,
    make: "Lexus",
    models: "LX570,IS250,IS350,GS450h,GX460,RX450h,CT200h,LS460,IS F,ES350,ES300h,GS350,RX350,LS600h",
  },
  {
    year: 2014,
    make: "Lincoln",
    models: "Mark LT,MKZ,Navigator,MKS,MKT,MKX",
  },
  {
    year: 2014,
    make: "Lotus",
    models: "Evora",
  },
  {
    year: 2014,
    make: "Maserati",
    models: "GranTurismo,Ghibli,GranCabrio,Quattroporte",
  },
  {
    year: 2014,
    make: "Mazda",
    models: "3 Sport,MX-5,2,5,CX-5,3,CX-9,6,MX-5 Miata",
  },
  {
    year: 2014,
    make: "McLaren",
    models: "MP4-12C,P1",
  },
  {
    year: 2014,
    make: "Mercedes-Benz",
    models:
      "G500,SL65 AMG,SLK350,GLK350,Viano,E250,B180,S63 AMG,A180,CLS63 AMG S,A45 AMG,C350,ML500,CL600,S550,E400,Sprinter 2500,A250,E500,SL63 AMG,S500,GLA200,B Electric Drive,ML63 AMG,CLS550,CL65 AMG,G550,GLK250,C180,C250,SLK55 AMG,E350,GLK300,E200,A200,G65 AMG,GL500,C63 AMG,S400,CLS500,Sprinter,CLS350,E300,CL63 AMG,E63 AMG S,C300,ML350,B250,G63 AMG,SL500,GL63 AMG,CLA250,CL550,CLS63 AMG,E63 AMG,GL450,ML550,GL550,E550,SLK250,C200,SLK200,SL550,GLA45 AMG,GL350,CLA45 AMG,SLS AMG,CLA200,GLA250",
  },
  {
    year: 2014,
    make: "Mini",
    models: "Cooper Paceman,Cooper Countryman,Cooper",
  },
  {
    year: 2014,
    make: "Mitsubishi",
    models: "Outlander,Mirage,Lancer,RVR,Outlander Sport,Montero,Montero Sport,ASX,i-MiEV,L200",
  },
  {
    year: 2014,
    make: "Mobility Ventures",
    models: "MV-1",
  },
  {
    year: 2014,
    make: "Nissan",
    models:
      "Versa,Leaf,NV1500,370Z,Cube,Tiida,Sentra,Armada,Frontier,Tsuru,Quest,NV2500,Murano,NV350 Urvan,Xterra,GT-R,Pathfinder,NP300,X-Trail,Altima,Titan,NV200,Versa Note,March,Rogue,Rogue Select,Maxima,Note,Juke",
  },
  {
    year: 2014,
    make: "Peugeot",
    models: "208 GT,Manager,RCZ,2008,207,3008,508,208,308,301,Partner",
  },
  {
    year: 2014,
    make: "Porsche",
    models: "Boxster,Cayenne,Cayman,Panamera,911",
  },
  {
    year: 2014,
    make: "Ram",
    models: "ProMaster 1500,ProMaster 2500,2500,C/V,1500,3500",
  },
  {
    year: 2014,
    make: "Rolls-Royce",
    models: "Wraith,Phantom,Ghost",
  },
  {
    year: 2014,
    make: "Scion",
    models: "iQ,xB,FR-S,xD,tC",
  },
  {
    year: 2014,
    make: "Smart",
    models: "Fortwo",
  },
  {
    year: 2014,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2014,
    make: "SRT",
    models: "Viper",
  },
  {
    year: 2014,
    make: "Subaru",
    models: "BRZ,Impreza,XV,WRX STI,Forester,Outback,Tribeca,WRX,Legacy,XV Crosstrek",
  },
  {
    year: 2014,
    make: "Suzuki",
    models: "SX4,S-Cross,Grand Vitara,Swift,Kizashi",
  },
  {
    year: 2014,
    make: "Toyota",
    models:
      "Prius V,Prius C,Sienna,Hiace,Venza,Yaris,FJ Cruiser,Tacoma,Highlander,Corolla,Land Cruiser,Prius,Tundra,Hilux,Prius Plug-In,Avanza,RAV4,Sequoia,4Runner,Matrix,Avalon,Camry",
  },
  {
    year: 2014,
    make: "Volkswagen",
    models:
      "Saveiro,Routan,Clasico,Beetle,GTI,CC,Nuevo Gol,Golf,Passat,Eos,Caddy,Transporter,Touareg,Jetta,CrossFox,Polo,Tiguan,Vento,Crafter Van,Amarok",
  },
  {
    year: 2014,
    make: "Volvo",
    models: "V60,XC90,XC60,XC70,S60,V40 Cross Country,S80,V40",
  },
  {
    year: 2013,
    make: "Acura",
    models: "TL,ZDX,TSX,ILX,RDX,MDX",
  },
  {
    year: 2013,
    make: "Alfa Romeo",
    models: "Mito,Giulietta",
  },
  {
    year: 2013,
    make: "Aston Martin",
    models: "V8 Vantage,DB9,Rapide,V12 Vantage",
  },
  {
    year: 2013,
    make: "Audi",
    models:
      "TT,RS5,Q3,A5,A7 Quattro,A6 Quattro,S5,Q7,S6,A4 Quattro,TT Quattro,TT RS Quattro,A5 Quattro,S8,A1,A3,A3 Quattro,S7,A6,A4 allroad,S4,A8 Quattro,Q5,A4,allroad,TTS Quattro",
  },
  {
    year: 2013,
    make: "Bentley",
    models: "Continental,Mulsanne,Flying Spur",
  },
  {
    year: 2013,
    make: "BMW",
    models:
      "320i xDrive,750Li xDrive,320i,Alpina B7L,740Li,Alpina B7 xDrive,528i,750Li,650i xDrive Gran Coupe,335is,Alpina B7L xDrive,X1,650i,118i,X3,X6,650i Gran Coupe,ActiveHybrid 5,528i xDrive,535i xDrive,M135i,640i,125i,550i GT,328i,550i xDrive,740i,135i,740Li xDrive,135is,325i,535i GT,335i,530i,535i GT xDrive,550i GT xDrive,535i,ActiveHybrid 7,550i,640i Gran Coupe,328i xDrive,335i xDrive,Alpina B7,760Li,128i,M5,M6,Z4,650i xDrive,ActiveHybrid 3,750i,750i xDrive,M3,X5",
  },
  {
    year: 2013,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2013,
    make: "Buick",
    models: "Regal,Enclave,Encore,LaCrosse,Verano",
  },
  {
    year: 2013,
    make: "Cadillac",
    models: "XTS,ATS,Escalade ESV,CTS,Escalade EXT,Escalade,SRX",
  },
  {
    year: 2013,
    make: "Chevrolet",
    models:
      "Malibu,Spark,Cheyenne,Tornado,Suburban 1500,Express 2500,Express 3500,Silverado 3500 HD,Cruze,Colorado,Equinox,Trax,Avalanche,Matiz,Silverado 1500,Traverse,Orlando,Tahoe,Impala,Express 1500,Suburban 2500,Volt,Corvette,Aveo,Silverado 2500 HD,Caprice,Sonic,Captiva Sport,Camaro,Silverado 2500",
  },
  {
    year: 2013,
    make: "Chrysler",
    models: "200,300,Town & Country",
  },
  {
    year: 2013,
    make: "Dodge",
    models: "Dart,Durango,Grand Caravan,Avenger,i10,Attitude,Journey,Challenger,Charger",
  },
  {
    year: 2013,
    make: "Ferrari",
    models: "F12 Berlinetta,458 Spider,California,458 Italia,FF",
  },
  {
    year: 2013,
    make: "Fiat",
    models: "Punto,Ducato,Strada,500,Uno,Palio",
  },
  {
    year: 2013,
    make: "Ford",
    models:
      "Fusion,Lobo,E-150,F-350 Super Duty,Focus,Taurus,Police Interceptor Sedan,E-250,Flex,Fiesta Ikon,EcoSport,F-150,Econoline Van,Ranger,Transit Connect,Expedition,Police Interceptor Utility,Explorer,Econoline Wagon,Edge,Fiesta,Escape,F-250 Super Duty,F-350,Mustang,C-Max,Transit",
  },
  {
    year: 2013,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2013,
    make: "GMC",
    models:
      "Sierra 2500 HD,Yukon XL 2500,Sierra,Terrain,Sierra 1500,Yukon,Savana 2500,Savana 1500,Acadia,Yukon XL 1500",
  },
  {
    year: 2013,
    make: "Honda",
    models: "Pilot,Civic,Odyssey,City,Accord,CR-V,Crosstour,Ridgeline,Fit,CR-Z,Insight",
  },
  {
    year: 2013,
    make: "Hyundai",
    models:
      "Tucson,Sonata,Veloster,Genesis,Accent,Santa Fe Sport,Elantra GT,Equus,Elantra,Azera,Genesis Coupe,Elantra Coupe,Santa Fe XL,Santa Fe",
  },
  {
    year: 2013,
    make: "Infiniti",
    models: "M56,QX56,M37,FX50,FX37,M35h,JX35,G37,EX37",
  },
  {
    year: 2013,
    make: "Jaguar",
    models: "XK,XKR,XKR-S,XFR-S,XF,XFR,XJ",
  },
  {
    year: 2013,
    make: "Jeep",
    models: "Patriot,Grand Cherokee,Liberty,Compass,Wrangler",
  },
  {
    year: 2013,
    make: "Kia",
    models: "Optima,Forte Koup,Forte5,Sportage,Forte,Rio,Sorento,Soul",
  },
  {
    year: 2013,
    make: "Lamborghini",
    models: "Gallardo,Aventador",
  },
  {
    year: 2013,
    make: "Land Rover",
    models: "Range Rover Evoque,LR2,Range Rover,Defender,LR4,Range Rover Sport",
  },
  {
    year: 2013,
    make: "Lexus",
    models: "ES350,ES300h,GS350,RX350,LS600h,LX570,IS250,IS350,GS450h,GX460,RX450h,CT200h,LS460,IS F",
  },
  {
    year: 2013,
    make: "Lincoln",
    models: "MKS,MKT,MKX,Mark LT,MKZ,Navigator",
  },
  {
    year: 2013,
    make: "Lotus",
    models: "Evora",
  },
  {
    year: 2013,
    make: "Maserati",
    models: "GranTurismo,GranCabrio,Quattroporte",
  },
  {
    year: 2013,
    make: "Mazda",
    models: "6,MX-5 Miata,3 Sport,MX-5,2,5,CX-5,3,CX-9",
  },
  {
    year: 2013,
    make: "McLaren",
    models: "MP4-12C",
  },
  {
    year: 2013,
    make: "Mercedes-Benz",
    models:
      "S350,S400,Sprinter,CLS350,CL63 AMG,C300,ML350,S500,B250,G63 AMG,SL500,GL63 AMG,CLA250,CL500,CL550,CLS63 AMG,E63 AMG,GL450,ML550,GL550,E550,R350,S600,SLK250,C200,SLK200,SL550,E300,GL350,Sprinter 2500,SLS AMG,G500,SL65 AMG,SLK350,GLK350,CLS500,Viano,E250,B180,S63 AMG,A200,C350,ML500,CL600,S550,E500,SL63 AMG,S65 AMG,ML63 AMG,CLS550,CL65 AMG,G550,GLK250,GLK300,C180,E400,C250,A250,CLA200,GL500,SLK55 AMG,E350,Vito,E200,A180,C63 AMG",
  },
  {
    year: 2013,
    make: "Mini",
    models: "Cooper,Cooper Paceman,Cooper Countryman",
  },
  {
    year: 2013,
    make: "Mitsubishi",
    models: "ASX,L200,Outlander,Lancer,RVR,Outlander Sport,Montero,Montero Sport",
  },
  {
    year: 2013,
    make: "Nissan",
    models:
      "X-Trail,Altima,Titan,NV200,March,Rogue,Maxima,Juke,Versa,Leaf,NV1500,370Z,Cube,Urvan,Tiida,Sentra,Armada,Frontier,Tsuru,Quest,NV2500,Murano,Xterra,GT-R,Pathfinder,NP300",
  },
  {
    year: 2013,
    make: "Peugeot",
    models: "3008,508,308,301,Partner,Manager,RCZ,207",
  },
  {
    year: 2013,
    make: "Porsche",
    models: "911,Boxster,Cayenne,Panamera",
  },
  {
    year: 2013,
    make: "Ram",
    models: "C/V,1500,3500,2500",
  },
  {
    year: 2013,
    make: "Rolls-Royce",
    models: "Ghost,Phantom",
  },
  {
    year: 2013,
    make: "Scion",
    models: "FR-S,xD,tC,iQ,xB",
  },
  {
    year: 2013,
    make: "Smart",
    models: "Fortwo",
  },
  {
    year: 2013,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2013,
    make: "SRT",
    models: "Viper",
  },
  {
    year: 2013,
    make: "Subaru",
    models: "Legacy,WRX,XV Crosstrek,BRZ,Impreza,XV,WRX STI,Forester,Outback,Tribeca",
  },
  {
    year: 2013,
    make: "Suzuki",
    models: "Kizashi,SX4,Grand Vitara,Swift",
  },
  {
    year: 2013,
    make: "Toyota",
    models:
      "RAV4,Sequoia,4Runner,Matrix,Avalon,Camry,Avanza,Prius V,Prius C,Sienna,Hiace,Venza,Yaris,FJ Cruiser,Tacoma,Highlander,Corolla,Land Cruiser,Prius,Tundra,Hilux,Prius Plug-In",
  },
  {
    year: 2013,
    make: "Volkswagen",
    models:
      "CrossFox,Polo,Crafter Van,Tiguan,Golf R,Amarok,Golf SportWagen,Saveiro,Routan,Multivan,Clasico,Gol,Beetle,GTI,CC,Golf,Passat,Eos,Gol Sedan,Transporter,Touareg,Jetta",
  },
  {
    year: 2013,
    make: "Volvo",
    models: "V40 Cross Country,S80,V60,XC90,C30,XC60,C70,XC70,S60",
  },
  {
    year: 2012,
    make: "Acura",
    models: "RDX,MDX,TL,ZDX,RL,TSX",
  },
  {
    year: 2012,
    make: "Alfa Romeo",
    models: "Giulietta,159,Mito",
  },
  {
    year: 2012,
    make: "Aston Martin",
    models: "DB9,Rapide,V12 Vantage,DBS,Virage,V8 Vantage",
  },
  {
    year: 2012,
    make: "Audi",
    models:
      "A8 Quattro,TTS Quattro,TT RS Quattro,Q5,A4,TT,A5,A7 Quattro,A6 Quattro,S5,Q7,A4 Quattro,TT Quattro,A5 Quattro,A1,A3,A3 Quattro,R8,A6,S3,S4",
  },
  {
    year: 2012,
    make: "Bentley",
    models: "Continental,Mulsanne",
  },
  {
    year: 2012,
    make: "BMW",
    models:
      "120i,325i,535i GT,335i,530i,535i GT xDrive,550i GT xDrive,535i,M6,550i,328i xDrive,335i xDrive,125i,Alpina B7,760Li,128i,Z4,650i xDrive,750i,750i xDrive,M3,X5,750Li xDrive,320i,Alpina B7L,740Li,Alpina B7 xDrive,528i,M5,750Li,335is,Alpina B7L xDrive,X1,650i,118i,X3,X6,ActiveHybrid 5,528i xDrive,535i xDrive,640i,550i GT,328i,550i xDrive,740i,135i",
  },
  {
    year: 2012,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2012,
    make: "Buick",
    models: "LaCrosse,Verano,Regal,Enclave",
  },
  {
    year: 2012,
    make: "Cadillac",
    models: "Escalade,SRX,Escalade ESV,CTS,Escalade EXT",
  },
  {
    year: 2012,
    make: "Chevrolet",
    models:
      "Express 1500,Suburban 2500,Volt,Corvette,Aveo,Silverado 2500 HD,Matiz,Caprice,Sonic,Captiva Sport,Camaro,Silverado 2500,Malibu,Cheyenne,Tornado,Suburban 1500,Express 2500,Express 3500,Silverado 3500 HD,Cruze,Colorado,Equinox,Spark,Avalanche,Silverado 1500,Traverse,Orlando,Chevy,Tahoe,Impala",
  },
  {
    year: 2012,
    make: "Chrysler",
    models: "200,300,Town & Country",
  },
  {
    year: 2012,
    make: "Dodge",
    models: "Attitude,Journey,Nitro,Caliber,Challenger,Charger,Durango,Grand Caravan,Avenger,i10,Atos,Dakota",
  },
  {
    year: 2012,
    make: "Ferrari",
    models: "FF,458 Spider,California,458 Italia",
  },
  {
    year: 2012,
    make: "Fiat",
    models: "500,Panda,Ducato,Strada",
  },
  {
    year: 2012,
    make: "Ford",
    models:
      "Escape,F-250 Super Duty,F-350,Mustang,Transit,Courier,Fusion,Lobo,E-150,F-350 Super Duty,Focus,Taurus,E-250,Flex,Fiesta Ikon,EcoSport,F-150,Econoline Van,Ranger,Transit Connect,Expedition,Explorer,Econoline Wagon,Edge,Fiesta",
  },
  {
    year: 2012,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2012,
    make: "GMC",
    models:
      "Savana 1500,Canyon,Acadia,Yukon XL 1500,Sierra 2500 HD,Yukon XL 2500,Sierra,Terrain,Sierra 1500,Yukon,Savana 2500",
  },
  {
    year: 2012,
    make: "Honda",
    models: "Odyssey,City,Accord,CR-V,Crosstour,Ridgeline,Fit,CR-Z,Insight,Pilot,Civic",
  },
  {
    year: 2012,
    make: "Hyundai",
    models: "Equus,Elantra,Azera,Genesis Coupe,Santa Fe,Tucson,Sonata,Veloster,Genesis,Accent,Veracruz",
  },
  {
    year: 2012,
    make: "Infiniti",
    models: "EX35,FX35,M56,G25,QX56,M37,FX50,M35h,G37",
  },
  {
    year: 2012,
    make: "Jaguar",
    models: "XF,XFR,XJ,XK,XKR,XKR-S",
  },
  {
    year: 2012,
    make: "Jeep",
    models: "Compass,Wrangler,Patriot,Grand Cherokee,Liberty",
  },
  {
    year: 2012,
    make: "Kia",
    models: "Sorento,Soul,Optima,Forte Koup,Sedona,Forte5,Sportage,Forte,Rio,Rondo",
  },
  {
    year: 2012,
    make: "Lamborghini",
    models: "Aventador,Gallardo",
  },
  {
    year: 2012,
    make: "Land Rover",
    models: "Range Rover Sport,Range Rover Evoque,LR2,Range Rover,Defender,LR4",
  },
  {
    year: 2012,
    make: "Lexus",
    models: "IS350,GX460,RX450h,CT200h,LS460,IS F,ES350,RX350,LS600h,HS250h,IS250,LFA",
  },
  {
    year: 2012,
    make: "Lincoln",
    models: "Navigator,MKS,MKT,MKX,Mark LT,MKZ",
  },
  {
    year: 2012,
    make: "Lotus",
    models: "Evora",
  },
  {
    year: 2012,
    make: "Maserati",
    models: "GranTurismo,Quattroporte",
  },
  {
    year: 2012,
    make: "Maybach",
    models: "57,62",
  },
  {
    year: 2012,
    make: "Mazda",
    models: "CX-7,5,3,CX-9,6,MX-5 Miata,3 Sport,MX-5,2",
  },
  {
    year: 2012,
    make: "McLaren",
    models: "MP4-12C",
  },
  {
    year: 2012,
    make: "Mercedes-Benz",
    models:
      "SL63 AMG,CLS500,S65 AMG,Sprinter 2500,ML63 AMG,CLS550,E500,CL65 AMG,G550,SL600,Sprinter,C180,C250,G500,SLK55 AMG,E350,E200,C63 AMG,ML500,S350,S400,CLS350,CL63 AMG,C300,ML350,SL500,CL500,CL550,CLS63 AMG,E63 AMG,GL450,ML550,GL550,E550,R350,S600,SLK250,C200,SLK200,Vito,SL550,GL350,SLS AMG,SLK350,GLK350,E250,B180,S63 AMG,C350,SL65 AMG,CL600,S550,GLK300,E300,GL500",
  },
  {
    year: 2012,
    make: "Mini",
    models: "Cooper Countryman,Cooper",
  },
  {
    year: 2012,
    make: "Mitsubishi",
    models: "Lancer,i-MiEV,RVR,Outlander Sport,Montero,Montero Sport,Galant,L200,Eclipse,Outlander",
  },
  {
    year: 2012,
    make: "Nissan",
    models:
      "Armada,Frontier,Tsuru,Quest,NV2500,Murano,Xterra,GT-R,Pathfinder,NP300,X-Trail,Altima,Titan,March,Rogue,Maxima,Juke,Versa,Leaf,NV1500,370Z,Cube,Urvan,Tiida,Sentra",
  },
  {
    year: 2012,
    make: "Peugeot",
    models: "RCZ,207,3008,508,308,Partner,Grand Raid,Manager",
  },
  {
    year: 2012,
    make: "Porsche",
    models: "Panamera,Cayman,911,Boxster,Cayenne",
  },
  {
    year: 2012,
    make: "Ram",
    models: "2500,C/V,1500,3500",
  },
  {
    year: 2012,
    make: "Rolls-Royce",
    models: "Ghost,Phantom",
  },
  {
    year: 2012,
    make: "Scion",
    models: "xB,xD,tC,iQ",
  },
  {
    year: 2012,
    make: "Smart",
    models: "Fortwo",
  },
  {
    year: 2012,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2012,
    make: "Subaru",
    models: "Forester,Outback,Tribeca,Legacy,Impreza,WRX",
  },
  {
    year: 2012,
    make: "Suzuki",
    models: "Grand Vitara,Swift,Equator,Kizashi,SX4,SX4 Crossover",
  },
  {
    year: 2012,
    make: "Toyota",
    models:
      "FJ Cruiser,Tacoma,Highlander,Corolla,Prius,Tundra,Hilux,Prius Plug-In,RAV4,Sequoia,4Runner,Matrix,Avalon,Camry,Prius V,Prius C,Sienna,Avanza,Hiace,Venza,Yaris",
  },
  {
    year: 2012,
    make: "Volkswagen",
    models:
      "GTI,CC,Golf,Passat,Eos,Crafter Van,Gol Sedan,Passat CC,Beetle,Transporter,Touareg,Jetta,CrossFox,Tiguan,Amarok,Golf SportWagen,Saveiro,Golf R,Routan,Clasico,Gol",
  },
  {
    year: 2012,
    make: "Volvo",
    models: "XC60,C70,XC70,S60,S40,S80,XC90,C30",
  },
  {
    year: 2012,
    make: "VPG",
    models: "MV-1",
  },
  {
    year: 2011,
    make: "Acura",
    models: "ZDX,RL,TSX,RDX,CSX,MDX,TL",
  },
  {
    year: 2011,
    make: "Aston Martin",
    models: "V8 Vantage,V12 Vantage,DB9,Rapide,One-77,DBS",
  },
  {
    year: 2011,
    make: "Audi",
    models:
      "A4 Quattro,TT Quattro,A5 Quattro,A1,A3,A3 Quattro,R8,A6,S3,S4,A8 Quattro,RS5,Q5,S6,A4,TTS Quattro,TT,A5,A6 Quattro,S5,Q7,TT RS Quattro",
  },
  {
    year: 2011,
    make: "Bentley",
    models: "Continental,Mulsanne",
  },
  {
    year: 2011,
    make: "BMW",
    models:
      "335d,X3,X6,535i xDrive,1 Series M,550i GT,328i,550i xDrive,740i,135i,120i,325i,335i,535i GT xDrive,535i,M6,X1,550i,328i xDrive,335i xDrive,Alpina B7,323i,760Li,128i,Z4,750i,650Ci,750i xDrive,M3,X5,125i,750Li xDrive,535i GT,Alpina B7L,740Li,Alpina B7 xDrive,528i,550i GT xDrive,750Li,335is,Alpina B7L xDrive",
  },
  {
    year: 2011,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2011,
    make: "Buick",
    models: "Regal,Enclave,LaCrosse,Lucerne",
  },
  {
    year: 2011,
    make: "Cadillac",
    models: "DTS,Escalade ESV,Escalade EXT,CTS,Escalade,SRX,STS",
  },
  {
    year: 2011,
    make: "Chevrolet",
    models:
      "Tornado,Suburban 1500,Express 2500,Express 3500,Silverado 3500 HD,Cruze,Colorado,Equinox,Aveo5,Avalanche,Silverado 1500,Traverse,Chevy,Tahoe,Impala,Captiva Sport,Express 1500,Suburban 2500,Volt,Corvette,Aveo,Silverado 2500 HD,Matiz,Caprice,Camaro,Silverado 2500,Malibu,Spark,Cheyenne,HHR",
  },
  {
    year: 2011,
    make: "Chrysler",
    models: "200,300,Town & Country",
  },
  {
    year: 2011,
    make: "Dodge",
    models: "Durango,Grand Caravan,Avenger,Atos,Attitude,Dakota,Journey,Nitro,Caliber,Challenger,Charger",
  },
  {
    year: 2011,
    make: "Ferrari",
    models: "FF,California,599 GTO,458 Italia,599 GTB,612 Scaglietti",
  },
  {
    year: 2011,
    make: "Fiat",
    models: "Strada,Palio,Albea,Panda,Linea,500,Ducato",
  },
  {
    year: 2011,
    make: "Ford",
    models:
      "E-250,Flex,Fiesta Ikon,EcoSport,F-150,Econoline Van,Ranger,Transit Connect,Expedition,Explorer,Econoline Wagon,Edge,Escape,F-250 Super Duty,F-350,Mustang,Transit,Courier,Fiesta,Fusion,Lobo,Crown Victoria,E-150,F-350 Super Duty,Focus,Taurus",
  },
  {
    year: 2011,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2011,
    make: "GMC",
    models:
      "Sierra,Terrain,Sierra 1500,Yukon,Savana 2500,Savana 1500,Canyon,Acadia,Yukon XL 1500,Sierra 2500 HD,Yukon XL 2500",
  },
  {
    year: 2011,
    make: "Honda",
    models: "Pilot,Civic,Odyssey,City,Accord,CR-V,Ridgeline,Fit,CR-Z,Insight,Element,Accord Crosstour",
  },
  {
    year: 2011,
    make: "Hyundai",
    models: "Sonata,Genesis,Accent,Veracruz,Equus,Elantra,Azera,Genesis Coupe,Santa Fe,Tucson",
  },
  {
    year: 2011,
    make: "Infiniti",
    models: "G37,EX35,FX35,M56,G25,QX56,M37,FX50",
  },
  {
    year: 2011,
    make: "Jaguar",
    models: "XKR,XF,XFR,XJ,XK",
  },
  {
    year: 2011,
    make: "Jeep",
    models: "Grand Cherokee,Liberty,Compass,Wrangler,Patriot",
  },
  {
    year: 2011,
    make: "Kia",
    models: "Borrego,Rio5,Rio,Sorento,Soul,Rondo,Optima,Forte Koup,Sedona,Sportage,Forte",
  },
  {
    year: 2011,
    make: "Lamborghini",
    models: "Gallardo",
  },
  {
    year: 2011,
    make: "Land Rover",
    models: "LR4,Range Rover Sport,LR2,Range Rover,Defender",
  },
  {
    year: 2011,
    make: "Lexus",
    models: "RX350,LS600h,HS250h,GS450h,IS250,IS350,GX460,GS350,RX450h,CT200h,LS460,IS F,ES350,LX570,GS460",
  },
  {
    year: 2011,
    make: "Lincoln",
    models: "MKX,Mark LT,Town Car,MKZ,Navigator,MKS,MKT",
  },
  {
    year: 2011,
    make: "Lotus",
    models: "Evora,Elise,Exige",
  },
  {
    year: 2011,
    make: "Maserati",
    models: "GranCabrio,GranTurismo,Quattroporte",
  },
  {
    year: 2011,
    make: "Maybach",
    models: "62,57",
  },
  {
    year: 2011,
    make: "Mazda",
    models: "MX-5 Miata,RX-8,MX-5,2,Tribute,3 Sport,CX-7,3,CX-9,6",
  },
  {
    year: 2011,
    make: "Mercedes-Benz",
    models:
      "R350,ML500,S600,C200,SLK200,Vito,GL350,SLS AMG,SL65 AMG,SLK350,GLK350,S450,CLS500,E250,S63 AMG,C350,B200,CL600,S550,GLK300,GL500,S65 AMG,SL550,Sprinter 2500,ML63 AMG,CLS550,CL65 AMG,G550,SL600,Sprinter,C180,C250,G55 AMG,SLK55 AMG,S350,S500,E350,C63 AMG,E500,SL63 AMG,G500,S400,CLS350,E300,ML450,CL63 AMG,C300,SLK300,ML350,SL500,CL500,CL550,CLS63 AMG,E63 AMG,GL450,ML550,GL550,E550",
  },
  {
    year: 2011,
    make: "Mercury",
    models: "Grand Marquis,Mariner,Milan",
  },
  {
    year: 2011,
    make: "Mini",
    models: "Cooper Countryman,Cooper",
  },
  {
    year: 2011,
    make: "Mitsubishi",
    models: "L200,Eclipse,Endeavor,Outlander,Lancer,RVR,Outlander Sport,Montero,Montero Sport,Galant",
  },
  {
    year: 2011,
    make: "Nissan",
    models:
      "Maxima,Juke,Versa,Leaf,370Z,Cube,Urvan,Tiida,Sentra,Armada,Frontier,Tsuru,Quest,Murano,Xterra,GT-R,Pathfinder,NP300,X-Trail,Altima,Titan,Rogue",
  },
  {
    year: 2011,
    make: "Peugeot",
    models: "Manager,Grand Raid,RCZ,207,3008,308,Partner,207 Compact",
  },
  {
    year: 2011,
    make: "Porsche",
    models: "Cayman,911,Boxster,Cayenne,Panamera",
  },
  {
    year: 2011,
    make: "Ram",
    models: "1500,3500,Dakota,2500",
  },
  {
    year: 2011,
    make: "Rolls-Royce",
    models: "Ghost,Phantom",
  },
  {
    year: 2011,
    make: "Saab",
    models: "9-5,9-3X,9-4X,9-3",
  },
  {
    year: 2011,
    make: "Scion",
    models: "xD,tC,xB",
  },
  {
    year: 2011,
    make: "Smart",
    models: "Fortwo",
  },
  {
    year: 2011,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2011,
    make: "Subaru",
    models: "Impreza,Forester,Outback,Tribeca,Legacy",
  },
  {
    year: 2011,
    make: "Suzuki",
    models: "Kizashi,SX4,Grand Vitara,Swift,Equator",
  },
  {
    year: 2011,
    make: "Think",
    models: "City",
  },
  {
    year: 2011,
    make: "Toyota",
    models:
      "Land Cruiser,Sienna,Hiace,Venza,Yaris,FJ Cruiser,Tacoma,Highlander,Avanza,Corolla,Prius,Tundra,Hilux,RAV4,Sequoia,4Runner,Matrix,Avalon,Camry",
  },
  {
    year: 2011,
    make: "Volkswagen",
    models:
      "Amarok,Golf SportWagen,Saveiro,Routan,Clasico,Gol,GTI,CC,Golf,Eos,Crafter Van,Gol Sedan,Passat CC,Transporter,Touareg,Jetta,Beetle Cabrio,CrossFox,Tiguan,Beetle",
  },
  {
    year: 2011,
    make: "Volvo",
    models: "V50,XC90,XC60,C70,XC70,C30,S60,S40,S80",
  },
  {
    year: 2011,
    make: "VPG",
    models: "MV-1",
  },
  {
    year: 2010,
    make: "Acura",
    models: "RDX,CSX,MDX,TL,ZDX,RL,TSX",
  },
  {
    year: 2010,
    make: "Aston Martin",
    models: "Rapide,DBS,V8 Vantage,V12 Vantage,DB9",
  },
  {
    year: 2010,
    make: "Audi",
    models:
      "TT,A5,A6 Quattro,S5,Q7,A4 Quattro,TT Quattro,TTS Quattro,A5 Quattro,A3,A3 Quattro,R8,A6,RS6,S3,S4,A8 Quattro,S8,Q5,S6,A4",
  },
  {
    year: 2010,
    make: "Bentley",
    models: "Azure,Continental",
  },
  {
    year: 2010,
    make: "BMW",
    models:
      "650i,750i,650Ci,750i xDrive,M3,X5,528i xDrive,750Li xDrive,525i,740i,M6,750Li,335d,535i GT,530i,550i GT xDrive,X3,X6,535i xDrive,528i,M5,550i GT,328i,135i,120i,325i,335i,535i,X1,550i,328i xDrive,335i xDrive,130i,125i,323i,760Li,128i,Z4",
  },
  {
    year: 2010,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2010,
    make: "Buick",
    models: "Allure,Lucerne,Enclave,LaCrosse",
  },
  {
    year: 2010,
    make: "Cadillac",
    models: "STS,DTS,Escalade ESV,Escalade EXT,CTS,Escalade,SRX",
  },
  {
    year: 2010,
    make: "Chevrolet",
    models:
      "Camaro,Malibu,Optra,Cheyenne,HHR,Tornado,Suburban 1500,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,Aveo5,Silverado 2500,Avalanche,Silverado 1500,Cobalt,Traverse,Chevy,Tahoe,Impala,Captiva Sport,Express 1500,Suburban 2500,Corvette,Aveo,Silverado 2500 HD,Cruze",
  },
  {
    year: 2010,
    make: "Chrysler",
    models: "Cirrus,Sebring,PT Cruiser,300,Town & Country",
  },
  {
    year: 2010,
    make: "Dodge",
    models:
      "Caliber,Challenger,Charger,Ram 1500,Grand Caravan,Avenger,Atos,Viper,Ram 2500,Dakota,H100,Attitude,Journey,Nitro",
  },
  {
    year: 2010,
    make: "Ferrari",
    models: "612 Scaglietti,California,458 Italia,599 GTB",
  },
  {
    year: 2010,
    make: "Fiat",
    models: "Strada,500,Palio,Albea",
  },
  {
    year: 2010,
    make: "Ford",
    models:
      "Transit,Courier,Fusion,Lobo,Crown Victoria,E-150,F-350 Super Duty,Focus,Taurus,Explorer Sport Trac,Fiesta,E-250,Flex,EcoSport,F-150,Econoline Van,Ranger,Transit Connect,Expedition,Explorer,Econoline Wagon,Edge,Escape,F-250 Super Duty,Mustang",
  },
  {
    year: 2010,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2010,
    make: "GMC",
    models:
      "Sierra 2500 HD,Yukon XL 2500,Sierra,Terrain,Sierra 1500,Yukon,Savana 2500,Savana 1500,Canyon,Acadia,Yukon XL 1500",
  },
  {
    year: 2010,
    make: "Honda",
    models: "Insight,Element,Accord Crosstour,Pilot,Civic,Odyssey,City,Accord,CR-V,Ridgeline,Fit",
  },
  {
    year: 2010,
    make: "Hummer",
    models: "H3T,H3",
  },
  {
    year: 2010,
    make: "Hyundai",
    models: "Santa Fe,Tucson,Sonata,Genesis,Accent,Veracruz,Elantra,Azera,Genesis Coupe,Entourage",
  },
  {
    year: 2010,
    make: "Infiniti",
    models: "FX35,QX56,M45,FX50,G37,M35,EX35",
  },
  {
    year: 2010,
    make: "Jaguar",
    models: "XK,XKR,XJ,XF,XFR",
  },
  {
    year: 2010,
    make: "Jeep",
    models: "Wrangler,Patriot,Grand Cherokee,Liberty,Commander,Compass",
  },
  {
    year: 2010,
    make: "Kia",
    models: "Optima,Forte Koup,Sedona,Sportage,Forte,Rondo,Borrego,Rio5,Rio,Soul,Magentis",
  },
  {
    year: 2010,
    make: "Lamborghini",
    models: "Gallardo,Murcielago",
  },
  {
    year: 2010,
    make: "Land Rover",
    models: "LR2,Range Rover,LR4,Range Rover Sport",
  },
  {
    year: 2010,
    make: "Lexus",
    models: "RX450h,LS460,IS F,ES350,LX570,GS460,RX350,LS600h,HS250h,SC430,GS450h,IS250,IS350,GX460,GS350",
  },
  {
    year: 2010,
    make: "Lincoln",
    models: "Navigator,MKS,MKT,MKX,Mark LT,Town Car,MKZ",
  },
  {
    year: 2010,
    make: "Lotus",
    models: "Exige,Evora,Elise",
  },
  {
    year: 2010,
    make: "Maserati",
    models: "Quattroporte,GranCabrio,GranTurismo",
  },
  {
    year: 2010,
    make: "Maybach",
    models: "57,62",
  },
  {
    year: 2010,
    make: "Mazda",
    models: "3 Sport,6,MX-5 Miata,RX-8,5,MX-5,Tribute,CX-7,B2300,B4000,3,CX-9",
  },
  {
    year: 2010,
    make: "Mercedes-Benz",
    models:
      "G55 AMG,SLK55 AMG,S500,E350,SL550,C63 AMG,G500,S400,S65 AMG,CLS350,ML450,CL63 AMG,C300,SLK300,ML350,SL500,CL500,CL550,CLS63 AMG,E63 AMG,GL450,ML550,GL550,E550,E500,SL63 AMG,R350,C200,SLK200,Vito,GL350,SLK350,S450,CLS500,S63 AMG,C350,ML500,B200,CL600,S550,GLK300,E300,GL500,SL65 AMG,S600,ML63 AMG,CLS550,CL65 AMG,GLK350,G550,SL600,Sprinter,Sprinter 2500,C250",
  },
  {
    year: 2010,
    make: "Mercury",
    models: "Milan,Grand Marquis,Mountaineer,Mariner",
  },
  {
    year: 2010,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2010,
    make: "Mitsubishi",
    models: "Montero Sport,Galant,L200,Eclipse,Endeavor,Outlander,Lancer,Montero",
  },
  {
    year: 2010,
    make: "Nissan",
    models:
      "Pathfinder,NP300,X-Trail,Altima,Titan,Rogue,Maxima,Pickup,Versa,370Z,Cube,Urvan,Tiida,Sentra,Armada,Frontier,Tsuru,Murano,Platina,Aprio,Xterra,GT-R",
  },
  {
    year: 2010,
    make: "Peugeot",
    models: "207,308,Grand Raid,307,Partner,207 Compact,Manager",
  },
  {
    year: 2010,
    make: "Pontiac",
    models: "G3,G6,G5,Vibe,Matiz",
  },
  {
    year: 2010,
    make: "Porsche",
    models: "Cayman,911,Boxster,Cayenne,Panamera",
  },
  {
    year: 2010,
    make: "Ram",
    models: "2500",
  },
  {
    year: 2010,
    make: "Rolls-Royce",
    models: "Ghost,Phantom",
  },
  {
    year: 2010,
    make: "Saab",
    models: "9-3,9-5,9-3X",
  },
  {
    year: 2010,
    make: "Saturn",
    models: "Vue,Sky,Outlook",
  },
  {
    year: 2010,
    make: "Scion",
    models: "xB,xD,tC",
  },
  {
    year: 2010,
    make: "Smart",
    models: "Fortwo",
  },
  {
    year: 2010,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2010,
    make: "Subaru",
    models: "Legacy,Impreza,Forester,Outback,Tribeca",
  },
  {
    year: 2010,
    make: "Suzuki",
    models: "Grand Vitara,Swift,Equator,Kizashi,Swift+,SX4",
  },
  {
    year: 2010,
    make: "Toyota",
    models:
      "Avanza,RAV4,Sequoia,4Runner,Matrix,Avalon,Camry,Land Cruiser,Sienna,Hiace,Venza,Yaris,FJ Cruiser,Tacoma,Highlander,Corolla,Prius,Tundra,Hilux",
  },
  {
    year: 2010,
    make: "Volkswagen",
    models:
      "Gol Sedan,Passat CC,Transporter,Touareg,Jetta,Golf City,Beetle Cabrio,Tiguan,GLI,Golf SportWagen,Saveiro,Routan,Passat,Gol,Beetle,SportVan,Bora,GTI,CC,Golf,Eos,Crafter Van",
  },
  {
    year: 2010,
    make: "Volvo",
    models: "C30,V70,C70,S80,V50,S40,XC90,XC60,XC70",
  },
  {
    year: 2009,
    make: "Acura",
    models: "RDX,CSX,MDX,TL,RL,TSX",
  },
  {
    year: 2009,
    make: "Aston Martin",
    models: "DB9,V8 Vantage,DBS",
  },
  {
    year: 2009,
    make: "Audi",
    models:
      "S3,S6,S4,A8 Quattro,TT Quattro,S8,TT,Q5,A4,A6,A5,A6 Quattro,TTS Quattro,S5,Q7,A4 Quattro,A5 Quattro,A3,A3 Quattro,R8",
  },
  {
    year: 2009,
    make: "Bentley",
    models: "Brooklands,Azure,Continental,Arnage",
  },
  {
    year: 2009,
    make: "BMW",
    models:
      "323i,328i,135i,120i,325i,335i,535i,550i,328i xDrive,335i xDrive,130i,125i,760Li,128i,Z4,650i,750i,650Ci,M3,X5,528i xDrive,525i,M6,750Li,335d,530i,X3,X6,535i xDrive,528i,M5",
  },
  {
    year: 2009,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2009,
    make: "Buick",
    models: "LaCrosse,Allure,Lucerne,Enclave",
  },
  {
    year: 2009,
    make: "Cadillac",
    models: "CTS,XLR,Escalade,SRX,STS,DTS,Escalade ESV,Escalade EXT",
  },
  {
    year: 2009,
    make: "Chevrolet",
    models:
      "Traverse,Chevy,Tahoe,Impala,Silverado 2500,Epica,Express 1500,Suburban 2500,Cheyenne,Corvette,Aveo,Silverado 2500 HD,Captiva Sport,Malibu,Aveo5,Optra,HHR,Suburban 1500,Uplander,Tornado,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,Trailblazer,Avalanche,Silverado 1500,Cobalt",
  },
  {
    year: 2009,
    make: "Chrysler",
    models: "PT Cruiser,300,Town & Country,Cirrus,Aspen,Sebring",
  },
  {
    year: 2009,
    make: "Dodge",
    models:
      "H100,Attitude,Journey,Nitro,Durango,Caliber,Challenger,Charger,Ram 1500,Grand Caravan,Avenger,Atos,Van 1000,Viper,Ram 2500,Dakota,Sprinter 2500",
  },
  {
    year: 2009,
    make: "Ferrari",
    models: "F430,599 GTB,612 Scaglietti,California",
  },
  {
    year: 2009,
    make: "Fiat",
    models: "Panda,Albea,Bravo,Palio,Ducato,Idea,Punto,Strada,500",
  },
  {
    year: 2009,
    make: "Ford",
    models:
      "Expedition,Explorer,Edge,Fiesta,Escape,F-250 Super Duty,Mustang,Transit,Courier,Taurus X,F-250,Fusion,Crown Victoria,E-150,F-350 Super Duty,Focus,Taurus,Explorer Sport Trac,E-250,Flex,EcoSport,F-150,Lobo,F-350,Ranger",
  },
  {
    year: 2009,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2009,
    make: "GMC",
    models:
      "Savana 2500,Savana 1500,Canyon,Acadia,Yukon XL 1500,Envoy,Sierra 2500 HD,Yukon XL 2500,Sierra,Sierra 1500,Yukon",
  },
  {
    year: 2009,
    make: "Honda",
    models: "S2000,Accord,CR-V,Ridgeline,Element,Fit,Pilot,Civic,Odyssey",
  },
  {
    year: 2009,
    make: "Hummer",
    models: "H3T,H2,H3",
  },
  {
    year: 2009,
    make: "Hyundai",
    models: "Elantra,Azera,Entourage,Santa Fe,Tucson,Sonata,Genesis,Accent,Veracruz",
  },
  {
    year: 2009,
    make: "Infiniti",
    models: "G37,M35,EX35,FX35,QX56,M45,FX50",
  },
  {
    year: 2009,
    make: "Jaguar",
    models: "XF,Vanden Plas,XJ8,XK,Super V8,XJR,XKR",
  },
  {
    year: 2009,
    make: "Jeep",
    models: "Compass,Wrangler,Patriot,Grand Cherokee,Liberty,Commander",
  },
  {
    year: 2009,
    make: "Kia",
    models: "Rio,Magentis,Optima,Sedona,Spectra,Spectra5,Sportage,Rondo,Sorento,Amanti,Borrego,Rio5",
  },
  {
    year: 2009,
    make: "Lamborghini",
    models: "Murcielago,Gallardo",
  },
  {
    year: 2009,
    make: "Land Rover",
    models: "Range Rover Sport,LR2,Range Rover,LR3",
  },
  {
    year: 2009,
    make: "Lexus",
    models: "IS250,GX470,IS350,GS350,LS460,IS F,ES350,LX570,GS460,RX350,LS600h,SC430,GS450h",
  },
  {
    year: 2009,
    make: "Lincoln",
    models: "Town Car,MKZ,Navigator,MKS,MKX",
  },
  {
    year: 2009,
    make: "Lotus",
    models: "Elise,Exige",
  },
  {
    year: 2009,
    make: "Maserati",
    models: "GranTurismo,Quattroporte",
  },
  {
    year: 2009,
    make: "Maybach",
    models: "57,62",
  },
  {
    year: 2009,
    make: "Mazda",
    models: "Tribute,CX-7,B2300,B4000,3,CX-9,MX-5,3 Sport,6,MX-5 Miata,RX-8,5",
  },
  {
    year: 2009,
    make: "Mercedes-Benz",
    models:
      "E500,C350,B200,SLR McLaren,S550,GL500,SL63 AMG,S600,ML63 AMG,CLS550,CLK350,CL65 AMG,ML320,G550,Vito,CLK550,G55 AMG,SLK55 AMG,S500,C230,E280,E350,CL600,Sprinter,E300,C63 AMG,GLK280,E320,G500,SL65 AMG,GLK350,S65 AMG,CLS350,CL63 AMG,C300,SLK300,ML350,SL500,SL600,C280,CL500,CLK280,CL550,GL320,CLS63 AMG,E63 AMG,GL450,ML550,GL550,E550,R350,CLS500,SLK200,SL550,CLK63 AMG,SLK350,ML500,S450,CLK500,S63 AMG,R320",
  },
  {
    year: 2009,
    make: "Mercury",
    models: "Mountaineer,Mariner,Sable,Milan,Grand Marquis",
  },
  {
    year: 2009,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2009,
    make: "Mitsubishi",
    models: "Outlander,Lancer,Montero,Grandis,Raider,Galant,Montero Sport,Endeavor,L200,Eclipse",
  },
  {
    year: 2009,
    make: "Nissan",
    models:
      "370Z,Cube,Tiida,Sentra,Armada,Frontier,Tsuru,Urvan,Platina,Xterra,GT-R,Pathfinder,NP300,X-Trail,Altima,Aprio,Titan,Murano,Rogue,Maxima,Pickup,Cabstar E,Quest,350Z,Versa",
  },
  {
    year: 2009,
    make: "Peugeot",
    models: "Manager,407,308,Grand Raid,307,Partner,207 Compact,Expert,206",
  },
  {
    year: 2009,
    make: "Pontiac",
    models: "G3 Wave,Vibe,G8,Solstice,G3,Torrent,G6,G5,Montana,Matiz",
  },
  {
    year: 2009,
    make: "Porsche",
    models: "Cayenne,Cayman,911,Boxster",
  },
  {
    year: 2009,
    make: "Rolls-Royce",
    models: "Phantom",
  },
  {
    year: 2009,
    make: "Saab",
    models: "9-7x,9-3,9-5",
  },
  {
    year: 2009,
    make: "Saturn",
    models: "Vue,Sky,Outlook,Astra,Aura",
  },
  {
    year: 2009,
    make: "Scion",
    models: "xB,xD,tC",
  },
  {
    year: 2009,
    make: "Smart",
    models: "Fortwo",
  },
  {
    year: 2009,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2009,
    make: "Subaru",
    models: "Forester,Outback,Tribeca,Legacy,Impreza",
  },
  {
    year: 2009,
    make: "Suzuki",
    models: "XL-7,Grand Vitara,Swift,Equator,Swift+,SX4",
  },
  {
    year: 2009,
    make: "Toyota",
    models:
      "Venza,FJ Cruiser,Tacoma,Highlander,Corolla,Prius,Tundra,Hilux,RAV4,Sequoia,4Runner,Avanza,Matrix,Avalon,Camry,Rush,Yaris,Land Cruiser,Sienna,Hiace",
  },
  {
    year: 2009,
    make: "Volkswagen",
    models:
      "Gol,Beetle,Derby,SportVan,Bora,CC,CrossFox,Lupo,Rabbit,Eos,Passat CC,Touareg,Jetta,Golf City,Crafter Van,EuroVan,Tiguan,Van,GTI,Routan,Jetta City,Pointer,Passat",
  },
  {
    year: 2009,
    make: "Volvo",
    models: "S60,XC70,C30,V70,C70,S80,V50,S40,XC90",
  },
  {
    year: 2008,
    make: "Acura",
    models: "TL,CSX,RL,TSX,RDX,MDX",
  },
  {
    year: 2008,
    make: "Alfa Romeo",
    models: "GT,8C,Brera,159,Spider",
  },
  {
    year: 2008,
    make: "Aston Martin",
    models: "V8 Vantage,DBS,DB9",
  },
  {
    year: 2008,
    make: "Audi",
    models: "Q7,A4 Quattro,A5 Quattro,A3,A3 Quattro,R8,S3,S6,S4,A8 Quattro,TT Quattro,S8,RS4,TT,A4,A6,A5,A6 Quattro,S5",
  },
  {
    year: 2008,
    make: "Bentley",
    models: "Continental,Arnage,Brooklands,Azure",
  },
  {
    year: 2008,
    make: "BMW",
    models:
      "750Li,535xi,530i,X3,X6,528i,328xi,M5,125i,323i,328i,135i,120i,325i,528xi,335i,535i,550i,130i,760Li,128i,Z4,650i,750i,650Ci,M3,X5,Alpina B7,525i,335xi,M6",
  },
  {
    year: 2008,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2008,
    make: "Buick",
    models: "Lucerne,Enclave,LaCrosse,Allure",
  },
  {
    year: 2008,
    make: "Cadillac",
    models: "DTS,Escalade ESV,Escalade EXT,CTS,XLR,Escalade,SRX,BLS,STS",
  },
  {
    year: 2008,
    make: "Chevrolet",
    models:
      "Meriva,Optra,HHR,Suburban 1500,Uplander,Captiva Sport,Tornado,Express 2500,Express 3500,Silverado 3500 HD,Colorado,Equinox,Trailblazer,Avalanche,Silverado 1500,Cobalt,Corsa,Chevy,Tahoe,Impala,Silverado 2500,Express 1500,Suburban 2500,Tracker,Cheyenne,Corvette,Aveo,Silverado 2500 HD,Aveo5,Vectra,Malibu,Astra",
  },
  {
    year: 2008,
    make: "Chrysler",
    models: "Sebring,Voyager,PT Cruiser,Crossfire,Pacifica,300,Town & Country,Cirrus,Aspen",
  },
  {
    year: 2008,
    make: "Dodge",
    models:
      "Ram 1500,Grand Caravan,Avenger,Atos,Van 1000,Viper,Ram 2500,Dakota,Sprinter 2500,H100,Attitude,Nitro,Durango,Magnum,Caliber,Challenger,Charger",
  },
  {
    year: 2008,
    make: "Ferrari",
    models: "612 Scaglietti,360,F430,599 GTB",
  },
  {
    year: 2008,
    make: "Fiat",
    models: "Palio,Panda,Ducato,Punto,Idea,Strada",
  },
  {
    year: 2008,
    make: "Ford",
    models:
      "Explorer Sport Trac,E-250,E-150 Econoline,F-150,F-350,Ranger,Expedition,Explorer,Edge,Fiesta,Ka,Lobo,Escape,F-250 Super Duty,Mustang,EcoSport,Transit,Courier,Taurus X,F-250,Fusion,Crown Victoria,E-150,F-350 Super Duty,Focus,Taurus",
  },
  {
    year: 2008,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2008,
    make: "GMC",
    models:
      "Yukon XL 2500,Sierra,Sierra 1500,Yukon,Savana 2500,Savana 1500,Canyon,Acadia,Yukon XL 1500,Envoy,Sierra 2500 HD",
  },
  {
    year: 2008,
    make: "Honda",
    models: "Fit,Pilot,Civic,Odyssey,S2000,Accord,CR-V,Ridgeline,Element",
  },
  {
    year: 2008,
    make: "Hummer",
    models: "H2,H3",
  },
  {
    year: 2008,
    make: "Hyundai",
    models: "Tucson,Sonata,Accent,Veracruz,Elantra,Azera,Entourage,Tiburon,Santa Fe",
  },
  {
    year: 2008,
    make: "Infiniti",
    models: "M45,FX45,G35,G37,M35,EX35,FX35,QX56",
  },
  {
    year: 2008,
    make: "Isuzu",
    models: "i-290,Ascender,i-370",
  },
  {
    year: 2008,
    make: "Jaguar",
    models: "X-Type,Super V8,S-Type,XJR,XKR,Vanden Plas,XJ8,XK",
  },
  {
    year: 2008,
    make: "Jeep",
    models: "Patriot,Grand Cherokee,Liberty,Commander,Compass,Wrangler",
  },
  {
    year: 2008,
    make: "Kia",
    models: "Sportage,Rondo,Sorento,Amanti,Rio5,Rio,Spectra5,Magentis,Optima,Sedona,Spectra",
  },
  {
    year: 2008,
    make: "Lamborghini",
    models: "Murcielago,Gallardo",
  },
  {
    year: 2008,
    make: "Land Rover",
    models: "Range Rover,LR3,Range Rover Sport,LR2",
  },
  {
    year: 2008,
    make: "Lexus",
    models: "GS460,RX350,LS600h,SC430,GS450h,IS250,GX470,IS350,GS350,LS460,IS F,RX400h,ES350,LX570",
  },
  {
    year: 2008,
    make: "Lincoln",
    models: "Mark LT,MKX,Town Car,MKZ,Navigator",
  },
  {
    year: 2008,
    make: "Lotus",
    models: "Elise,Exige",
  },
  {
    year: 2008,
    make: "Maserati",
    models: "Quattroporte,GranSport,GranTurismo",
  },
  {
    year: 2008,
    make: "Maybach",
    models: "57,62",
  },
  {
    year: 2008,
    make: "Mazda",
    models: "6,MX-5 Miata,RX-8,B3000,5,Tribute,CX-7,B2300,B4000,3,CX-9,MX-5",
  },
  {
    year: 2008,
    make: "Mercedes-Benz",
    models:
      "G500,SLK280,ML350,SL500,SL600,C280,CL500,CLK280,E300,CL550,GL320,CLS63 AMG,E63 AMG,GL450,ML550,GL550,E550,R350,R500,CLS500,SLK200,SL550,CLK63 AMG,SLK350,ML500,Sprinter,CLK500,GL500,S63 AMG,R550,R320,E500,C350,B200,SLR McLaren,S550,S600,ML63 AMG,CLS550,CLK350,CL65 AMG,ML320,SL55 AMG,C230,CLK550,G55 AMG,SLK55 AMG,S500,S450,E280,E350,CL600,C63 AMG,E320,SL65 AMG,S65 AMG,CLS350,CL63 AMG,C300",
  },
  {
    year: 2008,
    make: "Mercury",
    models: "Mountaineer,Mariner,Sable,Milan,Grand Marquis",
  },
  {
    year: 2008,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2008,
    make: "Mitsubishi",
    models: "Lancer,Raider,Galant,L200,Eclipse,Montero,Montero Sport,Outlander,Endeavor,Grandis",
  },
  {
    year: 2008,
    make: "Nissan",
    models:
      "Urvan,Rogue,Maxima,Pickup,Cabstar E,Quest,Murano,350Z,Versa,Aprio,Tiida,Sentra,Armada,X-Trail,Frontier,Tsuru,Platina,Xterra,Pathfinder,Altima,Titan",
  },
  {
    year: 2008,
    make: "Peugeot",
    models: "Grand Raid,307,Partner,Expert,206,607,207,407",
  },
  {
    year: 2008,
    make: "Pontiac",
    models: "Torrent,G6,G5,Montana,Matiz,G3,Grand Prix,Vibe,G8,Solstice,Wave",
  },
  {
    year: 2008,
    make: "Porsche",
    models: "Cayman,911,Boxster,Cayenne",
  },
  {
    year: 2008,
    make: "Rolls-Royce",
    models: "Phantom",
  },
  {
    year: 2008,
    make: "Saab",
    models: "9-7x,9-3,9-5",
  },
  {
    year: 2008,
    make: "Saturn",
    models: "Vue,Sky,Outlook,Astra,Aura",
  },
  {
    year: 2008,
    make: "Scion",
    models: "xD,tC,xB",
  },
  {
    year: 2008,
    make: "Smart",
    models: "Fortwo",
  },
  {
    year: 2008,
    make: "Spyker",
    models: "C12,C8",
  },
  {
    year: 2008,
    make: "Subaru",
    models: "Legacy,Impreza,Forester,Outback,Tribeca",
  },
  {
    year: 2008,
    make: "Suzuki",
    models: "Swift+,SX4,Reno,XL-7,Forenza,Grand Vitara,Swift",
  },
  {
    year: 2008,
    make: "Toyota",
    models:
      "Matrix,Avalon,Camry,Land Cruiser,Sienna,Solara,Hilux,Hiace,FJ Cruiser,Yaris,Tacoma,Highlander,Corolla,Prius,Tundra,Avanza,RAV4,Sequoia,4Runner",
  },
  {
    year: 2008,
    make: "Volkswagen",
    models:
      "Van,GTI,Jetta City,Pointer,Passat,Sharan,Crafter Van,Beetle,Derby,SportVan,Bora,CrossFox,Lupo,Rabbit,Eos,Touareg,Jetta,Golf City,R32,EuroVan",
  },
  {
    year: 2008,
    make: "Volvo",
    models: "S80,V50,S40,XC90,S60,XC70,C30,V70,C70",
  },
  {
    year: 2007,
    make: "Acura",
    models: "RDX,MDX,TL,RL,TSX,CSX",
  },
  {
    year: 2007,
    make: "Alfa Romeo",
    models: "Brera,159,Spider,147,GT",
  },
  {
    year: 2007,
    make: "Aston Martin",
    models: "V8 Vantage,DB9",
  },
  {
    year: 2007,
    make: "Audi",
    models: "RS4,A4,A6,A6 Quattro,Q7,A4 Quattro,TT,A3,A3 Quattro,TT Quattro,S6,S4,A8 Quattro,S8",
  },
  {
    year: 2007,
    make: "Bentley",
    models: "Azure,Continental,Arnage",
  },
  {
    year: 2007,
    make: "BMW",
    models:
      "550i,530xi,130i,330i,760Li,Z4,650i,750i,650Ci,X5,525xi,Alpina B7,525i,335xi,M6,750Li,530i,X3,328xi,M5,323i,328i,120i,325i,335i",
  },
  {
    year: 2007,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2007,
    make: "Buick",
    models: "Terraza,Allure,Lucerne,Rainier,Rendezvous,LaCrosse",
  },
  {
    year: 2007,
    make: "Cadillac",
    models: "BLS,STS,DTS,Escalade ESV,Escalade EXT,CTS,XLR,Escalade,SRX",
  },
  {
    year: 2007,
    make: "Chevrolet",
    models:
      "Cheyenne,Corvette,Aveo,Silverado 2500 HD,Silverado 2500 HD Classic,Silverado 1500 HD Classic,Aveo5,Vectra,Malibu,Astra,Meriva,Optra,HHR,Suburban 1500,Uplander,Tornado,Express 2500,Express 3500,Silverado 3500 HD,Silverado 1500 Classic,Colorado,Equinox,Trailblazer,Avalanche,Silverado 1500,Cobalt,Corsa,Chevy,Tahoe,Impala,Silverado 3500 Classic,Silverado 2500,Express 1500,Suburban 2500,Tracker,Monte Carlo",
  },
  {
    year: 2007,
    make: "Chrysler",
    models: "300,Town & Country,Cirrus,Aspen,Sebring,Voyager,PT Cruiser,Crossfire,Pacifica",
  },
  {
    year: 2007,
    make: "Dodge",
    models:
      "Nitro,Durango,Magnum,Caliber,Caravan,Charger,Ram 1500,Grand Caravan,Atos,Ram 2500,Dakota,Sprinter 2500,H100,Attitude",
  },
  {
    year: 2007,
    make: "Ferrari",
    models: "F430,599 GTB,612 Scaglietti",
  },
  {
    year: 2007,
    make: "Fiat",
    models: "Strada,Palio,Panda,Stilo,Punto",
  },
  {
    year: 2007,
    make: "Ford",
    models:
      "Escape,F-250 Super Duty,Mustang,EcoSport,Transit,Courier,Freestyle,F-250,Fusion,Crown Victoria,E-150,Five Hundred,F-350 Super Duty,Focus,Taurus,Explorer Sport Trac,Freestar,E-250,Mondeo,E-150 Econoline,F-150,Ikon,F-350,Ranger,Expedition,Explorer,Edge,Fiesta,Ka,Lobo",
  },
  {
    year: 2007,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2007,
    make: "GMC",
    models:
      "Yukon XL 1500,Sierra 2500 HD Classic,Envoy,Sierra 2500 HD,Yukon,Yukon XL 2500,Sierra 1500 Classic,Sierra,Sierra 1500,Sierra 3500 Classic,Savana 2500,Sierra 1500 HD Classic,Savana 1500,Canyon,Acadia",
  },
  {
    year: 2007,
    make: "Honda",
    models: "Ridgeline,Element,Fit,Pilot,Civic,Odyssey,S2000,Accord,CR-V",
  },
  {
    year: 2007,
    make: "Hummer",
    models: "H2,H3",
  },
  {
    year: 2007,
    make: "Hyundai",
    models: "Elantra,Azera,Entourage,Tiburon,Santa Fe,Tucson,Sonata,Accent,Veracruz",
  },
  {
    year: 2007,
    make: "Infiniti",
    models: "FX35,QX56,M45,FX45,G35,M35",
  },
  {
    year: 2007,
    make: "Isuzu",
    models: "i-290,Ascender,i-370",
  },
  {
    year: 2007,
    make: "Jaguar",
    models: "Vanden Plas,XJ8,XK,X-Type,Super V8,S-Type,XJR,XKR",
  },
  {
    year: 2007,
    make: "Jeep",
    models: "Wrangler,Patriot,Grand Cherokee,Liberty,Commander,Compass",
  },
  {
    year: 2007,
    make: "Kia",
    models: "Optima,Sedona,Spectra,Sportage,Rondo,Sorento,Amanti,Rio5,Rio,Spectra5,Magentis",
  },
  {
    year: 2007,
    make: "Lamborghini",
    models: "Gallardo,Murcielago",
  },
  {
    year: 2007,
    make: "Land Rover",
    models: "Range Rover,LR3,Range Rover Sport",
  },
  {
    year: 2007,
    make: "Lexus",
    models: "IS350,GS350,LS460,LX470,RX400h,ES350,RX350,SC430,GS450h,IS250,GX470,GS430",
  },
  {
    year: 2007,
    make: "Lincoln",
    models: "Navigator,Mark LT,MKX,Town Car,MKZ",
  },
  {
    year: 2007,
    make: "Lotus",
    models: "Elise,Exige",
  },
  {
    year: 2007,
    make: "Maserati",
    models: "Coupe,Quattroporte,GranSport",
  },
  {
    year: 2007,
    make: "Maybach",
    models: "57,62",
  },
  {
    year: 2007,
    make: "Mazda",
    models: "3,CX-9,MX-5,6,MX-5 Miata,RX-8,5,CX-7,B2300,B3000,B4000",
  },
  {
    year: 2007,
    make: "Mercedes-Benz",
    models:
      "CLK350,ML320,SL55 AMG,CLK550,G55 AMG,SLK55 AMG,S500,E280,E350,CL600,E320,SL65 AMG,S65 AMG,CLS350,G500,SLK280,ML350,SL500,SL600,C280,CL500,CLK280,CL550,GL320,CLS63 AMG,E63 AMG,GL450,E550,R350,R500,CLS500,SLK200,SL550,CLK63 AMG,R63 AMG,SLK350,ML500,Sprinter,CLK500,R320,E500,C350,C55 AMG,B200,SLR McLaren,C230,S550,S600,ML63 AMG,CLS550",
  },
  {
    year: 2007,
    make: "Mercury",
    models: "Mariner,Milan,Grand Marquis,Monterey,Mountaineer,Montego",
  },
  {
    year: 2007,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2007,
    make: "Mitsubishi",
    models: "Endeavor,Grandis,Raider,Galant,Lancer,Eclipse,Montero Sport,Outlander",
  },
  {
    year: 2007,
    make: "Nissan",
    models:
      "Murano,Platina,Micra,Xterra,Pathfinder,Altima,Titan,Urvan,Maxima,Pickup,Quest,350Z,Versa,Tiida,Sentra,Armada,X-Trail,Frontier,Tsuru",
  },
  {
    year: 2007,
    make: "Peugeot",
    models: "407,Grand Raid,307,Partner,206,607",
  },
  {
    year: 2007,
    make: "Pontiac",
    models: "Solstice,Wave,Torrent,G6,G5,Wave5,Montana,Matiz,G3,Grand Prix,Vibe",
  },
  {
    year: 2007,
    make: "Porsche",
    models: "Cayman,911,Boxster",
  },
  {
    year: 2007,
    make: "Rolls-Royce",
    models: "Phantom",
  },
  {
    year: 2007,
    make: "Saab",
    models: "9-7x,9-3,9-5",
  },
  {
    year: 2007,
    make: "Saturn",
    models: "Aura,Relay,Vue,Sky,Ion,Outlook",
  },
  {
    year: 2007,
    make: "Scion",
    models: "tC",
  },
  {
    year: 2007,
    make: "Smart",
    models: "Forfour,Fortwo",
  },
  {
    year: 2007,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2007,
    make: "Subaru",
    models: "B9 Tribeca,Legacy,Impreza,Forester,Outback",
  },
  {
    year: 2007,
    make: "Suzuki",
    models: "XL-7,Forenza,Grand Vitara,Swift,Aerio,Swift+,SX4,Reno",
  },
  {
    year: 2007,
    make: "Toyota",
    models:
      "Corolla,Prius,Tundra,RAV4,Sequoia,4Runner,Avanza,Matrix,Avalon,Camry,Land Cruiser,Sienna,Solara,Hilux,Hiace,FJ Cruiser,Yaris,Tacoma,Highlander",
  },
  {
    year: 2007,
    make: "Volkswagen",
    models:
      "Rabbit,Eos,GTI,Touareg,Jetta,Golf City,Golf,EuroVan,Polo,Van,Jetta City,Pointer,Passat,Sharan,Beetle,Derby,SportVan,Bora,CrossFox,Lupo",
  },
  {
    year: 2007,
    make: "Volvo",
    models: "V70,C30,C70,S80,V50,S40,XC90,S60,XC70",
  },
  {
    year: 2006,
    make: "Acura",
    models: "TSX,CSX,MDX,RSX,TL,RL",
  },
  {
    year: 2006,
    make: "Aston Martin",
    models: "DB9,V8 Vantage,Vanquish",
  },
  {
    year: 2006,
    make: "Audi",
    models: "TT,A3,A3 Quattro,S4,A8 Quattro,A4,A6,A6 Quattro,A4 Quattro,TT Quattro",
  },
  {
    year: 2006,
    make: "Bentley",
    models: "Arnage,Azure,Continental",
  },
  {
    year: 2006,
    make: "BMW",
    models:
      "325i,M5,323i,330xi,120i,550i,760i,530xi,130i,325xi,330i,760Li,Z4,650i,750i,650Ci,325Ci,M3,X5,525xi,330Ci,525i,M6,750Li,530i,X3,545i",
  },
  {
    year: 2006,
    make: "Bugatti",
    models: "Veyron 16.4",
  },
  {
    year: 2006,
    make: "Buick",
    models: "LaCrosse,Terraza,Allure,Lucerne,Rainier,Rendezvous",
  },
  {
    year: 2006,
    make: "Cadillac",
    models: "Escalade EXT,CTS,XLR,Escalade,SRX,STS,DTS,Escalade ESV",
  },
  {
    year: 2006,
    make: "Chevrolet",
    models:
      "Sonora,Silverado 1500,Cobalt,Corsa,Optra,Chevy,Tahoe,Impala,Avalanche 1500,Silverado 1500 HD,Silverado 2500,Express 1500,Suburban 2500,Tracker,Avalanche 2500,Monte Carlo,Trailblazer EXT,Silverado 2500 HD,Corvette,Aveo,Zafira,Epica,Vectra,SSR,Malibu,Astra,Aveo5,Meriva,HHR,Suburban 1500,Uplander,Tornado,Express 2500,Express 3500,Colorado,Equinox,Trailblazer",
  },
  {
    year: 2006,
    make: "Chrysler",
    models: "Voyager,PT Cruiser,Crossfire,Pacifica,300,Town & Country,Cirrus,Sebring",
  },
  {
    year: 2006,
    make: "Dodge",
    models:
      "Atos,Ram 2500,Dakota,H100,Attitude,Stratus,Sprinter 2500,Durango,Magnum,Caravan,Charger,Verna,Viper,Ram 1500,Grand Caravan",
  },
  {
    year: 2006,
    make: "Ferrari",
    models: "360,599 GTB,F430,612 Scaglietti",
  },
  {
    year: 2006,
    make: "Fiat",
    models: "Palio",
  },
  {
    year: 2006,
    make: "Ford",
    models:
      "Ikon,F-350,Ranger,Expedition,Explorer,Fiesta,Ka,Lobo,Escape,F-250 Super Duty,Mustang,Econoline,EcoSport,Courier,Freestyle,F-250,Fusion,Crown Victoria,E-150,Five Hundred,F-350 Super Duty,Focus,Taurus,Freestar,GT,E-250,Mondeo,F-150",
  },
  {
    year: 2006,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2006,
    make: "GMC",
    models:
      "Sierra 1500,Savana 2500,Sierra 1500 HD,Savana 1500,Envoy XL,Canyon,Envoy,Yukon XL 1500,Sierra 2500 HD,Yukon,Yukon XL 2500",
  },
  {
    year: 2006,
    make: "Honda",
    models: "Odyssey,S2000,Fit,Accord,CR-V,Ridgeline,Insight,Element,Pilot,Civic",
  },
  {
    year: 2006,
    make: "Hummer",
    models: "H2,H3",
  },
  {
    year: 2006,
    make: "Hyundai",
    models: "Elantra,Azera,Tiburon,Santa Fe,Tucson,Sonata,Accent",
  },
  {
    year: 2006,
    make: "Infiniti",
    models: "G35,M35,FX35,QX56,M45,Q45,FX45",
  },
  {
    year: 2006,
    make: "Isuzu",
    models: "i-350,Ascender,i-280",
  },
  {
    year: 2006,
    make: "Jaguar",
    models: "XJR,XKR,Vanden Plas,XJ8,XK8,X-Type,Super V8,S-Type",
  },
  {
    year: 2006,
    make: "Jeep",
    models: "Commander,TJ,Wrangler,Grand Cherokee,Liberty",
  },
  {
    year: 2006,
    make: "Kia",
    models: "Rio5,Rio,Spectra5,Optima,Sedona,Spectra,Magentis,Sportage,Sorento,Amanti",
  },
  {
    year: 2006,
    make: "Lamborghini",
    models: "Murcielago,Gallardo",
  },
  {
    year: 2006,
    make: "Land Rover",
    models: "LR3,Freelander,Range Rover Sport,Range Rover",
  },
  {
    year: 2006,
    make: "Lexus",
    models: "LS430,SC430,IS250,GX470,GS430,IS350,GS300,RX330,ES330,LX470,RX400h",
  },
  {
    year: 2006,
    make: "Lincoln",
    models: "Town Car,Navigator,LS,Mark LT,Zephyr",
  },
  {
    year: 2006,
    make: "Lotus",
    models: "Exige,Elise",
  },
  {
    year: 2006,
    make: "Maserati",
    models: "Quattroporte,GranSport,Spyder,Coupe",
  },
  {
    year: 2006,
    make: "Maybach",
    models: "62,57",
  },
  {
    year: 2006,
    make: "Mazda",
    models: "5,B2300,B3000,B4000,3,MX-5,Tribute,6,MPV,MX-5 Miata,RX-8",
  },
  {
    year: 2006,
    make: "Mercedes-Benz",
    models:
      "CL65 AMG,SLK350,ML500,CLS55 AMG,Sprinter,CLK500,C350,C55 AMG,B200,SLR McLaren,C230,CL500,S600,CLS500,E55 AMG,CLK350,SL55 AMG,G55 AMG,SLK55 AMG,S500,E350,CL55 AMG,CL600,E320,SL65 AMG,S65 AMG,CLK55 AMG,G500,SLK280,ML350,S350,S430,S55 AMG,SL500,SL600,C280,E500,R350,R500,SLK200",
  },
  {
    year: 2006,
    make: "Mercury",
    models: "Mountaineer,Montego,Mariner,Milan,Grand Marquis,Monterey",
  },
  {
    year: 2006,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2006,
    make: "Mitsubishi",
    models: "Eclipse,Montero Sport,Outlander,Lancer,Endeavor,Montero,Raider,Galant",
  },
  {
    year: 2006,
    make: "Nissan",
    models:
      "350Z,Sentra,Armada,Frontier,Tsuru,Murano,X-Trail,Platina,Micra,Xterra,Pathfinder,Altima,Titan,Urvan,Maxima,Pickup,Quest",
  },
  {
    year: 2006,
    make: "Peugeot",
    models: "206,607,407,307,Partner",
  },
  {
    year: 2006,
    make: "Pontiac",
    models: "GTO,Montana,Matiz,Grand Prix,Vibe,Solstice,Pursuit,Wave,Torrent,G6,G4,Wave5",
  },
  {
    year: 2006,
    make: "Porsche",
    models: "Boxster,Carrera GT,Cayenne,Cayman,911",
  },
  {
    year: 2006,
    make: "Rolls-Royce",
    models: "Phantom",
  },
  {
    year: 2006,
    make: "Saab",
    models: "9-2X,9-7x,9-3,9-5",
  },
  {
    year: 2006,
    make: "Saturn",
    models: "Vue,Ion,Relay",
  },
  {
    year: 2006,
    make: "Scion",
    models: "xB,xA,tC",
  },
  {
    year: 2006,
    make: "Smart",
    models: "Fortwo,Forfour",
  },
  {
    year: 2006,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2006,
    make: "Subaru",
    models: "Impreza,Forester,B9 Tribeca,Baja,Outback,Legacy",
  },
  {
    year: 2006,
    make: "Suzuki",
    models: "Swift+,Reno,XL-7,Forenza,Grand Vitara,Verona,Aerio",
  },
  {
    year: 2006,
    make: "Toyota",
    models:
      "Land Cruiser,Sienna,Solara,Hilux,Hiace,Yaris,MR2 Spyder,Tacoma,Highlander,Corolla,Prius,Tundra,RAV4,Sequoia,4Runner,Matrix,Avalon,Camry",
  },
  {
    year: 2006,
    make: "Volkswagen",
    models: "Pointer,Passat,Sharan,Beetle,Derby,Bora,Lupo,Rabbit,Phaeton,GTI,Touareg,Jetta,Golf,EuroVan,Polo,Van",
  },
  {
    year: 2006,
    make: "Volvo",
    models: "V50,S40,XC90,S60,XC70,V70,C70,S80",
  },
  {
    year: 2005,
    make: "Acura",
    models: "RSX,TL,NSX,RL,TSX,EL,MDX",
  },
  {
    year: 2005,
    make: "Alfa Romeo",
    models: "147",
  },
  {
    year: 2005,
    make: "Aston Martin",
    models: "Vanquish,DB9",
  },
  {
    year: 2005,
    make: "Audi",
    models: "A6 Quattro,A4 Quattro,TT Quattro,A3 Quattro,TT,Allroad Quattro,S4,A8 Quattro,A4,A3,A6",
  },
  {
    year: 2005,
    make: "Bentley",
    models: "Arnage,Continental",
  },
  {
    year: 2005,
    make: "BMW",
    models: "330Ci,525i,645Ci,320i,530i,X3,545i,325i,745Li,330xi,120i,760i,325xi,330i,760Li,Z4,325Ci,745i,M3,X5",
  },
  {
    year: 2005,
    make: "Buick",
    models: "Allure,Rainier,Rendezvous,LeSabre,LaCrosse,Park Avenue,Century,Terraza",
  },
  {
    year: 2005,
    make: "Cadillac",
    models: "STS,Escalade ESV,DeVille,Escalade EXT,CTS,XLR,Escalade,SRX",
  },
  {
    year: 2005,
    make: "Chevrolet",
    models:
      "Vectra,SSR,Astro,Malibu,Astra,Meriva,Suburban 1500,Classic,Uplander,Tornado,Epica,Express 2500,Express 3500,Venture,Colorado,Equinox,Trailblazer,Cavalier,Sonora,Silverado 1500,Cobalt,Corsa,Optra,Chevy,Tahoe,Impala,Avalanche 1500,LUV,Silverado 1500 HD,Silverado 2500,Express 1500,Suburban 2500,Tracker,Avalanche 2500,Blazer,Monte Carlo,Trailblazer EXT,Silverado 2500 HD,Corvette,Aveo,Zafira,P30",
  },
  {
    year: 2005,
    make: "Chrysler",
    models: "Cirrus,Sebring,Voyager,PT Cruiser,Crossfire,Pacifica,300,Town & Country",
  },
  {
    year: 2005,
    make: "Dodge",
    models:
      "Verna,Viper,Ram 1500,Grand Caravan,SX 2.0,Atos,Ram 2500,Dakota,H100,Stratus,Sprinter 2500,Durango,Magnum,Neon,Caravan",
  },
  {
    year: 2005,
    make: "Ferrari",
    models: "612 Scaglietti,F430,575 M Maranello,Superamerica,360",
  },
  {
    year: 2005,
    make: "Fiat",
    models: "Palio",
  },
  {
    year: 2005,
    make: "Ford",
    models:
      "E-350 Club Wagon,Crown Victoria,E-150,Five Hundred,F-350 Super Duty,Focus,Taurus,E-150 Club Wagon,Freestar,E-250,Mondeo,F-150,Ikon,Thunderbird,F-350,Ranger,Expedition,Explorer,Fiesta,GT,Ka,Lobo,Explorer Sport Trac,Escape,F-250 Super Duty,Mustang,Excursion,Econoline,EcoSport,Courier,Freestyle,F-250",
  },
  {
    year: 2005,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2005,
    make: "GMC",
    models:
      "Safari,Sierra 2500 HD,Envoy XUV,Yukon,Yukon XL 2500,Sierra 1500,Savana 2500,Sierra 1500 HD,Savana 1500,Jimmy,Envoy XL,Canyon,Envoy,Yukon XL 1500",
  },
  {
    year: 2005,
    make: "Honda",
    models: "Element,Pilot,Civic,Odyssey,S2000,Accord,CR-V,Insight",
  },
  {
    year: 2005,
    make: "Hummer",
    models: "H2",
  },
  {
    year: 2005,
    make: "Hyundai",
    models: "Tucson,Sonata,Accent,XG350,Elantra,Tiburon,Santa Fe",
  },
  {
    year: 2005,
    make: "Infiniti",
    models: "QX56,Q45,FX45,G35,FX35",
  },
  {
    year: 2005,
    make: "Isuzu",
    models: "Ascender",
  },
  {
    year: 2005,
    make: "Jaguar",
    models: "XJ8,XK8,X-Type,Super V8,S-Type,XJR,XKR,Vanden Plas",
  },
  {
    year: 2005,
    make: "Jeep",
    models: "Grand Cherokee,Liberty,TJ,Wrangler",
  },
  {
    year: 2005,
    make: "Kia",
    models: "Optima,Sedona,Spectra5,Spectra,Sportage,Sorento,Amanti,Magentis,Rio",
  },
  {
    year: 2005,
    make: "Lamborghini",
    models: "Gallardo,Murcielago",
  },
  {
    year: 2005,
    make: "Land Rover",
    models: "Range Rover,LR3,Freelander",
  },
  {
    year: 2005,
    make: "Lexus",
    models: "LX470,IS300,LS430,SC430,GX470,GS430,GS300,RX330,ES330",
  },
  {
    year: 2005,
    make: "Lincoln",
    models: "LS,Town Car,Aviator,Navigator",
  },
  {
    year: 2005,
    make: "Lotus",
    models: "Elise,Exige",
  },
  {
    year: 2005,
    make: "Maserati",
    models: "Coupe,Quattroporte,GranSport,Spyder",
  },
  {
    year: 2005,
    make: "Maybach",
    models: "57,62",
  },
  {
    year: 2005,
    make: "Mazda",
    models: "Tribute,6,MPV,RX-8,B2300,B3000,B4000,3,Miata",
  },
  {
    year: 2005,
    make: "Mercedes-Benz",
    models:
      "E320,SL65 AMG,CLK55 AMG,G500,ML350,S430,S55 AMG,SL500,SL600,A160,E500,A190,SLK200,CL65 AMG,SLK350,ML500,CL230,Sprinter,CLK500,C55 AMG,SLR McLaren,C230,CL500,S600,C320,E55 AMG,SL55 AMG,C240,S65 AMG,CLK320,G55 AMG,SLK55 AMG,S500,CLS500,CL55 AMG,CL600",
  },
  {
    year: 2005,
    make: "Mercury",
    models: "Grand Marquis,Sable,Monterey,Mountaineer,Montego,Mariner",
  },
  {
    year: 2005,
    make: "MG",
    models: "TF,ZR,ZT",
  },
  {
    year: 2005,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2005,
    make: "Mitsubishi",
    models: "Endeavor,Montero,Galant,Eclipse,Montero Sport,Outlander,Lancer",
  },
  {
    year: 2005,
    make: "Nissan",
    models:
      "Altima,Titan,Urvan,Maxima,Almera,Pickup,Quest,350Z,Sentra,Armada,Frontier,Tsuru,Murano,X-Trail,Platina,Micra,Xterra,Pathfinder",
  },
  {
    year: 2005,
    make: "Peugeot",
    models: "307,Partner,206,607,406",
  },
  {
    year: 2005,
    make: "Pontiac",
    models: "G6,G4,Wave5,GTO,Montana,Matiz,Aztek,Grand Am,Grand Prix,Vibe,Bonneville,Pursuit,Sunfire,Wave",
  },
  {
    year: 2005,
    make: "Porsche",
    models: "Cayenne,911,Boxster,Carrera GT",
  },
  {
    year: 2005,
    make: "Rolls-Royce",
    models: "Phantom",
  },
  {
    year: 2005,
    make: "Rover",
    models: "75",
  },
  {
    year: 2005,
    make: "Saab",
    models: "9-7x,9-3,9-5,9-2X",
  },
  {
    year: 2005,
    make: "Saturn",
    models: "Relay,L300,Vue,Ion",
  },
  {
    year: 2005,
    make: "Scion",
    models: "tC,xB,xA",
  },
  {
    year: 2005,
    make: "Smart",
    models: "Forfour,Cabrio,Roadster,Fortwo",
  },
  {
    year: 2005,
    make: "Spyker",
    models: "C8",
  },
  {
    year: 2005,
    make: "Subaru",
    models: "Baja,Outback,Legacy,Impreza,Forester",
  },
  {
    year: 2005,
    make: "Suzuki",
    models: "Aerio,Swift+,Reno,XL-7,Forenza,Grand Vitara,Verona",
  },
  {
    year: 2005,
    make: "Toyota",
    models:
      "RAV4,Sequoia,4Runner,Yaris,Matrix,Avalon,Camry,Echo,Land Cruiser,Sienna,Solara,Hilux,MR2 Spyder,Tacoma,Highlander,Celica,Corolla,Prius,Tundra",
  },
  {
    year: 2005,
    make: "Volkswagen",
    models: "Polo,Van,Bora,Pointer,Passat,Sharan,Beetle,Derby,Lupo,Phaeton,Touareg,Jetta,Golf",
  },
  {
    year: 2005,
    make: "Volvo",
    models: "S80,V50,S40,XC90,S60,XC70,C70,V70",
  },
  {
    year: 2004,
    make: "Acura",
    models: "EL,MDX,RSX,TL,NSX,RL,TSX",
  },
  {
    year: 2004,
    make: "Alfa Romeo",
    models: "147,156",
  },
  {
    year: 2004,
    make: "Aston Martin",
    models: "Vanquish,DB7",
  },
  {
    year: 2004,
    make: "Audi",
    models: "A8 Quattro,RS6,A4,A3,S6,A6 Quattro,A4 Quattro,TT Quattro,A3 Quattro,TT,Allroad Quattro,S3,A6,S4",
  },
  {
    year: 2004,
    make: "Bentley",
    models: "Continental,Arnage",
  },
  {
    year: 2004,
    make: "BMW",
    models: "760i,325xi,330i,760Li,Z4,325Ci,745i,M3,X5,330Ci,525i,645Ci,530i,X3,545i,325i,745Li,320i,330xi",
  },
  {
    year: 2004,
    make: "Buick",
    models: "Park Avenue,Century,Regal,Rainier,Rendezvous,LeSabre",
  },
  {
    year: 2004,
    make: "Cadillac",
    models: "Escalade,SRX,Seville,Escalade ESV,DeVille,Escalade EXT,CTS,XLR",
  },
  {
    year: 2004,
    make: "Chevrolet",
    models:
      "Express 1500,Suburban 2500,Avalanche 2500,Blazer,Monte Carlo,Trailblazer EXT,Silverado 2500 HD,Corvette,Aveo,Zafira,P30,Vectra,SSR,Astro,Malibu,Astra,Meriva,Suburban 1500,Tracker,Classic,S10,Cargo Van,Tornado,Express 2500,Express 3500,Venture,Colorado,Trailblazer,Cavalier,Sonora,Optra,Silverado 1500,Corsa,Epica,Chevy,Tahoe,Impala,Avalanche 1500,LUV,Silverado 2500",
  },
  {
    year: 2004,
    make: "Chrysler",
    models: "Pacifica,Town & Country,Cirrus,300M,Intrepid,Sebring,Concorde,Voyager,PT Cruiser,Crossfire",
  },
  {
    year: 2004,
    make: "Dodge",
    models:
      "Intrepid,H100,Stratus,Sprinter 2500,Durango,Neon,Caravan,Verna,Viper,Ram 1500,Grand Caravan,SX 2.0,Atos,Ram 2500,Dakota",
  },
  {
    year: 2004,
    make: "Ferrari",
    models: "Enzo,575 M Maranello,456 M GTA,456 M GT,360",
  },
  {
    year: 2004,
    make: "Fiat",
    models: "Palio",
  },
  {
    year: 2004,
    make: "Ford",
    models:
      "Ka,Lobo,Explorer Sport Trac,Escape,F-250 Super Duty,Mustang,Excursion,Econoline,EcoSport,Courier,F-250,E-350 Club Wagon,Fiesta Ikon,Crown Victoria,E-150,F-350 Super Duty,Focus,Taurus,E-150 Club Wagon,Freestar,E-250,Mondeo,F-150,Ikon,Thunderbird,Grand Marquis,F-350,F-150 Heritage,Ranger,Expedition,Explorer,Fiesta",
  },
  {
    year: 2004,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2004,
    make: "GMC",
    models:
      "Savana 1500,Envoy XL,Canyon,Envoy,Sonoma,Yukon XL 1500,Safari,Sierra 2500 HD,Jimmy,Envoy XUV,Yukon,Yukon XL 2500,Sierra 1500,Savana 2500,Sierra 2500",
  },
  {
    year: 2004,
    make: "Honda",
    models: "Accord,CR-V,Insight,Element,Pilot,Civic,Odyssey,S2000",
  },
  {
    year: 2004,
    make: "Hummer",
    models: "H2",
  },
  {
    year: 2004,
    make: "Hyundai",
    models: "XG350,Elantra,Tiburon,Santa Fe,Sonata,Accent",
  },
  {
    year: 2004,
    make: "Infiniti",
    models: "M45,FX35,QX56,Q45,FX45,G35,I35",
  },
  {
    year: 2004,
    make: "Isuzu",
    models: "Ascender,Rodeo,Axiom",
  },
  {
    year: 2004,
    make: "Jaguar",
    models: "Vanden Plas,XJ8,XK8,X-Type,S-Type,XJR,XKR",
  },
  {
    year: 2004,
    make: "Jeep",
    models: "TJ,Wrangler,Grand Cherokee,Liberty",
  },
  {
    year: 2004,
    make: "Kia",
    models: "Magentis,Optima,Sedona,Spectra,Sorento,Amanti,Rio",
  },
  {
    year: 2004,
    make: "Lamborghini",
    models: "Gallardo,Murcielago",
  },
  {
    year: 2004,
    make: "Land Rover",
    models: "Freelander,Discovery,Range Rover",
  },
  {
    year: 2004,
    make: "Lexus",
    models: "GS430,GS300,RX330,ES330,LX470,IS300,LS430,SC430,GX470",
  },
  {
    year: 2004,
    make: "Lincoln",
    models: "Aviator,Navigator,LS,Town Car",
  },
  {
    year: 2004,
    make: "Lotus",
    models: "Elise,Esprit",
  },
  {
    year: 2004,
    make: "Maserati",
    models: "Quattroporte,4200,Spyder,Coupe",
  },
  {
    year: 2004,
    make: "Maybach",
    models: "57,62",
  },
  {
    year: 2004,
    make: "Mazda",
    models: "B2300,B3000,B4000,3,Miata,Tribute,6,MPV,RX-8",
  },
  {
    year: 2004,
    make: "Mercedes-Benz",
    models:
      "S600,SLK32 AMG,C320,E55 AMG,SL55 AMG,C240,CLK320,G55 AMG,S500,CL55 AMG,CL600,E320,CLK55 AMG,G500,ML350,S430,S55 AMG,SL500,SL600,SLK230,SLK320,C32 AMG,A160,E500,A190,SLK200,ML500,C200,CL230,Sprinter,CLK500,C230,CL500",
  },
  {
    year: 2004,
    make: "Mercury",
    models: "Marauder,Grand Marquis,Sable,Monterey,Mountaineer",
  },
  {
    year: 2004,
    make: "MG",
    models: "ZT,TF,ZR",
  },
  {
    year: 2004,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2004,
    make: "Mitsubishi",
    models: "Lancer,Endeavor,Montero,Diamante,Galant,Space Star,Eclipse,Montero Sport,Outlander",
  },
  {
    year: 2004,
    make: "Nissan",
    models:
      "Frontier,Tsuru,Murano,Platina,Xterra,X-Trail,Pathfinder,Pathfinder Armada,Altima,Titan,Tsubame,Urvan,Maxima,Almera,Pickup,Quest,350Z,Sentra",
  },
  {
    year: 2004,
    make: "Oldsmobile",
    models: "Silhouette,Alero,Bravada",
  },
  {
    year: 2004,
    make: "Peugeot",
    models: "406,307,Partner,206,607",
  },
  {
    year: 2004,
    make: "Pontiac",
    models: "Grand Am,Grand Prix,Vibe,Sunfire,Bonneville,GTO,Montana,Matiz,Aztek",
  },
  {
    year: 2004,
    make: "Porsche",
    models: "Carrera GT,Cayenne,911,Boxster",
  },
  {
    year: 2004,
    make: "Rolls-Royce",
    models: "Phantom",
  },
  {
    year: 2004,
    make: "Rover",
    models: "75",
  },
  {
    year: 2004,
    make: "Saab",
    models: "9-3,9-5",
  },
  {
    year: 2004,
    make: "Saturn",
    models: "L300,Ion,Vue",
  },
  {
    year: 2004,
    make: "Scion",
    models: "xA,xB",
  },
  {
    year: 2004,
    make: "Smart",
    models: "City-Coupe,Crossblade,Cabrio,Roadster",
  },
  {
    year: 2004,
    make: "Subaru",
    models: "Forester,Baja,Outback,Legacy,Impreza",
  },
  {
    year: 2004,
    make: "Suzuki",
    models: "XL-7,Forenza,Grand Vitara,Verona,Aerio,Vitara,Swift+",
  },
  {
    year: 2004,
    make: "Toyota",
    models:
      "MR2 Spyder,Tacoma,Highlander,Celica,Corolla,Prius,Tundra,RAV4,Sequoia,4Runner,Yaris,Matrix,Avalon,Camry,Echo,Land Cruiser,Sienna,Solara,Hilux",
  },
  {
    year: 2004,
    make: "Volkswagen",
    models: "EuroVan,Phaeton,Touareg,Jetta,Golf,Sedan,R32,Polo,Van,Pointer,Passat,Sharan,Beetle,Derby",
  },
  {
    year: 2004,
    make: "Volvo",
    models: "C70,XC70,V70,S80,V40,S40,XC90,S60",
  },
  {
    year: 2003,
    make: "Acura",
    models: "NSX,RL,EL,CL,MDX,RSX,TL",
  },
  {
    year: 2003,
    make: "Alfa Romeo",
    models: "147,Spider,166,GTV,156",
  },
  {
    year: 2003,
    make: "Aston Martin",
    models: "Vanquish,DB7",
  },
  {
    year: 2003,
    make: "Audi",
    models: "A4 Quattro,TT Quattro,TT,Allroad Quattro,S3,A6,S8,A8 Quattro,RS6,A4,A3,A6 Quattro,S6",
  },
  {
    year: 2003,
    make: "Bentley",
    models: "Arnage,Continental,Azure",
  },
  {
    year: 2003,
    make: "BMW",
    models: "530i,325i,540i,745Li,320i,330xi,M5,325xi,330i,760Li,Z4,Z8,325Ci,745i,M3,X5,330Ci,525i",
  },
  {
    year: 2003,
    make: "Buick",
    models: "Rendezvous,Century,LeSabre,Park Avenue,Regal",
  },
  {
    year: 2003,
    make: "Cadillac",
    models: "Escalade ESV,Escalade EXT,CTS,Escalade,DeVille,Seville",
  },
  {
    year: 2003,
    make: "Chevrolet",
    models:
      "Cargo Van,Venture,Trailblazer,Corvette,Sonora,Chevy Monza,Express 2500,Express 3500,Silverado 1500,Corsa,Chevy,SSR,Tahoe,Avalanche 1500,LUV,Silverado 2500,Suburban 2500,Impala,Avalanche 2500,Monte Carlo,Trailblazer EXT,Silverado 2500 HD,Express 1500,Cavalier,Zafira,P30,Chevy Pickup,Vectra,Astro,Blazer,Malibu,Astra,Suburban 1500,Tracker,S10,Silverado 1500 HD",
  },
  {
    year: 2003,
    make: "Chrysler",
    models: "Concorde,Voyager,PT Cruiser,Intrepid,Town & Country,Sebring,Cirrus,300M",
  },
  {
    year: 2003,
    make: "Dodge",
    models:
      "Grand Caravan,Sprinter 2500,SX 2.0,Intrepid,Atos,Ram 2500,Dakota,Neon,H100,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Caravan,Stratus,Durango,Viper,Ram 1500",
  },
  {
    year: 2003,
    make: "Ferrari",
    models: "360,456 M GTA,456 M GT,550 Maranello,Enzo,575 M Maranello",
  },
  {
    year: 2003,
    make: "Ford",
    models:
      "E-250,Explorer Sport,Mondeo,Thunderbird,Windstar,Mustang,Escort,Ikon,Grand Marquis,F-350,Ranger,Taurus,Escape,Expedition,Explorer,Fiesta,Excursion,Ka,Lobo,Explorer Sport Trac,F-150,F-250 Super Duty,Econoline,Courier,F-250,E-350 Club Wagon,Fiesta Ikon,Crown Victoria,E-150,F-350 Super Duty,Focus,E-150 Club Wagon",
  },
  {
    year: 2003,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2003,
    make: "GMC",
    models:
      "Sierra 2500 HD,Savana 2500,Sierra 1500,Savana 1500,Sierra 2500,Envoy XL,Sonoma,Yukon XL 1500,Safari,Envoy,Jimmy,Sierra 1500 HD,Yukon,Yukon XL 2500",
  },
  {
    year: 2003,
    make: "Honda",
    models: "Pilot,Civic,Odyssey,S2000,Accord,CR-V,Insight,Element",
  },
  {
    year: 2003,
    make: "Hummer",
    models: "H2",
  },
  {
    year: 2003,
    make: "Hyundai",
    models: "Sonata,Accent,XG350,Elantra,Tiburon,Santa Fe",
  },
  {
    year: 2003,
    make: "Infiniti",
    models: "G35,I35,M45,QX4,FX35,Q45,FX45",
  },
  {
    year: 2003,
    make: "Isuzu",
    models: "Rodeo Sport,Ascender,Rodeo,Axiom",
  },
  {
    year: 2003,
    make: "Jaguar",
    models: "S-Type,XJR,XKR,Vanden Plas,XJ8,XK8,X-Type",
  },
  {
    year: 2003,
    make: "Jeep",
    models: "Grand Cherokee,Liberty,Wrangler,TJ",
  },
  {
    year: 2003,
    make: "Kia",
    models: "Sorento,Rio,Magentis,Optima,Sedona,Spectra",
  },
  {
    year: 2003,
    make: "Lamborghini",
    models: "Murcielago",
  },
  {
    year: 2003,
    make: "Land Rover",
    models: "Freelander,Discovery,Range Rover",
  },
  {
    year: 2003,
    make: "Lexus",
    models: "LS430,SC430,GX470,GS430,GS300,ES300,LX470,IS300,RX300",
  },
  {
    year: 2003,
    make: "Lincoln",
    models: "Blackwood,Town Car,Aviator,Navigator,LS",
  },
  {
    year: 2003,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 2003,
    make: "Maserati",
    models: "3200 GT,Quattroporte,Spyder,Coupe",
  },
  {
    year: 2003,
    make: "Maybach",
    models: "62,57",
  },
  {
    year: 2003,
    make: "Mazda",
    models: "Protege5,B2300,MPV,B3000,Miata,B4000,Tribute,6,Protege",
  },
  {
    year: 2003,
    make: "Mercedes-Benz",
    models:
      "E500,E55 AMG,A190,SLK200,ML500,ML55 AMG,C200,CL230,CLK500,SL600,C230,CL500,ML320,S600,SLK32 AMG,C320,SL55 AMG,C240,CLK320,CLK430,G55 AMG,S500,CL55 AMG,CL600,CLK55 AMG,E320,G500,ML350,S430,S55 AMG,SL500,SLK230,SLK320,C32 AMG,A160,CL200",
  },
  {
    year: 2003,
    make: "Mercury",
    models: "Mountaineer,Marauder,Grand Marquis,Sable",
  },
  {
    year: 2003,
    make: "MG",
    models: "ZT,TF",
  },
  {
    year: 2003,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2003,
    make: "Mitsubishi",
    models: "Space Star,Eclipse,Montero Sport,Outlander,Lancer,Montero,Diamante,Galant",
  },
  {
    year: 2003,
    make: "Nissan",
    models:
      "Maxima,Almera,Pickup,350Z,Sentra,Tsuru,Murano,Platina,Xterra,X-Trail,Pathfinder,Altima,Frontier,Tsubame,Urvan",
  },
  {
    year: 2003,
    make: "Oldsmobile",
    models: "Alero,Bravada,Silhouette,Aurora",
  },
  {
    year: 2003,
    make: "Peugeot",
    models: "206,607,406,307,Partner",
  },
  {
    year: 2003,
    make: "Pontiac",
    models: "Montana,Aztek,Grand Prix,Vibe,Bonneville,Grand Am,Sunfire",
  },
  {
    year: 2003,
    make: "Porsche",
    models: "911,Boxster,Cayenne",
  },
  {
    year: 2003,
    make: "Rover",
    models: "75",
  },
  {
    year: 2003,
    make: "Saab",
    models: "9-5,9-3",
  },
  {
    year: 2003,
    make: "Saturn",
    models: "L300,L200,Vue,Ion,LW300,LW200",
  },
  {
    year: 2003,
    make: "Smart",
    models: "City-Coupe",
  },
  {
    year: 2003,
    make: "Subaru",
    models: "Impreza,Forester,Legacy,Baja,Outback",
  },
  {
    year: 2003,
    make: "Suzuki",
    models: "Aerio,Vitara,XL-7,Grand Vitara",
  },
  {
    year: 2003,
    make: "Toyota",
    models:
      "Echo,Land Cruiser,Sienna,Solara,MR2 Spyder,Tacoma,Highlander,Celica,Corolla,Prius,Tundra,RAV4,Sequoia,4Runner,Matrix,Avalon,Camry",
  },
  {
    year: 2003,
    make: "Volkswagen",
    models: "Van,Golf,R32,Pointer,Passat,Sharan,Beetle,Derby,EuroVan,Jetta,Sedan,Polo",
  },
  {
    year: 2003,
    make: "Volvo",
    models: "S40,V70,XC90,S60,C70,XC70,S80,V40",
  },
  {
    year: 2002,
    make: "Acura",
    models: "MDX,RSX,TL,NSX,RL,EL,CL",
  },
  {
    year: 2002,
    make: "Alfa Romeo",
    models: "166,147,Sportwagon,156",
  },
  {
    year: 2002,
    make: "Aston Martin",
    models: "Vanquish,DB7",
  },
  {
    year: 2002,
    make: "Audi",
    models: "A3,A6 Quattro,RS4,S6,S4,A4 Quattro,TT Quattro,TT,Allroad Quattro,S3,A6,S8,A8 Quattro,A4",
  },
  {
    year: 2002,
    make: "Bentley",
    models: "Continental,Azure,Arnage",
  },
  {
    year: 2002,
    make: "BMW",
    models: "Z8,325Ci,745i,M3,X5,330Ci,525i,530i,325i,540i,745Li,320i,330xi,M5,325xi,330i,Z3",
  },
  {
    year: 2002,
    make: "Buick",
    models: "Regal,Rendezvous,Century,LeSabre,Park Avenue",
  },
  {
    year: 2002,
    make: "Cadillac",
    models: "DeVille,Seville,Eldorado,Escalade EXT,Escalade",
  },
  {
    year: 2002,
    make: "Chevrolet",
    models:
      "Zafira,Prizm,Chevy Pickup,Astro,Blazer,Malibu,Astra,Suburban 1500,Tracker,S10,Silverado 1500 HD,P30,Trailblazer,Sonora,Express 2500,Express 3500,C1500,Camaro,Silverado 1500,Corvette,Corsa,Chevy,Tahoe,Avalanche 1500,LUV,Silverado 2500,Suburban 2500,Impala,Venture,Avalanche 2500,Monte Carlo,Trailblazer EXT,Silverado 2500 HD,Express 1500,G30,Cavalier",
  },
  {
    year: 2002,
    make: "Chrysler",
    models: "Sebring,Cirrus,Intrepid,Prowler,300M,Concorde,Voyager,PT Cruiser,Neon,Grand Voyager,Town & Country",
  },
  {
    year: 2002,
    make: "Daewoo",
    models: "Leganza,Lanos,Nubira",
  },
  {
    year: 2002,
    make: "Dodge",
    models:
      "Durango,Neon,Caravan,Viper,Grand Caravan,Ram 1500,Intrepid,Atos,Ram 2500,Dakota,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Stratus",
  },
  {
    year: 2002,
    make: "Ferrari",
    models: "360,456 M GTA,456 M GT,550 Maranello,575 M Maranello",
  },
  {
    year: 2002,
    make: "Ford",
    models:
      "Econoline,Courier,F-250,Fiesta Ikon,Crown Victoria,E-250 Econoline,F-350 Super Duty,Focus,E-150,Mondeo,Windstar,Mustang,Thunderbird,Escape,Grand Marquis,F-350,Ranger,Taurus,E-350 Club Wagon,Explorer Sport,Escort,Expedition,Explorer,E-150 Econoline,E-150 Econoline Club Wagon,Excursion,Ka,Lobo,F-150,Explorer Sport Trac,F-250 Super Duty",
  },
  {
    year: 2002,
    make: "Freightliner",
    models: "Sprinter 2500",
  },
  {
    year: 2002,
    make: "GMC",
    models:
      "Jimmy,Sierra 1500 HD,Yukon,Yukon XL 2500,Envoy,Sierra 2500 HD,Savana 2500,Sierra 1500,Savana 1500,Sierra 2500,Envoy XL,Sonoma,Safari,Yukon XL 1500",
  },
  {
    year: 2002,
    make: "Honda",
    models: "Civic,Odyssey,S2000,Accord,CR-V,Passport,Insight",
  },
  {
    year: 2002,
    make: "Hyundai",
    models: "Santa Fe,Sonata,Accent,XG350,Elantra",
  },
  {
    year: 2002,
    make: "Infiniti",
    models: "Q45,G20,I35,QX4",
  },
  {
    year: 2002,
    make: "Isuzu",
    models: "Rodeo,Axiom,Trooper,Rodeo Sport",
  },
  {
    year: 2002,
    make: "Jaguar",
    models: "XJ8,XK8,X-Type,S-Type,XJR,XKR,Vanden Plas",
  },
  {
    year: 2002,
    make: "Jeep",
    models: "Grand Cherokee,Liberty,Wrangler,TJ",
  },
  {
    year: 2002,
    make: "Kia",
    models: "Sedona,Spectra,Sportage,Rio,Magentis,Optima",
  },
  {
    year: 2002,
    make: "Lamborghini",
    models: "Murcielago",
  },
  {
    year: 2002,
    make: "Land Rover",
    models: "Discovery,Range Rover,Freelander",
  },
  {
    year: 2002,
    make: "Lexus",
    models: "ES300,LX470,IS300,RX300,LS430,SC430,GS430,GS300",
  },
  {
    year: 2002,
    make: "Lincoln",
    models: "Navigator,LS,Blackwood,Town Car,Continental",
  },
  {
    year: 2002,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 2002,
    make: "Maserati",
    models: "3200 GT,Quattroporte,Spyder",
  },
  {
    year: 2002,
    make: "Mazda",
    models: "Miata,Millenia,626,Protege5,B4000,Tribute,Protege,B2300,MPV,B3000",
  },
  {
    year: 2002,
    make: "Mercedes-Benz",
    models:
      "S500,CL55 AMG,CL600,E500,CLK55 AMG,G500,S430,S55 AMG,SL500,SL55 AMG,SLK230,SLK320,C32 AMG,A160,CL200,A190,E55 AMG,ML500,ML55 AMG,C200,CL230,C230,CL500,E320,ML320,S600,SLK32 AMG,C320,E430,SL600,C240,CLK320,CLK430",
  },
  {
    year: 2002,
    make: "Mercury",
    models: "Cougar,Grand Marquis,Sable,Villager,Mountaineer",
  },
  {
    year: 2002,
    make: "Mini",
    models: "Cooper",
  },
  {
    year: 2002,
    make: "Mitsubishi",
    models: "Montero,Diamante,Galant,Eclipse,Mirage,Montero Sport,Lancer",
  },
  {
    year: 2002,
    make: "Nissan",
    models: "X-Trail,Pathfinder,Quest,Altima,Frontier,Tsubame,Urvan,Maxima,Almera,Pickup,Sentra,Tsuru,Platina,Xterra",
  },
  {
    year: 2002,
    make: "Oldsmobile",
    models: "Silhouette,Intrigue,Aurora,Alero,Bravada",
  },
  {
    year: 2002,
    make: "Peugeot",
    models: "406,206,607,306",
  },
  {
    year: 2002,
    make: "Pontiac",
    models: "Bonneville,Grand Am,Sunfire,Montana,Aztek,Firebird,Grand Prix",
  },
  {
    year: 2002,
    make: "Porsche",
    models: "911,Boxster",
  },
  {
    year: 2002,
    make: "Rolls-Royce",
    models: "Park Ward,Silver Seraph,Corniche",
  },
  {
    year: 2002,
    make: "Saab",
    models: "9-3,9-5",
  },
  {
    year: 2002,
    make: "Saturn",
    models: "SL,SL2,LW300,LW200,SC1,L100,L300,SC2,SL1,L200,Vue",
  },
  {
    year: 2002,
    make: "Subaru",
    models: "Outback,Impreza,Forester,Legacy",
  },
  {
    year: 2002,
    make: "Suzuki",
    models: "Grand Vitara,Aerio,Vitara,Esteem,XL-7",
  },
  {
    year: 2002,
    make: "Toyota",
    models:
      "RAV4,Sequoia,4Runner,Avalon,Camry,Echo,Land Cruiser,Sienna,Solara,MR2 Spyder,Tacoma,Highlander,Celica,Corolla,Prius,Tundra",
  },
  {
    year: 2002,
    make: "Volkswagen",
    models: "Jetta,Sedan,Cabrio,Golf,Pointer,Passat,Sharan,Beetle,Derby,EuroVan",
  },
  {
    year: 2002,
    make: "Volvo",
    models: "V70,S80,V40,S40,S60,C70",
  },
  {
    year: 2001,
    make: "Acura",
    models: "Integra,EL,CL,MDX,TL,NSX,RL",
  },
  {
    year: 2001,
    make: "Aston Martin",
    models: "Vanquish,DB7",
  },
  {
    year: 2001,
    make: "Audi",
    models: "S8,A8 Quattro,A4,A3,A6 Quattro,S6,S4,A4 Quattro,TT Quattro,TT,Allroad Quattro,S3,A6",
  },
  {
    year: 2001,
    make: "Bentley",
    models: "Continental,Azure,Arnage",
  },
  {
    year: 2001,
    make: "BMW",
    models: "320i,330xi,M5,323i,325xi,330i,Z3,Z8,325Ci,740iL,M3,X5,330Ci,525i,740i,530i,325i,540i,750iL",
  },
  {
    year: 2001,
    make: "Buick",
    models: "Park Avenue,Regal,Century,LeSabre",
  },
  {
    year: 2001,
    make: "Cadillac",
    models: "Catera,DeVille,Seville,Eldorado",
  },
  {
    year: 2001,
    make: "Chevrolet",
    models:
      "Chevy,Tahoe,LUV,Lumina,Silverado 2500,Suburban 2500,Impala,Metro,Monte Carlo,Silverado 2500 HD,Express 1500,G30,Cavalier,Venture,Prizm,Chevy Pickup,Astro,Blazer,Malibu,Astra,Suburban 1500,Tracker,S10,Silverado 1500 HD,P30,Sonora,Express 2500,Express 3500,C1500,Camaro,Silverado 1500,Corvette",
  },
  {
    year: 2001,
    make: "Chrysler",
    models: "PT Cruiser,Town & Country,Neon,Grand Voyager,LHS,Sebring,Prowler,Cirrus,Concorde,300M,Intrepid,Voyager",
  },
  {
    year: 2001,
    make: "Daewoo",
    models: "Lanos,Nubira,Leganza",
  },
  {
    year: 2001,
    make: "Dodge",
    models:
      "Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Stratus,Durango,Neon,Viper,Ram 1500,Caravan,Ramcharger,Grand Caravan,Intrepid,Atos,Ram 2500,Dakota",
  },
  {
    year: 2001,
    make: "Ferrari",
    models: "550 Maranello,360,456 M,F355 Spider",
  },
  {
    year: 2001,
    make: "Ford",
    models:
      "Escort,Expedition,Explorer,E-150 Econoline,E-150 Econoline Club Wagon,Ka,Lobo,F-150,Explorer Sport Trac,Explorer Sport,F-250 Super Duty,Econoline,Courier,F-250,F-100 Ranger,Crown Victoria,E-250 Econoline,F-350 Super Duty,Focus,Excursion,Fiesta,Mondeo,Windstar,Sable,Mustang,Escape,Grand Marquis,F-350,Ranger,Taurus",
  },
  {
    year: 2001,
    make: "GMC",
    models:
      "Savana 2500,Savana 1500,Jimmy,Sierra 1500,Sonoma,Safari,Sierra 2500,Yukon XL 1500,Sierra 1500 HD,Yukon,Yukon XL 2500,Sierra 2500 HD",
  },
  {
    year: 2001,
    make: "Honda",
    models: "S2000,Accord,CR-V,Passport,Insight,Prelude,Civic,Odyssey",
  },
  {
    year: 2001,
    make: "Hyundai",
    models: "Tiburon,XG300,Elantra,Santa Fe,Sonata,Accent",
  },
  {
    year: 2001,
    make: "Infiniti",
    models: "I30,QX4,Q45,G20",
  },
  {
    year: 2001,
    make: "Isuzu",
    models: "VehiCROSS,Rodeo,Trooper,Rodeo Sport",
  },
  {
    year: 2001,
    make: "Jaguar",
    models: "Vanden Plas,XJ8,XK8,X-Type,S-Type,XJR,XKR",
  },
  {
    year: 2001,
    make: "Jeep",
    models: "Cherokee,Wrangler,TJ,Grand Cherokee",
  },
  {
    year: 2001,
    make: "Kia",
    models: "Rio,Magentis,Optima,Sephia,Spectra,Sportage",
  },
  {
    year: 2001,
    make: "Lamborghini",
    models: "Murcielago,Diablo",
  },
  {
    year: 2001,
    make: "Land Rover",
    models: "Freelander,Discovery,Range Rover",
  },
  {
    year: 2001,
    make: "Lexus",
    models: "GS430,GS300,ES300,LX470,IS300,RX300,LS430",
  },
  {
    year: 2001,
    make: "Lincoln",
    models: "Town Car,Continental,Navigator,LS",
  },
  {
    year: 2001,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 2001,
    make: "Maserati",
    models: "3200 GT,Quattroporte",
  },
  {
    year: 2001,
    make: "Mazda",
    models: "MPV,B3000,Miata,Millenia,626,B4000,Tribute,Protege,B2300,B2500",
  },
  {
    year: 2001,
    make: "Mercedes-Benz",
    models:
      "CL500,E320,ML320,S600,C320,E430,ML430,SL600,C240,CLK320,CLK430,S500,CL55 AMG,CL600,CLK55 AMG,S430,S55 AMG,SL500,SLK230,SLK320,A160,A190,E55 AMG,G500,ML55 AMG,C200",
  },
  {
    year: 2001,
    make: "Mercury",
    models: "Mountaineer,Cougar,Grand Marquis,Sable,Villager",
  },
  {
    year: 2001,
    make: "Mitsubishi",
    models: "Mirage,Montero Sport,Montero,Diamante,Galant,Eclipse",
  },
  {
    year: 2001,
    make: "Nissan",
    models: "Sentra,Tsuru,Xterra,Pathfinder,Quest,Altima,Frontier,Altra EV,Tsubame,Urvan,Maxima,Almera,Pickup",
  },
  {
    year: 2001,
    make: "Oldsmobile",
    models: "Bravada,Silhouette,Intrigue,Aurora,Alero",
  },
  {
    year: 2001,
    make: "Peugeot",
    models: "306,406,206",
  },
  {
    year: 2001,
    make: "Plymouth",
    models: "Neon,Prowler",
  },
  {
    year: 2001,
    make: "Pontiac",
    models: "Aztek,Firebird,Grand Prix,Bonneville,Grand Am,Sunfire,Montana",
  },
  {
    year: 2001,
    make: "Porsche",
    models: "911,Boxster",
  },
  {
    year: 2001,
    make: "Rolls-Royce",
    models: "Corniche,Silver Seraph",
  },
  {
    year: 2001,
    make: "Saab",
    models: "9-3,9-5",
  },
  {
    year: 2001,
    make: "Saturn",
    models: "L100,SW2,SL,SL2,LW300,LW200,SC1,L300,L200,SC2,SL1",
  },
  {
    year: 2001,
    make: "Subaru",
    models: "Forester,Legacy,Outback,Impreza",
  },
  {
    year: 2001,
    make: "Suzuki",
    models: "Grand Vitara,Swift,Vitara,Esteem",
  },
  {
    year: 2001,
    make: "Toyota",
    models:
      "MR2 Spyder,Tacoma,Highlander,Celica,Corolla,Prius,Tundra,RAV4,Sequoia,4Runner,Avalon,Camry,Echo,Land Cruiser,Sienna,Solara",
  },
  {
    year: 2001,
    make: "Volkswagen",
    models: "Beetle,Derby,EuroVan,Jetta,Sedan,Cabrio,Golf,Pointer,Passat",
  },
  {
    year: 2001,
    make: "Volvo",
    models: "S60,C70,V70,S80,V40,S40",
  },
  {
    year: 2000,
    make: "Acura",
    models: "TL,NSX,RL,Integra,EL",
  },
  {
    year: 2000,
    make: "Aston Martin",
    models: "DB7",
  },
  {
    year: 2000,
    make: "Audi",
    models: "S4,A4 Quattro,TT Quattro,TT,S3,A6,A8 Quattro,A4,A8,A3,A6 Quattro",
  },
  {
    year: 2000,
    make: "Bentley",
    models: "Continental,Azure,Arnage",
  },
  {
    year: 2000,
    make: "BMW",
    models: "323ti,328Ci,740i,328i,540i,750iL,M5,323Ci,528i,Z3,Z8,740iL,X5,323i",
  },
  {
    year: 2000,
    make: "Buick",
    models: "LeSabre,Park Avenue,Regal,Century",
  },
  {
    year: 2000,
    make: "Cadillac",
    models: "Escalade,Eldorado,Seville,Catera,DeVille",
  },
  {
    year: 2000,
    make: "Chevrolet",
    models:
      "L30,Suburban 1500,Tracker,C35,P30,S10,Venture,K2500,Sonora,Express 2500,Express 3500,C1500,C2500,Camaro,Silverado 1500,Corvette,Cargo Van,Chevy,Tahoe,LUV,Lumina,Blazer,Silverado 2500,Suburban 2500,Impala,Metro,Monte Carlo,Express 1500,Cavalier,Prizm,Chevy Pickup,Astro,Malibu,Astra",
  },
  {
    year: 2000,
    make: "Chrysler",
    models: "300M,Intrepid,Voyager,Grand Voyager,Town & Country,Sebring,Neon,LHS,Cirrus,Concorde",
  },
  {
    year: 2000,
    make: "Daewoo",
    models: "Leganza,Lanos,Nubira",
  },
  {
    year: 2000,
    make: "Dodge",
    models:
      "Ram 1500,Ramcharger,Intrepid,Ram 2500,Dakota,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Avenger,Grand Caravan,Neon,Stratus,Caravan,Durango,Viper",
  },
  {
    year: 2000,
    make: "Ferrari",
    models: "F355 Spider,550 Maranello,360,456 M",
  },
  {
    year: 2000,
    make: "Ford",
    models:
      "Excursion,Contour,Fiesta,Windstar,Mystique,Sable,Mustang,Ranger,Grand Marquis,F-350,Taurus,Expedition,Explorer,E-150 Econoline,E-150 Econoline Club Wagon,F-150,Lobo,F-250 Super Duty,Escort,Econoline,F-250,F-100 Ranger,Crown Victoria,E-250 Econoline,F-350 Super Duty,Focus",
  },
  {
    year: 2000,
    make: "GMC",
    models:
      "Yukon XL 2500,K2500,Savana 2500,Savana 1500,Jimmy,Sierra 1500,Sonoma,Safari,Sierra 2500,Yukon,Yukon XL 1500,C2500",
  },
  {
    year: 2000,
    make: "Honda",
    models: "Prelude,Civic,Odyssey,S2000,Accord,CR-V,Passport,Insight",
  },
  {
    year: 2000,
    make: "Hyundai",
    models: "Sonata,Accent,Tiburon,Elantra",
  },
  {
    year: 2000,
    make: "Infiniti",
    models: "Q45,G20,I30,QX4",
  },
  {
    year: 2000,
    make: "Isuzu",
    models: "VehiCROSS,Hombre,Amigo,Rodeo,Trooper",
  },
  {
    year: 2000,
    make: "Jaguar",
    models: "S-Type,XJR,XKR,Vanden Plas,XJ8,XK8",
  },
  {
    year: 2000,
    make: "Jeep",
    models: "Cherokee,Wrangler,TJ,Grand Cherokee",
  },
  {
    year: 2000,
    make: "Kia",
    models: "Sportage,Sephia,Spectra",
  },
  {
    year: 2000,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 2000,
    make: "Land Rover",
    models: "Discovery,Range Rover,Freelander",
  },
  {
    year: 2000,
    make: "Lexus",
    models: "RX300,LS400,SC300,GS400,GS300,ES300,LX470,SC400",
  },
  {
    year: 2000,
    make: "Lincoln",
    models: "Town Car,Continental,Navigator,LS",
  },
  {
    year: 2000,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 2000,
    make: "Maserati",
    models: "3200 GT,Quattroporte",
  },
  {
    year: 2000,
    make: "Mazda",
    models: "Protege,B2500,B4000,MPV,B3000,Miata,Millenia,626",
  },
  {
    year: 2000,
    make: "Mercedes-Benz",
    models:
      "S430,SL500,SLK230,C230,A160,C280,E55 AMG,G500,ML55 AMG,S600,C43 AMG,CL500,E320,ML320,ML430,SL600,CLK320,CLK430,E430,S500",
  },
  {
    year: 2000,
    make: "Mercury",
    models: "Sable,Villager,Mountaineer,Cougar,Grand Marquis,Mystique",
  },
  {
    year: 2000,
    make: "Mitsubishi",
    models: "Diamante,Galant,Eclipse,Mirage,Montero Sport,Montero",
  },
  {
    year: 2000,
    make: "Nissan",
    models: "Tsubame,Urvan,Maxima,Pickup,Lucino,Sentra,Tsuru,Pathfinder,Xterra,Quest,Altima,Frontier,Altra EV",
  },
  {
    year: 2000,
    make: "Oldsmobile",
    models: "Alero,Bravada,Silhouette,Intrigue",
  },
  {
    year: 2000,
    make: "Peugeot",
    models: "206,306,406",
  },
  {
    year: 2000,
    make: "Plymouth",
    models: "Neon,Voyager,Grand Voyager,Prowler,Breeze",
  },
  {
    year: 2000,
    make: "Pontiac",
    models: "Firefly,Montana,Firebird,Grand Prix,Bonneville,Grand Am,Sunfire",
  },
  {
    year: 2000,
    make: "Porsche",
    models: "911,Boxster",
  },
  {
    year: 2000,
    make: "Rolls-Royce",
    models: "Silver Seraph,Corniche",
  },
  {
    year: 2000,
    make: "Rover",
    models: "75",
  },
  {
    year: 2000,
    make: "Saab",
    models: "9-3,9-5",
  },
  {
    year: 2000,
    make: "Saturn",
    models: "SC1,LS1,SC2,SL1,LS,LW1,SW2,LS2,SL,SL2,LW2",
  },
  {
    year: 2000,
    make: "Subaru",
    models: "Impreza,Legacy,Outback,Forester",
  },
  {
    year: 2000,
    make: "Suzuki",
    models: "Vitara,Esteem,Grand Vitara,Swift",
  },
  {
    year: 2000,
    make: "Toyota",
    models: "Avalon,Camry,Echo,Land Cruiser,Sienna,Solara,MR2 Spyder,Tacoma,Celica,Corolla,Tundra,RAV4,4Runner",
  },
  {
    year: 2000,
    make: "Volkswagen",
    models: "Cabrio,Golf,Pointer,Passat,Beetle,Derby,EuroVan,Jetta,Sedan",
  },
  {
    year: 2000,
    make: "Volvo",
    models: "S80,V40,S70,S40,C70,V70",
  },
  {
    year: 1999,
    make: "Acura",
    models: "SLX,TL,NSX,RL,CL,Integra,EL",
  },
  {
    year: 1999,
    make: "Audi",
    models: "A4,A8,A3,A6 Quattro,A4 Quattro,A6,A8 Quattro,TT",
  },
  {
    year: 1999,
    make: "Bentley",
    models: "Continental,Azure,Arnage",
  },
  {
    year: 1999,
    make: "BMW",
    models: "318is,528i,Z3,318ti,740iL,M5,323i,323ti,740i,328i,540i,750iL,318i,323is,M3,328is,850Ci",
  },
  {
    year: 1999,
    make: "Buick",
    models: "Park Avenue,Regal,Century,Riviera,LeSabre",
  },
  {
    year: 1999,
    make: "Cadillac",
    models: "Eldorado,Seville,Catera,DeVille,Escalade",
  },
  {
    year: 1999,
    make: "Chevrolet",
    models:
      "Express 1500,Cavalier,K1500,K2500 Suburban,P30,Prizm,Chevy Pickup,Astro,Malibu,C2500 Suburban,L30,Tracker,C35,K1500 Suburban,C1500,Monte Carlo,S10,Venture,K2500,C1500 Suburban,Tigra,Express 2500,Express 3500,C2500,Camaro,Silverado 1500,Corvette,Cargo Van,Chevy,Suburban 1500,Tahoe,LUV,Lumina,Silverado 2500,Silverado,Blazer,Metro",
  },
  {
    year: 1999,
    make: "Chrysler",
    models: "Cirrus,Voyager,Concorde,300M,Intrepid,Grand Voyager,Town & Country,Sebring,LHS",
  },
  {
    year: 1999,
    make: "Daewoo",
    models: "Leganza,Lanos,Nubira",
  },
  {
    year: 1999,
    make: "Dodge",
    models:
      "Grand Caravan,Stratus,Caravan,Durango,Viper,Ram 1500,Ramcharger,Intrepid,Ram 2500,Dakota,Neon,Ram 2500 Van,Ram 3500 Van,Ram 1500 Van,Avenger",
  },
  {
    year: 1999,
    make: "Ferrari",
    models: "456 M,F355 Spider,550 Maranello,F355",
  },
  {
    year: 1999,
    make: "Ford",
    models:
      "F-150,F-250 Super Duty,Escort,Econoline,F-100 Ranger,Crown Victoria,E-250 Econoline,F-350 Super Duty,Contour,Fiesta,Mystique,Sable,Mustang,Ranger,Windstar,Grand Marquis,F-350,Taurus,Expedition,Explorer,E-150 Econoline,E-150 Econoline Club Wagon,F-250,Lobo",
  },
  {
    year: 1999,
    make: "GMC",
    models:
      "Jimmy,Sonoma,Safari,Sierra 2500,Yukon,C2500,K2500 Suburban,C2500 Suburban,K1500 Suburban,K2500,C1500 Suburban,C1500,Savana 2500,K1500,Savana 1500,Sierra 1500",
  },
  {
    year: 1999,
    make: "Honda",
    models: "Passport,EV Plus,Prelude,Civic,Odyssey,Accord,CR-V",
  },
  {
    year: 1999,
    make: "Hyundai",
    models: "Elantra,Sonata,Accent,Tiburon",
  },
  {
    year: 1999,
    make: "Infiniti",
    models: "QX4,Q45,G20,I30",
  },
  {
    year: 1999,
    make: "Isuzu",
    models: "Amigo,Rodeo,Trooper,VehiCROSS,Hombre,Oasis",
  },
  {
    year: 1999,
    make: "Jaguar",
    models: "Vanden Plas,XJ8,XK8,XJR,S-Type",
  },
  {
    year: 1999,
    make: "Jeep",
    models: "Grand Cherokee,Cherokee,Wrangler,TJ",
  },
  {
    year: 1999,
    make: "Kia",
    models: "Sephia,Sportage",
  },
  {
    year: 1999,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1999,
    make: "Land Rover",
    models: "Freelander,Discovery,Range Rover",
  },
  {
    year: 1999,
    make: "Lexus",
    models: "GS300,ES300,LX470,SC400,RX300,LS400,SC300,GS400",
  },
  {
    year: 1999,
    make: "Lincoln",
    models: "Navigator,Town Car,Continental",
  },
  {
    year: 1999,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 1999,
    make: "Maserati",
    models: "3200 GT,Quattroporte",
  },
  {
    year: 1999,
    make: "Mazda",
    models: "Miata,Millenia,626,Protege,B2500,B3000,B4000",
  },
  {
    year: 1999,
    make: "Mercedes-Benz",
    models:
      "ML430,SL600,CLK320,CLK430,E430,S500,S420,S430,SL500,SLK230,C230,S600,C280,E280,E420,E55 AMG,G500,C43 AMG,CL500,CL600,E300,E320,ML320,S320,SL60 AMG",
  },
  {
    year: 1999,
    make: "Mercury",
    models: "Cougar,Tracer,Grand Marquis,Mystique,Sable,Villager,Mountaineer",
  },
  {
    year: 1999,
    make: "Mitsubishi",
    models: "Montero,3000GT,Diamante,Galant,Eclipse,Mirage,Montero Sport",
  },
  {
    year: 1999,
    make: "Nissan",
    models: "Pathfinder,Quest,Altima,Frontier,Altra EV,Tsubame,Maxima,Pickup,Lucino,Sentra,Tsuru",
  },
  {
    year: 1999,
    make: "Oldsmobile",
    models: "Silhouette,Cutlass,Intrigue,88,LSS,Alero,Bravada,Aurora",
  },
  {
    year: 1999,
    make: "Peugeot",
    models: "306,405",
  },
  {
    year: 1999,
    make: "Plymouth",
    models: "Voyager,Neon,Grand Voyager,Prowler,Breeze",
  },
  {
    year: 1999,
    make: "Pontiac",
    models: "Bonneville,Grand Am,Sunfire,Firefly,Montana,Firebird,Trans Sport,Grand Prix",
  },
  {
    year: 1999,
    make: "Porsche",
    models: "911,Boxster",
  },
  {
    year: 1999,
    make: "Rolls-Royce",
    models: "Silver Seraph",
  },
  {
    year: 1999,
    make: "Rover",
    models: "Mini",
  },
  {
    year: 1999,
    make: "Saab",
    models: "9-3,9-5",
  },
  {
    year: 1999,
    make: "Saturn",
    models: "SL,SL2,SC1,SW1,SC2,SL1,SW2",
  },
  {
    year: 1999,
    make: "Subaru",
    models: "Impreza,Legacy,Forester",
  },
  {
    year: 1999,
    make: "Suzuki",
    models: "Grand Vitara,Swift,Vitara,Esteem",
  },
  {
    year: 1999,
    make: "Toyota",
    models: "Celica,Corolla,Paseo,RAV4,4Runner,Avalon,Camry,Land Cruiser,Sienna,Solara,Tercel,Tacoma",
  },
  {
    year: 1999,
    make: "Volkswagen",
    models: "EuroVan,Jetta,Sedan,Cabrio,Golf,Pointer,Passat,Beetle,Derby",
  },
  {
    year: 1999,
    make: "Volvo",
    models: "S40,V40,S80,V70,S70,C70",
  },
  {
    year: 1998,
    make: "Acura",
    models: "CL,Integra,EL,SLX,TL,NSX,RL",
  },
  {
    year: 1998,
    make: "Aston Martin",
    models: "DB7",
  },
  {
    year: 1998,
    make: "Audi",
    models: "A6,A8 Quattro,A4,A8,A3,A6 Quattro,A4 Quattro,Cabriolet",
  },
  {
    year: 1998,
    make: "Bentley",
    models: "Turbo R,Continental,Azure,Brooklands",
  },
  {
    year: 1998,
    make: "BMW",
    models: "540i,750iL,318i,323is,M3,328is,850Ci,318is,528i,Z3,318ti,323i,740iL,740i,328i",
  },
  {
    year: 1998,
    make: "Buick",
    models: "Riviera,Skylark,Park Avenue,Regal,Century,LeSabre",
  },
  {
    year: 1998,
    make: "Cadillac",
    models: "Eldorado,Catera,DeVille,Seville",
  },
  {
    year: 1998,
    make: "Chevrolet",
    models:
      "Express 2500,Express 3500,Camaro,Corvette,Cargo Van,Chevy,Suburban 1500,Tahoe,LUV,Lumina,Silverado,K2500,Blazer,Metro,Express 1500,C2500,Cavalier,K1500,K2500 Suburban,P30,Prizm,Astro,Malibu,C2500 Suburban,Tracker,C35,K1500 Suburban,C1500,S10,Venture,C1500 Suburban,Monte Carlo",
  },
  {
    year: 1998,
    make: "Chrysler",
    models: "Sebring,300M,Cirrus,Voyager,Town & Country,Concorde,Intrepid,Grand Voyager",
  },
  {
    year: 1998,
    make: "Dodge",
    models:
      "Intrepid,Ram 2500,Dakota,B2500,Neon,Ram 2500 Van,Ram 3500 Van,Avenger,Grand Caravan,Stratus,Caravan,Durango,Viper,Ram 1500,Ram 1500 Van",
  },
  {
    year: 1998,
    make: "Eagle",
    models: "Talon",
  },
  {
    year: 1998,
    make: "Ferrari",
    models: "550 Maranello,F355 F1,F355 Spider,456 GTA,456 GT,F355 GTS",
  },
  {
    year: 1998,
    make: "Ford",
    models:
      "Lobo,E-150 Econoline Club Wagon,Windstar,Grand Marquis,F-350,Expedition,Explorer,E-150 Econoline,F-250,Escort,F-150,Contour,F-100 Ranger,Crown Victoria,E-250 Econoline,Club Wagon,Fiesta,Taurus,Mystique,Sable,Mustang,Ranger",
  },
  {
    year: 1998,
    make: "GMC",
    models:
      "Savana 1500,Yukon,C1500,C2500,Jimmy,Sonoma,Safari,K1500,K2500 Suburban,C2500 Suburban,K1500 Suburban,Savana 2500,K2500,C1500 Suburban",
  },
  {
    year: 1998,
    make: "Honda",
    models: "Odyssey,Accord,CR-V,Passport,EV Plus,Prelude,Civic",
  },
  {
    year: 1998,
    make: "Hyundai",
    models: "Tiburon,Elantra,Sonata,Accent",
  },
  {
    year: 1998,
    make: "Infiniti",
    models: "I30,QX4,Q45",
  },
  {
    year: 1998,
    make: "Isuzu",
    models: "Hombre,Oasis,Amigo,Rodeo,Trooper",
  },
  {
    year: 1998,
    make: "Jaguar",
    models: "XJR,Vanden Plas,XJ8,XK8",
  },
  {
    year: 1998,
    make: "Jeep",
    models: "Cherokee,Grand Cherokee,Wrangler,TJ",
  },
  {
    year: 1998,
    make: "Kia",
    models: "Sportage,Sephia",
  },
  {
    year: 1998,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1998,
    make: "Land Rover",
    models: "Discovery,Range Rover",
  },
  {
    year: 1998,
    make: "Lexus",
    models: "LS400,SC300,GS400,GS300,ES300,LX470,SC400",
  },
  {
    year: 1998,
    make: "Lincoln",
    models: "Continental,Mark VIII,Town Car,Navigator",
  },
  {
    year: 1998,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 1998,
    make: "Mazda",
    models: "B3000,B2500,Millenia,626,Protege,B4000,MPV",
  },
  {
    year: 1998,
    make: "Mercedes-Benz",
    models:
      "E420,C43 AMG,CL500,CL600,E300,E320,ML320,S320,SL60 AMG,SL600,CLK320,E430,S500,E55 AMG,S420,SL500,SLK230,C230,ML430,S600,C280",
  },
  {
    year: 1998,
    make: "Mercury",
    models: "Mountaineer,Villager,Mystique,Tracer,Grand Marquis,Sable",
  },
  {
    year: 1998,
    make: "Mitsubishi",
    models: "Eclipse,Mirage,Montero Sport,Montero,3000GT,Diamante,Galant",
  },
  {
    year: 1998,
    make: "Nissan",
    models: "240SX,Lucino,Sentra,Tsuru,Pathfinder,Frontier,200SX,Quest,Altima,Tsubame,Maxima,Pickup",
  },
  {
    year: 1998,
    make: "Oldsmobile",
    models: "88,LSS,Bravada,Achieva,Aurora,Silhouette,Cutlass,Intrigue,Regency",
  },
  {
    year: 1998,
    make: "Peugeot",
    models: "306,405",
  },
  {
    year: 1998,
    make: "Plymouth",
    models: "Breeze,Voyager,Neon,Grand Voyager",
  },
  {
    year: 1998,
    make: "Pontiac",
    models: "Firebird,Trans Sport,Bonneville,Grand Prix,Sunfire,Grand Am,Firefly",
  },
  {
    year: 1998,
    make: "Porsche",
    models: "Boxster,911",
  },
  {
    year: 1998,
    make: "Rolls-Royce",
    models: "Silver Spur",
  },
  {
    year: 1998,
    make: "Saab",
    models: "900,9000",
  },
  {
    year: 1998,
    make: "Saturn",
    models: "SC2,SL1,SW2,SL,SL2,SC1,SW1",
  },
  {
    year: 1998,
    make: "Subaru",
    models: "Forester,Impreza,Legacy",
  },
  {
    year: 1998,
    make: "Suzuki",
    models: "Esteem,Sidekick,X-90,Swift",
  },
  {
    year: 1998,
    make: "Toyota",
    models: "Land Cruiser,Sienna,Tercel,Tacoma,Celica,Corolla,Paseo,RAV4,4Runner,T100,Avalon,Camry,Supra",
  },
  {
    year: 1998,
    make: "Volkswagen",
    models: "Pointer,Passat,Beetle,Derby,Jetta,Sedan,Cabrio,Golf",
  },
  {
    year: 1998,
    make: "Volvo",
    models: "C70,S90,V90,S70,V70",
  },
  {
    year: 1997,
    make: "Acura",
    models: "SLX,TL,NSX,RL,CL,Integra,EL",
  },
  {
    year: 1997,
    make: "Aston Martin",
    models: "DB7",
  },
  {
    year: 1997,
    make: "Audi",
    models: "A4 Quattro,A6 Quattro,S6,Cabriolet,A6,A8 Quattro,A4,A8",
  },
  {
    year: 1997,
    make: "Bentley",
    models: "Continental,Azure,Brooklands,Turbo R",
  },
  {
    year: 1997,
    make: "BMW",
    models: "740i,325i,328i,540i,750iL,318i,M3,323i,328is,850Ci,318is,528i,Z3,318ti,740iL,840Ci",
  },
  {
    year: 1997,
    make: "Buick",
    models: "Century,LeSabre,Skylark,Riviera,Park Avenue,Regal",
  },
  {
    year: 1997,
    make: "Cadillac",
    models: "Seville,Eldorado,Catera,DeVille",
  },
  {
    year: 1997,
    make: "Chevrolet",
    models:
      "Astro,Malibu,C2500,C2500 Suburban,C35,K1500 Suburban,C1500,S10,Venture,C1500 Suburban,Monte Carlo,Express 2500,Express 3500,K1500,Camaro,Corvette,Cargo Van,Chevy,Suburban 1500,Tahoe,LUV,Lumina,Silverado,Blazer,Express 1500,Cavalier,K2500,K2500 Suburban,P30",
  },
  {
    year: 1997,
    make: "Chrysler",
    models: "Chrysler R/T,Intrepid,Stratus,Grand Voyager,LHS,Sebring,Neon,Concorde,Voyager,Cirrus,Town & Country",
  },
  {
    year: 1997,
    make: "Dodge",
    models:
      "Viper,Ram 1500,Ram 1500 Van,Intrepid,Neon,Ram 2500,Dakota,B2500,Ram 2500 Van,Ram 3500 Van,Avenger,Grand Caravan,Stratus,Caravan",
  },
  {
    year: 1997,
    make: "Eagle",
    models: "Talon,Vision",
  },
  {
    year: 1997,
    make: "Ferrari",
    models: "456 GT,F355 GTS,F50,550 Maranello,F355 Spider,456 GTA",
  },
  {
    year: 1997,
    make: "Ford",
    models:
      "F-100 Ranger,Crown Victoria,E-250 Econoline,F-350,Probe,Club Wagon,Aspire,Thunderbird,Lobo,Mystique,Sable,Mustang,Ranger,E-150 Econoline Club Wagon,Grand Marquis,Taurus,Escort,Expedition,Explorer,E-150 Econoline,Windstar,F-250,Aerostar,F-250 HD,F-150,Contour",
  },
  {
    year: 1997,
    make: "Geo",
    models: "Metro,Prizm,Tracker",
  },
  {
    year: 1997,
    make: "GMC",
    models:
      "C2500 Suburban,Savana 2500,K1500 Suburban,K2500,Savana 1500,C1500 Suburban,Yukon,C2500,Jimmy,Sonoma,Safari,K1500,C1500,K2500 Suburban",
  },
  {
    year: 1997,
    make: "Honda",
    models: "Civic del Sol,Prelude,Civic,Odyssey,CR-V,Accord,Passport",
  },
  {
    year: 1997,
    make: "Hyundai",
    models: "Sonata,Accent,Tiburon,Elantra",
  },
  {
    year: 1997,
    make: "Infiniti",
    models: "J30,Q45,I30,QX4",
  },
  {
    year: 1997,
    make: "Isuzu",
    models: "Trooper,Hombre,Oasis,Rodeo",
  },
  {
    year: 1997,
    make: "Jaguar",
    models: "XJ6,XK8,XJR,Vanden Plas",
  },
  {
    year: 1997,
    make: "Jeep",
    models: "Wrangler,Cherokee,Grand Cherokee,TJ",
  },
  {
    year: 1997,
    make: "Kia",
    models: "Sportage,Sephia",
  },
  {
    year: 1997,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1997,
    make: "Land Rover",
    models: "Discovery,Range Rover",
  },
  {
    year: 1997,
    make: "Lexus",
    models: "LX450,SC400,LS400,SC300,GS300,ES300",
  },
  {
    year: 1997,
    make: "Lincoln",
    models: "Continental,Mark VIII,Town Car",
  },
  {
    year: 1997,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 1997,
    make: "Mazda",
    models: "626,Protege,B2300,B4000,MPV,Miata,B3000,MX-6,Millenia",
  },
  {
    year: 1997,
    make: "Mercedes-Benz",
    models:
      "CL600,S420,SL500,SLK230,C230,E320,S600,C280,E420,CLK320,E500,C220,C36 AMG,E300,S320,SL320,SL60 AMG,SL600,CL500,S500",
  },
  {
    year: 1997,
    make: "Mercury",
    models: "Grand Marquis,Cougar,Sable,Mountaineer,Villager,Mystique,Tracer",
  },
  {
    year: 1997,
    make: "Mitsubishi",
    models: "Montero,3000GT,Diamante,Galant,Eclipse,Mirage,Montero Sport",
  },
  {
    year: 1997,
    make: "Nissan",
    models: "Altima,Tsubame,Maxima,240SX,Lucino,Pickup,Sentra,Tsuru,Pathfinder,200SX,Quest",
  },
  {
    year: 1997,
    make: "Oldsmobile",
    models: "Cutlass,Achieva,Regency,88,LSS,Bravada,Aurora,Cutlass Supreme,Silhouette",
  },
  {
    year: 1997,
    make: "Peugeot",
    models: "306,405",
  },
  {
    year: 1997,
    make: "Plymouth",
    models: "Voyager,Grand Voyager,Neon,Breeze,Prowler",
  },
  {
    year: 1997,
    make: "Pontiac",
    models: "Grand Am,Firefly,Firebird,Trans Sport,Bonneville,Sunfire,Sunrunner,Grand Prix",
  },
  {
    year: 1997,
    make: "Porsche",
    models: "911,Boxster",
  },
  {
    year: 1997,
    make: "Rolls-Royce",
    models: "Silver Dawn,Silver Spur",
  },
  {
    year: 1997,
    make: "Saab",
    models: "9000,900",
  },
  {
    year: 1997,
    make: "Saturn",
    models: "SC1,SW1,SC2,SL1,SW2,SL,SL2",
  },
  {
    year: 1997,
    make: "Subaru",
    models: "Legacy,SVX,Impreza",
  },
  {
    year: 1997,
    make: "Suzuki",
    models: "Swift,X-90,Esteem,Sidekick",
  },
  {
    year: 1997,
    make: "Toyota",
    models: "Paseo,RAV4,4Runner,T100,Avalon,Camry,Supra,Land Cruiser,Tercel,Previa,Tacoma,Celica,Corolla",
  },
  {
    year: 1997,
    make: "Volkswagen",
    models: "Cabrio,Golf,Passat,EuroVan,Derby,Jetta,Sedan",
  },
  {
    year: 1997,
    make: "Volvo",
    models: "850,S90,V90,960",
  },
  {
    year: 1996,
    make: "Acura",
    models: "SLX,TL,NSX,RL,Integra",
  },
  {
    year: 1996,
    make: "Audi",
    models: "A4,A4 Quattro,A6 Quattro,S6,Cabriolet,A6",
  },
  {
    year: 1996,
    make: "Bentley",
    models: "Continental,Azure,Brooklands,Turbo R",
  },
  {
    year: 1996,
    make: "BMW",
    models: "328is,850Ci,318is,528i,Z3,318ti,740iL,840Ci,325i,328i,750iL,318i,M3",
  },
  {
    year: 1996,
    make: "Buick",
    models: "Park Avenue,Regal,Roadmaster,Century,LeSabre,Skylark,Commercial Chassis,Riviera",
  },
  {
    year: 1996,
    make: "Cadillac",
    models: "Commercial Chassis,DeVille,Seville,Eldorado,Fleetwood",
  },
  {
    year: 1996,
    make: "Chevrolet",
    models:
      "Silverado,Blazer,Express 1500,Cavalier,Impala,K2500,K2500 Suburban,P30,Corsica,Astro,Lumina APV,C2500,C2500 Suburban,C35,K1500 Suburban,C1500,S10,G30,Beretta,C1500 Suburban,Monte Carlo,Lumina Van,Express 2500,Express 3500,K1500,Camaro,Corvette,Chevy,Suburban 1500,Tahoe,Lumina,Caprice",
  },
  {
    year: 1996,
    make: "Chrysler",
    models: "Neon,Breeze,Concorde,Town & Country,Voyager,Cirrus,New Yorker,Intrepid,Stratus,Grand Voyager,LHS,Sebring",
  },
  {
    year: 1996,
    make: "Dodge",
    models:
      "B2500,Ram 2500 Van,Ram 3500 Van,Avenger,Grand Caravan,Stratus,Caravan,Viper,Ram 1500,Ram 1500 Van,Intrepid,Neon,Ram 2500,Dakota,Ramcharger,Stealth",
  },
  {
    year: 1996,
    make: "Eagle",
    models: "Vision,Summit,Talon",
  },
  {
    year: 1996,
    make: "Ferrari",
    models: "F355 Berlinetta,F355 Spider,456 GT,F355 GTS,F50",
  },
  {
    year: 1996,
    make: "Ford",
    models:
      "Aerostar,F-350,Contour,F-100 Ranger,Crown Victoria,E-250 Econoline,Probe,Club Wagon,Aspire,Bronco,Thunderbird,Mystique,Sable,Mustang,Ranger,F-150,E-150 Econoline Club Wagon,Grand Marquis,Taurus,Escort,Explorer,E-150 Econoline,Windstar,F-250",
  },
  {
    year: 1996,
    make: "Geo",
    models: "Prizm,Tracker,Metro",
  },
  {
    year: 1996,
    make: "GMC",
    models:
      "C2500,Sonoma,Safari,K1500,K2500 Suburban,C2500 Suburban,Savana 2500,K1500 Suburban,K2500,Savana 1500,C1500,C1500 Suburban,Yukon,Jimmy",
  },
  {
    year: 1996,
    make: "Honda",
    models: "Accord,Passport,Civic del Sol,Prelude,Civic,Odyssey",
  },
  {
    year: 1996,
    make: "Hyundai",
    models: "Elantra,Sonata,Accent",
  },
  {
    year: 1996,
    make: "Infiniti",
    models: "G20,J30,Q45,I30",
  },
  {
    year: 1996,
    make: "Isuzu",
    models: "Hombre,Oasis,Trooper,Rodeo",
  },
  {
    year: 1996,
    make: "Jaguar",
    models: "XJ12,Vanden Plas,XJ6,XJS,XJR",
  },
  {
    year: 1996,
    make: "Jeep",
    models: "Grand Cherokee,Cherokee",
  },
  {
    year: 1996,
    make: "Kia",
    models: "Sephia,Sportage",
  },
  {
    year: 1996,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1996,
    make: "Land Rover",
    models: "Discovery,Range Rover",
  },
  {
    year: 1996,
    make: "Lexus",
    models: "GS300,ES300,LX450,SC400,LS400,SC300",
  },
  {
    year: 1996,
    make: "Lincoln",
    models: "Town Car,Continental,Mark VIII",
  },
  {
    year: 1996,
    make: "Mazda",
    models: "MX-3,MX-6,Millenia,B3000,626,Protege,B2300,B4000,MPV,Miata",
  },
  {
    year: 1996,
    make: "Mercedes-Benz",
    models: "S320,SL320,C230,SL600,S500,S420,SL500,E320,S600,C280,CL500,CL600,C220,C36 AMG,E300",
  },
  {
    year: 1996,
    make: "Mercury",
    models: "Mystique,Tracer,Grand Marquis,Cougar,Sable,Villager",
  },
  {
    year: 1996,
    make: "Mitsubishi",
    models: "Montero,3000GT,Diamante,Galant,Eclipse,Mirage",
  },
  {
    year: 1996,
    make: "Nissan",
    models: "Tsuru,Pathfinder,200SX,Quest,Altima,300ZX,Tsubame,Maxima,240SX,Lucino,Pickup,Sentra",
  },
  {
    year: 1996,
    make: "Oldsmobile",
    models: "Aurora,Cutlass Supreme,98,Silhouette,Cutlass Ciera,Achieva,Cutlass,88,LSS,Bravada",
  },
  {
    year: 1996,
    make: "Plymouth",
    models: "Grand Voyager,Voyager,Neon,Breeze",
  },
  {
    year: 1996,
    make: "Pontiac",
    models: "Bonneville,Sunfire,Sunrunner,Grand Prix,Grand Am,Firefly,Firebird,Trans Sport",
  },
  {
    year: 1996,
    make: "Porsche",
    models: "911",
  },
  {
    year: 1996,
    make: "Rolls-Royce",
    models: "Silver Dawn,Silver Spur",
  },
  {
    year: 1996,
    make: "Saab",
    models: "9000,900",
  },
  {
    year: 1996,
    make: "Saturn",
    models: "SL,SL2,SC1,SW1,SC2,SL1,SW2",
  },
  {
    year: 1996,
    make: "Subaru",
    models: "SVX,Impreza,Legacy",
  },
  {
    year: 1996,
    make: "Suzuki",
    models: "Swift,X-90,Esteem,Sidekick",
  },
  {
    year: 1996,
    make: "Toyota",
    models: "Previa,Tacoma,Celica,Corolla,Paseo,RAV4,4Runner,T100,Avalon,Camry,Supra,Land Cruiser,Tercel",
  },
  {
    year: 1996,
    make: "Volkswagen",
    models: "Jetta,Sedan,EuroVan,Cabrio,Golf,Caravelle,Passat,Derby",
  },
  {
    year: 1996,
    make: "Volvo",
    models: "960,850",
  },
  {
    year: 1995,
    make: "Acura",
    models: "NSX,Integra,Legend,TL",
  },
  {
    year: 1995,
    make: "Alfa Romeo",
    models: "164",
  },
  {
    year: 1995,
    make: "Audi",
    models: "Cabriolet,A6,90 Quattro,90,S6,A6 Quattro",
  },
  {
    year: 1995,
    make: "Bentley",
    models: "Turbo R,Continental,Brooklands",
  },
  {
    year: 1995,
    make: "BMW",
    models: "530i,750iL,850CSi,318i,325i,M3,320i,325is,850Ci,318is,740i,318ti,740iL,840Ci,540i,525i",
  },
  {
    year: 1995,
    make: "Buick",
    models: "Commercial Chassis,Riviera,Park Avenue,Regal,Roadmaster,Century,LeSabre,Skylark",
  },
  {
    year: 1995,
    make: "Cadillac",
    models: "Eldorado,Fleetwood,Commercial Chassis,DeVille,Seville",
  },
  {
    year: 1995,
    make: "Chevrolet",
    models:
      "G10,G20,G30,Beretta,C1500 Suburban,Monte Carlo,Lumina Van,K1500,Camaro,Corvette,Chevy,Suburban 1500,Tahoe,Lumina,Caprice,Silverado,Blazer,Cavalier,Impala,K2500,K2500 Suburban,P30,Corsica,Astro,Lumina APV,C2500,C2500 Suburban,C35,K1500 Suburban,LLV,C1500,S10",
  },
  {
    year: 1995,
    make: "Chrysler",
    models:
      "Intrepid,Stratus,Grand Voyager,LHS,LeBaron,Sebring,Concorde,Spirit,Neon,Town & Country,Voyager,Cirrus,New Yorker",
  },
  {
    year: 1995,
    make: "Dodge",
    models:
      "Ram 1500 Van,Intrepid,Neon,Ram 2500,Dakota,Ramcharger,Stealth,B2500,Avenger,Grand Caravan,Stratus,Caravan,Spirit,Viper,Colt,Ram 1500",
  },
  {
    year: 1995,
    make: "Eagle",
    models: "Vision,Talon,Summit",
  },
  {
    year: 1995,
    make: "Ferrari",
    models: "F355 Berlinetta,456 GT,F355 GTS,F50,348 Spider,512 M",
  },
  {
    year: 1995,
    make: "Ford",
    models:
      "Mystique,Sable,Mustang,Ranger,F-150,E-150 Econoline Club Wagon,Grand Marquis,Taurus,Escort,Explorer,E-150 Econoline,Windstar,F-250,Aerostar,F-350,Contour,Cougar,Crown Victoria,E-250 Econoline,Probe,Club Wagon,Aspire,Bronco,Thunderbird",
  },
  {
    year: 1995,
    make: "Geo",
    models: "Metro,Prizm,Tracker",
  },
  {
    year: 1995,
    make: "GMC",
    models:
      "C1500,C1500 Suburban,Yukon,Jimmy,C2500,Sonoma,Safari,K1500,K2500 Suburban,C2500 Suburban,K1500 Suburban,K2500",
  },
  {
    year: 1995,
    make: "Honda",
    models: "Prelude,Civic,Odyssey,Accord,Passport,Civic del Sol",
  },
  {
    year: 1995,
    make: "Hyundai",
    models: "Sonata,Scoupe,Accent,Elantra",
  },
  {
    year: 1995,
    make: "Infiniti",
    models: "G20,J30,Q45",
  },
  {
    year: 1995,
    make: "Isuzu",
    models: "Pickup,Rodeo,Trooper",
  },
  {
    year: 1995,
    make: "Jaguar",
    models: "XJR,XJ12,Vanden Plas,XJ6,XJS",
  },
  {
    year: 1995,
    make: "Jeep",
    models: "Cherokee,Grand Cherokee,Wrangler",
  },
  {
    year: 1995,
    make: "Kia",
    models: "Sportage,Sephia",
  },
  {
    year: 1995,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1995,
    make: "Land Rover",
    models: "Discovery,Range Rover",
  },
  {
    year: 1995,
    make: "Lexus",
    models: "LS400,SC300,GS300,ES300,SC400",
  },
  {
    year: 1995,
    make: "Lincoln",
    models: "Mark VIII,Town Car,Continental",
  },
  {
    year: 1995,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 1995,
    make: "Mazda",
    models: "929,MPV,Miata,MX-6,RX-7,MX-3,Protege,Millenia,B3000,323,626,B2300,B4000",
  },
  {
    year: 1995,
    make: "Mercedes-Benz",
    models: "S600,C280,S350,C220,C36 AMG,E300,S320,SL320,SL600,S500,E420,S600L,S420,SL500,E320",
  },
  {
    year: 1995,
    make: "Mercury",
    models: "Villager,Mystique,Sable,Tracer,Grand Marquis,Cougar",
  },
  {
    year: 1995,
    make: "Mitsubishi",
    models: "Expo,Eclipse,Mirage,Montero,3000GT,Diamante,Galant",
  },
  {
    year: 1995,
    make: "Nissan",
    models: "Maxima,240SX,Pickup,Sentra,Tsuru,Pathfinder,200SX,Axxess,Quest,Altima,300ZX,Tsubame",
  },
  {
    year: 1995,
    make: "Oldsmobile",
    models: "88,Aurora,Cutlass Supreme,98,Silhouette,Achieva,Cutlass Ciera,Cutlass",
  },
  {
    year: 1995,
    make: "Plymouth",
    models: "Neon,Acclaim,Colt,Grand Voyager,Voyager",
  },
  {
    year: 1995,
    make: "Pontiac",
    models: "Firefly,Firebird,Trans Sport,Bonneville,Grand Prix,Sunfire,Sunrunner,Grand Am",
  },
  {
    year: 1995,
    make: "Porsche",
    models: "911,928,968",
  },
  {
    year: 1995,
    make: "Rolls-Royce",
    models: "Silver Spur,Flying Spur,Corniche S,Silver Spirit,Corniche IV,Silver Dawn",
  },
  {
    year: 1995,
    make: "Saab",
    models: "900,9000",
  },
  {
    year: 1995,
    make: "Saturn",
    models: "SC2,SL1,SW2,SL,SL2,SC1,SW1",
  },
  {
    year: 1995,
    make: "Subaru",
    models: "Impreza,Justy,Legacy,SVX",
  },
  {
    year: 1995,
    make: "Suzuki",
    models: "Esteem,Sidekick,Swift",
  },
  {
    year: 1995,
    make: "Toyota",
    models: "Supra,Land Cruiser,Tercel,Pickup,Previa,Tacoma,Celica,Corolla,Paseo,4Runner,MR2,T100,Avalon,Camry",
  },
  {
    year: 1995,
    make: "Volkswagen",
    models: "Cabrio,Golf,Caravelle,Corrado,Passat,Derby,Jetta,Sedan,EuroVan",
  },
  {
    year: 1995,
    make: "Volvo",
    models: "960,940,850",
  },
  {
    year: 1994,
    make: "Acura",
    models: "Legend,NSX,Integra,Vigor",
  },
  {
    year: 1994,
    make: "Alfa Romeo",
    models: "Spider,164",
  },
  {
    year: 1994,
    make: "Aston Martin",
    models: "Virage",
  },
  {
    year: 1994,
    make: "Audi",
    models: "Cabriolet,100,S4,100 Quattro,V8 Quattro,90 Quattro,90",
  },
  {
    year: 1994,
    make: "Bentley",
    models: "Continental,Brooklands,Turbo R",
  },
  {
    year: 1994,
    make: "BMW",
    models: "740iL,540i,M3,525i,530i,750iL,840Ci,850CSi,325i,318i,320i,325is,850Ci,318is,740i",
  },
  {
    year: 1994,
    make: "Buick",
    models: "Regal,Roadmaster,Century,LeSabre,Skylark,Commercial Chassis,Park Avenue",
  },
  {
    year: 1994,
    make: "Cadillac",
    models: "DeVille,Seville,Eldorado,Fleetwood,Commercial Chassis",
  },
  {
    year: 1994,
    make: "Chevrolet",
    models:
      "Impala,K2500,K2500 Suburban,P30,Cavalier,Corsica,C35,Astro,Lumina APV,C2500,C2500 Suburban,S10 Blazer,K1500 Suburban,LLV,Blazer,C1500,S10,G10,G20,G30,Beretta,C1500 Suburban,Commercial Chassis,Chevy,K1500,Camaro,Corvette,Lumina,Suburban,Caprice",
  },
  {
    year: 1994,
    make: "Chrysler",
    models: "New Yorker,Phantom,Grand Voyager,Intrepid,LHS,LeBaron,Concorde,Spirit,Shadow,Voyager,Town & Country",
  },
  {
    year: 1994,
    make: "Dodge",
    models:
      "Caravan,Colt,Spirit,Viper,Shadow,Ram 1500,Ramcharger,Intrepid,B250,Ram 2500,Dakota,B350,Stealth,Grand Caravan",
  },
  {
    year: 1994,
    make: "Eagle",
    models: "Summit,Vision,Talon",
  },
  {
    year: 1994,
    make: "Ferrari",
    models: "348 Spider,512 TR,F355 GTS,F355 Berlinetta,348 GTB,348 GTS",
  },
  {
    year: 1994,
    make: "Ford",
    models:
      "F-150,Crown Victoria,E-250 Econoline,Ghia,F-250,Probe,Aspire,Bronco,Thunderbird,F-350,Sable,Mustang,Ranger,E-150 Econoline Club Wagon,Grand Marquis,Taurus,Escort,Explorer,E-150 Econoline,Topaz,Aerostar,Cougar,Tempo",
  },
  {
    year: 1994,
    make: "Geo",
    models: "Tracker,Metro,Prizm",
  },
  {
    year: 1994,
    make: "GMC",
    models:
      "K2500 Suburban,Safari,C2500 Suburban,K1500 Suburban,K2500,C1500,C1500 Suburban,Yukon,Jimmy,C2500,Sonoma,K1500",
  },
  {
    year: 1994,
    make: "Honda",
    models: "Civic del Sol,Prelude,Civic,Accord,Passport",
  },
  {
    year: 1994,
    make: "Hyundai",
    models: "Excel,Sonata,Scoupe,Elantra",
  },
  {
    year: 1994,
    make: "Infiniti",
    models: "J30,Q45,G20",
  },
  {
    year: 1994,
    make: "Isuzu",
    models: "Pickup,Rodeo,Trooper,Amigo",
  },
  {
    year: 1994,
    make: "Jaguar",
    models: "XJ6,XJS,XJ12,Vanden Plas",
  },
  {
    year: 1994,
    make: "Jeep",
    models: "Cherokee,Wrangler,Grand Cherokee",
  },
  {
    year: 1994,
    make: "Kia",
    models: "Sephia",
  },
  {
    year: 1994,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1994,
    make: "Land Rover",
    models: "Discovery,Range Rover",
  },
  {
    year: 1994,
    make: "Lexus",
    models: "ES300,SC400,LS400,SC300,GS300",
  },
  {
    year: 1994,
    make: "Lincoln",
    models: "Continental,Mark VIII,Town Car",
  },
  {
    year: 1994,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 1994,
    make: "Mazda",
    models: "B3000,323,626,B2300,B4000,929,MX-6,Miata,RX-7,Protege,MX-3,MPV",
  },
  {
    year: 1994,
    make: "Mercedes-Benz",
    models: "S500,E420,S420,SL500,E320,E500,S600,C280,S350,C220,S320,SL320,SL600",
  },
  {
    year: 1994,
    make: "Mercury",
    models: "Tracer,Grand Marquis,Cougar,Topaz,Villager,Capri,Sable",
  },
  {
    year: 1994,
    make: "Mitsubishi",
    models: "Montero,3000GT,Diamante,Galant,Expo,Eclipse,Mirage,Precis,Expo LRV",
  },
  {
    year: 1994,
    make: "Nissan",
    models: "Axxess,Tsuru,Pickup,Quest,Altima,300ZX,Maxima,240SX,Sentra,Tsubame,Pathfinder",
  },
  {
    year: 1994,
    make: "Oldsmobile",
    models: "Silhouette,Achieva,Bravada,98,Cutlass Ciera,88,Cutlass,Cutlass Cruiser,Cutlass Supreme",
  },
  {
    year: 1994,
    make: "Plymouth",
    models: "Colt,Grand Voyager,Laser,Voyager,Sundance,Acclaim",
  },
  {
    year: 1994,
    make: "Pontiac",
    models: "Sunbird,Grand Am,Firefly,Firebird,Trans Sport,Bonneville,Grand Prix,Sunrunner",
  },
  {
    year: 1994,
    make: "Porsche",
    models: "968,911,928",
  },
  {
    year: 1994,
    make: "Rolls-Royce",
    models: "Corniche IV,Silver Spur,Touring Limousine,Silver Spirit",
  },
  {
    year: 1994,
    make: "Saab",
    models: "9000,900",
  },
  {
    year: 1994,
    make: "Saturn",
    models: "SL,SL2,SC1,SW1,SC2,SL1,SW2",
  },
  {
    year: 1994,
    make: "Subaru",
    models: "Legacy,Impreza,Justy,Loyale,SVX",
  },
  {
    year: 1994,
    make: "Suzuki",
    models: "Swift,Sidekick",
  },
  {
    year: 1994,
    make: "Toyota",
    models: "Paseo,4Runner,MR2,T100,Camry,Supra,Land Cruiser,Tercel,Pickup,Previa,Celica,Corolla",
  },
  {
    year: 1994,
    make: "Volkswagen",
    models: "Jetta,Transporter,Golf,Corrado,Passat,EuroVan,Sedan",
  },
  {
    year: 1994,
    make: "Volvo",
    models: "940,850,960",
  },
  {
    year: 1993,
    make: "Acura",
    models: "Integra,Vigor,Legend,NSX",
  },
  {
    year: 1993,
    make: "Alfa Romeo",
    models: "164,Spider",
  },
  {
    year: 1993,
    make: "Aston Martin",
    models: "Virage",
  },
  {
    year: 1993,
    make: "Asuna",
    models: "SE,GT,Sunfire,Sunrunner",
  },
  {
    year: 1993,
    make: "Audi",
    models: "90 Quattro,90,100,S4,100 Quattro,V8 Quattro",
  },
  {
    year: 1993,
    make: "Bentley",
    models: "Turbo R,Continental,Brooklands",
  },
  {
    year: 1993,
    make: "BMW",
    models: "325i,525iT,318i,325is,850Ci,318is,740i,740iL,525i,320i,535i,750iL,M5",
  },
  {
    year: 1993,
    make: "Buick",
    models: "Riviera,Park Avenue,Regal,Roadmaster,Century,LeSabre,Skylark,Commercial Chassis",
  },
  {
    year: 1993,
    make: "Cadillac",
    models: "Commercial Chassis,DeVille,Fleetwood,Seville,Allante,Eldorado,60 Special",
  },
  {
    year: 1993,
    make: "Chevrolet",
    models:
      "Lumina,Suburban,Caprice,K2500 Suburban,P30,Cavalier,Corvette,C35,Astro,Blazer,Lumina APV,C2500 Suburban,S10 Blazer,K1500 Suburban,K2500,LLV,C1500,S10,G10,G20,G30,Beretta,C1500 Suburban,C2500,Corsica,Commercial Chassis,K1500,Camaro",
  },
  {
    year: 1993,
    make: "Chrysler",
    models:
      "Shadow,Dynasty,Town & Country,New Yorker,Phantom,Grand Voyager,Intrepid,Imperial,LeBaron,Concorde,Spirit,Daytona",
  },
  {
    year: 1993,
    make: "Dodge",
    models:
      "B350,Stealth,Ram 50,D150,Spirit,Caravan,Colt,Daytona,Grand Caravan,Viper,Ramcharger,Shadow,D250,Dynasty,Intrepid,B250,Dakota",
  },
  {
    year: 1993,
    make: "Eagle",
    models: "Vision,Talon,Summit,2000 GTX",
  },
  {
    year: 1993,
    make: "Excalibur",
    models: "Limited",
  },
  {
    year: 1993,
    make: "Ferrari",
    models: "348 GTB,348 GTS,Mondial t,348 Spider,512 TR",
  },
  {
    year: 1993,
    make: "Ford",
    models:
      "Escort,Explorer,E-150 Econoline,Topaz,Aerostar,Cougar,Tempo,F-150,Crown Victoria,E-250 Econoline,Ghia,F-250,Festiva,Probe,Bronco,Thunderbird,Mustang,F-350,Ranger,E-150 Econoline Club Wagon,Grand Marquis,Taurus",
  },
  {
    year: 1993,
    make: "Geo",
    models: "Metro,Prizm,Tracker,Storm",
  },
  {
    year: 1993,
    make: "GMC",
    models:
      "Jimmy,Typhoon,C1500,C2500,Sonoma,K1500,K2500 Suburban,Safari,C2500 Suburban,K1500 Suburban,K2500,C1500 Suburban,Yukon",
  },
  {
    year: 1993,
    make: "Honda",
    models: "Accord,Civic del Sol,Prelude,Civic",
  },
  {
    year: 1993,
    make: "Hyundai",
    models: "Elantra,Excel,Sonata,Scoupe",
  },
  {
    year: 1993,
    make: "Infiniti",
    models: "G20,J30,Q45",
  },
  {
    year: 1993,
    make: "Isuzu",
    models: "Stylus,Amigo,Pickup,Rodeo,Trooper",
  },
  {
    year: 1993,
    make: "Jaguar",
    models: "XJRS,Vanden Plas,XJ6,XJS",
  },
  {
    year: 1993,
    make: "Jeep",
    models: "Wrangler,Grand Wagoneer,Cherokee,Grand Cherokee",
  },
  {
    year: 1993,
    make: "Lada",
    models: "Samara,Niva,Signet",
  },
  {
    year: 1993,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1993,
    make: "Land Rover",
    models: "Range Rover",
  },
  {
    year: 1993,
    make: "Lexus",
    models: "GS300,ES300,SC400,LS400,SC300",
  },
  {
    year: 1993,
    make: "Lincoln",
    models: "Continental,Mark VIII,Town Car",
  },
  {
    year: 1993,
    make: "Lotus",
    models: "Esprit",
  },
  {
    year: 1993,
    make: "Mazda",
    models: "Miata,RX-7,MPV,MX-3,323,626,929,B2600,MX-6,Protege",
  },
  {
    year: 1993,
    make: "Mercedes-Benz",
    models: "300SD,500SL,600SL,400SEL,600SEC,300D,300SL,500SEC,600SEL,300SE,500SEL,190E,500E,300CE,300E,300TE,400E",
  },
  {
    year: 1993,
    make: "Mercury",
    models: "Villager,Capri,Sable,Grand Marquis,Cougar,Tracer,Topaz",
  },
  {
    year: 1993,
    make: "Mitsubishi",
    models: "Mirage,Precis,Expo LRV,Montero,3000GT,Diamante,Galant,Expo,Eclipse",
  },
  {
    year: 1993,
    make: "Nissan",
    models: "240SX,Sentra,Tsubame,Pathfinder,Axxess,Tsuru,Quest,Altima,Pickup,300ZX,Maxima,NX",
  },
  {
    year: 1993,
    make: "Oldsmobile",
    models: "Cutlass,Cutlass Supreme,Silhouette,Cutlass Cruiser,Achieva,Bravada,98,Cutlass Ciera,88",
  },
  {
    year: 1993,
    make: "Plymouth",
    models: "Acclaim,Colt,Grand Voyager,Laser,Voyager,Sundance",
  },
  {
    year: 1993,
    make: "Pontiac",
    models: "Firebird,LeMans,Trans Sport,Bonneville,Grand Prix,Sunbird,Grand Am",
  },
  {
    year: 1993,
    make: "Porsche",
    models: "968,911,928",
  },
  {
    year: 1993,
    make: "Rolls-Royce",
    models: "Silver Spirit,Corniche IV,Silver Spur",
  },
  {
    year: 1993,
    make: "Saab",
    models: "900,9000",
  },
  {
    year: 1993,
    make: "Saturn",
    models: "SW2,SL,SL2,SC1,SW1,SC2,SL1",
  },
  {
    year: 1993,
    make: "Subaru",
    models: "SVX,Justy,Loyale,Legacy,Impreza",
  },
  {
    year: 1993,
    make: "Suzuki",
    models: "Swift,Sidekick",
  },
  {
    year: 1993,
    make: "Toyota",
    models: "Tercel,Pickup,Previa,Celica,Corolla,Paseo,4Runner,MR2,T100,Camry,Supra,Land Cruiser",
  },
  {
    year: 1993,
    make: "Volkswagen",
    models: "EuroVan,Jetta,Sedan,Golf,Fox,Transporter,Cabriolet,Corrado,Passat",
  },
  {
    year: 1993,
    make: "Volvo",
    models: "960,940,850,240",
  },
  {
    year: 1992,
    make: "Acura",
    models: "NSX,Integra,Vigor,Legend",
  },
  {
    year: 1992,
    make: "Alfa Romeo",
    models: "Spider,164",
  },
  {
    year: 1992,
    make: "Aston Martin",
    models: "Virage",
  },
  {
    year: 1992,
    make: "Asuna",
    models: "Sunrunner",
  },
  {
    year: 1992,
    make: "Audi",
    models: "100,S4,90 Quattro,100 Quattro,V8 Quattro,80 Quattro,80",
  },
  {
    year: 1992,
    make: "Bentley",
    models: "Continental,Mulsanne,Turbo R",
  },
  {
    year: 1992,
    make: "BMW",
    models: "525i,535i,750iL,M5,325i,850i,318i,320i,325is,318is,735iL,735i",
  },
  {
    year: 1992,
    make: "Buick",
    models: "Commercial Chassis,Skylark,Riviera,Park Avenue,Regal,Roadmaster,Century,LeSabre",
  },
  {
    year: 1992,
    make: "Cadillac",
    models: "Allante,Fleetwood,Eldorado,Commercial Chassis,DeVille,Seville,Brougham",
  },
  {
    year: 1992,
    make: "Chevrolet",
    models:
      "K1500 Suburban,K2500,LLV,C1500,S10,G10,G20,G30,Beretta,C1500 Suburban,C2500,Corsica,Commercial Chassis,Sprint,K1500,Lumina,Suburban,Camaro,Caprice,K2500 Suburban,Metro,P30,Cavalier,C35,Astro,Blazer,Lumina APV,C2500 Suburban,S10 Blazer,Corvette",
  },
  {
    year: 1992,
    make: "Chrysler",
    models: "Grand Voyager,Imperial,LeBaron,Spirit,Daytona,Shadow,Dynasty,Town & Country,New Yorker,Phantom",
  },
  {
    year: 1992,
    make: "Daihatsu",
    models: "Rocky,Charade",
  },
  {
    year: 1992,
    make: "Dodge",
    models:
      "Ramcharger,D250,Dynasty,Grand Caravan,B250,Dakota,DM350,B350,Stealth,Ram 50,Shadow,Spirit,D150,Monaco,Caravan,Colt,Daytona,Viper",
  },
  {
    year: 1992,
    make: "Eagle",
    models: "Vista,Premier,Summit,Talon,2000 GTX",
  },
  {
    year: 1992,
    make: "Ferrari",
    models: "512 TR,F40,348 TB,348 TS,Mondial t",
  },
  {
    year: 1992,
    make: "Ford",
    models:
      "Bronco,E-150 Econoline Club Wagon,Grand Marquis,Ranger,Taurus,Explorer,E-150 Econoline,Topaz,Escort,F-150,Aerostar,Cougar,Mustang,F-250,Tempo,Thunderbird,Crown Victoria,E-250 Econoline,Ghia,F-350,Festiva,Probe",
  },
  {
    year: 1992,
    make: "Geo",
    models: "Storm,Prizm,Metro,Tracker",
  },
  {
    year: 1992,
    make: "GMC",
    models:
      "K1500 Suburban,K2500,C1500 Suburban,Yukon,Jimmy,Typhoon,C1500,C2500,Sonoma,K1500,K2500 Suburban,Safari,C2500 Suburban",
  },
  {
    year: 1992,
    make: "Honda",
    models: "Prelude,Civic,Accord",
  },
  {
    year: 1992,
    make: "Hyundai",
    models: "Excel,Sonata,Scoupe,Elantra",
  },
  {
    year: 1992,
    make: "Infiniti",
    models: "Q45,G20,M30",
  },
  {
    year: 1992,
    make: "Isuzu",
    models: "Trooper,Impulse,Amigo,Pickup,Rodeo,Stylus",
  },
  {
    year: 1992,
    make: "Jaguar",
    models: "XJ12,Vanden Plas,XJ6,XJS",
  },
  {
    year: 1992,
    make: "Jeep",
    models: "Cherokee,Comanche,Wrangler",
  },
  {
    year: 1992,
    make: "Lada",
    models: "Samara,Niva,Signet",
  },
  {
    year: 1992,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1992,
    make: "Land Rover",
    models: "Range Rover",
  },
  {
    year: 1992,
    make: "Lexus",
    models: "LS400,SC300,ES300,SC400",
  },
  {
    year: 1992,
    make: "Lincoln",
    models: "Mark VII,Town Car,Continental",
  },
  {
    year: 1992,
    make: "Lotus",
    models: "Esprit,Elan",
  },
  {
    year: 1992,
    make: "Mazda",
    models: "323,626,MX-3,929,Protege,B2600,Miata,MX-6,MPV",
  },
  {
    year: 1992,
    make: "Mercedes-Benz",
    models: "190E,500E,300CE,300E,300TE,400E,300SD,500SL,400SE,300D,300SL,600SEL,300SE,500SEL",
  },
  {
    year: 1992,
    make: "Mercury",
    models: "Topaz,Capri,Sable,Grand Marquis,Cougar,Tracer",
  },
  {
    year: 1992,
    make: "Mitsubishi",
    models: "3000GT,Diamante,Galant,Expo,Eclipse,Mirage,Precis,Expo LRV,Montero",
  },
  {
    year: 1992,
    make: "Nissan",
    models: "300ZX,Stanza,Maxima,Hikari,NX,240SX,Sentra,Pathfinder,Axxess,Tsuru,Pickup",
  },
  {
    year: 1992,
    make: "Oldsmobile",
    models:
      "Bravada,98,Toronado,Cutlass Ciera,88,Custom Cruiser,Cutlass,Cutlass Supreme,Cutlass Cruiser,Silhouette,Achieva",
  },
  {
    year: 1992,
    make: "Plymouth",
    models: "Laser,Voyager,Acclaim,Sundance,Colt,Grand Voyager",
  },
  {
    year: 1992,
    make: "Pontiac",
    models: "Grand Am,Firebird,LeMans,Trans Sport,Bonneville,Grand Prix,Sunbird",
  },
  {
    year: 1992,
    make: "Porsche",
    models: "911,968",
  },
  {
    year: 1992,
    make: "Rolls-Royce",
    models: "Silver Spur,Silver Spirit,Corniche II",
  },
  {
    year: 1992,
    make: "Saab",
    models: "9000,900",
  },
  {
    year: 1992,
    make: "Saturn",
    models: "SC,SL1,SL,SL2",
  },
  {
    year: 1992,
    make: "Subaru",
    models: "SVX,Justy,Loyale,Legacy",
  },
  {
    year: 1992,
    make: "Suzuki",
    models: "Sidekick,Swift",
  },
  {
    year: 1992,
    make: "Toyota",
    models: "MR2,Camry,Supra,Land Cruiser,Tercel,Pickup,Previa,Celica,Corolla,Cressida,Paseo,4Runner",
  },
  {
    year: 1992,
    make: "Volkswagen",
    models: "Fox,Transporter,Cabriolet,EuroVan,Corrado,Passat,Jetta,Golf,Sedan",
  },
  {
    year: 1992,
    make: "Volvo",
    models: "240,960,740,940",
  },
  {
    year: 1992,
    make: "Yugo",
    models: "Cabrio,GV",
  },
  {
    year: 1991,
    make: "Acura",
    models: "Legend,NSX,Integra",
  },
  {
    year: 1991,
    make: "Alfa Romeo",
    models: "Spider,164",
  },
  {
    year: 1991,
    make: "Aston Martin",
    models: "Virage",
  },
  {
    year: 1991,
    make: "Audi",
    models: "200 Quattro,Coupe Quattro,100,90,90 Quattro,100 Quattro,V8 Quattro,80 Quattro,200,80",
  },
  {
    year: 1991,
    make: "Bentley",
    models: "Continental,Eight,Mulsanne,Turbo R",
  },
  {
    year: 1991,
    make: "BMW",
    models: "318is,735iL,735i,525i,325iX,535i,750iL,M3,M5,325i,850i,318i,325is",
  },
  {
    year: 1991,
    make: "Buick",
    models: "Reatta,LeSabre,Park Avenue,Regal,Century,Commercial Chassis,Skylark,Riviera,Roadmaster",
  },
  {
    year: 1991,
    make: "Cadillac",
    models: "Seville,Brougham,Allante,Eldorado,Fleetwood,Commercial Chassis,DeVille",
  },
  {
    year: 1991,
    make: "Chevrolet",
    models:
      "P30,Cavalier,C35,Astro,Lumina APV,R1500 Suburban,S10 Blazer,Corvette,V1500 Suburban,V3500,K2500,LLV,C1500,G10,G20,G30,Beretta,C2500,Corsica,Commercial Chassis,Sprint,K1500,Tracker,Lumina,Blazer,R2500 Suburban,Suburban,S10,Camaro,Caprice,V2500 Suburban",
  },
  {
    year: 1991,
    make: "Chrysler",
    models:
      "Dynasty,Town & Country,New Yorker,Phantom,Grand Voyager,Imperial,LeBaron,Spirit,TC Maserati,Daytona,Shadow",
  },
  {
    year: 1991,
    make: "Daihatsu",
    models: "Charade,Rocky",
  },
  {
    year: 1991,
    make: "Dodge",
    models:
      "Monaco,DM350,Caravan,Colt,Daytona,Grand Caravan,Ramcharger,D250,Dynasty,B250,Dakota,B350,Stealth,Ram 50,Shadow,Spirit,D150",
  },
  {
    year: 1991,
    make: "Eagle",
    models: "Summit,Talon,2000 GTX,Vista,Premier",
  },
  {
    year: 1991,
    make: "Ferrari",
    models: "Mondial t,F40,Testarossa,348 TB,348 TS",
  },
  {
    year: 1991,
    make: "Ford",
    models:
      "F-250,Tempo,E-250 Econoline Club Wagon,B-200,Thunderbird,E-150 Econoline Club Wagon,Ghia,F-350,Festiva,Probe,Bronco,E-250 Econoline,Grand Marquis,F-200,Ranger,Taurus,Explorer,Country Squire,LTD Crown Victoria,Topaz,Escort,F-150,Aerostar,E-150 Econoline,Cougar,Mustang",
  },
  {
    year: 1991,
    make: "Geo",
    models: "Tracker,Storm,Metro,Prizm",
  },
  {
    year: 1991,
    make: "GMC",
    models:
      "Sonoma,Tracker,K1500,R2500 Suburban,V2500 Suburban,Safari,K2500,R1500 Suburban,V1500 Suburban,V3500,Jimmy,S15 Jimmy,Syclone,C1500,C2500",
  },
  {
    year: 1991,
    make: "Honda",
    models: "Prelude,Civic,CRX,Accord",
  },
  {
    year: 1991,
    make: "Hyundai",
    models: "Excel,Sonata,Scoupe",
  },
  {
    year: 1991,
    make: "Infiniti",
    models: "M30,Q45,G20",
  },
  {
    year: 1991,
    make: "Isuzu",
    models: "Amigo,Pickup,Rodeo,Trooper,Stylus,Impulse",
  },
  {
    year: 1991,
    make: "Jaguar",
    models: "Vanden Plas,XJ6,XJS,XJ12",
  },
  {
    year: 1991,
    make: "Jeep",
    models: "Cherokee,Comanche,Grand Wagoneer,Wrangler",
  },
  {
    year: 1991,
    make: "Lada",
    models: "Niva,Signet,Samara",
  },
  {
    year: 1991,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1991,
    make: "Land Rover",
    models: "Range Rover",
  },
  {
    year: 1991,
    make: "Lexus",
    models: "LS400,ES250",
  },
  {
    year: 1991,
    make: "Lincoln",
    models: "Continental,Mark VII,Town Car",
  },
  {
    year: 1991,
    make: "Lotus",
    models: "Elan,Esprit",
  },
  {
    year: 1991,
    make: "Maserati",
    models: "Spyder",
  },
  {
    year: 1991,
    make: "Mazda",
    models: "MX-6,MPV,Protege,RX-7,323,929,626,B2600,Miata",
  },
  {
    year: 1991,
    make: "Mercedes-Benz",
    models: "300D,300SL,300SE,560SEC,190E,350SD,420SEL,300CE,300E,300TE,560SEL,350SDL,300SEL,500SL",
  },
  {
    year: 1991,
    make: "Mercury",
    models: "Colony Park,Sable,Grand Marquis,Cougar,Tracer,Topaz,Capri",
  },
  {
    year: 1991,
    make: "Mitsubishi",
    models: "Montero,3000GT,Galant,Eclipse,Mirage,Precis",
  },
  {
    year: 1991,
    make: "Nissan",
    models: "Pathfinder,Axxess,Tsuru,Micra,Pickup,300ZX,Stanza,Maxima,Hikari,NX,240SX,Sentra",
  },
  {
    year: 1991,
    make: "Oldsmobile",
    models:
      "Cutlass Supreme,Delta 88,Cutlass Cruiser,Silhouette,Cutlass Calais,Bravada,Toronado,Cutlass Ciera,Custom Cruiser,Cutlass,98",
  },
  {
    year: 1991,
    make: "Peugeot",
    models: "505,405",
  },
  {
    year: 1991,
    make: "Plymouth",
    models: "Sundance,Colt,Grand Voyager,Laser,Voyager,Acclaim",
  },
  {
    year: 1991,
    make: "Pontiac",
    models: "Grand Prix,Tempest,6000,Grand Am,Sunbird,Optima,Firebird,LeMans,Trans Sport,Firefly,Bonneville",
  },
  {
    year: 1991,
    make: "Porsche",
    models: "928,944,911",
  },
  {
    year: 1991,
    make: "Rolls-Royce",
    models: "Corniche II,Silver Spur,Silver Spirit",
  },
  {
    year: 1991,
    make: "Saab",
    models: "9000,900",
  },
  {
    year: 1991,
    make: "Saturn",
    models: "SL,SL2,SC,SL1",
  },
  {
    year: 1991,
    make: "Sterling",
    models: "827",
  },
  {
    year: 1991,
    make: "Subaru",
    models: "Justy,Loyale,Legacy,XT",
  },
  {
    year: 1991,
    make: "Suzuki",
    models: "Swift,Sidekick",
  },
  {
    year: 1991,
    make: "Toyota",
    models: "Celica,Corolla,Cressida,4Runner,MR2,Camry,Supra,Land Cruiser,Tercel,Pickup,Previa",
  },
  {
    year: 1991,
    make: "Volkswagen",
    models: "Jetta,Vanagon,Golf,Sedan,Fox,Transporter,Cabriolet,Corrado,Passat",
  },
  {
    year: 1991,
    make: "Volvo",
    models: "740,940,240,780",
  },
  {
    year: 1991,
    make: "Yugo",
    models: "Cabrio,GV",
  },
  {
    year: 1990,
    make: "Acura",
    models: "Integra,Legend",
  },
  {
    year: 1990,
    make: "Alfa Romeo",
    models: "Spider",
  },
  {
    year: 1990,
    make: "Aston Martin",
    models: "Virage",
  },
  {
    year: 1990,
    make: "Audi",
    models: "90,90 Quattro,100 Quattro,V8 Quattro,80 Quattro,200,80,200 Quattro,Coupe Quattro,100",
  },
  {
    year: 1990,
    make: "Bentley",
    models: "Mulsanne,Turbo R,Continental,Eight",
  },
  {
    year: 1990,
    make: "BMW",
    models: "325iX,535i,750iL,M3,325i,325is,735iL,735i,525i",
  },
  {
    year: 1990,
    make: "Buick",
    models: "Skylark,Riviera,LeSabre,Reatta,Electra,Regal,Century",
  },
  {
    year: 1990,
    make: "Cadillac",
    models: "Eldorado,Fleetwood,Commercial Chassis,DeVille,Seville,Brougham,Allante",
  },
  {
    year: 1990,
    make: "Chevrolet",
    models:
      "Sprint,K1500,Tracker,Lumina,Blazer,R2500 Suburban,Suburban,Camaro,Caprice,V2500 Suburban,S10,Celebrity,P30,C35,Astro,Lumina APV,R1500 Suburban,S10 Blazer,Corvette,V1500 Suburban,V3500,K2500,LLV,C1500,G10,G20,G30,Beretta,C2500,Cavalier,Corsica",
  },
  {
    year: 1990,
    make: "Chrysler",
    models: "Spirit,TC Maserati,Daytona,Shadow,Dynasty,Phantom,Town & Country,New Yorker,Imperial,LeBaron",
  },
  {
    year: 1990,
    make: "Daihatsu",
    models: "Rocky,Charade",
  },
  {
    year: 1990,
    make: "Dodge",
    models:
      "Shadow,B250,Dakota,Omni,B350,2000 GTX,Ram 50,Spirit,D150,Monaco,Caravan,Colt,Daytona,DM350,Grand Caravan,Ramcharger,D250,Dynasty",
  },
  {
    year: 1990,
    make: "Eagle",
    models: "Premier,Talon,Vista,Summit",
  },
  {
    year: 1990,
    make: "Ferrari",
    models: "F40,Testarossa,348 TB,348 TS,Mondial t",
  },
  {
    year: 1990,
    make: "Ford",
    models:
      "F-200,Ranger,Taurus,Country Squire,LTD Crown Victoria,Festiva,Topaz,Escort,F-150,Aerostar,E-150 Econoline,Cougar,Mustang,F-250,Tempo,E-250 Econoline Club Wagon,B-200,Thunderbird,E-150 Econoline Club Wagon,F-350,Probe,Bronco,Bronco II,E-250 Econoline",
  },
  {
    year: 1990,
    make: "Geo",
    models: "Prizm,Tracker,Storm,Metro",
  },
  {
    year: 1990,
    make: "GMC",
    models:
      "V1500 Suburban,V3500,Jimmy,S15 Jimmy,C1500,C2500,S15,Tracker,K1500,R2500 Suburban,V2500 Suburban,Safari,K2500,R1500 Suburban",
  },
  {
    year: 1990,
    make: "Honda",
    models: "Accord,Prelude,Civic,CRX",
  },
  {
    year: 1990,
    make: "Hyundai",
    models: "Excel,Sonata",
  },
  {
    year: 1990,
    make: "Infiniti",
    models: "M30,Q45",
  },
  {
    year: 1990,
    make: "Isuzu",
    models: "Impulse,Amigo,Pickup,Trooper",
  },
  {
    year: 1990,
    make: "Jaguar",
    models: "Vanden Plas,XJ6,XJS,XJ12",
  },
  {
    year: 1990,
    make: "Jeep",
    models: "Wrangler,Cherokee,Comanche,Grand Wagoneer,Wagoneer",
  },
  {
    year: 1990,
    make: "Lada",
    models: "Samara,Niva,Signet",
  },
  {
    year: 1990,
    make: "Lamborghini",
    models: "Diablo",
  },
  {
    year: 1990,
    make: "Land Rover",
    models: "Range Rover",
  },
  {
    year: 1990,
    make: "Lexus",
    models: "LS400,ES250",
  },
  {
    year: 1990,
    make: "Lincoln",
    models: "Town Car,Continental,Mark VII",
  },
  {
    year: 1990,
    make: "Lotus",
    models: "Esprit,Elan",
  },
  {
    year: 1990,
    make: "Maserati",
    models: "228i,Spyder,430i",
  },
  {
    year: 1990,
    make: "Mazda",
    models: "Miata,MX-6,626,MPV,RX-7,323,Protege,929,B2600",
  },
  {
    year: 1990,
    make: "Mercedes-Benz",
    models: "350SDL,300SEL,500SL,190E,300D,300SL,300SE,560SEC,420SEL,300CE,300E,300TE,560SEL",
  },
  {
    year: 1990,
    make: "Mercury",
    models: "Topaz,Colony Park,Sable,Grand Marquis,Cougar,Tracer",
  },
  {
    year: 1990,
    make: "Mitsubishi",
    models: "Eclipse,Mirage,Precis,Van,Sigma,Montero,Galant",
  },
  {
    year: 1990,
    make: "Nissan",
    models: "240SX,Pulsar NX,Sentra,Pathfinder,Axxess,Tsuru,Micra,Pickup,300ZX,Stanza,Maxima,Hikari",
  },
  {
    year: 1990,
    make: "Oldsmobile",
    models:
      "Cutlass Ciera,Custom Cruiser,Cutlass,Cutlass Cruiser,Cutlass Supreme,Delta 88,98,Silhouette,Cutlass Calais,Toronado",
  },
  {
    year: 1990,
    make: "Peugeot",
    models: "505,405",
  },
  {
    year: 1990,
    make: "Plymouth",
    models: "Acclaim,Sundance,Horizon,Colt,Grand Voyager,Laser,Voyager",
  },
  {
    year: 1990,
    make: "Pontiac",
    models: "Optima,Firebird,Grand Prix,LeMans,Sunbird,Trans Sport,Firefly,Bonneville,Grand Am,Tempest,6000",
  },
  {
    year: 1990,
    make: "Porsche",
    models: "928,944,911",
  },
  {
    year: 1990,
    make: "Rolls-Royce",
    models: "Silver Spirit,Corniche II,Silver Spur",
  },
  {
    year: 1990,
    make: "Saab",
    models: "900,9000",
  },
  {
    year: 1990,
    make: "Sterling",
    models: "827",
  },
  {
    year: 1990,
    make: "Subaru",
    models: "XT,Loyale,Legacy,Justy",
  },
  {
    year: 1990,
    make: "Suzuki",
    models: "Sidekick,Swift",
  },
  {
    year: 1990,
    make: "Toyota",
    models: "Land Cruiser,Tercel,Pickup,Celica,Corolla,Cressida,4Runner,Camry,Supra",
  },
  {
    year: 1990,
    make: "Volkswagen",
    models: "Corrado,Passat,Jetta,Vanagon,Golf,Sedan,Fox,Transporter,Cabriolet",
  },
  {
    year: 1990,
    make: "Volvo",
    models: "240,780,760,740",
  },
  {
    year: 1990,
    make: "Yugo",
    models: "Cabrio,GV",
  },
];
