import React, { useEffect, useState } from "react";
import { Job, PreJobCheckList, PreJobCheckListV2 } from "../../generated/nest-graphql";
import { DetailViewContainer } from "../Contacts/DetailsViewContainer";
import { SelectablePageableEntityTableRenderer } from "../TableViewsPages/SelectablePageableEntityTableRenderer";
import { mappedSnapshotData } from "./constants";
import { getStatus } from "./ColorGradeHelpers";
import { Link } from "react-router-dom";
import { formatDateTime } from "../../lib/functions";

const parseReplaced = (ids: string[], job: Job) => {
  return job.invoice?.services?.some(service => ids?.includes(service.service)) ? "TRUE": "FALSE"
}

interface SnapshotItem {
  service: string,
  thickness?: string | number,
  condition?: string,
  status?: string,
  replaced?: string,
  configIndex: number,
}

const calculatePoints = (item: SnapshotItem) => {
  let points = 0
  if(item.condition?.length) points++
  if(item.status) points++
  if(!Number.isNaN(Number(item.thickness))) points++
  return points
}

const parseCheckistItem = (key: string, value: any, job: Job, items: SnapshotItem[] = []) => {
  let thickness = value?.padsThickness ?? value?.thicknessInches ?? value?.thicknessInches ?? value?.level ?? value?.padThickness?.thickness ?? value?.additionalInfo
  if(value?.innerPadThickness != null && value?.outerPadThickness != null) {
    thickness = value?.innerPadThickness < value?.outerPadThickness ? value?.innerPadThickness : value?.outerPadThickness
  } else if(value?.innerPadThickness != null || value?.outerPadThickness != null) {
    thickness = value?.innerPadThickness ?? value?.outerPadThickness
  }
  let condition = value?.condition ?? value?.partCondition
  const configIndex = mappedSnapshotData.findIndex(([itemKey]) => itemKey.includes(key))
  if(configIndex === -1) return null
  const snapshotItemConfig = mappedSnapshotData[configIndex]?.[1]
  let status = getStatus({
    thickness,
    condition,
    colorGrade: value?.colorGrade,
    job,
    typeName: value?.__typename
  })
  const replaced = parseReplaced(snapshotItemConfig?.services ?? [], job)

  if (!thickness && !condition?.length && !status && replaced === "FALSE") return null
  const result = {
    service: snapshotItemConfig?.title,
    thickness,
    condition,
    status,
    replaced,
    configIndex
  }

  const duplicatedItemIndex = items.findIndex(item => item.configIndex === configIndex)
  if(duplicatedItemIndex !== -1) {
    const beforeItem = items[duplicatedItemIndex]
    let actualPoints = calculatePoints(result)
    let beforePoints = calculatePoints(beforeItem)


    if(!Number.isNaN(Number(result.thickness)) && !Number.isNaN(Number(beforeItem.thickness))) {
      if(Number(result.thickness) < Number(beforeItem.thickness)) actualPoints++
      else beforePoints++
    }

    if(actualPoints > beforePoints) items.splice(duplicatedItemIndex, 1)
    else return
  }

  return result;
}

export const getSnapshotData = (job?: Job) => {
  if (!job) {
    return [];
  }
  let snapshotItems = {}
  const preJobCheckList: PreJobCheckListV2 | PreJobCheckList = JSON.parse(JSON.stringify(job.preJobCheckListV2 ?? job.preJobCheckList))
  if (preJobCheckList.__typename === "PreJobCheckListV2") {
      snapshotItems = {
        ...preJobCheckList,
        ...preJobCheckList.inspectedServices,
      }
  } else {
    snapshotItems = {
      ...preJobCheckList,
    }
  }

  const newSpanshotData = [];
  Object.entries(snapshotItems).forEach(([key, value]) => {
    const item = parseCheckistItem(key, value, job, newSpanshotData);
    if (item) {
      newSpanshotData.push(item);
    }
  })
  return newSpanshotData;
}

export const StatusSnapshot: React.FC<{
  snapshotData?: any;
  job: Job
}> = ({ snapshotData, job }) => {
  return <DetailViewContainer title={"Status Snapshot"} defaultExpanded={true}>
    <SelectablePageableEntityTableRenderer
        title={
        <div className="flex items-center gap-2">
          <Link className="underline" to={`/jobs/${job.id}`}>Job {job.jobNumber}</Link>
          <span className="text-sm">{formatDateTime(job.invoice?.createdAt || job.appointment.startDate)}</span>
        </div>
      }
        currentPage={0}
        columns={[
            { Header: "Service", accessor: "service" },
            { Header: "Thickness", accessor: "thickness" },
            { Header: "Condition", accessor: "condition" },
            { Header: "Status", accessor: "status" },
            { Header: "Replaced", accessor: "replaced" }
        ]}
        numRecords={snapshotData.length}
        data={snapshotData}
        canNextPage={false}
        nextPage={undefined}
        canPrevPage={false}
        prevPage={undefined}
        hiddenSelection
    />
  </DetailViewContainer>

}