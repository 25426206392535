import * as React from "react";
import { PostJobChecklist, PreJobCheckList, PreJobCheckListV2 } from "../../generated/nest-graphql";
import { List, Paper, Typography, ListItem, ListItemText } from "@material-ui/core";
import { cond, includes, T, __, isEmpty, isNil } from "ramda";
import { isArray } from "remeda";

const formatText = (text:string) => text.replace(/([a-z])([A-Z])/g, '$1 $2')

const getValuesAsString = (objectValue:any) => Object.keys(objectValue).filter(item=>item !== "__typename").map(propName=>{
      const value = objectValue[propName];
      return `${formatText(propName)}:  ${isArray(value)? value.length ? value.join(',') : "N/A" : value || 'N/A'}`;
    }).join(', ');

export const JobChecklist: React.FC<{
  preJobCheckList: PreJobCheckList;
  preJobCheckListV2?: PreJobCheckListV2 | null;
  postJobChecklist?: PostJobChecklist;
}> = ({ preJobCheckList, preJobCheckListV2, postJobChecklist }) => {
  const itemNames = preJobCheckList
    ? Object.keys(preJobCheckList).filter((item) => preJobCheckList[item] && item !== "__typename")
    : [];

  const preJobitemNamesV2 = preJobCheckListV2? Object.keys(preJobCheckListV2).filter((item) => preJobCheckListV2[item] && item !== "__typename") : [];
  const inspectedServices = preJobCheckListV2? preJobCheckListV2['inspectedServices'] : {};
  const inspectedServicesNames = Object.keys(inspectedServices).filter((item) => inspectedServices[item] && item !== "__typename");
  const preJobitemServicesNames = preJobitemNamesV2.filter(item=>item!=='inspectedServices');
  const itemNamesV2 = [...preJobitemServicesNames, ...inspectedServicesNames];

  return (
    <>
      {!isNil(preJobCheckListV2) && !isEmpty(preJobCheckListV2)
        ? <PreJobCheckListV2Display itemNamesV2={itemNamesV2} preJobCheckListV2={preJobCheckListV2} inspectedServices={inspectedServices} inspectedServicesNames={inspectedServicesNames}/>
        : <PreJobCheckListDisplay itemNames={itemNames} preJobCheckList={preJobCheckList}/>
      }
      {postJobChecklist && <PostJobChecklistDisplay postJobChecklist={postJobChecklist}/>}
    </>
  );
};

const PostJobChecklistDisplay = ({ postJobChecklist }) => (
  <Paper className="mt-2 mb-3" elevation={3} square>
    <Typography variant="h5" className="p-3 pb-0">
      Post job checklist
    </Typography>
    <List dense>
      {Object.entries(postJobChecklist).map(([key, value] : [string, any], index) => {
        return cond([
          [
            includes(__, ['__typename']),
            () => null,
          ],
          [
            includes(__, ['recommendedServices']),
            () => <PreJobCheckListItemDisplay
            label={formatText(key)}
              value={value?.length ? value.join(', ') : 'N/A'}
              index={index}
            ></PreJobCheckListItemDisplay>,
          ],
          [
            T,
            () => (
              <PreJobCheckListItemDisplay
                label={formatText(key)}
                value={value != null ? value : 'N/A'}
                index={index}
              ></PreJobCheckListItemDisplay>
            )
          ]
        ])(key)
      })}
    </List>
  </Paper>
)

const PreJobCheckListDisplay = ({itemNames, preJobCheckList}) => (
  <Paper className="mt-2 mb-3" elevation={3} square>
    <List dense>
      {itemNames.length === 0 ? (
        <EmptyList />
      ) : (
        itemNames.map((itemName, index) => {
          return cond([
            [
              includes(__, ["tireTread"]),
              () => (
                <PreJobCheckListItemDisplay
                  label={itemName}
                  value={`(FrontLeft, FrontRight, RearLeft, RearRight) = (${
                    preJobCheckList[itemName].frontLeft?.depth || "unavailable"
                  }, ${preJobCheckList[itemName].frontRight?.depth || "unavailable"}, ${
                    preJobCheckList[itemName].rearLeft?.depth || "unavailable"
                  }, ${preJobCheckList[itemName].rearRight?.depth || "unavailable"}) /32"`}
                  index={index}
                ></PreJobCheckListItemDisplay>
              ),
            ],
            [
              includes(__, ["oilLife"]),
              () => (
                <PreJobCheckListItemDisplay
                  label={itemName}
                  value={`Next Oil Change Mileage: ${
                    preJobCheckList[itemName].nextOilChangeMileage.mileage || "unavailable"
                  }`}
                  index={index}
                ></PreJobCheckListItemDisplay>
              ),
            ],
            [
              includes(__, ["frontPadsV2", "rearPadsV2"]),
              () => (
                <PreJobCheckListItemDisplay
                  label={itemName}
                  value={
                    !preJobCheckList[itemName]?.condition
                      ? ""
                      : `${preJobCheckList[itemName]?.condition} (${
                          preJobCheckList[itemName]?.padThickness?.thickness || "unavailable"
                        } mm)`
                  }
                  index={index}
                ></PreJobCheckListItemDisplay>
              ),
            ],
            [
              T,
              () => (
                <PreJobCheckListItemDisplay
                  label={itemName}
                  value={preJobCheckList[itemName].partCondition}
                  index={index}
                ></PreJobCheckListItemDisplay>
              ),
            ],
          ])(itemName);
        })
      )}
    </List>
  </Paper>
)

const PreJobCheckListV2Display = ({ itemNamesV2, preJobCheckListV2, inspectedServices, inspectedServicesNames }) => (
  <Paper className="mt-2 mb-3" elevation={3} square>
    <List dense>
      {itemNamesV2.map((itemName, index) => {
        return cond([
          [
            includes(__, ["odometer"]),
            () => (
              <PreJobCheckListItemDisplay
                label={itemName}
                value={`${preJobCheckListV2[itemName]}`}
                index={index}
              ></PreJobCheckListItemDisplay>
            ),
          ],
          [
            includes(__, ["brakeFluid"]),
            () => (
              <PreJobCheckListItemDisplay
                label={formatText(itemName)}
                value={`PPM: ${preJobCheckListV2[itemName].ppm} | Level: ${preJobCheckListV2[itemName].level}`}
                index={index}
              ></PreJobCheckListItemDisplay>
            ),
          ],
          [
            includes(__, ["frontPads", "rearPads", "frontRotors", "rearRotors", "rearShoes", "rearDrums"]),
            () => (
              <PreJobCheckListItemDisplay
                label={formatText(itemName)}
                value={`${getValuesAsString(preJobCheckListV2[itemName])}`}
                index={index}
              ></PreJobCheckListItemDisplay>
            ),
          ],
          [
            includes(__, ["privateNotes"]),
            () => (
              <PreJobCheckListItemDisplay
                label={formatText(itemName)}
                value={`${preJobCheckListV2[itemName]}`}
                index={index}
              ></PreJobCheckListItemDisplay>
            ),
          ],
          [
            includes(__, inspectedServicesNames),
            () => (
              <PreJobCheckListItemDisplay
                label={formatText(itemName)}
                value={`${inspectedServices[itemName]?.included? getValuesAsString({colorGrade: inspectedServices[itemName].colorGrade, notes: inspectedServices[itemName].notes}) : 'N/A'}`}
                index={index}
              ></PreJobCheckListItemDisplay>
            ),
          ],
          [
            T,
            () => (
              <PreJobCheckListItemDisplay
                label={formatText(itemName)}
                value={"N/A"}
                index={index}
              ></PreJobCheckListItemDisplay>
            ),
          ],
        ])(itemName);
      })}
    </List>
  </Paper>
)

const EmptyList = () => (
  <div className="flex content-center justify-center p-4">
    <Typography variant="body2">No Checklist Available</Typography>
  </div>
);

const PreJobCheckListItemDisplay: React.FC<{ label: any; value: any; index: number }> = ({ label, value, index }) => {
  return (
    <ListItem button key={index}>
      <ListItemText
        primary={
          <Typography className={"block"} component={"div"} style={{ textTransform: "capitalize" }}>
            {label}
          </Typography>
        }
      />{" "}
      {value}
    </ListItem>
  );
};
