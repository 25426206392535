import { applySpec, path, pipe, prop } from "ramda";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import React from "react";
import { formatDateTime } from "../../lib/functions";
import { Button } from "../Buttons/Button";

type VehicleTableType = {
    id: string;
    make: String,
    model: String,
    year: String,
    vin: String,
    name: String,
    licensePlate: String,
    createdAt: String,
    updatedAt: String;
  };

export const VehicleColumns: Column<VehicleTableType>[] = [
    {
      Header: "Id",
      accessor: "id",
      Cell: (props) => {
        return (
          <Link className={"text-primary"} to={`/vehicles/${props.cell.value}`}>
            {props.cell.value}
          </Link>
        );
      },
    },
    { Header: "Year", accessor: "year" },
    { Header: "Make", accessor: "make" },
    { Header: "Model", accessor: "model" },
    { Header: "Vin", accessor: "vin" },
    { Header: "Created", accessor: "createdAt" },
    { Header: "Updated", accessor: "updatedAt" },
    /*{
      Header: "Actions",
      Cell: (props) => {
        return (
          <Button onClick={() => true/*setEnableAddJob(true)}>Create Inquiry Request</Button>
        );
      },
    },*/
  ];


export const vehicleToVehicleColumns = applySpec({
    id: prop("id"),
    make: prop("make"),
    model: prop("model"),
    year: prop("year"),
    vin: prop("vin"),
    createdAt: pipe(prop("createdAt"), formatDateTime),
    updatedAt: pipe(prop("updatedAt"), formatDateTime),
  });
  