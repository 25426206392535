import { useState } from "react";
import * as Sentry from "@sentry/browser";
import { useAuth0 } from "@auth0/auth0-react";
import { nhtsaDecodeVinFlat } from "../lib/vinDecoder";

export const useVinDecoder = () => {
  const [vinInfo, setVinInfo] = useState<{
    make: string;
    model: string;
    year: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { user } = useAuth0();
  const fetchVinInfo = async ({ vin, year }: { vin: string; year?: string }) => {
    if (loading) return;
    setLoading(true);
    setErrorMessage(null);
    try {
      const decodedVinResponse = await nhtsaDecodeVinFlat({
        vin,
        modelYear: year,
      });
      const foundInfo =
        decodedVinResponse?.Results?.length > 0 &&
        decodedVinResponse.Results[0]?.Make &&
        decodedVinResponse.Results[0]?.Model &&
        decodedVinResponse.Results[0]?.ModelYear;

      if (foundInfo) {
        const result = decodedVinResponse.Results[0];
        const vinInfo = {
          make: result.Make,
          model: result.Model,
          year: result.ModelYear,
        };
        setVinInfo(vinInfo);
      } else {
        Sentry.captureMessage("VIN vehicle information not found", {
          level: Sentry.Severity.Info,
          user: {
            email: user?.email,
          },
          extra: {
            vin,
            decodedVinResponse,
          },
        });
        setErrorMessage(
          "Could not decode this VIN. If you still belive it is correct, please verify it on some external VIN checker."
        );
      }
    } catch (e) {
      Sentry.captureException(e, {
        user: {
          email: user?.email,
        },
        extra: {
          message: "Error decoding VIN",
          vin,
        },
      });
      setErrorMessage(
        "Could not decode this VIN. If you believe the VIN is correct, please verify it on some external vin checker."
      );
    } finally {
      setLoading(false);
    }
  };

  return {
    vinInfo,
    loading,
    setVinInfo,
    errorMessage,
    setErrorMessage,
    fetchVinInfo,
  };
};
