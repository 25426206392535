import { useQuery } from "@apollo/client";
import { Helmet } from "react-helmet";
import { Chip } from "@material-ui/core";
import { path, prop, indexBy, isEmpty, isNil } from "ramda";
import * as React from "react";
import { Query, QueryGetJobArgs } from "../../generated/nest-graphql";
import { GET_JOB } from "../../graphql/queries/getJob";

export const DiagnosticsTab: React.FC<{ jobId: string }> = ({ jobId }) => {
  const { data } = useQuery<Query, QueryGetJobArgs>(GET_JOB, {
    variables: {
      id: jobId,
    },
  });

  const diagnosticsQuestionsAnswers = data?.getJob?.diagnosticsQuestionsAnswers ?? null;

  return (
    <div className="flex flex-row">
      <Helmet>
        <title>Diagnostics Questions</title>
      </Helmet>
      <div className={"flex-1 mr-6"}>
        {path(["getJob", "mHelpId"], data) && (
          <div className="my-2 flex flex-row justify-end">
            <Chip label={"Imported from MHelp"} color={"primary"} />
          </div>
        )}
        <div className="flex flex-1 flex-col">
          {diagnosticsQuestionsAnswers && !isEmpty(diagnosticsQuestionsAnswers) ? (
            diagnosticsQuestionsAnswers.map((qa) => {
              const translatedSymptoms =
                qa.relatedSymptoms?.map((symptom) => symptomOptionsDict[symptom]?.label ?? symptom) || [];
              return (
                <div className="mb-4 flex flex-col">
                  <p className="font-bold">Question: {qa.question}</p>
                  <p>Answer: {qa.answer}</p>
                  <p>
                    Related symptoms:{" "}
                    {isEmpty(translatedSymptoms) || isNil(translatedSymptoms) ? "-" : translatedSymptoms.toString()}
                  </p>
                </div>
              );
            })
          ) : (
            <p>No diagnostics questions have been answered for this job.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export const symptomOptions = [
  { label: "I need new pads / shoes", value: "newPadsShoes" },
  { label: "I need new rotors / drums", value: "newRotorsDrums" },
  { label: "Squeaking / squealing", value: "squeakingSquealing" },
  { label: "Grinding / scraping", value: "grindingScraping" },
  { label: "Vibrating / shaking / pulsing", value: "vibratingShakingPulsing" },
  { label: "Pedal pressure issue", value: "pedalPressureIssue" },
  { label: "Something else", value: "somethingElse" },
];

const symptomOptionsDict = indexBy(prop("value"), symptomOptions);
